import { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import { Box, Button, Container, Grid, Stack, Pagination, FormControl, InputAdornment, OutlinedInput, Skeleton, Typography, CardContent, Avatar, InputLabel, Select, MenuItem } from '@mui/material';
import { fDate } from 'src/utils/format-time';
import Iconify from 'src/components/iconify';
import { useLocales } from 'src/locales';
import { useGetAdsTxtQuery } from 'src/context/api/competitorsMonitoring/api';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/system';
import { usePreviousLocation } from 'src/components/previousLocationContext';
import { setFilterCountry, setFilterDevice, setFilterKeyword, setFilterLoadedCountry, setFilterLoadedDevice, setFilterLoadedKeyword } from 'src/context/reducers/filter-settings/filterSlice';

export default function TextAds({ domain, workspaceTable }: any) {
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const adsPerPage = 12; // Number of ads per page
    const filterStore = useSelector((state: any) => state.filters);
    const [sortBy, setSortBy] = useState("Impression Share");
    const [sortOrder, setSortOrder] = useState('desc');
    const theme = useTheme();
    const { t } = useLocales();
    const dispatch = useDispatch()
    const prevLocation = usePreviousLocation();

    const { data: ads, error: statsError, isFetching } = useGetAdsTxtQuery({
        startDate: filterStore.start ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        domain: domain,
        device: filterStore.devices.map((device: string) => device.toLowerCase()),
        countries: filterStore.countries,
        keywords: filterStore.keywords,
        workspaceTable: workspaceTable,
        platform: filterStore?.platforms
    });

    useEffect(() => {
        if (prevLocation === "/dash/brand-wayback") {
            dispatch(setFilterLoadedKeyword([]));
            dispatch(setFilterKeyword([]))
            dispatch(setFilterDevice([]))
            dispatch(setFilterLoadedDevice([]))
            dispatch(setFilterCountry([]))
            dispatch(setFilterLoadedCountry([]))
        }
    }, [prevLocation])

    const [selectedAds, setSelectedAds]: any = useState([]);


    const urlTransformer = (url: any) => {
        const transformUrl = (url: any) => {
            try {
                // Parse the URL
                const parsedUrl = new URL(url);

                // Extract the scheme and hostname
                const scheme = parsedUrl.protocol.slice(0, -1); // remove trailing colon
                const hostname = parsedUrl.hostname;

                // Extract the path and split it to get the language code
                const pathParts = parsedUrl.pathname.split('/').filter(part => part);
                const languageCode = pathParts[1] || '';

                // Construct the desired output
                return `${scheme}://${hostname} > ${languageCode}`;
            } catch (error) {
                console.error("Invalid URL", error);
                return "Invalid URL";
            }
        };

        return (

            transformUrl(url)

        );
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    };

    const handlePageChange = (event: any, value: any) => {
        setCurrentPage(value);
    };

    const handleAdClick = (add: any) => {
        setSelectedAds((prevSelectedAds: any) =>
            prevSelectedAds.includes(add)
                ? prevSelectedAds.filter((item: any) => item !== add)
                : [...prevSelectedAds, add]
        );
    };
    const handleAdHoverEnter = (index: any) => {
        setSelectedAds([index]);  // Sets the current hovered ad
    };

    const handleAdHoverLeave = () => {
        setSelectedAds([]);  // Resets when mouse leaves
    };
    const handleHover = (index: any) => {
        setSelectedAds([index]);
    };

    const handleHoverOut = () => {
        setSelectedAds([]);
    };


    const handleSortOrderChange = (event: any) => {
        setSortOrder(event.target.value);
    };

    const handleSortChange = (event: any) => {
        setSortBy(event.target.value);
    };


    const filteredAds = ads?.filter((ad: any) =>
        (ad.title ?? "").toLowerCase().includes(searchQuery.toLowerCase()) || (ad.description ?? "").toLowerCase().includes(searchQuery.toLowerCase())
    ) || [];





    const sortedAds = [...filteredAds].sort((a, b) => {
        const compareValue = (valueA: number, valueB: number) => {
            if (sortOrder === 'asc') {
                return valueA - valueB;
            } else {
                return valueB - valueA;
            }
        };

        switch (sortBy) {
            case 'Impression Share':
                return compareValue(a.impression_share, b.impression_share);
            case 'Average Position':
                return compareValue(a.position, b.position);
            default:
                return 0;
        }
    });

    const formatToOneDecimal = (number: any) => {
        return Number(number.toFixed(1));
    };

    const indexOfLastAd = currentPage * adsPerPage;
    const indexOfFirstAd = indexOfLastAd - adsPerPage;
    const currentAds = sortedAds.slice(indexOfFirstAd, indexOfLastAd);

    return (
        <Container maxWidth="xl" sx={{ marginTop: 5 }} >
            <Stack spacing={3} >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <FormControl sx={{ my: 1, p: 0 }}>
                        <OutlinedInput
                            sx={{ width: '100%' }}
                            id="outlined-adornment-amount"
                            placeholder={t('dashboard.textAdsPage.search_placeholder')}
                            size="small"
                            value={searchQuery}
                            onChange={handleSearch}
                            startAdornment={<InputAdornment position="start"><Iconify icon={"eva:search-fill"} /></InputAdornment>}
                        />
                    </FormControl>

                    <Box gap={2} sx={{ display: 'flex', }}>
                        <FormControl sx={{ minWidth: 120, }}>
                            <InputLabel id="sort-by-label">Sort by</InputLabel>
                            <Select
                                labelId="sort-by-label"
                                id="sort-by"
                                size='small'
                                value={sortBy}
                                label="Sort by"
                                onChange={handleSortChange}
                            >
                                <MenuItem value="Impression Share" >Impression Share</MenuItem>
                                <MenuItem value="Average Position">Average Position</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl sx={{ minWidth: 120 }}>
                            <InputLabel id="sort-order-label">Order</InputLabel>
                            <Select
                                labelId="sort-order-label"
                                id="sort-order"
                                size='small'
                                value={sortOrder}
                                label="Order"
                                onChange={handleSortOrderChange}
                            >
                                <MenuItem value="asc">Ascending</MenuItem>
                                <MenuItem value="desc">Descending</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>

                </Box>
            </Stack>
            <Grid spacing={3} container sx={{ mt: 2 }}>
                {isFetching || (!ads && !statsError) ? (
                    Array.from(new Array(adsPerPage)).map((_, index) => (
                        <Grid item key={index} xs={12} sm={6} >
                            <Skeleton variant="rectangular" width="100%" height={230} style={{ borderRadius: 10 }} />
                        </Grid>
                    ))
                ) : (

                    currentAds.length > 0 ? (
                        currentAds.map((ad: any, index: number) => (
                            <Grid item key={index} xs={12} sm={6}  >
                                <Card
                                    sx={{
                                        flex: 1,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        height: 230,
                                        bgcolor: selectedAds.includes(index) ? theme.palette.mode === "dark" ? '#1c252e' : 'white' : 'none',
                                        color: selectedAds.includes(index) ? theme.palette.mode === "dark" ? '#fff' : "#202124" : '#000',
                                        transition: 'transform 0.3s ease',
                                        '&:hover': {
                                            transform: 'scale(1.02)'
                                        },
                                        overflow: 'hidden',
                                        cursor: 'pointer'
                                    }}
                                    onMouseEnter={() => handleAdHoverEnter(index)}
                                    onMouseLeave={() => handleAdHoverLeave()}
                                >
                                    {selectedAds.includes(index) ? (
                                        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', }}>
                                            <CardContent sx={{ height: '100%', width: '100%' }}>
                                                <Stack direction={'row'} spacing={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', height: '100%', width: '100%' }}>
                                                    <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column', alignItems: 'center', height: '100%', justifyContent: 'center', textAlign: 'center', border: 2, flexGrow: 1, flexBasis: 0, flexShrink: 1, borderRadius: 2, borderColor: theme.palette.mode === "dark" ? "#212a33" : "#6f777d", p: 3 }}>
                                                        <Iconify icon="mdi:eye" width={30} color={theme.palette.mode === 'dark' ? '#fff' : '#1C252E'} />
                                                        <Typography variant="h6" color={theme.palette.mode === 'dark' ? '#fff' : '#1C252E'}>
                                                            {(ad?.impression_share * 100)?.toFixed(2) + "%" ?? "N/A"}
                                                        </Typography>
                                                        <Typography variant="subtitle2" color="grey">
                                                            {t('dashboard.textAdsPage.Ad_impressionShare')}
                                                        </Typography>
                                                    </Box>
                                                    <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column', alignItems: 'center', height: '100%', justifyContent: 'center', textAlign: 'center', border: 2, flexGrow: 1, flexBasis: 0, flexShrink: 1, borderRadius: 2, borderColor: theme.palette.mode === "dark" ? "#212a33" : "#6f777d", p: 3 }}>
                                                        <Iconify icon="mdi:eye" width={30} color={theme.palette.mode === 'dark' ? '#fff' : '#1C252E'} />
                                                        <Typography variant="h6" color={theme.palette.mode === 'dark' ? '#fff' : '#1C252E'}>
                                                            {ad?.item_position}
                                                        </Typography>
                                                        <Typography variant="subtitle2" color="grey">
                                                            {t('dashboard.textAdsPage.Ad_average_position')}
                                                        </Typography>
                                                    </Box>
                                                    <Button onClick={() => window.open(ad?.link)} sx={{ display: 'flex', gap: 2, flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', border: 2, flexGrow: 1, height: '100%', flexBasis: 0, flexShrink: 1, borderRadius: 2, borderColor: theme.palette.mode === "dark" ? "#212a33" : "#6f777d", p: 3 }}>
                                                        <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', width: '100%', height: '100%', borderRadius: 2, }}>
                                                            <Iconify sx={{ width: 30, height: 30 }} icon={"mingcute:external-link-fill"} color={theme.palette.mode === 'dark' ? '#fff' : '#1C252E'} />
                                                            <Typography variant="body2" fontSize={15} sx={{ fontWeight: 'bold', mt: 1 }}>
                                                                {t('dashboard.textAdsPage.Visit')}
                                                            </Typography>
                                                            <Typography variant="body2" sx={{ mt: 1, color: '#6f777d' }}>
                                                                {t('dashboard.textAdsPage.Ad_landing_page')}
                                                            </Typography>
                                                        </Box>
                                                    </Button>
                                                </Stack>



                                            </CardContent>
                                        </Box>
                                    ) : (
                                        <Box sx={{ backgroundColor: theme.palette.mode === 'dark' ? '#1C252E' : 'white', borderRadius: 2, p: 2, height: 230, }}>

                                            <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: theme.palette.mode === 'dark' ? '#fff' : '#1C252E', }} gutterBottom>{t('dashboard.textAdsPage.sponsorised')}</Typography>
                                            <Stack direction={'row'} spacing={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                                <Avatar sx={{ width: 24, height: 24, border: '1px solid #dbdde1', backgroundColor: '#f1f3f4' }} src={ad?.link_base64 || "/assets/no_img.png"} />
                                                <Box gap={1} >
                                                    <Typography variant="body1" sx={{ fontSize: 12, color: theme.palette.mode === 'dark' ? '#ffffff' : '#000' }} >{ad.domain}</Typography>
                                                    <Typography variant="body1" sx={{ fontSize: 12, color: theme.palette.mode === 'dark' ? '#ffffff' : '#000' }} >{urlTransformer(ad.link)}</Typography>
                                                </Box>

                                            </Stack>
                                            <Typography variant='body2' sx={{ fontSize: 18, color: theme.palette.mode === 'dark' ? '#8AB4F8' : '#1558D6', }}>{ad.title}</Typography>
                                            <Typography variant="body2" sx={{
                                                color: theme.palette.mode === 'dark' ? '#fff' : '#1C252E',
                                                display: '-webkit-box',
                                                WebkitBoxOrient: 'vertical',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                WebkitLineClamp: 3,
                                                lineClamp: 3
                                            }}
                                                gutterBottom
                                            >
                                                {ad.description}
                                            </Typography>

                                        </Box>
                                    )}
                                </Card>
                            </Grid>
                        ))
                    ) : (
                        <Grid item xs={12}>
                            <Typography variant="h6" color="text.secondary" align="center">
                                Aucun résultat trouvé.
                            </Typography>
                        </Grid>
                    )
                )}
            </Grid>
            {currentAds?.length !== 0 &&
                <Stack spacing={2} sx={{ mt: 5 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                        <Pagination
                            count={Math.ceil(filteredAds.length / adsPerPage)}
                            page={currentPage}
                            onChange={handlePageChange}
                            color="primary"
                        />
                    </Box>
                </Stack>
            }
        </Container>
    );
}