import { createApi } from "@reduxjs/toolkit/query/react";
import fetchBaseQueryCSRF from "../helper";

const trafficAnalysis = createApi({
  baseQuery: fetchBaseQueryCSRF({ baseUrl: CROSS_API_URL }),
  reducerPath: 'api.trafficAnalysis',
  tagTypes: ['trafficAnalysis'],
  endpoints: (builder) => ({
    getStatsDash: builder.query<any, { compareStartDate: string | null, compareEndDate: string | null, startDate: string | null, endDate: string | null, device: string[] | null, countries: string[], keywords: string[], timestep: string, workspaceId: any | null, platform: any }>({
      query: ({ startDate, endDate, device, countries, keywords, timestep, compareStartDate, compareEndDate, workspaceId, platform }) => {
        const url = `/trafficAnalysis/getStats`;
        const body = {
          startDate,
          endDate,
          compareStartDate,
          compareEndDate,
          device,
          countries,
          keywords,
          timestep,
          workspaceId,
          platform
        };

        return {
          url,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
          },
        };
      },
    }),
    getStatsDashCustom: builder.query<any, { compareStartDate: string | null, compareEndDate: string | null, startDate: string | null, endDate: string | null, device: string[] | null, countries: string[], keywords: string[], timestep: string, workspaceId: any | null, platform: any }>({
      query: ({ startDate, endDate, device, countries, keywords, timestep, compareStartDate, compareEndDate, workspaceId, platform }) => {
        const url = `/trafficAnalysis/getStatsCustom`;
        const body = {
          startDate,
          endDate,
          compareStartDate,
          compareEndDate,
          device,
          countries,
          keywords,
          timestep,
          platform,
          workspaceId
        };

        return {
          url,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
          },
        };
      },
    }),
    getKeywords: builder.query<any, { startDate: string | null, endDate: string | null, device: string[] | null, countries: string[], workspaceId: any, platforms: any }>({
      query: ({ startDate, endDate, device, countries, workspaceId, platforms }) => {
        const url = `/trafficAnalysis/getKeywords`;
        const body = {
          startDate,
          endDate,
          device,
          countries,
          workspaceId,
          platforms
        };

        return {
          url,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
          },
        };
      },
    }),
    getKeywordsBrandWayback: builder.query<any, { startDate: string | null, endDate: string | null, device: string[] | null, countries: string[], workspaceId: any, platforms: any }>({
      query: ({ startDate, endDate, device, countries, workspaceId, platforms }) => {
        const url = `/serpHistory/getKeywords`;
        const body = {
          startDate,
          endDate,
          device,
          countries,
          workspaceId,
          platforms
        };

        return {
          url,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
          },
        };
      },
    }),
    getCountriesBrandWayback: builder.query<any, { startDate: string | null, endDate: string | null, device: string[] | null, keywords: string[], workspaceId: any, platforms: any }>({
      query: ({ startDate, endDate, device, keywords, workspaceId, platforms }) => {
        const url = `/serpHistory/getCountries`;
        const body = {
          startDate,
          endDate,
          device,
          keywords,
          workspaceId,
          platforms,
        };

        return {
          url,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
          },
        };
      },
    }),
    getCountries: builder.query<any, { startDate: string | null, endDate: string | null, device: string[] | null, keywords: string[], workspaceId: any, platforms: any }>({
      query: ({ startDate, endDate, device, keywords, workspaceId, platforms }) => {
        const url = `/trafficAnalysis/getCountries`;
        const body = {
          startDate,
          endDate,
          device,
          keywords,
          workspaceId,
          platforms,
        };

        return {
          url,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
          },
        };
      },
    }),
    getDevicesBrandWayback: builder.query<any, { startDate: string | null, endDate: string | null, countries: any, keywords: string[], workspaceId: any, platforms: any }>({
      query: ({ startDate, endDate, countries, keywords, workspaceId, platforms }) => {
        const url = `/serpHistory/getDevices`;
        const body = {
          startDate,
          endDate,
          keywords,
          countries,
          workspaceId,
          platforms
        };

        return {
          url,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
          },
        };
      },
    }),
    getDevices: builder.query<any, { startDate: string | null, endDate: string | null, countries: string[], keywords: string[], workspaceId: any, platforms: any }>({
      query: ({ startDate, endDate, countries, keywords, workspaceId, platforms }) => {
        const url = `/trafficAnalysis/getDevices`;
        const body = {
          startDate,
          endDate,
          keywords,
          countries,
          workspaceId,
          platforms
        };

        return {
          url,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
          },
        };
      },
    }),
    getPlatforms: builder.query<any, { startDate: string | null, endDate: string | null, countries: string[], keywords: string[], devices: string[], workspaceId: any }>({
      query: ({ startDate, endDate, countries, keywords, devices, workspaceId }) => {
        const url = `/trafficAnalysis/getPlatforms`;
        const body = {
          startDate,
          endDate,
          keywords,
          countries,
          devices,
          workspaceId
        };

        return {
          url,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
          },
        };
      },
    }),
  }),
});

export const {
  useGetStatsDashQuery,
  useGetCountriesQuery,
  useGetDevicesQuery,
  useGetKeywordsQuery,
  useGetStatsDashCustomQuery,
  useGetPlatformsQuery,
  useGetKeywordsBrandWaybackQuery,
  useGetCountriesBrandWaybackQuery,
  useGetDevicesBrandWaybackQuery
} = trafficAnalysis;

export default trafficAnalysis;
