import { useState, useCallback } from 'react';
import {
  DataGridPro,
  GridColDef,
  GridColumnVisibilityModel,
} from '@mui/x-data-grid-pro';
import { Box, LinearProgress, Stack, Typography } from '@mui/material';
import { fPercent } from 'src/utils/format-number';
import { useDispatch, useSelector } from 'react-redux';
import { setFilterKeyword } from 'src/context/reducers/filter-settings/filterSlice';
import { filter } from 'lodash';
import { useCurrentWorkspace } from 'src/context/reducers/app-settings';

const createColumns = (): GridColDef[] => [
  {
    field: 'search_term',
    headerName: "Search Term",
    flex: 1,
  },
  {
    field: 'presenceRate',
    headerName: "Presence Rate",
    flex: 1,
    width: 160,

    renderCell: (params) => (
      <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
        <LinearProgress
          value={params.row.presenceRate || 0}
          variant="determinate"
          color={
            (Number.isNaN(params.row.presenceRate) && 'error') ||
            (params.row.presenceRate < 30 && 'error') ||
            (params.row.presenceRate >= 30 && params.row.presenceRate < 70 && 'warning') ||
            'primary'
          }
          sx={{ width: 1, height: 6 }}
        />
        <Typography variant="caption" sx={{ width: 80 }}>
          {params.row.presenceRate ? fPercent(params.row.presenceRate) : '0%'}
        </Typography>
      </Stack>
    ),
  },
];

const customColumns = (): GridColDef[] => [
  {
    field: 'search_term',
    headerName: "Search Term",
    flex: 1,
  },
  {
    field: 'presenceRate',
    headerName: "Presence Rate",
    flex: 1,
    width: 160,
    renderCell: (params) => (
      <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
        <LinearProgress
          value={params.row.presenceRate || 0}
          variant="determinate"
          color={
            (Number.isNaN(params.row.presenceRate) && 'error') ||
            (params.row.presenceRate < 30 && 'error') ||
            (params.row.presenceRate >= 30 && params.row.presenceRate < 70 && 'warning') ||
            'primary'
          }
          sx={{ width: 1, height: 6 }}
        />
        <Typography variant="caption" sx={{ width: 80 }}>
          {params.row.presenceRate ? fPercent(params.row.presenceRate) : '0%'}
        </Typography>
      </Stack>
    ),
  },
  {

    field: 'presenceSearchRate',
    headerName: "Presence Search Rate",
    flex: 1,
    width: 160,
    renderCell: (params) => (
      <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
        <LinearProgress
          value={params.row.presenceSearchRate || 0}
          variant="determinate"
          color={
            (Number.isNaN(params.row.presenceSearchRate) && 'error') ||
            (params.row.presenceSearchRate < 30 && 'error') ||
            (params.row.presenceSearchRate >= 30 && params.row.presenceSearchRate < 70 && 'warning') ||
            'primary'
          }
          sx={{ width: 1, height: 6 }}
        />
        <Typography variant="caption" sx={{ width: 80 }}>
          {params.row.presenceSearchRate ? fPercent(params.row.presenceSearchRate) : '0%'}
        </Typography>
      </Stack>
    )


  },
  {
    field: 'presenceShoppingRate',
    headerName: "Presence Shopping Rate",
    flex: 1,
    width: 160,
    renderCell: (params) => (
      <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
        <LinearProgress
          value={params.row.presenceShoppingRate || 0}
          variant="determinate"
          color={
            (Number.isNaN(params.row.presenceShoppingRate) && 'error') ||
            (params.row.presenceShoppingRate < 30 && 'error') ||
            (params.row.presenceShoppingRate >= 30 && params.row.presenceShoppingRate < 70 && 'warning') ||
            'primary'
          }
          sx={{ width: 1, height: 6 }}
        />
        <Typography variant="caption" sx={{ width: 80 }}>
          {params.row.presenceShoppingRate ? fPercent(params.row.presenceShoppingRate) : '0%'}
        </Typography>
      </Stack>
    )

    ,
  },
]

const customSearchColumns = (): GridColDef[] => [
  {
    field: 'search_term',
    headerName: "Search Term",
    flex: 1,
  },
  {
    field: 'presenceRate',
    headerName: "Presence Rate",
    flex: 1,
    width: 160,
    renderCell: (params) => (
      <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
        <LinearProgress
          value={params.row.presenceRate || 0}
          variant="determinate"
          color={
            (Number.isNaN(params.row.presenceRate) && 'error') ||
            (params.row.presenceRate < 30 && 'error') ||
            (params.row.presenceRate >= 30 && params.row.presenceRate < 70 && 'warning') ||
            'primary'
          }
          sx={{ width: 1, height: 6 }}
        />
        <Typography variant="caption" sx={{ width: 80 }}>
          {params.row.presenceRate ? fPercent(params.row.presenceRate) : '0%'}
        </Typography>
      </Stack>
    ),
  },
  {

    field: 'presenceSearchRate',
    headerName: "Presence Search Rate",
    flex: 1,
    width: 160,
    renderCell: (params) => (

      <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
        <LinearProgress
          value={params.row.presenceSearchRate || 0}
          variant="determinate"
          color={
            (Number.isNaN(params.row.presenceSearchRate) && 'error') ||
            (params.row.presenceSearchRate < 30 && 'error') ||
            (params.row.presenceSearchRate >= 30 && params.row.presenceSearchRate < 70 && 'warning') ||
            'primary'
          }
          sx={{ width: 1, height: 6 }}
        />
        <Typography variant="caption" sx={{ width: 80 }}>
          {params.row.presenceSearchRate ? fPercent(params.row.presenceSearchRate) : '0%'}
        </Typography>
      </Stack>
    ),
  }
]
const customShoppingColumns = (): GridColDef[] => [
  {
    field: 'search_term',
    headerName: "Search Term",
    flex: 1,
  },
  {
    field: 'presenceRate',
    headerName: "Presence Rate",
    flex: 1,
    width: 160,
    renderCell: (params) => (
      <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
        <LinearProgress
          value={params.row.presenceRate || 0}
          variant="determinate"
          color={
            (Number.isNaN(params.row.presenceRate) && 'error') ||
            (params.row.presenceRate < 30 && 'error') ||
            (params.row.presenceRate >= 30 && params.row.presenceRate < 70 && 'warning') ||
            'primary'
          }
          sx={{ width: 1, height: 6 }}
        />
        <Typography variant="caption" sx={{ width: 80 }}>
          {params.row.presenceRate ? fPercent(params.row.presenceRate) : '0%'}
        </Typography>
      </Stack>
    ),
  },
  {
    field: 'presenceShoppingRate',
    headerName: "Presence Shopping Rate",
    flex: 1,
    width: 160,
    renderCell: (params) => (
      <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
        <LinearProgress
          value={params.row.presenceShoppingRate || 0}
          variant="determinate"
          color={
            (Number.isNaN(params.row.presenceShoppingRate) && 'error') ||
            (params.row.presenceShoppingRate < 30 && 'error') ||
            (params.row.presenceShoppingRate >= 30 && params.row.presenceShoppingRate < 70 && 'warning') ||
            'primary'
          }
          sx={{ width: 1, height: 6 }}
        />
        <Typography variant="caption" sx={{ width: 80 }}>
          {params.row.presenceShoppingRate ? fPercent(params.row.presenceShoppingRate) : '0%'}
        </Typography>
      </Stack>
    )

  },
]


type Props = {
  data: {
    id: string;
    role: string;
    email: string;
    profile: {
      firstname: string;
      lastname: string;
    }
  }[]
  pageSize: any;
};

export default function DataGridCustomPresence({ data }: Props) {
  const dispatch = useDispatch();
  const filterStore = useSelector((state: any) => state.filters);
  const workspace = useCurrentWorkspace();

  const getColumns = () => {
    7

    const hasPresenceSearch = data?.some((item: any) => item?.presenceSearchRate !== undefined) ?? false;
    const hasPresenceShopping = data?.some((item: any) => item?.presenceShoppingRate !== undefined) ?? false;

    if (workspace?.template === 'E-commerce') {
      if (hasPresenceSearch && !hasPresenceShopping) return customSearchColumns();
      if (hasPresenceShopping && !hasPresenceSearch) return customShoppingColumns();
      if (hasPresenceSearch && hasPresenceShopping) return customColumns();
    }
    return createColumns();
  };
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    id: true,
  });

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 5,
    page: 0,
  });

  const handleChangeColumnVisibilityModel = useCallback((newModel: GridColumnVisibilityModel) => {
    setColumnVisibilityModel(newModel);
  }, []);

  const handlePaginationModelChange = (newModel: any) => {
    setPaginationModel(newModel);
  };

  const handleRowClick = (params: any) => {
    const item = params.row;
    const search_term = item.search_term;

    if (filterStore?.keywords.includes(search_term)) {
      const newKeywords = filter(filterStore?.keywords, (keyword: any) => keyword !== search_term);
      dispatch(setFilterKeyword(newKeywords));
    } else {
      dispatch(setFilterKeyword([search_term]));
    }

  };

  return (
    <Box
      sx={{
        '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus': {
          outline: 'none !important',
        },
        '& .MuiDataGrid-cell': {
          cursor: 'pointer',
        },
        '& .MuiDataGrid-root': {
          '--unstable_DataGrid-radius': '0px',
        },
        '& .css-jr9x4l-MuiTablePagination-displayedRows': {
          'display': 'none!important'
        },
      }}
    >
      <DataGridPro
        checkboxSelection={false}
        disableRowSelectionOnClick
        rows={data}
        pagination
        onRowClick={(params) => handleRowClick(params)}
        paginationModel={paginationModel}
        columns={getColumns()}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={handleChangeColumnVisibilityModel}
        onPaginationModelChange={handlePaginationModelChange}
      />
    </Box>
  );
}