import { useAuth0 } from '@auth0/auth0-react';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { SplashScreen } from 'src/components/loading-screen';
import { useCurrentUserQuery, useSignoutMutation } from 'src/context/api/auth';
import Auth from 'src/pages/auth';
import { useRouter } from 'src/routes/hooks';

const signinPath = '/auth/signin';
const signUpPath = '/auth/password'

const useSession = () => {
  const router = useRouter()
  const [sessionActive, setSessionActive] = useState(false);
  const {data:user} = useCurrentUserQuery()
  const [signout] = useSignoutMutation()
  const [code,setCode]:any = useState(null)

  const disconectUser = async() =>{
    await signout({});
    router.reload()
  }

  
  useEffect(() => {
    if (window.location.pathname.includes('/auth/signin') || window.location.pathname.includes('/auth/password')) {
      return; // Ne pas vérifier la session si on est sur la page de connexion
    } else {

    let intervalRef:any;
    
    const checkSession = async () => {
      try {
        const response = await fetch(`${CROSS_API_URL}`+"/session-status", { credentials: "include" });
    
        if (response.status === 401) {
          setSessionActive(false);
          setCode(response.status)
          clearInterval(intervalRef)
  
          //  Stocker l'info dans localStorage pour alerter les autres onglets
          localStorage.setItem("sessionExpired", Date.now().toString());

          disconectUser()
          
        }else {
          setSessionActive(true);
        }
      } catch (error) {
        setSessionActive(false);
      }
    };

     intervalRef = setInterval(checkSession, 1200000); //Vérifie toutes les  1200000 ms (20min) 
    return () => clearInterval(intervalRef);
  }
  }, [user, sessionActive, window.location.pathname]);

  return {'sessionActive':sessionActive, code:code};
};


export default function AuthGuard(props: Props) {
  const router = useRouter()
  const [auth, setAuth] = useState(false);
  const [loading, setLoading] = useState(true);
  const { isAuthenticated, user: auth0User, isLoading: isLoadingAuth0 } = useAuth0();
  const { data: user, isLoading, isFetching, isError, error } = useCurrentUserQuery();

  const [signout] = useSignoutMutation();

const disconectUser = async() =>{
  await signout({});
  router.reload()
}

  const sessionActive = useSession();
  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === "sessionExpired") {
        enqueueSnackbar("Session expired, please sign in again!", { variant: "warning" });
        // Rediriger proprement vers la page de connexion
        disconectUser();
      }
    };
  
    window.addEventListener("storage", handleStorageChange);
  
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  
   useEffect(() => {
    
    
    if ((!sessionActive.sessionActive|| !user) && window.location.pathname !== signinPath) {
     
      if (window.location.pathname == signinPath || window.location.pathname == signUpPath) {
        return 
      } else {
        if(!sessionActive.sessionActive && sessionActive?.code === 401 ){
          enqueueSnackbar('Session expired, please sign in again!')
           disconectUser()
        }
      }
    }
    },[sessionActive,user,window.location.pathname])

  const checkAuth = useCallback(() => {
    if (isAuthenticated && auth0User) {
      setAuth(true)
      setLoading(isLoadingAuth0)
    } else {
      setAuth(!error)
      setLoading(isLoading)

    }

  }, [user, error, auth0User])

  useEffect(() => {
    
    checkAuth()
  }, [user, error]);

  if (loading) {
    return <SplashScreen />
  }


  if (!auth) {
    return <Auth />

  }


  return <>{props.children}</>
}

interface Props {
  children: React.ReactNode;
}
