import { createApi } from "@reduxjs/toolkit/query/react";
import fetchBaseQueryCSRF from "../helper";

const serpHistory = createApi({
  baseQuery: fetchBaseQueryCSRF({ baseUrl: CROSS_API_URL }),
  reducerPath: 'api.serpHistory',
  tagTypes: ['serpHistory'],
  endpoints: (builder) => ({
    getMinDateSerp: builder.query<any, { workspaceId: any }>({
      query: ({ workspaceId }) => ({
        url: `/serpHistory/getMinDateSerp`,
        method: 'POST',
        body: { workspaceId },
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'max-age=0',
        },
      }),
    }),    
    getSerpHistory: builder.query<
      any,
      {
        startDate: string | null;
        endDate: string | null;
        countries: string[];
        keywords: string[];
        devices: string[];
        workspaceId: any;
        offset?: number;
        pageSize?: number;
        platform: any,
      }
    >({
      query: ({ startDate, endDate, countries, keywords, devices, workspaceId, offset, pageSize, platform }) => {
        const url = `/serpHistory/`;
        const body = {
          startDate,
          endDate,
          keywords,
          countries,
          devices,
          workspaceId,
          offset,
          pageSize,
          platform
        };
        return {
          url,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
          },
        };
      },
    }),
  }),
});

export const {
  useLazyGetSerpHistoryQuery,
  useGetMinDateSerpQuery,
} = serpHistory;

export default serpHistory;
