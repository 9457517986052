import { useTheme } from "@mui/material/styles";
import { Paper, Box, Typography, Skeleton, Tooltip } from "@mui/material";
import { Carousel, CarouselArrowNumberButtons } from "src/components/carousel-serp";
import { useCarousel } from "src/hooks/use-carousel";
import { useLazyGetSerpHistoryQuery, useGetMinDateSerpQuery } from "src/context/api/serpHistory/api";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setFilterLoadedPlatform,
  setFilterMinDatePicker,
  setFilterPlatform,
} from "src/context/reducers/filter-settings/filterSlice";
import { useLocales } from "src/locales";
import { useCurrentWorkspace } from "src/context/reducers/app-settings";
import { useGetPlatformsQuery } from "src/context/api/trafficAnalysis/api";
import { fDate } from "src/utils/format-time";

export default function HistorySerp() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { currentLang } = useLocales();

  // Sélecteurs et workspace
  const filterStore = useSelector((state: any) => state.filters);
  const workspace: any = useCurrentWorkspace();
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const skipQuery = !filterStore.start || !filterStore.end || !workspace?.id;
  const [pageLoading, setPageLoading] = useState(true);

  // Requêtes API
  const [trigger] = useLazyGetSerpHistoryQuery();
  const { data: minDatePicker } = useGetMinDateSerpQuery({ workspaceId: workspace?.id });
  const { data: platformsData } = useGetPlatformsQuery(
    {
      startDate: filterStore.start ? fDate(filterStore.start, "yyyy-MM-dd") : null,
      endDate: filterStore.end ? fDate(filterStore.end, "yyyy-MM-dd") : null,
      devices: [],
      countries: [],
      keywords: [],
      workspaceId: workspace?.id,
    },
    { skip: skipQuery }
  );

  // Pagination
  const initialPageSize = 5;
  const pageSize = 5;
  const [hasMore, setHasMore] = useState(true);
  const offsetRef = useRef(0);

  // Données SERP et listes d'URLs
  const [allSerpHistory, setAllSerpHistory]: any = useState([]);
  const [iframeSrcList, setIframeSrcList] = useState([]);
  const [thumbsSrcList, setThumbsSrcList] = useState([]);

  // Slide active dans le carousel
  const [selectedIndex, setSelectedIndex] = useState(0);

  // Variables du lazy load
  const [loadingMore, setLoadingMore] = useState(false);
  const lastLazyLoadOffset: any = useRef(null);
  const sentinelRef = useRef(null);

  // Extraction des filtres
  const { start, end, countries, keywords, platforms, devices } = filterStore;

  // ----------------------------------------------------------------------
  // 1) Mise à jour du store avec minDatePicker
  // ----------------------------------------------------------------------
  useEffect(() => {
    if (minDatePicker) {
      dispatch(setFilterMinDatePicker(minDatePicker.minDate));
    }
  }, [minDatePicker, dispatch]);

  // ----------------------------------------------------------------------
  // 2) Mise à jour du store avec les plateformes
  // ----------------------------------------------------------------------
  useEffect(() => {
    if (platformsData) {
      dispatch(setFilterLoadedPlatform(platformsData));
      const googlePlatform = platformsData.find((p: any) =>
        typeof p === "string"
          ? p.toLowerCase() === "google"
          : p.name?.toLowerCase() === "google"
      );
      if (googlePlatform) {
        dispatch(setFilterPlatform([googlePlatform]));
      } else {
        dispatch(setFilterPlatform([platformsData[0]]));
      }
    }
  }, [platformsData, dispatch]);

  // ----------------------------------------------------------------------
  // 3) Réinitialisation des états lors d'un changement de filtre
  // ----------------------------------------------------------------------
  useEffect(() => {
    setInitialLoadComplete(false);
    setSelectedIndex(0);
    offsetRef.current = 0;
    setHasMore(true);
    setPageLoading(true);
    lastLazyLoadOffset.current = null;
    sentinelRef.current = null
  }, [start, end, countries, keywords, devices, platforms]);


  // ----------------------------------------------------------------------
  // 4) Chargement initial (10 items)
  // ----------------------------------------------------------------------
  useEffect(() => {
    if (!workspace?.id) return;
    if (!start || !end || !countries.length || !devices.length || !platforms.length || !keywords.length) return;

    async function loadInitial() {
      try {
        const params = {
          workspaceId: workspace.id,
          startDate: start ? fDate(start, "yyyy-MM-dd") : null,
          endDate: end ? fDate(end, "yyyy-MM-dd") : null,
          devices: devices[0],
          countries: countries[0],
          keywords,
          platform: platforms[0],
          offset: offsetRef.current,
          pageSize: initialPageSize,
        };
        const result = await trigger(params).unwrap();
        if (result && result.length > 0) {
          setAllSerpHistory(result);
          offsetRef.current += result.length;
          if (result.length < initialPageSize) setHasMore(false);
        } else {
          setHasMore(false);
        }
      } catch (error) {
        console.error("Erreur au chargement initial:", error);
        setHasMore(false);
      } finally {
        setPageLoading(false);
        setInitialLoadComplete(true);
      }
    }
    loadInitial();
  }, [workspace?.id, start, end, countries, keywords, platforms, devices, trigger, initialPageSize]);

  const lastTriggeredIndex = useRef(0);

  // ----------------------------------------------------------------------
  // 5) Fonction de lazy load dynamique
  // ----------------------------------------------------------------------
  const loadMoreIfNeeded = (force = false) => {
    const threshold = 5; // On déclenche le lazy load quand il reste 5 slides ou moins

    if (!force) {
      if (!initialLoadComplete || loadingMore || !hasMore) return;

      const remainingSlides = iframeSrcList.length - selectedIndex;
      if (remainingSlides > threshold) return;
      if (selectedIndex <= lastTriggeredIndex.current) return;
    }

    lastTriggeredIndex.current = selectedIndex;
    lastLazyLoadOffset.current = offsetRef.current;
    setLoadingMore(true);

    (async () => {
      try {
        const params = {
          workspaceId: workspace?.id,
          startDate: start ? fDate(start, "yyyy-MM-dd") : null,
          endDate: end ? fDate(end, "yyyy-MM-dd") : null,
          devices: devices[0],
          countries: countries[0],
          platform: platforms[0],
          keywords,
          offset: offsetRef.current,
          pageSize: pageSize,
        };
        const result = await trigger(params).unwrap();
        if (result && result.length > 0) {
          setAllSerpHistory((prev: any) => [...prev, ...result]);
          offsetRef.current += result.length;
          if (result.length < pageSize) setHasMore(false);
        } else {
          setHasMore(false);
        }
      } catch (err) {
        console.error("Erreur lors du lazy load:", err);
        setHasMore(false);
      } finally {
        setLoadingMore(false);
      }
    })();
  };




  // Déclenchement lors du changement de selectedIndex et de la longueur de la liste
  useEffect(() => {
    loadMoreIfNeeded();
  }, [selectedIndex, iframeSrcList.length, initialLoadComplete, loadingMore, hasMore]);

  // ----------------------------------------------------------------------
  // 6) Génération des objectURLs à partir de allSerpHistory
  // ----------------------------------------------------------------------
  useEffect(() => {
    if (allSerpHistory.length === 0) {
      setIframeSrcList([]);
      setThumbsSrcList([]);
      return;
    }
    const newIframes: any = allSerpHistory.map((item: any) => {
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = item.html;
      // Nettoyage du DOM
      tempDiv.querySelectorAll(".cTcgmb").forEach((el: any) => (el.style.display = "none"));
      const popup = tempDiv.querySelector('div[aria-modal="true"]');
      if (popup) popup.remove();
      tempDiv.querySelectorAll("a").forEach((el) => {
        el.removeAttribute("onClick");
        el.setAttribute("target", "_blank");
        const ping = el.getAttribute("ping");
        if (ping) {
          const match = ping.match(/url=([^&]+)/);
          if (match) {
            el.setAttribute("href", decodeURIComponent(match[1]));
          }
        }
      });
      const blob = new Blob([tempDiv.innerHTML], { type: "text/html" });
      return URL.createObjectURL(blob);
    });
    setIframeSrcList(newIframes);
    setThumbsSrcList(newIframes);
    return () => newIframes.forEach((url: any) => URL.revokeObjectURL(url));
  }, [allSerpHistory]);

  // ----------------------------------------------------------------------
  // 7) Initialisation du carousel
  // ----------------------------------------------------------------------
  const carousel: any = useCarousel({ slides: iframeSrcList });
  useEffect(() => {
    if (carousel.mainApi) {
      carousel.mainApi.on("select", () => {
        setSelectedIndex(carousel.mainApi.selectedScrollSnap());
      });
    }
  }, [carousel.mainApi]);

  // ----------------------------------------------------------------------
  // 8) Clic sur une miniature => scroll vers la slide correspondante
  // ----------------------------------------------------------------------
  const handleThumbClick = (index: any) => {
    if (carousel.mainApi) {
      carousel.mainApi.scrollTo(index);
    }
    setSelectedIndex(index);
  };

  // ----------------------------------------------------------------------
  // 9) Recentrement automatique de la miniature active
  // ----------------------------------------------------------------------
  const thumbsContainerRef: any = useRef(null);
  useEffect(() => {
    if (thumbsContainerRef.current) {
      const selectedThumb = thumbsContainerRef.current.children[selectedIndex];
      if (selectedThumb) {
        selectedThumb.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "center",
        });
      }
    }
  }, [selectedIndex]);

  // ----------------------------------------------------------------------
  // 10) Affichage du Skeleton
  // ----------------------------------------------------------------------
  const isInitialLoading = pageLoading || iframeSrcList.length === 0;

  const [carouselWidth, setCarouselWidth] = useState(devices?.includes("MOBILE") ? "25%" : "100%");

  useEffect(() => {
    const timeout = setTimeout(() => {
      setCarouselWidth(devices?.includes("MOBILE") ? "25%" : "100%");
    }, 1000);

    return () => clearTimeout(timeout)
  }, [devices]);

  // Déterminez si on est en mode mobile
  const isMobile = devices?.includes("MOBILE");

  // Définissez des tailles adaptées pour mobile et desktop
  const thumbWidth = isMobile ? "110px" : "220px";
  const thumbHeight = isMobile ? "160px" : "150px";
  // Ajustez éventuellement le scale de l'iframe en fonction du device
  const iframeScale = isMobile ? 0.25 : 0.25;


  // ----------------------------------------------------------------------
  // Rendu du composant
  // ----------------------------------------------------------------------
  return (
    <Box sx={{ px: 3 }}>
      <Paper
        sx={{
          backgroundColor: theme.palette.mode === "dark" ? "#212b36" : "white",
          boxShadow:
            "0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)",
          borderRadius: 2,
          mt: 2,
          p: 2,
          position: "relative",
        }}
      >
        {/* Header */}
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ pt: 2, px: 2, mb: 3 }}>
            <Typography variant="overline" color="textSecondary" sx={{ mb: 1 }}>
              Competitive Intelligence
            </Typography>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Brand Wayback
            </Typography>
          </Box>
        </Box>

        {/* Contenu principal : Thumbnails + Carousel */}
        <Box
          sx={{
            backgroundColor: theme.palette.mode === "dark" ? "#28323C" : "#eff2f4",
            borderRadius: 2,
            py: 2,
            mb: 0.8,
          }}
        >
          {/* Thumbnails */}
          <Box sx={{ width: "100%", display: "flex", justifyContent: "center", position: "relative" }}>
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                pointerEvents: "none",
                zIndex: 1,
              }}
            />
            <Box
              ref={thumbsContainerRef}
              sx={{
                mb: 2,
                display: "flex",
                justifyContent: "flex-start",
                flexDirection: "row-reverse",
                overflowX: "auto",
                maxWidth: "45vw",
                height: isMobile ? "170px" : "170px", // ajustement de la hauteur globale si besoin
                scrollbarWidth: "none",
                "&::-webkit-scrollbar": { display: "none" },
                whiteSpace: "nowrap",
                scrollSnapType: "x mandatory",
                position: "relative",
              }}
            >
              {isInitialLoading
                ? Array.from({ length: 5 }).map((_, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "inline-block",
                      width: thumbWidth,
                      height: thumbHeight,
                      borderRadius: 1,
                      margin: "7px",
                    }}
                  >
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height="100%"
                      sx={{ borderRadius: 2 }}
                      animation="wave"
                    />
                  </Box>
                ))
                : thumbsSrcList.map((thumbSrc, index) => {
                  const distance = Math.abs(selectedIndex - index);
                  const opacity = Math.max(0.25, 1 - distance * 0.25);
                  return (
                    <Tooltip
                      key={index}
                      title={
                        allSerpHistory[index]?.date &&
                        new Intl.DateTimeFormat(
                          currentLang?.value === "fr" ? "fr-FR" : "en-US",
                          { day: "2-digit", month: "short", year: "numeric" }
                        ).format(new Date(allSerpHistory[index]?.date))
                      }
                      arrow
                    >
                      <Box
                        sx={{
                          display: "inline-block",
                          width: thumbWidth,
                          height: thumbHeight,
                          margin: "7px",
                          cursor: "pointer",
                          position: "relative",
                          overflow: "hidden",
                          borderRadius: 1,
                          boxShadow:
                            index === selectedIndex
                              ? "rgba(223, 48, 242, 0.48) 4px 4px 8px 0px"
                              : "",
                          border:
                            index === selectedIndex
                              ? "4px solid #8E33FF"
                              : "2px solid transparent",
                          flexShrink: 0,
                          marginRight: index === thumbsSrcList.length - 1 ? 0 : "8px",
                          transition: "opacity 0.5s ease-in-out",
                          opacity: opacity,
                        }}
                        onClick={() => handleThumbClick(index)}
                      >
                        <iframe
                          src={thumbSrc}
                          scrolling="no"
                          style={{
                            width: "400%",
                            height: "400%",
                            transform: "scale(0.25)",
                            transformOrigin: "top left",
                            pointerEvents: "none",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            border: "none",
                          }}
                        />
                      </Box>
                    </Tooltip>
                  );
                })}
            </Box>
          </Box>

          {/* Carousel principal */}
          <Box
            sx={{
              height: "880px",
              px: 3,
              display: 'flex',
              justifyContent: 'center',
              position: "relative",
              borderRadius: "15px",
              width: "100%",
            }}
          >
            {isInitialLoading ? (
              <Skeleton
                variant="rectangular"
                width={carouselWidth}
                height="870px"
                sx={{ borderRadius: 2 }}
                animation="wave"
              />
            ) : (
              <Carousel key={`carousel-${fDate(start)}-${fDate(end)}-${countries.join('-')}`} carousel={carousel} sx={{ overflow: "hidden", borderRadius: "15px", width: "100%" }}>
                {iframeSrcList.map((iframeSrc, index) => (
                  <Box key={index} sx={{ borderRadius: "15px", width: "100%", display: 'flex', justifyContent: 'center' }}>
                    {iframeSrc && (
                      <iframe
                        src={iframeSrc}
                        style={{
                          width: carouselWidth,
                          height: "870px",
                          border: "1px solid transparent",
                          borderRadius: "15px",
                        }}
                      />
                    )}
                  </Box>
                ))}
              </Carousel>
            )}
            {/* Sentinel en absolu pour déclencher le lazy load */}
            <Box
              ref={sentinelRef}
              sx={{
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                height: "1px",
                opacity: 0,
                pointerEvents: "none",
              }}
            />
            {/* Flèches du carousel */}
            {!isInitialLoading && (
              <CarouselArrowNumberButtons
                {...carousel.arrows}
                totalSlides={iframeSrcList.length}
                selectedIndex={selectedIndex + 1}
                currentDate={allSerpHistory}
                sx={{
                  position: "absolute",
                  top: 30,
                  left: "50%",
                  transform: "translateX(-50%)",
                  border: "1px solid",
                  borderRadius: "40px",
                  backgroundColor: "rgba(0, 0, 0, 0.6)",
                  color: "white",
                  padding: "5px 15px",
                  opacity: 0.75,
                }}
              />
            )}
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}
