import React, { useEffect, useState, useMemo, forwardRef, useRef } from 'react';
import { TextField, Autocomplete, Chip, Checkbox, Box, Button } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { setFilterKeyword, setFilterLoadedKeyword } from 'src/context/reducers/filter-settings/filterSlice';
import { useLocales } from 'src/locales';
import { FixedSizeList as List } from 'react-window';
import { useLocation } from 'react-router';

const ListboxComponent = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData = React.Children.toArray(children);
    const itemSize = 40; // Ajustez en fonction de la taille de vos options

    return (
        <div ref={ref} {...other}>
            <List
                height={350} // Hauteur totale de la liste déroulante
                width="100%"
                itemSize={itemSize}
                itemCount={itemData.length}
                overscanCount={5}
            >
                {({ index, style }) => (
                    <Box style={style} component="li" sx={{ '& > div': { display: 'block' } }}>
                        {itemData[index]}
                    </Box>
                )}
            </List>
        </div>
    );
});

interface InfiniteScrollKeywordsProps {
    keywords: any[];
    onKeywordChange: (keyword: any) => void;
}

const InfiniteScrollKeywords: React.FC<InfiniteScrollKeywordsProps> = ({ keywords, onKeywordChange }) => {
    const filterStore = useSelector((state: any) => state.filters);
    const dispatch = useDispatch();
    const { t } = useLocales();
    const location = useLocation();
    const optionRefs: any = useRef({}); // Référence pour suivre les options survolées

    // Mettre à jour filterStore.loadedKeywords lorsque keywords change
    useEffect(() => {
        const regex = /^\/dash\/competitor\/([^/]+)\/$/;
        const match = location.pathname.match(regex);

        if (location.pathname === '/dash/competitors-monitoring' || location.pathname === '/dash/competitor-focus' || location.pathname === '/dash/product-listing-ads' || location.pathname === '/dash/text-ads') {
            return;
        }

        if (!match && keywords.length > 0) {
            dispatch(setFilterLoadedKeyword(keywords));
        } else {
            dispatch(setFilterLoadedKeyword(filterStore.loadedKeywords || []));
        }
    }, [keywords, dispatch, location.pathname, filterStore.loadedKeywords]);

    // Créer allKeywords à partir de filterStore.loadedKeywords
    const allKeywords = useMemo(() => {
        if (location.pathname !== "/dash/brand-wayback") {
            return [{ search_term: 'All', total_organic_searches: null }, ...filterStore.loadedKeywords];
        }
        return filterStore.loadedKeywords;
    }, [filterStore.loadedKeywords, location.pathname]);

    // Action pour sélectionner tout
    const handleSelectAll = () => {
        dispatch(setFilterKeyword([])); // Sélectionner tout
    };

    const handleKeywordChange = (keyword: string) => {
        if (location.pathname === "/dash/brand-wayback") {
            // En mode single, on fixe directement le mot-clé sélectionné
            dispatch(setFilterKeyword([keyword]));
        } else {
            let currentKeywords = filterStore.keywords;

            // Si aucun mot-clé n'est sélectionné, on initialise currentKeywords avec tous les mots-clés possibles
            if (currentKeywords.length === 0) {
                currentKeywords = allKeywords.map((kw: any) => kw.search_term);
            }

            if (currentKeywords.includes(keyword)) {
                const updatedKeywords = currentKeywords.filter((kw: string) => kw !== keyword);
                dispatch(setFilterKeyword(updatedKeywords));
            } else {
                dispatch(setFilterKeyword([...currentKeywords, keyword]));
            }
        }
    };

    // Gérer la suppression de "All" si tous les mots-clés ne sont pas sélectionnés
    useEffect(() => {
        if (filterStore?.keywords.includes('All') && filterStore?.keywords.length !== allKeywords.length) {
            const updatedKeywords = filterStore?.keywords?.filter((kw: string) => kw !== 'All');
            dispatch(setFilterKeyword(updatedKeywords));
        }
    }, [filterStore?.keywords, allKeywords, dispatch]);

    // Fonction pour sélectionner un seul mot-clé (bouton "Only")
    const handleOnlyKeyword = (keyword: string) => {
        dispatch(setFilterKeyword([keyword]));
    };

    return (
        <Box sx={{
            width: {
                lg: 280, xs: '100%',
            }
        }}>
            {location?.pathname === "/dash/brand-wayback"
                ? <Autocomplete
                    multiple={false}
                    disableClearable
                    size="small"
                    disableCloseOnSelect={false}
                    options={allKeywords} // [{ search_term: 'iPhone' }, { search_term: 'macbook' }...]
                    getOptionLabel={(option) => option.search_term}
                    value={allKeywords.find((opt: any) => opt.search_term === filterStore.keywords[0]) || null}
                    onChange={(event, newValue) => {
                        if (newValue) {
                            // newValue est un objet { search_term: '...' }
                            dispatch(setFilterKeyword([newValue.search_term]));
                        }
                    }}
                    renderInput={(params) => (
                        <TextField {...params} label="Keywords" />
                    )}
                />
                : <Autocomplete
                    multiple
                    size="small"
                    disableCloseOnSelect
                    options={allKeywords}
                    getOptionLabel={(option: any) => option.search_term}
                    value={
                        filterStore.keywords.length === 0
                            ? [allKeywords[0]] // L'option "All"
                            : location.pathname === "/dash/brand-wayback" ? allKeywords.find((option: any) => option.search_term === filterStore.keywords[0]) : allKeywords.filter((option: any) => filterStore.keywords.includes(option.search_term))
                    }
                    onChange={(event: any, value: any) => {

                        if (location.pathname === "/dash/brand-wayback") {
                            // Cas multiple false, la valeur est un objet
                            if (value.search_term === 'All') {
                                handleSelectAll();
                            } else {
                                dispatch(setFilterKeyword([value.search_term]));
                            }
                        } else {
                            // Cas multiple true, la valeur est un tableau
                            if (value.some((option: any) => option?.search_term === undefined)) {
                                return;
                            }
                            if (value.some((option: any) => option.search_term === 'All')) {
                                handleSelectAll();
                            } else {
                                dispatch(setFilterKeyword(value.map((option: any) => option.search_term)));
                            }
                        }
                    }}

                    renderInput={(params) => (
                        <TextField
                            style={{ height: 5, position: 'relative', top: -8, zIndex: 999 }}
                            {...params}
                            label="Keywords"
                        />
                    )}
                    ListboxComponent={ListboxComponent}
                    renderOption={(props, option) => (
                        <li {...props} key={option.search_term}
                            ref={(el) => (optionRefs.current[option.search_term] = el)}
                            onClick={() =>
                                option.search_term === 'All'
                                    ? handleSelectAll()
                                    : handleKeywordChange(option.search_term)
                            } // Rendre l'option cliquable
                            onMouseEnter={() => {
                                // Ajouter une classe pour styliser au survol, sans déclencher un rerender
                                optionRefs.current[option.search_term].classList.add('hovered');
                            }}
                            onMouseLeave={() => {
                                optionRefs.current[option.search_term].classList.remove('hovered');
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%',
                                    overflow: 'hidden',
                                }}
                            >
                                <div style={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                                    {location.pathname !== "/dash/brand-wayback" &&
                                        <Checkbox
                                            checked={
                                                filterStore.keywords.length === 0
                                                    ? true
                                                    : option.search_term === 'All'
                                                        ? filterStore?.keywords?.length === filterStore?.loadedKeywords?.length
                                                        : filterStore?.keywords?.includes(option?.search_term)
                                            }
                                            onChange={() =>
                                                option.search_term === 'All'
                                                    ? handleSelectAll()
                                                    : handleKeywordChange(option.search_term)
                                            }
                                            onClick={(event) => event.stopPropagation()} // Empêcher le clic de la checkbox de déclencher le clic de l'option
                                        />
                                    }
                                    <Box
                                        sx={{
                                            width: 'auto',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            flexGrow: 1,
                                        }}
                                    >
                                        <label style={{ cursor: 'pointer' }}>{option?.search_term}</label>
                                    </Box>
                                </div>
                                {location.pathname !== "/dash/brand-wayback" &&
                                    <Button
                                        className="hover-only-button"
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            handleOnlyKeyword(option.search_term);
                                        }}
                                        variant="contained"
                                        color="inherit"
                                        size="small"
                                        sx={{
                                            mt: 0.3,
                                            height: 24,
                                            minWidth: 50,
                                            padding: 0,
                                            display: 'none',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        Only
                                    </Button>
                                }
                                {!["/dash/product-listing-ads", "/dash/text-ads", "/dash/brand-wayback"].includes(location.pathname) && (
                                    <div className={location.pathname !== "/dash/brand-wayback" ? "organic-searches" : ""} style={{ paddingRight: 10 }}>
                                        {option.search_term !== 'All' && (option?.total_organic_searches ?? 0).toFixed(0)}
                                    </div>
                                )}
                            </div>

                        </li>
                    )}

                    renderTags={(value, getTagProps) => {
                        const selectedOptions = value || [];

                        if (selectedOptions.length > 2) {
                            return (
                                <>
                                    {selectedOptions.slice(0, 2).map((option, index) => (
                                        <Chip
                                            label={option.search_term}
                                            size="small"
                                            {...getTagProps({ index })}
                                        />
                                    ))}
                                    <Chip
                                        label={`+${selectedOptions.length - 2}`}
                                        size="small"
                                        variant="soft"
                                        sx={{ color: '#919eab', marginLeft: .3 }}
                                    />
                                </>
                            );
                        } else if (selectedOptions.length > 0) {
                            return selectedOptions.map((option, index) => (
                                <Chip
                                    label={option.search_term}
                                    size="small"
                                    variant='soft'
                                    {...getTagProps({ index })}
                                    deleteIcon={option?.search_term === t('dashboard.filters.all') ? <></> : undefined}
                                />
                            ));
                        } else {
                            return (
                                <Chip
                                    label="All"
                                    size="small"
                                    variant='soft'
                                />
                            );
                        }
                    }}
                />
            }

        </Box>
    );
};

export default InfiniteScrollKeywords;
