import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RegisterQueryArg, SigninQueryArg, UpdateAccountQueryArg } from "./query";
import { CurrentUserResponse, RegisterResponse, SigninResponse } from "./models";
import fetchBaseQueryCSRF from "../helper";

const auth = createApi({
  baseQuery: fetchBaseQueryCSRF({ baseUrl: CROSS_API_URL, credentials: 'include' }),
  reducerPath: 'api.auth',
  tagTypes: ['auth'],
  endpoints: (builder) => ({
    signin: builder.mutation<SigninResponse, SigninQueryArg>({
      query: (arg) => ({
        url: '/auth/signin',
        method: 'POST',
        body: arg,
        headers: {
          'Cache-Control': 'max-age=0',
          Accept: 'application/json',
        },
      }),
    }),
    signout: builder.mutation<{}, {}>({
      query: (arg) => ({
        url: '/auth/signout',
        method: 'PUT',
        body: arg,
        headers: {
          'Cache-Control': 'max-age=0',
          Accept: 'application/json',
        },
      }),
    }),
    providerRegister: builder.mutation<{}, { token: string, provider: string }>({
      query: ({ token, provider }) => ({
        url: '/auth/provider/register',
        method: 'POST',
        body: { token, provider },
        headers: {
          'Cache-Control': 'max-age=0',
          Accept: 'application/json',
        },
      }),
    }),
    providerSignIn: builder.mutation<{}, { token: string, provider: string }>({
      query: ({ token, provider }) => ({
        url: '/auth/provider/signin',
        method: 'POST',
        body: { token, provider },
        headers: {
          'Cache-Control': 'max-age=0',
          Accept: 'application/json',
        },
      }),
    }),
    updateSso: builder.mutation({
      query: ({ token, provider, user_email }) => ({
        url: '/auth/update_sso',
        method: 'POST',
        body: { token, provider, user_email },
        headers: {
          'Cache-Control': 'max-age=0',
          Accept: 'application/json',
        },
      }),
    }),
    currentUser: builder.query<CurrentUserResponse, void>({
      query: () => ({
        url: '/account/me',
        headers: {
          'Cache-Control': 'max-age=0',
          Accept: 'application/json',
        },
      }),
    }),
    updateAccount: builder.mutation<{}, UpdateAccountQueryArg>({
      query: ({ profile, address }) => ({
        url: '/account/me',
        method: 'PATCH',
        body: { profile, address },
        headers: {
          'Cache-Control': 'max-age=0',
          Accept: 'application/json',
        },
      }),
    }),
    register: builder.mutation<RegisterResponse, RegisterQueryArg>({
      query: (arg) => ({
        url: '/auth/register',
        method: 'POST',
        body: arg,
        headers: {
          'Cache-Control': 'max-age=0',
          Accept: 'application/json',
        },
      }),
    }),
    deleteAccount: builder.mutation<{}, {}>({
      query: () => ({
        url: '/account/me',
        method: 'DELETE',
        headers: {
          'Cache-Control': 'max-age=0',
          Accept: 'application/json',
        },
      }),
    }),
    updatePassword: builder.mutation<{}, { password: string; confirmPassword: string, currentPassword: string }>({
      query: ({ password, confirmPassword, currentPassword }) => ({
        url: '/account/me/password',
        method: 'PATCH',
        body: { password, confirm_password: confirmPassword, current_password: currentPassword },
        headers: {
          'Cache-Control': 'max-age=0',
          Accept: 'application/json',
        },
      }),
    }),
    updatePasswordFirstLog: builder.mutation<{}, { password: string; confirmPassword: string, token: string, lastname: string; firstname: string }>({
      query: ({ password, confirmPassword, token, firstname, lastname }) => ({
        url: '/auth/password',
        method: 'PATCH',
        body: { password, confirm_password: confirmPassword, token, firstname, lastname },
        headers: {
          'Cache-Control': 'max-age=0',
          Accept: 'application/json',
        },
      }),
    }),
    forgotPassword: builder.mutation<{}, { email: string }>({
      query: ({ email }) => ({
        url: '/tokens/forgot',
        method: 'POST',
        body: { email },
        headers: {
          'Cache-Control': 'max-age=0',
          Accept: 'application/json',
        },
      }),
    }),
    verifyToken: builder.mutation<{}, { token: string }>({
      query: ({ token }) => ({
        url: '/tokens/verify_token',
        method: 'POST',
        body: { token },
        headers: {
          'Cache-Control': 'max-age=0',
          Accept: 'application/json',
        },
      }),
    }),
    resetPassword: builder.mutation<{}, { password: string; confirm_password: string, token: string }>({
      query: ({ password, confirm_password, token }) => ({
        url: '/tokens/password_reset',
        method: 'PATCH',
        body: { password, confirm_password, token },
        headers: {
          'Cache-Control': 'max-age=0',
          Accept: 'application/json',
        },
      }),
    }),
    providerUserDelete: builder.mutation<{}, { token: string, userId: string }>({
      query: ({ token, userId }) => ({
        url: 'auth/provider/delete_user',
        method: 'DELETE',
        body: { token, userId },
        headers: {
          'Cache-Control': 'max-age=0',
          Accept: 'application/json',
        },
      }),
    }),
    checkSession: builder.mutation<{},{}>({
      query: () => ({
        url: '/auth/check_session',
        method: 'GET',
        headers: {
          'Cache-Control': 'max-age=0',
          Accept: 'application/json',
        },
      }),
    })
  }),
});

export const {
  useSigninMutation,
  useSignoutMutation,
  useRegisterMutation,
  useCurrentUserQuery,
  useUpdateAccountMutation,
  useDeleteAccountMutation,
  useUpdatePasswordMutation,
  useUpdatePasswordFirstLogMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useVerifyTokenMutation,
  useProviderSignInMutation,
  useProviderRegisterMutation,
  useUpdateSsoMutation,
  useProviderUserDeleteMutation,
  useCheckSessionMutation

} = auth;
export default auth;