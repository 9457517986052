import { useEffect, useState } from 'react';
import {
    Box,
    Container,
    Grid,
    Stack,
    InputAdornment,
    OutlinedInput,
    Typography, FormControl, Card, CardMedia, CardContent, Pagination, Rating, Button, Skeleton, MenuItem, Select, InputLabel,
    useTheme
} from '@mui/material';
import Iconify from 'src/components/iconify';
import { useGetAdsPlaQuery } from 'src/context/api/competitorsMonitoring/api';
import { useDispatch, useSelector } from 'react-redux';
import { fDate } from 'src/utils/format-time';
import { useLocales } from 'src/locales';
import { useCurrentWorkspace } from 'src/context/reducers/app-settings';
import { usePreviousLocation } from 'src/components/previousLocationContext';
import { setFilterCountry, setFilterDevice, setFilterKeyword, setFilterLoadedCountry, setFilterLoadedDevice, setFilterLoadedKeyword } from 'src/context/reducers/filter-settings/filterSlice';

export default function PlaAds({ domain, workspaceTable }: any) {
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const adsPerPage = 12;
    const filterStore = useSelector((state: any) => state.filters);
    const [selectedAds, setSelectedAds]: any = useState([]);
    const [sortBy, setSortBy] = useState('Impression Share');
    const [sortOrder, setSortOrder] = useState('desc');
    const theme = useTheme();
    const { t } = useLocales();
    const workspace = useCurrentWorkspace();
    const lang = localStorage.getItem('i18nextLng') || 'en';
    const prevLocation = usePreviousLocation();
    const dispatch = useDispatch();

    const { data: ads, error: statsError, isFetching } = useGetAdsPlaQuery({
        startDate: filterStore.start ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        device: filterStore.devices.map((device: string) => device.toLowerCase()),
        countries: filterStore.countries,
        keywords: filterStore.keywords,
        domain: workspace?.url || domain,
        workspaceTable: workspace?.bqTable,
        platform: filterStore?.platforms
    },
    );

    useEffect(() => {
        if (prevLocation === "/dash/brand-wayback") {
            dispatch(setFilterLoadedKeyword([]));
            dispatch(setFilterKeyword([]))
            dispatch(setFilterDevice([]))
            dispatch(setFilterLoadedDevice([]))
            dispatch(setFilterCountry([]))
            dispatch(setFilterLoadedCountry([]))
        }
    }, [prevLocation])

    const handleSearch = (event: any) => {
        setSearchQuery(event.target.value);
    };

    const handlePageChange = (event: any, value: any) => {
        setCurrentPage(value);
    };

    const handleAdHoverEnter = (index: any) => {
        setSelectedAds([index]);  // Sets the current hovered ad
    };

    const handleAdHoverLeave = () => {
        setSelectedAds([]);  // Resets when mouse leaves
    };

    const truncateText = (text: any, length: any) => {
        if (text?.length > length) {
            return text?.substring(0, length) + '...';
        }
        return text;
    };

    const containsLetters = (str: string) => {
        return /[a-zA-Z]/.test(str);
    }

    const handleSortOrderChange = (event: any) => {
        setSortOrder(event.target.value);
    };

    const handleSortChange = (event: any) => {
        setSortBy(event.target.value);
    };

    const filteredAds = ads?.ads?.filter((ad: any) =>
        (ad.title ?? "").toLowerCase().includes(searchQuery.toLowerCase())
    ) || [];

    const sortedAds = [...filteredAds].sort((a, b) => {
        const compareValue = (valueA: number, valueB: number) => {
            if (sortOrder === 'asc') {
                return valueA - valueB;
            } else {
                return valueB - valueA;
            }
        };

        switch (sortBy) {
            case 'Impression Share':
                return compareValue(a.impression_share, b.impression_share);
            case 'Average Position':
                return compareValue(a.position, b.position);
            case 'Price':
                const priceA = parseFloat(a.price.replace(/[^0-9.-]+/g, '')) || 0;
                const priceB = parseFloat(b.price.replace(/[^0-9.-]+/g, '')) || 0;
                return compareValue(priceA, priceB);
            default:
                return 0;
        }
    });

    const indexOfLastAd = currentPage * adsPerPage;
    const indexOfFirstAd = indexOfLastAd - adsPerPage;
    const currentAds = sortedAds.slice(indexOfFirstAd, indexOfLastAd);

    return (
        <Container maxWidth="xl" sx={{ marginTop: 5 }}>
            <Stack spacing={3}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <FormControl sx={{ my: 1, p: 0 }}>
                        <OutlinedInput
                            sx={{ width: '100%' }}
                            id="outlined-adornment-amount"
                            placeholder="Search..."
                            size="small"
                            value={searchQuery}
                            onChange={handleSearch}
                            startAdornment={<InputAdornment position="start"><Iconify icon={"eva:search-fill"} /></InputAdornment>}
                        />
                    </FormControl>
                    <Box gap={2} sx={{ display: 'flex', }}>
                        <FormControl sx={{ minWidth: 120, ml: 2 }}>
                            <InputLabel id="sort-by-label">Sort by</InputLabel>
                            <Select
                                labelId="sort-by-label"
                                id="sort-by"
                                size='small'
                                value={sortBy}
                                label="Sort by"
                                onChange={handleSortChange}
                            >
                                <MenuItem value="Impression Share">Impression Share</MenuItem>
                                <MenuItem value="Average Position">Average Position</MenuItem>
                                <MenuItem value="Price">Price</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl sx={{ minWidth: 120 }}>
                            <InputLabel id="sort-order-label">Order</InputLabel>
                            <Select
                                labelId="sort-order-label"
                                id="sort-order"
                                size='small'
                                value={sortOrder}
                                label="Order"
                                onChange={handleSortOrderChange}
                            >
                                <MenuItem value="asc">Ascending</MenuItem>
                                <MenuItem value="desc">Descending</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
            </Stack>
            <Grid container spacing={2} sx={{ mt: 2 }}>
                {isFetching || (!ads && !statsError) ? (
                    Array.from(new Array(adsPerPage)).map((_, index) => (
                        <Grid item key={index} xs={12} sm={6} md={4} lg={3} xl={12 / 6}>
                            <Skeleton variant="rectangular" width="100%" height={315} style={{ borderRadius: 10 }} />
                        </Grid>
                    ))
                ) : (
                    currentAds.length > 0 ? (
                        currentAds.map((ad: any, index: any) => (
                            <Grid item key={index} xs={12} sm={6} md={4} lg={3} xl={12 / 6}>
                                <Card
                                    sx={{
                                        height: '100%',
                                        bgcolor: selectedAds.includes(index) ? theme.palette.mode === "dark" ? '#1c252e' : 'white' : 'none',
                                        color: selectedAds.includes(index) ? theme.palette.mode === "dark" ? '#fff' : "#202124" : '#000',
                                        display: 'flex',
                                        transition: 'transform 0.3s ease',
                                        '&:hover': {
                                            transform: 'scale(1.02)'
                                        },
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        borderRadius: 2,
                                        overflow: 'hidden',
                                        cursor: 'pointer'
                                    }}
                                    onMouseEnter={() => handleAdHoverEnter(index)}
                                    onMouseLeave={handleAdHoverLeave}
                                >
                                    {selectedAds.includes(index) ? (
                                        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                            <CardContent>
                                                <Box sx={{ textAlign: 'center', mb: 2, border: 2, borderRadius: 2, borderColor: theme.palette.mode === "dark" ? "#212a33" : "#6f777d", p: 2 }}>
                                                    <Iconify
                                                        sx={{ width: 25, height: 25 }}
                                                        icon={"mdi:eye"}
                                                    />
                                                    <Typography variant="h6" sx={{ mt: 1 }}>
                                                        {(ad?.impression_share * 100)?.toFixed(2) + "%"}
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ mt: 1, color: '#6f777d' }}>
                                                        {t('dashboard.textAdsPage.Ad_impressionShare')}
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ textAlign: 'center', mb: 2, border: 2, borderRadius: 2, borderColor: theme.palette.mode === "dark" ? "#212a33" : "#6f777d", p: 2 }}>
                                                    <Iconify
                                                        sx={{ width: 25, height: 25 }}
                                                        icon={"mdi:podium-gold"}
                                                    />
                                                    <Typography variant="h6" sx={{ mt: 1 }}>
                                                        {ad?.item_position}
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ mt: 1, color: '#6f777d' }}>
                                                        {t('dashboard.textAdsPage.Ad_average_position')}
                                                    </Typography>
                                                </Box>
                                                <Button onClick={() => window.open(ad?.link)} sx={{ flexDirection: 'column', textAlign: 'center', mb: 2, border: 2, width: "100%", borderRadius: 2, borderColor: theme.palette.mode === "dark" ? "#212a33" : "#6f777d", p: 2 }}>
                                                    <Iconify
                                                        sx={{ width: 25, height: 25 }}
                                                        icon={"mingcute:external-link-fill"}
                                                    />
                                                    <Typography variant="body2" fontSize={15} sx={{ fontWeight: 'bold', mt: 1 }}>
                                                        {t('dashboard.textAdsPage.Visit')}
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ mt: 1, color: '#6f777d' }}>
                                                        {t('dashboard.textAdsPage.Ad_landing_page')}
                                                    </Typography>
                                                </Button>
                                            </CardContent>
                                        </Box>
                                    ) : (
                                        <>
                                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '350px', overflow: 'hidden', backgroundColor: '#f8f8f8' }}>
                                                <CardMedia
                                                    component="img"
                                                    image={ad?.image || "/assets/no_img.png"}
                                                    alt={ad?.title}
                                                    sx={{ maxHeight: '100%', maxWidth: '70%', objectFit: 'cover' }}
                                                />
                                            </Box>
                                            <CardContent sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign: 'left', p: 1, backgroundColor: theme.palette.mode === "dark" ? '#1c252e' : "white" }}>
                                                <Box>
                                                    <Typography sx={{
                                                        '&:hover': {
                                                            textDecoration: 'underline'
                                                        },
                                                    }} fontSize={16} color={theme.palette.mode === "dark" ? "#99c3ff" : "#1a0dab"} gutterBottom>
                                                        {truncateText(ad?.title, 45)}
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    {ad?.price && (
                                                        <Typography variant="h6" color={theme.palette.mode === "dark" ? "#dddddd" : "#202124"}>
                                                            {ad?.price}
                                                        </Typography>
                                                    )}

                                                    {ad?.seller_name && (
                                                        <Typography sx={{ mt: 1 }} variant="body2" color={theme.palette.mode === "dark" ? "#dddddd" : "#70757a"}>
                                                            {ad?.seller_name}
                                                        </Typography>
                                                    )}

                                                    {ad?.shipping && (
                                                        <Typography sx={{ mt: 1 }} variant="body2" color="text.secondary">
                                                            {ad?.shipping}
                                                        </Typography>
                                                    )}

                                                    {(ad?.rating && containsLetters(ad?.rating)) && (
                                                        <Typography sx={{ mt: 1 }} fontSize={14} color="#dddddd">
                                                            {ad?.rating}
                                                        </Typography>
                                                    )}

                                                    {(ad?.rating && ad?.reviews_count) && (
                                                        <Box sx={{ display: 'flex', mt: 1, flexDirection: 'row' }}>
                                                            <Rating size='small' name="read-only" value={ad?.rating} readOnly />
                                                            <Typography fontSize={14} sx={{ ml: .4 }} color={theme.palette.mode === "dark" ? "#dddddd" : "#70757a"}>({parseInt(ad?.reviews_count)?.toFixed(0)})</Typography>
                                                        </Box>
                                                    )}
                                                </Box>
                                            </CardContent>
                                        </>
                                    )}
                                </Card>
                            </Grid>
                        ))
                    ) : (
                        <Grid item xs={12}>
                            <Typography variant="h6" color="text.secondary" align="center">
                                Aucun résultat trouvé.
                            </Typography>
                        </Grid>
                    )
                )}
            </Grid>
            {currentAds?.length !== 0 &&
                <Stack spacing={2} sx={{ mt: 5 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                        <Pagination
                            count={Math.ceil(filteredAds.length / adsPerPage)}
                            page={currentPage}
                            onChange={handlePageChange}
                            color="primary"
                        />
                    </Box>
                </Stack>
            }
        </Container>
    );
}
