import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
// hooks
import { Box, Skeleton, Typography } from '@mui/material';
import DataGridCustomPresence from 'src/components/data-grid/data-grid-custom-presence-keyword-domain';
import ChartColumn from "src/sections/charts/chart-column-custom";
import Iconify from 'src/components/iconify';
import { useLocales } from 'src/locales';
import { useSelector } from 'react-redux';
import { formatWeekLabel } from 'src/utils/dates-labels';
import { useCurrentWorkspace } from 'src/context/reducers/app-settings';


// ----------------------------------------------------------------------

interface Props {
  post: any;
  info: any;
  isFetching?: boolean;
  name: any,
}

export default function ProfilePostItem({ name, post, info, isFetching }: Props) {
  const { t, currentLang } = useLocales();
  const filterStore = useSelector((state: any) => state.filters);
  const workspace: any = useCurrentWorkspace();
  const formatString = (str: string) => {
    const noSpaces = str.replace(/\s+/g, ''); // Supprime les espaces
    const formatted = noSpaces.replace(/([a-zA-Z])(\d)/g, '$1-$2'); // Insère un tiret entre les lettres et les chiffres
    return `${formatted}.com`;
  };

  const dataPresence = post?.presence?.map((item: any, index: any) => ({
    id: index,
    search_term: item?.search_term,
    presenceRate: item?.presence_rate * 100
  })) || [];

  const dataSearchPresence = post?.presenceSearch?.map((item: any, index: any) => ({
    id: index,
    search_term: item?.search_term,
    presenceSearchRate: item?.presence_search_rate * 100
  })) || []

  const dataShoppingPresence = post?.presenceShopping?.map((item: any, index: any) => ({
    id: index,
    search_term: item?.search_term,
    presenceShoppingRate: item?.presence_shopping_rate * 100
  })) || []

  let presenceAll: any = []
  if (workspace?.template === 'E-commerce') {
    presenceAll = dataPresence?.map((item: any) => {
      const matchSearchPresence = dataSearchPresence.find((searchItem: any) => searchItem.search_term === item.search_term)
      const matchShoppingPresence = dataShoppingPresence.find((searchItem: any) => searchItem.search_term === item.search_term)

      return {
        ...item,
        presenceSearchRate: matchSearchPresence?.presenceSearchRate,
        presenceShoppingRate: matchShoppingPresence?.presenceShoppingRate
      }
    })

  }



  const seriesCompetitorsOverTime = [];
  const labelsCompetitorsOverTime = [];

  if (post?.competitorsOverTime) {
    const domainGroups: any = Object.entries(post?.competitorsOverTime);
    const updatedDomainGroups = domainGroups.map((item: any, index: any) => {

      if (item[0] === "client") {
        return [
          "client.com",
          item[1]
        ]
      }
      return item
    });
    for (const [domain, data] of updatedDomainGroups) {

      const hasSpace = (str: any) => str.includes(' ');

      seriesCompetitorsOverTime.push({
        name: hasSpace(domain) ? formatString(domain) : domain,
        data: data.map((item: any) => item.ratio_present_scrap * 100),
      });
    }

    if (domainGroups.length > 0) {
      if (filterStore.timestep === 'year_week') {
        labelsCompetitorsOverTime.push(...domainGroups[0][1].map((item: any) => {
          const dateObj = new Date(item.timestep);

          if (dateObj.getUTCDate()) {
            const year = dateObj.getUTCFullYear();
            const month = String(dateObj.getUTCMonth() + 1).padStart(2, '0');
            const day = String(dateObj.getUTCDate()).padStart(2, '0');
            const newDate = currentLang.value === 'fr' ? `${day}/${month}/${year}` : `${month}/${day}/${year}`
            return newDate
          } else {
            const [year, week] = item.timestep.split('-');
            return formatWeekLabel(parseInt(year), parseInt(week), currentLang.value);
          }
        }))

      } else if (filterStore.timestep === "year_month") {
        labelsCompetitorsOverTime.push(...domainGroups[0][1].map((item: any) => {
          const [year, month] = item.timestep.split('-');
          return `${month}/${year}`;
        }));
      } else {
        labelsCompetitorsOverTime.push(...domainGroups[0][1].map((item: any) => {
          const newDate = new Date(item.timestep);
          const month = String(newDate.getUTCMonth() + 1).padStart(2, '0'); // getUTCMonth() is zero-based, so we add 1
          const day = String(newDate.getUTCDate()).padStart(2, '0');
          const year = newDate.getUTCFullYear();
          if (currentLang.value === 'fr') {
            return `${day}/${month}/${year}`;
          } else {
            return `${month}/${day}/${year}`;
          }
        }));

      }

    }
  }

  // Helper function to transform the domain URL
  const transformDomain = (domainUrl: any) => {
    if (!domainUrl) return '';
    const domainRemoved = domainUrl.replace(/\.[^/.]+$/, '');
    return domainRemoved.split('-').map((word: any) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  };

  const capitalizeFirstLetter = (str: any) => {
    if (!str) return ''; // Gérer les cas où la chaîne est vide ou non définie
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const displayName = info?.name || transformDomain(info?.domain);
  return (
    <>
      <Card sx={{ height: "auto" }}>
        {isFetching ?
          <Skeleton width={'100%'} height={'100%'} style={{ borderRadius: 10 }} variant="rectangular" animation={"wave"}>
            <>
              <Iconify py={"auto"} icon={"eva:checkmark-circle-2-fill"} color={"success.main"} />
              <Typography color={"success.main"}>Active</Typography>
            </>
          </Skeleton>
          : <>
            <CardHeader
              disableTypography
              title={
                <Typography color="inherit" variant="subtitle1">
                  {workspace?.name.includes('Demo') ? capitalizeFirstLetter(name) : displayName} {t('dashboard.competitorPage.is_bidding_on_your_brand_keywords')}
                </Typography>
              }
            />

            <Box sx={{ mt: 2 }}>
              <DataGridCustomPresence pageSize={5} data={workspace?.template === "E-commerce" ? presenceAll : dataPresence} />
            </Box>
          </>
        }
      </Card>

      <Card sx={{ height: 500 }}>
        {isFetching ?
          <Skeleton width={'100%'} height={'100%'} style={{ borderRadius: 10 }} variant="rectangular" animation={"wave"}>
            <>
              <Iconify py={"auto"} icon={"eva:checkmark-circle-2-fill"} color={"success.main"} />
              <Typography color={"success.main"}>Active</Typography>
            </>
          </Skeleton>
          : <>
            <CardHeader
              disableTypography
              title={
                <Typography color="inherit" variant="subtitle1">
                  {workspace?.name.includes('Demo') ? capitalizeFirstLetter(name) : displayName} {t('dashboard.competitorPage.presence_rate_on_your_brand_keywords_over_time')}
                </Typography>
              }
            />
            <Box sx={{ paddingLeft: 2, paddingRight: 2 }}>
              <ChartColumn
                series={seriesCompetitorsOverTime}
                labels={labelsCompetitorsOverTime}
              />
            </Box>
          </>
        }
      </Card>
    </>
  );
}