import { Box, Alert, Typography } from '@mui/material'
import { ComponentBlock } from 'src/sections/mui/component-block'
import { ComponentBlockCustom } from 'src/sections/mui/component-block-custom'

type StepThreeContentActiveModeProps = {
  formData: any
  searchActiveModeDuplicate: any
  workspace: any
}

export function StepThreeContentActiveMode({
  formData,
  searchActiveModeDuplicate,
  workspace
}: StepThreeContentActiveModeProps) {
  return (
    <Box sx={{ mt: 4 }}>
      {searchActiveModeDuplicate && (
        <Alert sx={{ my: 3, backgroundColor: '#322f33', color: '#e0ac8a' }} severity="error">
          <Typography sx={{ fontWeight: 900 }}>{searchActiveModeDuplicate.title}</Typography>
          <Typography fontSize={14} sx={{ mt: 0.4 }}>
            {searchActiveModeDuplicate.description}
          </Typography>
        </Alert>
      )}
      <ComponentBlock sx={{ marginTop: 2 }} title="Review Your Selections(s)">
        <ComponentBlockCustom sx={{ mb: 0 }} title="Monitoring">
          <Typography>
            The presence of the {workspace.url} domain on Google Ads in{' '}
            <span
              style={{
                backgroundColor: 'black',
                color: '#b884f3',
                border: '1px solid #454f5b',
                padding: 4,
                borderRadius: '6px'
              }}
            >
              {formData.country}
            </span>{' '}
            will be monitored in real time for the keyword{' '}
            <span
              style={{
                backgroundColor: 'black',
                color: '#b884f3',
                border: '1px solid #454f5b',
                padding: 4,
                borderRadius: '6px'
              }}
            >
              {formData.keywords}
            </span>{' '}
            on{' '}
            {formData.device.length === 2 ? (
              <>
                <span
                  style={{
                    backgroundColor: 'black',
                    color: '#b884f3',
                    border: '1px solid #454f5b',
                    padding: 4,
                    borderRadius: '6px'
                  }}
                >
                  {formData.device[0]}
                </span>{' '}
                and{' '}
                <span
                  style={{
                    backgroundColor: 'black',
                    color: '#b884f3',
                    border: '1px solid #454f5b',
                    padding: 4,
                    borderRadius: '6px'
                  }}
                >
                  {formData.device[1]}
                </span>
              </>
            ) : (
              <span
                style={{
                  backgroundColor: 'black',
                  color: '#b884f3',
                  border: '1px solid #454f5b',
                  padding: 4,
                  borderRadius: '6px'
                }}
              >
                {formData.device[0]}
              </span>
            )}
            .
          </Typography>
        </ComponentBlockCustom>
        <ComponentBlock sx={{ marginTop: 2 }} title="Activation">
          <Typography>
            Each time no competitor is detected, this keyword will be added to the exclusion list for the{' '}
            <span
              style={{
                backgroundColor: 'black',
                color: '#b884f3',
                border: '1px solid #454f5b',
                padding: 4,
                borderRadius: '6px'
              }}
            >
              {formData.campaign_name}
            </span>{' '}
            campaign of the{' '}
            <span
              style={{
                backgroundColor: 'black',
                color: '#b884f3',
                border: '1px solid #454f5b',
                padding: 4,
                borderRadius: '6px'
              }}
            >
              {formData.account}
            </span>{' '}
            account in,{' '}
            {formData.device.length === 2 ? (
              <>
                <span
                  style={{
                    backgroundColor: 'black',
                    color: '#b884f3',
                    border: '1px solid #454f5b',
                    padding: 4,
                    borderRadius: '6px'
                  }}
                >
                  {formData.device[0]}
                </span>{' '}
                and{' '}
                <span
                  style={{
                    backgroundColor: 'black',
                    color: '#b884f3',
                    border: '1px solid #454f5b',
                    padding: 4,
                    borderRadius: '6px'
                  }}
                >
                  {formData.device[1]}
                </span>
              </>
            ) : (
              <span
                style={{
                  backgroundColor: 'black',
                  color: '#b884f3',
                  border: '1px solid #454f5b',
                  padding: 4,
                  borderRadius: '6px'
                }}
              >
                {formData.device[0]}
              </span>
            )},{' '}
            thus pausing the bidding on this keyword/device(s). As soon as one or more competitors are detected
            again, the keyword will be removed from the exclusion list in order to resume bidding.
          </Typography>
        </ComponentBlock>
      </ComponentBlock>
    </Box>
  )
}
