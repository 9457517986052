import { format, getTime, formatDistanceToNow } from 'date-fns';

type InputValue = Date | string | number | null | undefined;

export function fDate(date: any, newFormat?: string) {
  const fm = newFormat || 'dd MMM yyyy';
  if (!date) return '';

  const d = new Date(date);

  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const options: any = {
    timeZone: (userTimeZone === "Europe/Paris" || userTimeZone === "Asia/Jerusalem") ? userTimeZone : 'UTC',
    year: 'numeric',
    month: 'short',
    day: '2-digit'
  };

  return format(new Date(new Intl.DateTimeFormat('en-US', options).format(d)), fm);
}

export function fDateTime(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date: InputValue) {
  return date ? getTime(new Date(date)) : '';
}

export function fCustomDate(date: InputValue, newFormat?: string) {
  const fm = newFormat || "EEE, dd MMM yyyy HH:mm:ss 'GMT'";

  return date ? format(new Date(date), fm) : '';
}

export function fToNow(date: InputValue) {
  return date
    ? formatDistanceToNow(new Date(date), {
      addSuffix: true,
    })
    : '';
}
