import { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import React from 'react';
// @mui
import {
  DataGridPro,
  GridColDef,
  GridToolbar,
  GridColumnVisibilityModel,
  GridLogicOperator,
  useGridApiRef,
  GridSortModel,
  GridFilterModel,
  GridPaginationModel,
} from '@mui/x-data-grid-pro';
import { Box, Stack, useTheme } from '@mui/system';
import { LinearProgress, Typography } from '@mui/material';
import { fNumber, fPercent } from 'src/utils/format-number';
import { setFilterKeyword } from 'src/context/reducers/filter-settings/filterSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useLocales } from 'src/locales';
import { useResponsive } from 'src/hooks/use-responsive';
import { useCurrentWorkspace } from 'src/context/reducers/app-settings';
import { formatDate } from 'src/utils/dates-labels';
import { formatCurrency } from 'src/utils/format-currency';
import "./data-grid-custom.css";
import CustomLineChart from '../inline-chart/inline-chart';
import FilterPanelCustom from './filter-panel-custom/filter-panel-custom';
import { useLocation } from 'react-router-dom';
import ReactDOM from 'react-dom';


// ----------------------------------------------------------------------

const hasNonZeroValues = (data: any[], field: string): boolean => {
  return data?.some((item) => item[field] && item[field] !== 0);
};


const createColumns = (theme: any, t: any, lgUp: any, filterStore: any, formatDate: any, formatCurrency: any, currency: any, locale: any): GridColDef[] => [
  {
    field: 'keyword',
    headerName: t('dashboard.global.keyword'),
    minWidth: 120,
    resizable: true,
  },
  // BRAND SEARCHES
  {
    field: 'brandSearches',
    headerName: t('dashboard.brandTraffic.brandSearches') + ` (${formatDate(filterStore?.start)} - ${formatDate(filterStore?.end)})`,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.brandSearches')}</span>
        {filterStore?.compare && (
          <span style={{ textAlign: 'left' }}>
            {formatDate(filterStore?.start)} - {formatDate(filterStore?.end)}
          </span>
        )}
      </div>
    ),
    type: 'number',
    width: 200, minWidth: filterStore?.compare ? 300 : 200, maxWidth: 225,
    flex: lgUp ? 2 : undefined,
    renderCell: (params) => (
      <span style={{ color: theme.palette.mode === "dark" ? '#db8377' : '#B71D18' }}>
        {params.row.brandSearches}
      </span>
    ),
  },

  // BRAND SEARCHES COMPARE
  {
    field: 'brandSearchesCompare',
    filterable: filterStore?.compare ? true : false,
    headerName: t('dashboard.brandTraffic.brandSearches') + ` (${formatDate(filterStore?.compareStart)} - ${formatDate(filterStore?.compareEnd)})`,
    width: 200, minWidth: filterStore?.compare ? 300 : 100, maxWidth: 225,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', opacity: .6 }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.brandSearches')}</span>
        {filterStore?.compare && (
          <span style={{ textAlign: 'left' }}>
            {formatDate(filterStore?.compareStart)} - {formatDate(filterStore?.compareEnd)}
          </span>
        )}
      </div>
    ),
    disableExport: filterStore?.compare ? true : false,
    hideable: filterStore?.compare ? true : false,
    type: 'number',
    flex: lgUp ? 2 : undefined,
    renderCell: (params) => (
      <span style={{ color: theme.palette.mode === "dark" ? '#db8377' : '#B71D18', opacity: .6 }}>
        {params.row.brandSearchesCompare}
      </span>
    ),
  },

  // BRAND SEARCHES DIFF
  {
    field: 'brandSearchesDiff',
    filterable: filterStore?.compare ? true : false,
    headerName: "Brand Searches Difference",
    width: 200, minWidth: filterStore?.compare ? 225 : 100, maxWidth: 225,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', fontWeight: 700 }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.brandSearches')}</span>
        Difference
      </div>
    ),
    disableExport: filterStore?.compare ? true : false,
    hideable: filterStore?.compare ? true : false,
    type: 'number',
    flex: lgUp ? 1 : undefined,
    renderCell: (params) => (
      <span style={{ color: theme.palette.mode === "dark" ? '#db8377' : '#B71D18', fontWeight: 700 }}>
        {params.row.brandSearchesDiff}
      </span>
    ),
  },

  // BRAND TRAFFIC
  {
    field: 'brandTraffic',
    headerName: t('dashboard.brandTraffic.brandTraffic') + ` (${formatDate(filterStore?.start)} - ${formatDate(filterStore?.end)})`,
    width: 200, minWidth: filterStore?.compare ? 300 : 200, maxWidth: 225,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.brandTraffic')}</span>
        {filterStore?.compare && (
          <span style={{ textAlign: 'left' }}>
            {formatDate(filterStore?.start)} - {formatDate(filterStore?.end)}
          </span>
        )}
      </div>
    ),
    flex: lgUp ? 1 : undefined,
    renderCell: (params) => (
      <span style={{ color: theme.palette.mode === "dark" ? ' #a64b7b' : '#880E4F' }}>
        {params.row.brandTraffic}
      </span>
    ),
    type: 'number',
  },

  // BRAND TRAFFIC COMPARE
  {
    field: 'brandTrafficCompare',
    filterable: filterStore?.compare ? true : false,
    headerName: t('dashboard.brandTraffic.brandTraffic') + ` (${formatDate(filterStore?.compareStart)} - ${formatDate(filterStore?.compareEnd)})`,
    width: 200, minWidth: filterStore?.compare ? 300 : 100, maxWidth: 225,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', opacity: .6 }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.brandTraffic')}</span>
        {filterStore?.compare && (
          <span style={{ textAlign: 'left' }}>
            {formatDate(filterStore?.compareStart)} - {formatDate(filterStore?.compareEnd)}
          </span>
        )}
      </div>
    ),
    disableExport: filterStore?.compare ? true : false,
    hideable: filterStore?.compare ? true : false,
    type: 'number',
    flex: lgUp ? 1 : undefined,
    renderCell: (params) => (
      <span style={{ color: theme.palette.mode === "dark" ? '#a64b7b' : '#880E4F', opacity: .6 }}>
        {params.row.brandTrafficCompare}
      </span>
    ),
  },

  // BRAND TRAFFIC DIFF
  {
    field: 'brandTrafficDiff',
    filterable: filterStore?.compare ? true : false,
    headerName: "Brand Traffic Difference",
    disableExport: filterStore?.compare ? true : false,
    hideable: filterStore?.compare ? true : false,
    width: 200, minWidth: filterStore?.compare ? 225 : 100, maxWidth: 225,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', fontWeight: 700 }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.brandTraffic')}</span>
        Difference
      </div>
    ),
    type: 'number',
    flex: lgUp ? 1 : undefined,
    renderCell: (params) => (
      <span style={{ color: theme.palette.mode === "dark" ? '#a64b7b' : '#880E4F', fontWeight: 900 }}>
        {params.row.brandTrafficDiff}
      </span>
    ),
  },

  // ORGANIC BRAND TRAFFIC
  {
    field: 'organicBrandTraffic',
    headerName: t('dashboard.brandTraffic.organicBrandTraffic') + ` (${formatDate(filterStore?.start)} - ${formatDate(filterStore?.end)})`,
    width: 200, minWidth: filterStore?.compare ? 300 : 200, maxWidth: 225,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.organicBrandTraffic')}</span>
        {filterStore?.compare && (
          <span style={{ textAlign: 'left' }}>
            {formatDate(filterStore?.start)} - {formatDate(filterStore?.end)}
          </span>
        )}
      </div>
    ),
    flex: lgUp ? 1 : undefined,
    type: 'number',
    renderCell: (params) => (
      <span style={{ color: theme.palette.mode === "dark" ? '#6dad8d' : '#065E49' }}>
        {params.row.organicBrandTraffic}
      </span>
    ),
  },

  // ORGANIC BRAND TRAFFIC COMPARE
  {
    field: 'organicBrandTrafficCompare',
    filterable: filterStore?.compare ? true : false,
    disableExport: filterStore?.compare ? true : false,
    hideable: filterStore?.compare ? true : false,
    headerName: t('dashboard.brandTraffic.organicBrandTraffic') + ` (${formatDate(filterStore?.compareStart)} - ${formatDate(filterStore?.compareEnd)})`,
    width: 200, minWidth: filterStore?.compare ? 300 : 100, maxWidth: 225,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', opacity: .6 }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.organicBrandTraffic')}</span>
        {filterStore?.compare && (
          <span style={{ textAlign: 'left' }}>
            {formatDate(filterStore?.compareStart)} - {formatDate(filterStore?.compareEnd)}
          </span>
        )}
      </div>
    ),
    type: 'number',
    flex: lgUp ? 1 : undefined,
    renderCell: (params) => (
      <span style={{ color: theme.palette.mode === "dark" ? '#6dad8d' : '#065E49', opacity: .6 }}>
        {params.row.organicBrandTrafficCompare}
      </span>
    ),
  },

  // ORGANIC BRAND TRAFFIC DIFF
  {
    field: 'organicBrandTrafficDiff',
    filterable: filterStore?.compare ? true : false,
    disableExport: filterStore?.compare ? true : false,
    hideable: filterStore?.compare ? true : false,
    headerName: "Organic Difference",
    width: 200, minWidth: filterStore?.compare ? 225 : 100, maxWidth: 225,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', fontWeight: 700 }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.organicBrandTraffic')}</span>
        Difference
      </div>
    ),
    type: 'number',
    flex: lgUp ? 1 : undefined,
    renderCell: (params) => (
      <span style={{ color: theme.palette.mode === "dark" ? '#6dad8d' : '#065E49', fontWeight: 900 }}>
        {params.row.organicBrandTrafficDiff}
      </span>
    ),
  },

  // PAID BRAND TRAFFIC
  {
    field: 'paidBrandTraffic',
    headerName: t('dashboard.brandTraffic.paidBrandTraffic') + ` (${formatDate(filterStore?.start)} - ${formatDate(filterStore?.end)})`,
    width: 200, minWidth: filterStore?.compare ? 300 : 200, maxWidth: 225,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.paidBrandTraffic')}</span>
        {filterStore?.compare && (
          <span style={{ textAlign: 'left' }}>
            {formatDate(filterStore?.start)} - {formatDate(filterStore?.end)}
          </span>
        )}
      </div>
    ),
    flex: lgUp ? 1 : undefined,
    type: 'number',
    renderCell: (params) => (
      <span style={{ color: theme.palette.mode === "dark" ? '#65b5c9' : '#006C9C' }}>
        {params.row.paidBrandTraffic}
      </span>
    ),
  },

  // PAID BRAND TRAFFIC COMPARE
  {
    field: 'paidBrandTrafficCompare',
    filterable: filterStore?.compare ? true : false,
    disableExport: filterStore?.compare ? true : false,
    hideable: filterStore?.compare ? true : false,
    headerName: t('dashboard.brandTraffic.paidBrandTraffic') + ` (${formatDate(filterStore?.compareStart)} - ${formatDate(filterStore?.compareEnd)})`,
    width: 200, minWidth: filterStore?.compare ? 300 : 100, maxWidth: 225,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', opacity: .6 }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.paidBrandTraffic')}</span>
        {filterStore?.compare && (
          <span style={{ textAlign: 'left' }}>
            {formatDate(filterStore?.compareStart)} - {formatDate(filterStore?.compareEnd)}
          </span>
        )}
      </div>
    ),
    flex: lgUp ? 1 : undefined,
    type: 'number',
    renderCell: (params) => (
      <span style={{ color: theme.palette.mode === "dark" ? '#65b5c9' : '#006C9C', opacity: .6 }}>
        {params.row.paidBrandTrafficCompare}
      </span>
    ),
  },

  // PAID BRAND TRAFFIC DIFF
  {
    field: 'paidBrandTrafficDiff',
    filterable: filterStore?.compare ? true : false,
    disableExport: filterStore?.compare ? true : false,
    hideable: filterStore?.compare ? true : false,
    headerName: "Paid Brand Traffic Difference",
    width: 200, minWidth: filterStore?.compare ? 225 : 100, maxWidth: 225,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', fontWeight: 700 }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.paidBrandTraffic')}</span>
        Difference
      </div>
    ),
    flex: lgUp ? 1 : undefined,
    type: 'number',
    renderCell: (params) => (
      <span style={{ color: theme.palette.mode === "dark" ? '#65b5c9' : '#006C9C', fontWeight: 900 }}>
        {params.row.paidBrandTrafficDiff}
      </span>
    ),
  },
  // UNCAPTURED BRAND TRAFFIC
  {
    field: 'uncapturedBrandTraffic',
    headerName: t('dashboard.brandTraffic.uncapturedBrandTraffic') + ` (${formatDate(filterStore?.start)} - ${formatDate(filterStore?.end)})`,
    width: 200, minWidth: filterStore?.compare ? 300 : 200, maxWidth: 225,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.uncapturedBrandTraffic')}</span>
        {filterStore?.compare && (
          <span style={{ textAlign: 'left' }}>
            {formatDate(filterStore?.start)} - {formatDate(filterStore?.end)}
          </span>
        )}
      </div>
    ),
    flex: lgUp ? 1 : undefined,
    type: 'number',
    renderCell: (params) => (
      <span style={{ color: theme.palette.mode === "dark" ? '#b7b7b7' : '#B7B7B7' }}>
        {params.row.uncapturedBrandTraffic}
      </span>
    ),
  },

  // UNCAPTURED BRAND TRAFFIC COMPARE
  {
    field: 'uncapturedBrandTrafficCompare',
    filterable: filterStore?.compare ? true : false,
    disableExport: filterStore?.compare ? true : false,
    hideable: filterStore?.compare ? true : false,
    headerName: t('dashboard.brandTraffic.uncapturedBrandTraffic') + ` (${formatDate(filterStore?.compareStart)} - ${formatDate(filterStore?.compareEnd)})`,
    width: 200, minWidth: filterStore?.compare ? 300 : 100, maxWidth: 225,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', opacity: .6 }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.uncapturedBrandTraffic')}</span>
        {filterStore?.compare && (
          <span style={{ textAlign: 'left' }}>
            {formatDate(filterStore?.compareStart)} - {formatDate(filterStore?.compareEnd)}
          </span>
        )}
      </div>
    ),
    flex: lgUp ? 1 : undefined,
    type: 'number',
    renderCell: (params) => (
      <span style={{ color: theme.palette.mode === "dark" ? '#b7b7b7' : '#B7B7B7', opacity: .6 }}>
        {params.row.uncapturedBrandTrafficCompare}
      </span>
    ),
  },

  // UNCAPTURED BRAND TRAFFIC DIFF
  {
    field: 'uncapturedBrandTrafficDiff',
    filterable: filterStore?.compare ? true : false,
    disableExport: filterStore?.compare ? true : false,
    hideable: filterStore?.compare ? true : false,
    width: 200, minWidth: filterStore?.compare ? 225 : 100, maxWidth: 225,
    headerName: "Uncaptured Difference",
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', fontWeight: 700 }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.uncapturedBrandTraffic')}</span>
        Difference
      </div>
    ),
    flex: lgUp ? 1 : undefined,
    type: 'number',
    renderCell: (params) => (
      <span style={{ color: theme.palette.mode === "dark" ? '#b7b7b7' : '#B7B7B7', fontWeight: 900 }}>
        {params.row.uncapturedBrandTrafficDiff}
      </span>
    ),
  },
  // BLENDED CTR
  {
    field: 'blendedCtr',
    width: 200, minWidth: filterStore?.compare ? 245 : 200, maxWidth: 225,
    cellClassName: 'sparkline-cell',
    headerName: t('dashboard.global.blended_ctr') + ` (${formatDate(filterStore?.start)} - ${formatDate(filterStore?.end)})`,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.global.blended_ctr')}</span>
        {filterStore?.compare && (
          <span style={{ textAlign: 'left' }}>
            {formatDate(filterStore?.start)} - {formatDate(filterStore?.end)}
          </span>
        )}
      </div>
    ),
    flex: lgUp ? 1 : undefined,
    renderCell: (params) => {
      const cappedBlendedCtr = Math.min(params.row.blendedCtr, 100); // Limiter à 100%
      const series = [{
        name: 'Blended CTR',
        data: params.row.sparkline?.blended_ctr?.map((point: { date: string; value: number }) => ({
          x: new Date(point.date).getTime(),
          y: point.value
        })) || []
      }];

      return (
        <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1, overflow: 'visible' }}>
          <CustomLineChart series={series} color={'#7635dc'} height={30} width={100} />
          <Typography variant="caption" sx={{ width: 80 }}>
            {cappedBlendedCtr === 0 ? '0%' : fPercent(cappedBlendedCtr)}
          </Typography>
        </Stack>
      );
    },
    type: 'number',
  },

  // BLENDED CTR COMPARE
  {
    field: 'blendedCtrCompare',
    cellClassName: 'sparkline-cell',
    disableExport: filterStore?.compare ? true : false,
    hideable: filterStore?.compare ? true : false,
    filterable: filterStore?.compare ? true : false,
    width: 200, minWidth: filterStore?.compare ? 245 : 100, maxWidth: 225,
    headerName: t('dashboard.global.blended_ctr') + ` (${formatDate(filterStore?.compareStart)} - ${formatDate(filterStore?.compareEnd)})`,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', opacity: .6 }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.global.blended_ctr')}</span>
        {filterStore?.compare && (
          <span style={{ textAlign: 'left' }}>
            {formatDate(filterStore?.compareStart)} - {formatDate(filterStore?.compareEnd)}
          </span>
        )}
      </div>
    ),
    flex: lgUp ? 1 : undefined,
    renderCell: (params) => {
      const cappedBlendedCtr = Math.min(params.row.blendedCtrCompare, 100); // Limiter à 100%
      const series = [{
        name: 'Blended CTR Compare',
        data: params.row.sparkline_compare?.blended_ctr?.map((point: { date: string; value: number }) => ({
          x: new Date(point.date).getTime(),
          y: point.value
        })) || []
      }];

      return (
        <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1, overflow: 'visible' }}>
          <CustomLineChart series={series} color={'#7635dc'} height={30} width={100} />
          <Typography variant="caption" sx={{ width: 80 }}>
            {cappedBlendedCtr === 0 ? '0%' : fPercent(cappedBlendedCtr)}
          </Typography>
        </Stack>
      );
    },
    type: 'number',
  },
  // BLENDED CTR DIFF
  {
    field: 'blendedCtrDiff',
    disableExport: filterStore?.compare ? true : false,
    hideable: filterStore?.compare ? true : false,
    filterable: filterStore?.compare ? true : false,
    headerName: "Blended CTR Difference",
    width: 200, minWidth: filterStore?.compare ? 170 : 100, maxWidth: 225,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', fontWeight: 700 }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.global.blended_ctr')}</span>
        Difference
      </div>
    ),
    flex: lgUp ? 1 : undefined,
    renderCell: (params) => {
      return (
        <span style={{ color: theme.palette.mode === "dark" ? '#b7b7b7' : '#B7B7B7', fontWeight: 900 }}>
          {params.row.blendedCtrDiff} pp%
        </span>
      );
    },

    type: 'number',
  },
  // PAID CTR
  {
    field: 'paidCtr',
    headerName: t('dashboard.brandTraffic.paidCtr') + ` (${formatDate(filterStore?.start)} - ${formatDate(filterStore?.end)})`,
    cellClassName: 'sparkline-cell',
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.paidCtr')}</span>
        {filterStore?.compare && (
          <span style={{ textAlign: 'left' }}>
            {formatDate(filterStore?.start)} - {formatDate(filterStore?.end)}
          </span>
        )}
      </div>
    ),
    width: 200, minWidth: filterStore?.compare ? 245 : 200, maxWidth: 225,
    flex: lgUp ? 1 : undefined,
    renderCell: (params) => {
      const cappedPaidCtr = Math.min(params.row.paidCtr, 100);
      const series = [{
        name: 'Paid CTR',
        data: params.row.sparkline?.paid_ctr?.map((point: { date: string; value: number }) => ({
          x: new Date(point.date).getTime(),
          y: point.value
        })) || []
      }];
      return (
        <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
          <CustomLineChart series={series} color={"#006C9C"} height={30} width={100} />
          <Typography variant="caption" sx={{ width: 80 }}>
            {cappedPaidCtr === 0 ? '0%' : fPercent(cappedPaidCtr)}
          </Typography>
        </Stack>
      );
    },
    type: 'number',
  },
  // PAID CTR COMPARE
  {
    field: 'paidCtrCompare',
    disableExport: filterStore?.compare ? true : false,
    hideable: filterStore?.compare ? true : false,
    cellClassName: 'sparkline-cell',
    filterable: filterStore?.compare ? true : false,
    headerName: t('dashboard.brandTraffic.paidCtr') + ` (${formatDate(filterStore?.compareStart)} - ${formatDate(filterStore?.compareEnd)})`,
    width: 200,
    minWidth: filterStore?.compare ? 245 : 200,
    maxWidth: 225,
    flex: lgUp ? 1 : undefined,
    renderHeader: () => (
      <div style={{
        whiteSpace: 'pre-line',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        opacity: 0.6
      }}>
        <span style={{ textAlign: 'right' }}>
          {t('dashboard.brandTraffic.paidCtr')}
        </span>
        {filterStore?.compare && (
          <span style={{ textAlign: 'left' }}>
            {formatDate(filterStore?.compareStart)} - {formatDate(filterStore?.compareEnd)}
          </span>
        )}
      </div>
    ),
    renderCell: (params) => {
      const cappedPaidCtr = Math.min(params.row.paidCtrCompare, 100); // Limiter à 100%
      const series = [{
        name: 'Paid CTR Compare',
        data: params.row.sparkline_compare?.paid_ctr?.map((point: any) => ({
          x: new Date(point.date).getTime(),
          y: point.value
        })) || []
      }];

      return (
        <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1, overflow: 'visible' }}>
          <CustomLineChart series={series} color={'#006C9C'} height={30} width={100} />
          <Typography variant="caption" sx={{ width: 80 }}>
            {cappedPaidCtr === 0 ? '0%' : fPercent(cappedPaidCtr)}
          </Typography>
        </Stack>
      );
    },
    type: 'number',
  },
  // PAID CTR DIFF
  {
    field: 'paidCtrDiff',
    disableExport: filterStore?.compare ? true : false,
    hideable: filterStore?.compare ? true : false,
    filterable: filterStore?.compare ? true : false,
    headerName: 'Paid CTR Difference',
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', fontWeight: 700 }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.paidCtr')}</span>
        Difference
      </div>
    ),
    width: 200, minWidth: filterStore?.compare ? 170 : 100, maxWidth: 225,
    flex: lgUp ? 1 : undefined,
    renderCell: (params) => {

      return (
        <span style={{ color: theme.palette.mode === "dark" ? '#b7b7b7' : '#B7B7B7', fontWeight: 900 }}>
          {params.row.paidCtrDiff} pp%
        </span>
      );
    },
    type: 'number',
  },
  // SEARCH CTR
  {
    field: 'searchCtr',
    headerName: t('dashboard.brandTraffic.searchCtr') + ` (${formatDate(filterStore?.start)} - ${formatDate(filterStore?.end)})`,
    cellClassName: 'sparkline-cell',
    width: 200, minWidth: filterStore?.compare ? 245 : 200, maxWidth: 225,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.searchCtr')}</span>
        {filterStore?.compare && (
          <span style={{ textAlign: 'left' }}>
            {formatDate(filterStore?.start)} - {formatDate(filterStore?.end)}
          </span>
        )}
      </div>
    ),
    flex: lgUp ? 1 : undefined,
    renderCell: (params) => {
      const series = [{
        name: 'Search CTR',
        data: params.row.sparkline?.paid_ctr_search?.map((point: { date: string; value: number }) => ({
          x: new Date(point.date).getTime(),
          y: point.value
        })) || []
      }];
      return (
        <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
          <CustomLineChart series={series} color={'#006C9C'} height={30} width={100} />
          <Typography variant="caption" sx={{ width: 80 }}>
            {params.row.searchCtr === 0 ? '0%' : fPercent(params.row.searchCtr)}
          </Typography>
        </Stack>
      );
    },
    type: 'number',
  },
  {
    field: 'searchCtrCompare',
    disableExport: filterStore?.compare ? true : false,
    hideable: filterStore?.compare ? true : false,
    cellClassName: 'sparkline-cell',
    filterable: filterStore?.compare ? true : false,
    headerName: t('dashboard.brandTraffic.searchCtr') + ` (${formatDate(filterStore?.compareStart)} - ${formatDate(filterStore?.compareEnd)})`,
    width: 200,
    minWidth: filterStore?.compare ? 245 : 100,
    maxWidth: 225,
    flex: lgUp ? 1 : undefined,
    renderHeader: () => (
      <div
        style={{
          whiteSpace: 'pre-line',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          opacity: 0.6
        }}
      >
        <span style={{ textAlign: 'right' }}>
          {t('dashboard.brandTraffic.searchCtr')}
        </span>
        {filterStore?.compare && (
          <span style={{ textAlign: 'left' }}>
            {formatDate(filterStore?.compareStart)} - {formatDate(filterStore?.compareEnd)}
          </span>
        )}
      </div>
    ),
    renderCell: (params) => {
      const series = [{
        name: 'Search CTR Compare',
        data: params.row.sparkline_compare?.paid_ctr_search?.map((point: any) => ({
          x: new Date(point.date).getTime(),
          y: point.value
        })) || []
      }];

      return (
        <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1, overflow: 'visible' }}>
          <CustomLineChart series={series} color={'#006C9C'} height={30} width={100} />
          <Typography variant="caption" sx={{ width: 80 }}>
            {params.row.searchCtrCompare === 0 ? '0%' : fPercent(params.row.searchCtrCompare)}
          </Typography>
        </Stack>
      );
    },
    type: 'number',
  },
  // SEARCH CTR DIFF
  {
    field: 'searchCtrDiff',
    disableExport: filterStore?.compare ? true : false,
    hideable: filterStore?.compare ? true : false,
    filterable: filterStore?.compare ? true : false,
    headerName: 'Search CTR Difference',
    width: 200, minWidth: filterStore?.compare ? 170 : 100, maxWidth: 225,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', fontWeight: 700 }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.searchCtr')}</span>
        Difference
      </div>
    ),
    flex: lgUp ? 1 : undefined,
    renderCell: (params) => (
      <span style={{ color: theme.palette.mode === "dark" ? '#b7b7b7' : '#B7B7B7', fontWeight: 900 }}>
        {params.row.searchCtrDiff} pp%
      </span>
    ),
    type: 'number',
  },

  // SHOPPING CTR
  {
    field: 'shoppingCtr',
    headerName: t('dashboard.brandTraffic.shoppingCtr') + ` (${formatDate(filterStore?.start)} - ${formatDate(filterStore?.end)})`,
    cellClassName: 'sparkline-cell',
    width: 200, minWidth: filterStore?.compare ? 245 : 200, maxWidth: 225,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.shoppingCtr')}</span>
        {filterStore?.compare && (
          <span style={{ textAlign: 'left' }}>
            {formatDate(filterStore?.start)} - {formatDate(filterStore?.end)}
          </span>
        )}
      </div>
    ),
    flex: lgUp ? 1 : undefined,
    renderCell: (params) => {
      const series: any = [{
        name: 'Shopping CTR',
        data: params.row.sparkline?.paid_ctr_shopping?.map((point: { date: string; value: number }) => ({
          x: new Date(point.date).getTime(),
          y: point.value
        })) || []
      }];
      return (
        <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
          <CustomLineChart series={series} color={'#006C9C'} height={30} width={100} />
          <Typography variant="caption" sx={{ width: 80 }}>
            {params.row.shoppingCtr === 0 ? '0%' : fPercent(params.row.shoppingCtr)}
          </Typography>
        </Stack>
      );
    },
    type: 'number',
  },

  // SHOPPING CTR COMPARE
  {
    field: 'shoppingCtrCompare',
    disableExport: filterStore?.compare ? true : false,
    hideable: filterStore?.compare ? true : false,
    cellClassName: 'sparkline-cell',
    filterable: filterStore?.compare ? true : false,
    headerName: t('dashboard.brandTraffic.shoppingCtr') + ` (${formatDate(filterStore?.compareStart)} - ${formatDate(filterStore?.compareEnd)})`,
    width: 200,
    minWidth: filterStore?.compare ? 245 : 100,
    maxWidth: 225,
    flex: lgUp ? 1 : undefined,
    renderHeader: () => (
      <div style={{
        whiteSpace: 'pre-line',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        opacity: 0.6
      }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.shoppingCtr')}</span>
        {filterStore?.compare && (
          <span style={{ textAlign: 'left' }}>
            {formatDate(filterStore?.compareStart)} - {formatDate(filterStore?.compareEnd)}
          </span>
        )}
      </div>
    ),
    renderCell: (params) => {
      const series = [{
        name: 'Shopping CTR Compare',
        data: params.row.sparkline_compare?.paid_ctr_shopping?.map((point: any) => ({
          x: new Date(point.date).getTime(),
          y: point.value
        })) || []
      }];

      return (
        <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1, overflow: 'visible' }}>
          <CustomLineChart series={series} color={'#006C9C'} height={30} width={100} />
          <Typography variant="caption" sx={{ width: 80 }}>
            {params.row.shoppingCtrCompare === 0 ? '0%' : fPercent(params.row.shoppingCtrCompare)}
          </Typography>
        </Stack>
      );
    },
    type: 'number',
  },

  // SHOPPING CTR DIFF
  {
    field: 'shoppingCtrDiff',
    disableExport: filterStore?.compare ? true : false,
    hideable: filterStore?.compare ? true : false,
    filterable: filterStore?.compare ? true : false,
    headerName: 'Shopping CTR Difference',
    width: 200, minWidth: filterStore?.compare ? 170 : 100, maxWidth: 225,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', fontWeight: 700 }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.shoppingCtr')}</span>
        Difference
      </div>
    ),
    flex: lgUp ? 1 : undefined,
    renderCell: (params) => (
      <span style={{ color: theme.palette.mode === "dark" ? '#b7b7b7' : '#B7B7B7', fontWeight: 900 }}>
        {params.row.shoppingCtrDiff} pp%
      </span>
    ),
    type: 'number',
  },

  // PMAX CTR
  {
    field: 'pmaxCtr',
    headerName: t('dashboard.brandTraffic.pmaxCtr') + ` (${formatDate(filterStore?.start)} - ${formatDate(filterStore?.end)})`,
    cellClassName: 'sparkline-cell',
    width: 200, minWidth: filterStore?.compare ? 245 : 200, maxWidth: 225,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.pmaxCtr')}</span>
        {filterStore?.compare && (
          <span style={{ textAlign: 'left' }}>
            {formatDate(filterStore?.start)} - {formatDate(filterStore?.end)}
          </span>
        )}
      </div>
    ),
    flex: lgUp ? 1 : undefined,
    renderCell: (params) => {
      const series = [{
        name: 'Pmax CTR',
        data: params.row.sparkline?.paid_ctr_pmax?.map((point: { date: string; value: number }) => ({
          x: new Date(point.date).getTime(),
          y: point.value
        })) || []
      }];
      return (
        <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
          <CustomLineChart series={series} color={'#006C9C'} height={30} width={100} />
          <Typography variant="caption" sx={{ width: 80 }}>
            {params.row.pmaxCtr === 0 ? '0%' : fPercent(params.row.pmaxCtr)}
          </Typography>
        </Stack>
      );
    },
    type: 'number',
  },

  // PMAX CTR COMPARE
  {
    field: 'pmaxCtrCompare',
    cellClassName: 'sparkline-cell',
    disableExport: filterStore?.compare ? true : false,
    hideable: filterStore?.compare ? true : false,
    filterable: filterStore?.compare ? true : false,
    headerName: t('dashboard.brandTraffic.pmaxCtr') + ` (${formatDate(filterStore?.compareStart)} - ${formatDate(filterStore?.compareEnd)})`,
    width: 200,
    minWidth: filterStore?.compare ? 245 : 100,
    maxWidth: 225,
    flex: lgUp ? 1 : undefined,
    renderHeader: () => (
      <div style={{
        whiteSpace: 'pre-line',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        opacity: 0.6
      }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.pmaxCtr')}</span>
        {filterStore?.compare && (
          <span style={{ textAlign: 'left' }}>
            {formatDate(filterStore?.compareStart)} - {formatDate(filterStore?.compareEnd)}
          </span>
        )}
      </div>
    ),
    renderCell: (params) => {
      const series = [{
        name: 'Pmax CTR Compare',
        data: params.row.sparkline_compare?.paid_ctr_pmax?.map((point: any) => ({
          x: new Date(point.date).getTime(),
          y: point.value
        })) || []
      }];
      return (
        <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1, overflow: 'visible' }}>
          <CustomLineChart series={series} color={'#006C9C'} height={30} width={100} />
          <Typography variant="caption" sx={{ width: 80 }}>
            {params.row.pmaxCtrCompare === 0 ? '0%' : fPercent(params.row.pmaxCtrCompare)}
          </Typography>
        </Stack>
      );
    },
    type: 'number',
  },
  // PMAX CTR DIFF
  {
    field: 'pmaxCtrDiff',
    disableExport: filterStore?.compare ? true : false,
    hideable: filterStore?.compare ? true : false,
    filterable: filterStore?.compare ? true : false,
    headerName: 'Pmax CTR Difference',
    width: 200, minWidth: filterStore?.compare ? 170 : 100, maxWidth: 225,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', fontWeight: 700 }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.pmaxCtr')}</span>
        Difference
      </div>
    ),
    flex: lgUp ? 1 : undefined,
    renderCell: (params) => (
      <span style={{ color: theme.palette.mode === "dark" ? '#b7b7b7' : '#B7B7B7', fontWeight: 900 }}>
        {params.row.pmaxCtrDiff} pp%
      </span>
    ),
    type: 'number',
  },
  // ORGANIC CTR
  {
    field: 'organicCtr',
    cellClassName: 'sparkline-cell',
    headerName: t('dashboard.brandTraffic.organicCtr') + ` (${formatDate(filterStore?.start)} - ${formatDate(filterStore?.end)})`,
    width: 200, minWidth: filterStore?.compare ? 245 : 200, maxWidth: 225,

    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.organicCtr')}</span>
        {filterStore?.compare && (
          <span style={{ textAlign: 'left' }}>
            {formatDate(filterStore?.start)} - {formatDate(filterStore?.end)}
          </span>
        )}
      </div>
    ),
    flex: lgUp ? 1 : undefined,
    renderCell: (params) => {
      const series = [{
        name: 'Organic CTR',
        data: params.row.sparkline?.organic_ctr?.map((point: { date: string; value: number }) => ({
          x: new Date(point.date).getTime(),
          y: point.value
        })) || []
      }];
      return (
        <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
          <CustomLineChart series={series} color={"#065E49"} height={30} width={100} />
          <Typography variant="caption" sx={{ width: 80 }}>
            {params.row.organicCtr === 0 ? '0%' : fPercent(params.row.organicCtr)}
          </Typography>
        </Stack>
      );
    },
    type: 'number',
  },
  // ORGANIC CTR COMPARE
  {
    field: 'organicCtrCompare',
    disableExport: filterStore?.compare ? true : false,
    hideable: filterStore?.compare ? true : false,
    cellClassName: 'sparkline-cell',
    filterable: filterStore?.compare ? true : false,
    headerName: t('dashboard.brandTraffic.organicCtr') + ` (${formatDate(filterStore?.compareStart)} - ${formatDate(filterStore?.compareEnd)})`,
    width: 200,
    minWidth: filterStore?.compare ? 245 : 100,
    maxWidth: 225,
    flex: lgUp ? 1 : undefined,
    renderHeader: () => (
      <div
        style={{
          whiteSpace: 'pre-line',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          opacity: 0.6,
        }}
      >
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.organicCtr')}</span>
        {filterStore?.compare && (
          <span style={{ textAlign: 'left' }}>
            {formatDate(filterStore?.compareStart)} - {formatDate(filterStore?.compareEnd)}
          </span>
        )}
      </div>
    ),
    renderCell: (params) => {
      const series = [{
        name: 'Organic CTR Compare',
        data: params.row.sparkline_compare?.organic_ctr?.map((point: any) => ({
          x: new Date(point.date).getTime(),
          y: point.value,
        })) || [],
      }];

      return (
        <Stack
          spacing={1}
          direction="row"
          alignItems="center"
          sx={{ px: 1, width: 1, height: 1, overflow: 'visible' }}
        >
          <CustomLineChart series={series} color={'#065E49'} height={30} width={100} />
          <Typography variant="caption" sx={{ width: 80 }}>
            {params.row.organicCtrCompare === 0 ? '0%' : fPercent(params.row.organicCtrCompare)}
          </Typography>
        </Stack>
      );
    },
    type: 'number',
  },
  // ORGANIC CTR DIFF
  {
    field: 'organicCtrDiff',
    disableExport: filterStore?.compare ? true : false,
    hideable: filterStore?.compare ? true : false,
    filterable: filterStore?.compare ? true : false,
    headerName: "Organic CTR Difference",
    width: 200, minWidth: filterStore?.compare ? 170 : 100, maxWidth: 225,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', fontWeight: 700 }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.organicCtr')}</span>
        Difference
      </div>
    ),
    flex: lgUp ? 1 : undefined,
    renderCell: (params) => (
      <span style={{ color: theme.palette.mode === "dark" ? '#b7b7b7' : '#B7B7B7', fontWeight: 900 }}>
        {params.row.organicCtrDiff === 0 ? '0%' : params.row.organicCtrDiff} pp%
      </span>
    ),
    type: 'number',
  },
  // UNCAPTURED PERCENT
  {
    field: 'uncapturedPercent',
    width: 200, minWidth: filterStore?.compare ? 245 : 200, maxWidth: 225,
    headerName: t('dashboard.brandTraffic.percentUncaptured') + ` (${formatDate(filterStore?.start)} - ${formatDate(filterStore?.end)})`,
    cellClassName: 'sparkline-cell',
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.percentUncaptured')}</span>
        {filterStore?.compare && (
          <span style={{ textAlign: 'left' }}>
            {formatDate(filterStore?.start)} - {formatDate(filterStore?.end)}
          </span>
        )}
      </div>
    ),
    flex: lgUp ? 1 : undefined,
    renderCell: (params) => {
      const series = [{
        name: '% Uncaptured',
        data: params.row.sparkline?.uncaptured_percent?.map((point: { date: string; value: number }) => ({
          x: new Date(point.date).getTime(),
          y: point.value
        })) || []
      }];
      const cappedUncapturedPercent = Number(params.row.uncapturedPercent);
      return (
        <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
          <CustomLineChart series={series} color={'#ADADAD'} height={30} width={100} />
          <Typography variant="caption" sx={{ width: 80 }}>
            {cappedUncapturedPercent === 0 ? '0%' : fPercent(cappedUncapturedPercent)}
          </Typography>
        </Stack>
      )
    },
    type: 'number',
  },
  // UNCAPTURED PERCENT COMPARE
  {
    field: 'uncapturedPercentCompare',
    cellClassName: 'sparkline-cell',
    disableExport: filterStore?.compare ? true : false,
    hideable: filterStore?.compare ? true : false,
    filterable: filterStore?.compare ? true : false,
    headerName: t('dashboard.brandTraffic.percentUncaptured') + ` (${formatDate(filterStore?.compareStart)} - ${formatDate(filterStore?.compareEnd)})`,
    width: 200,
    minWidth: filterStore?.compare ? 245 : 100,
    maxWidth: 225,
    flex: lgUp ? 1 : undefined,
    renderHeader: () => (
      <div
        style={{
          whiteSpace: 'pre-line',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          opacity: 0.6,
        }}
      >
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.percentUncaptured')}</span>
        {filterStore?.compare && (
          <span style={{ textAlign: 'left' }}>
            {formatDate(filterStore?.compareStart)} - {formatDate(filterStore?.compareEnd)}
          </span>
        )}
      </div>
    ),
    renderCell: (params) => {
      const series = [{
        name: 'Uncaptured % Compare',
        data: params.row.sparkline_compare?.uncaptured_percent?.map((point: any) => ({
          x: new Date(point.date).getTime(),
          y: point.value,
        })) || [],
      }];
      return (
        <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1, overflow: 'visible' }}>
          <CustomLineChart series={series} color={'#ADADAD'} height={30} width={100} />
          <Typography variant="caption" sx={{ width: 80 }}>
            {params.row.uncapturedPercentCompare === 0 ? '0%' : fPercent(params.row.uncapturedPercentCompare)}
          </Typography>
        </Stack>
      );
    },
    type: 'number',
  },
  // UNCAPTURED PERCENT DIFF
  {
    field: 'uncapturedPercentDiff',
    disableExport: filterStore?.compare ? true : false,
    hideable: filterStore?.compare ? true : false,
    filterable: filterStore?.compare ? true : false,
    headerName: '% Uncaptured Difference',
    width: 200, minWidth: filterStore?.compare ? 170 : 100, maxWidth: 225,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', fontWeight: 700 }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.percentUncaptured')}</span>
        Difference
      </div>
    ),
    flex: lgUp ? 1 : undefined,
    renderCell: (params) => (
      <span style={{ color: theme.palette.mode === "dark" ? '#b7b7b7' : '#B7B7B7', fontWeight: 900 }}>
        {params.row.uncapturedPercentDiff === 0 ? '0%' : params.row.uncapturedPercentDiff} pp%
      </span>
    ),
    type: 'number',
  },

  // paid CPC
  {
    field: 'paidCpc',
    headerName: t('dashboard.brandTraffic.cpc') + ` (${formatDate(filterStore?.start)} - ${formatDate(filterStore?.end)})`,
    cellClassName: 'sparkline-cell',
    width: 200, minWidth: filterStore?.compare ? 245 : 200, maxWidth: 225,
    flex: lgUp ? 1 : undefined,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.cpc')}</span>
        {filterStore?.compare && (
          <span style={{ textAlign: 'left' }}>
            {formatDate(filterStore?.start)} - {formatDate(filterStore?.end)}
          </span>
        )}
      </div>
    ),
    renderCell: (params) => {
      const series = [{
        name: 'Paid CPC',
        data: params.row.sparkline?.cpc?.map((point: { date: string; value: number }) => ({
          x: new Date(point.date).getTime(),
          y: point.value
        })) || []
      }];
      return (
        <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
          <CustomLineChart series={series} formatValue={(value: any) => formatCurrency(value, currency, locale, false, 2)} color={'#FF5630'} type="bar" height={30} width={100} />
          <Typography variant="caption" sx={{ width: 80 }}>
            {formatCurrency(params.row.paidCpc, currency, locale, false, 2)}
          </Typography>
        </Stack>
      );
    },
    type: 'number',
  },

  // paid CPC COMPARE
  {
    field: 'paidCpcCompare',
    cellClassName: 'sparkline-cell',
    disableExport: filterStore?.compare ? true : false,
    hideable: filterStore?.compare ? true : false,
    filterable: filterStore?.compare ? true : false,
    headerName: t('dashboard.brandTraffic.cpc') + ` (${formatDate(filterStore?.compareStart)} - ${formatDate(filterStore?.compareEnd)})`,
    width: 200, minWidth: filterStore?.compare ? 245 : 200, maxWidth: 225,
    flex: lgUp ? 1 : undefined,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', opacity: .6 }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.cpc')}</span>
        {filterStore?.compare && (
          <span style={{ textAlign: 'left' }}>
            {formatDate(filterStore?.compareStart)} - {formatDate(filterStore?.compareEnd)}
          </span>
        )}
      </div>
    ),
    renderCell: (params) => {
      const series = [{
        name: 'Paid CPC Compare',
        data: params.row.sparkline?.cpc?.map((point: { date: string; value: number }) => ({
          x: new Date(point.date).getTime(),
          y: point.value
        })) || []
      }];
      return (
        <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
          <CustomLineChart series={series} formatValue={(value: any) => formatCurrency(value, currency, locale, false, 2)} color={'#FF5630'} type="bar" height={30} width={100} />
          <Typography variant="caption" sx={{ width: 80, opacity: 0.6 }}>
            {formatCurrency(params.row.paidCpcCompare, currency, locale, false, 2)}
          </Typography>
        </Stack>
      )
    },
    type: 'number',
  },

  // paid CPC DIFF
  {
    field: 'paidCpcDiff',
    disableExport: filterStore?.compare ? true : false,
    hideable: filterStore?.compare ? true : false,
    filterable: filterStore?.compare ? true : false,
    headerName: "Paid CPC Difference",
    width: 200, minWidth: filterStore?.compare ? 220 : 100, maxWidth: 225,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', fontWeight: 700 }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.cpc')}</span>
        Difference
      </div>
    ),
    flex: lgUp ? 1 : undefined,
    renderCell: (params) => (
      <Typography variant='caption' style={{ fontWeight: 900 }}>
        {formatCurrency(params.row.paidCpcDiff, currency, locale, false, 2)}
      </Typography>
    ),
    type: 'number',
  },
  // PAID SEARCH CPC
  {
    field: 'paidSearchCpc',
    headerName: `${t('dashboard.brandTraffic.search_cpc')} (${formatDate(filterStore?.start)} - ${formatDate(filterStore?.end)})`,
    cellClassName: 'sparkline-cell',
    width: 200, minWidth: filterStore?.compare ? 245 : 200, maxWidth: 225,
    flex: lgUp ? 1 : undefined,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.search_cpc')}</span>
        {filterStore?.compare && (
          <span style={{ textAlign: 'left' }}>
            {formatDate(filterStore?.start)} - {formatDate(filterStore?.end)}
          </span>
        )}
      </div>
    ),
    renderCell: (params) => {
      const series = [{
        name: 'Paid Search CPC',
        data: params.row.sparkline?.cpc_search?.map((point: { date: string; value: number }) => ({
          x: new Date(point.date).getTime(),
          y: point.value
        })) || []
      }];
      return (
        <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
          <CustomLineChart series={series} formatValue={(value: any) => formatCurrency(value, currency, locale, false, 2)} color={'#FF5630'} type="bar" height={30} width={100} />
          <Typography variant="caption" sx={{ width: 80 }}>
            {formatCurrency(params.row.paidSearchCpc, currency, locale, false, 2)}
          </Typography>
        </Stack>
      );
    },
    type: 'number',
  },

  // PAID SEARCH CPC COMPARE
  {
    field: 'paidSearchCpcCompare',
    cellClassName: 'sparkline-cell',
    disableExport: filterStore?.compare ? true : false,
    hideable: filterStore?.compare ? true : false,
    filterable: filterStore?.compare ? true : false,
    headerName: `${t('dashboard.brandTraffic.search_cpc')} (${formatDate(filterStore?.compareStart)} - ${formatDate(filterStore?.compareEnd)})`,
    width: 200, minWidth: filterStore?.compare ? 220 : 100, maxWidth: 225,
    flex: lgUp ? 1 : undefined,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', opacity: .6 }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.search_cpc')}</span>
        {filterStore?.compare && (
          <span style={{ textAlign: 'left' }}>
            {formatDate(filterStore?.compareStart)} - {formatDate(filterStore?.compareEnd)}
          </span>
        )}
      </div>
    ),
    renderCell: (params) => {
      const series = [{
        name: 'Paid Search CPC Compare',
        data: params.row.sparkline_compare?.cpc_search?.map((point: { date: string; value: number }) => ({
          x: new Date(point.date).getTime(),
          y: point.value
        })) || []
      }];
      return (
        <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
          <CustomLineChart series={series} formatValue={(value: any) => formatCurrency(value, currency, locale, false, 2)} color={'#FF5630'} type="bar" height={30} width={100} />
          <Typography variant="caption" sx={{ width: 80 }}>
            {formatCurrency(params.row.paidSearchCpcCompare, currency, locale, false, 2)}
          </Typography>
        </Stack>
      );
    },
    type: 'number',
  },
  // PAID SEARCH CPC DIFF
  {
    field: 'paidSearchCpcDiff',
    disableExport: filterStore?.compare ? true : false,
    hideable: filterStore?.compare ? true : false,
    filterable: filterStore?.compare ? true : false,
    headerName: "Paid Search CPC Difference",
    width: 200, minWidth: filterStore?.compare ? 220 : 100, maxWidth: 225,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', fontWeight: 700 }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.search_cpc')}</span>
        Difference
      </div>
    ),
    flex: lgUp ? 1 : undefined,
    renderCell: (params) => (
      <Typography variant='caption' style={{ fontWeight: 900 }}>
        {formatCurrency(params.row.paidSearchCpcDiff, currency, locale, false, 2)}
      </Typography>
    ),
    type: 'number',
  },
  // PAID SHOPPING CPC
  {
    field: 'paidShoppingCpc',
    headerName: `${t('dashboard.brandTraffic.shopping_cpc')} (${formatDate(filterStore?.start)} - ${formatDate(filterStore?.end)})`,
    cellClassName: 'sparkline-cell',
    width: 200, minWidth: filterStore?.compare ? 245 : 200, maxWidth: 225,
    flex: lgUp ? 1 : undefined,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.shopping_cpc')}</span>
        {filterStore?.compare && (
          <span style={{ textAlign: 'left' }}>
            {formatDate(filterStore?.start)} - {formatDate(filterStore?.end)}
          </span>
        )}
      </div>
    ),
    renderCell: (params) => {
      const series = [{
        name: 'Paid Shopping CPC',
        data: params.row.sparkline?.cpc_shopping?.map((point: { date: string; value: number }) => ({
          x: new Date(point.date).getTime(),
          y: point.value
        })) || []
      }];
      return (
        <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
          <CustomLineChart series={series} formatValue={(value: any) => formatCurrency(value, currency, locale, false, 2)} color={'#FF5630'} type="bar" height={30} width={100} />
          <Typography variant="caption" sx={{ width: 80 }}>
            {formatCurrency(params.row.paidShoppingCpc, currency, locale, false, 2)}
          </Typography>
        </Stack>
      );
    },
    type: 'number',
  },

  // PAID SHOPPING CPC COMPARE
  {
    field: 'paidShoppingCpcCompare',
    disableExport: filterStore?.compare ? true : false,
    cellClassName: 'sparkline-cell',
    hideable: filterStore?.compare ? true : false,
    filterable: filterStore?.compare ? true : false,
    headerName: `${t('dashboard.brandTraffic.shopping_cpc')} (${formatDate(filterStore?.compareStart)} - ${formatDate(filterStore?.compareEnd)})`,
    width: 200, minWidth: filterStore?.compare ? 220 : 100, maxWidth: 225,
    flex: lgUp ? 1 : undefined,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', opacity: .6 }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.shopping_cpc')}</span>
        {filterStore?.compare && (
          <span style={{ textAlign: 'left' }}>
            {formatDate(filterStore?.compareStart)} - {formatDate(filterStore?.compareEnd)}
          </span>
        )}
      </div>
    ),
    renderCell: (params) => {
      const series = [{
        name: 'Paid Shopping CPC Compare',
        data: params.row.sparkline_compare?.cpc_shopping?.map((point: { date: string; value: number }) => ({
          x: new Date(point.date).getTime(),
          y: point.value
        })) || []
      }];
      return (
        <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
          <CustomLineChart series={series} formatValue={(value: any) => formatCurrency(value, currency, locale, false, 2)} color={'#FF5630'} type="bar" height={30} width={100} />
          <Typography variant="caption" sx={{ width: 80 }}>
            {formatCurrency(params.row.paidShoppingCpcCompare, currency, locale, false, 2)}
          </Typography>
        </Stack>
      );
    },
    type: 'number',
  },
  // PAID SHOPPING CPC DIFF
  {
    field: 'paidShoppingCpcDiff',
    disableExport: filterStore?.compare ? true : false,
    hideable: filterStore?.compare ? true : false,
    filterable: filterStore?.compare ? true : false,
    headerName: "Paid Shopping CPC Difference",
    width: 200, minWidth: filterStore?.compare ? 220 : 100, maxWidth: 225,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', fontWeight: 700 }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.shopping_cpc')}</span>
        Difference
      </div>
    ),
    flex: lgUp ? 1 : undefined,
    renderCell: (params) => (
      <Typography variant='caption' style={{ fontWeight: 900 }}>
        {formatCurrency(params.row.paidShoppingCpcDiff, currency, locale, false, 2)}
      </Typography>
    ),
    type: 'number',
  },
  // PAID PMAX CPC
  {
    field: 'paidPmaxCpc',
    headerName: `${t('dashboard.brandTraffic.pmax_cpc')} (${formatDate(filterStore?.start)} - ${formatDate(filterStore?.end)})`,
    cellClassName: 'sparkline-cell',
    width: 200, minWidth: filterStore?.compare ? 245 : 200, maxWidth: 225,
    flex: lgUp ? 1 : undefined,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.pmax_cpc')}</span>
        {filterStore?.compare && (
          <span style={{ textAlign: 'left' }}>
            {formatDate(filterStore?.start)} - {formatDate(filterStore?.end)}
          </span>
        )}
      </div>
    ),
    renderCell: (params) => {
      const series = [{
        name: 'Paid Pmax CPC',
        data: params.row.sparkline?.cpc_pmax?.map((point: { date: string; value: number }) => ({
          x: new Date(point.date).getTime(),
          y: point.value
        })) || []
      }];
      return (
        <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
          <CustomLineChart series={series} formatValue={(value: any) => formatCurrency(value, currency, locale, false, 2)} color={'#FF5630'} type="bar" height={30} width={100} />
          <Typography variant="caption" sx={{ width: 80 }}>
            {formatCurrency(params.row.paidPmaxCpc, currency, locale, false, 2)}
          </Typography>
        </Stack>
      );
    },
    type: 'number',
  },

  // PAID PMAX CPC COMPARE
  {
    field: 'paidPmaxCpcCompare',
    disableExport: filterStore?.compare ? true : false,
    cellClassName: 'sparkline-cell',
    hideable: filterStore?.compare ? true : false,
    filterable: filterStore?.compare ? true : false,
    headerName: `${t('dashboard.brandTraffic.pmax_cpc')} (${formatDate(filterStore?.compareStart)} - ${formatDate(filterStore?.compareEnd)})`,
    width: 200, minWidth: filterStore?.compare ? 220 : 100, maxWidth: 225,
    flex: lgUp ? 1 : undefined,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', opacity: .6 }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.pmax_cpc')}</span>
        {filterStore?.compare && (
          <span style={{ textAlign: 'left' }}>
            {formatDate(filterStore?.compareStart)} - {formatDate(filterStore?.compareEnd)}
          </span>
        )}
      </div>
    ),
    renderCell: (params) => {
      const series = [{
        name: 'Paid Pmax CPC Compare',
        data: params.row.sparkline_compare?.cpc_pmax?.map((point: { date: string; value: number }) => ({
          x: new Date(point.date).getTime(),
          y: point.value
        })) || []
      }];
      return (
        <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
          <CustomLineChart series={series} formatValue={(value: any) => formatCurrency(value, currency, locale, false, 2)} color={'#FF5630'} type="bar" height={30} width={100} />
          <Typography variant="caption" sx={{ width: 80 }}>
            {formatCurrency(params.row.paidPmaxCpcCompare, currency, locale, false, 2)}
          </Typography>
        </Stack>
      );
    },
    type: 'number',
  },
  // PAID PMAX CPC DIFF
  {
    field: 'paidPmaxCpcDiff',
    disableExport: filterStore?.compare ? true : false,
    hideable: filterStore?.compare ? true : false,
    filterable: filterStore?.compare ? true : false,
    headerName: "Paid Pmax CPC Difference",
    width: 200, minWidth: filterStore?.compare ? 220 : 100, maxWidth: 225,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', fontWeight: 700 }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.pmax_cpc')}</span>
        Difference
      </div>
    ),
    flex: lgUp ? 1 : undefined,
    renderCell: (params) => (
      <Typography variant='caption' style={{ fontWeight: 900 }}>
        {formatCurrency(params.row.paidPmaxCpcDiff, currency, locale, false, 2)}
      </Typography>
    ),
    type: 'number',
  },

  // AD COST
  {
    field: 'adCost',
    headerName: t('dashboard.global.ad_cost') + ` (${formatDate(filterStore?.start)} - ${formatDate(filterStore?.end)})`,
    cellClassName: 'sparkline-cell',
    width: 200, minWidth: filterStore?.compare ? 300 : 200, maxWidth: 225,
    flex: lgUp ? 1 : undefined,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.global.ad_cost')}</span>
        {filterStore?.compare && (
          <span style={{ textAlign: 'left' }}>
            {formatDate(filterStore?.start)} - {formatDate(filterStore?.end)}
          </span>
        )}
      </div>
    ),
    renderCell: (params) => {
      const series = [{
        name: 'Ad Cost',
        data: params.row.sparkline?.ad_cost?.map((point: { date: string; value: number }) => ({
          x: new Date(point.date).getTime(),
          y: point.value
        })) || []
      }];
      return (
        <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
          <CustomLineChart series={series} formatValue={(value: any) => formatCurrency(value, currency, locale, false, 2)} type="bar" color={"#FFD066"} height={30} width={100} />
          <Typography variant="caption" sx={{ width: 80 }}>
            {formatCurrency(params.row.adCost, currency, locale, false, 1)}
          </Typography>
        </Stack>
      );
    },
    type: 'number',
  },

  // AD COST COMPARE
  {
    field: 'adCostCompare',
    disableExport: filterStore?.compare ? true : false,
    cellClassName: 'sparkline-cell',
    hideable: filterStore?.compare ? true : false,
    filterable: filterStore?.compare ? true : false,
    headerName: t('dashboard.global.ad_cost') + ` (${formatDate(filterStore?.compareStart)} - ${formatDate(filterStore?.compareEnd)})`,
    width: 200, minWidth: filterStore?.compare ? 300 : 100, maxWidth: 225,
    flex: lgUp ? 1 : undefined,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', opacity: .6 }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.global.ad_cost')}</span>
        {filterStore?.compare && (
          <span style={{ textAlign: 'left' }}>
            {formatDate(filterStore?.compareStart)} - {formatDate(filterStore?.compareEnd)}
          </span>
        )}
      </div>
    ),
    renderCell: (params) => {
      const series = [{
        name: 'Ad Cost Compare',
        data: params.row.sparkline_compare?.ad_cost?.map((point: { date: string; value: number }) => ({
          x: new Date(point.date).getTime(),
          y: point.value
        })) || []
      }];
      return (
        <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
          <CustomLineChart series={series} formatValue={(value: any) => formatCurrency(value, currency, locale, false, 2)} type="bar" color={"#FFD066"} height={30} width={100} />
          <Typography variant="caption" sx={{ width: 80 }}>
            {formatCurrency(params.row.adCostCompare, currency, locale, false, 1)}
          </Typography>
        </Stack>
      );
    },
    type: 'number',
  },
  // AD COST DIFF
  {
    field: 'adCostDiff',
    disableExport: filterStore?.compare ? true : false,
    hideable: filterStore?.compare ? true : false,
    filterable: filterStore?.compare ? true : false,
    headerName: "Ad Cost Difference",
    width: 200, minWidth: filterStore?.compare ? 225 : 100, maxWidth: 225,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', fontWeight: 700 }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.global.ad_cost')}</span>
        Difference
      </div>
    ),
    flex: lgUp ? 1 : undefined,
    renderCell: (params) => (
      <Typography variant='caption' style={{ fontWeight: 900 }}>
        {formatCurrency(params.row.adCostDiff, currency, locale, false, 1)}
      </Typography>
    ),
    type: 'number',
  },
  // AD COST SEARCH
  {
    field: 'adCostSearch',
    cellClassName: 'sparkline-cell',
    headerName: `${t('dashboard.global.ad_cost_search')} (${formatDate(filterStore?.start)} - ${formatDate(filterStore?.end)})`,
    width: 200, minWidth: filterStore?.compare ? 300 : 200, maxWidth: 225,
    flex: lgUp ? 1 : undefined,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.global.ad_cost_search')}</span>
        {filterStore?.compare && (
          <span style={{ textAlign: 'left' }}>
            {formatDate(filterStore?.start)} - {formatDate(filterStore?.end)}
          </span>
        )}
      </div>
    ),
    renderCell: (params) => {
      const series = [{
        name: 'Ad Cost Search',
        data: params.row.sparkline?.ad_cost_search?.map((point: { date: string; value: number }) => ({
          x: new Date(point.date).getTime(),
          y: point.value
        })) || []
      }];
      return (
        <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
          <CustomLineChart series={series} formatValue={(value: any) => formatCurrency(value, currency, locale, false, 2)} type="bar" color={"#FFD066"} height={30} width={100} />
          <Typography variant="caption" sx={{ width: 80 }}>
            {formatCurrency(params.row.adCostSearch, currency, locale, false, 0)}
          </Typography>
        </Stack>
      );
    },
    type: 'number',
  },

  // AD COST SEARCH COMPARE
  {
    field: 'adCostSearchCompare',
    disableExport: filterStore?.compare ? true : false,
    cellClassName: 'sparkline-cell',
    hideable: filterStore?.compare ? true : false,
    filterable: filterStore?.compare ? true : false,
    headerName: `${t('dashboard.global.ad_cost_search')} (${formatDate(filterStore?.compareStart)} - ${formatDate(filterStore?.compareEnd)})`,
    width: 200, minWidth: filterStore?.compare ? 300 : 100, maxWidth: 225,
    flex: lgUp ? 1 : undefined,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', opacity: .6 }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.global.ad_cost_search')}</span>
        {filterStore?.compare && (
          <span style={{ textAlign: 'left' }}>
            {formatDate(filterStore?.compareStart)} - {formatDate(filterStore?.compareEnd)}
          </span>
        )}
      </div>
    ),
    renderCell: (params) => {
      const series = [{
        name: 'Ad Cost Search Compare',
        data: params.row.sparkline_compare?.ad_cost_search?.map((point: { date: string; value: number }) => ({
          x: new Date(point.date).getTime(),
          y: point.value
        })) || []
      }];
      return (
        <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
          <CustomLineChart series={series} formatValue={(value: any) => formatCurrency(value, currency, locale, false, 2)} type="bar" color={"#FFD066"} height={30} width={100} />
          <Typography variant="caption" sx={{ width: 80 }}>
            {formatCurrency(params.row.adCostSearchCompare, currency, locale, false, 0)}
          </Typography>
        </Stack>
      );
    },
    type: 'number',
  },
  // AD COST SEARCH DIFF
  {
    field: 'adCostSearchDiff',
    disableExport: filterStore?.compare ? true : false,
    hideable: filterStore?.compare ? true : false,
    filterable: filterStore?.compare ? true : false,
    headerName: "Ad Cost Search Difference",
    width: 200, minWidth: filterStore?.compare ? 225 : 100, maxWidth: 225,
    flex: lgUp ? 1 : undefined,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', fontWeight: 700 }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.global.ad_cost_search')}</span>
        Difference
      </div>
    ),
    renderCell: (params) => (
      <Typography variant='caption' style={{ fontWeight: 900 }}>
        {formatCurrency(params.row.adCostSearchDiff, currency, locale, false, 0)}
      </Typography>
    ),
    type: 'number',
  },
  // AD COST SHOPPING
  {
    field: 'adCostShopping',
    cellClassName: 'sparkline-cell',
    headerName: `${t('dashboard.global.ad_cost_shopping')} (${formatDate(filterStore?.start)} - ${formatDate(filterStore?.end)})`,
    width: 200, minWidth: filterStore?.compare ? 300 : 200, maxWidth: 225,
    flex: lgUp ? 1 : undefined,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.global.ad_cost_shopping')}</span>
        {filterStore?.compare && (
          <span style={{ textAlign: 'left' }}>
            {formatDate(filterStore?.start)} - {formatDate(filterStore?.end)}
          </span>
        )}
      </div>
    ),
    renderCell: (params) => {
      const series = [{
        name: 'Ad Cost Shopping',
        data: params.row.sparkline?.ad_cost_shopping?.map((point: { date: string; value: number }) => ({
          x: new Date(point.date).getTime(),
          y: point.value
        })) || []
      }];
      return (
        <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
          <CustomLineChart series={series} formatValue={(value: any) => formatCurrency(value, currency, locale, false, 2)} type="bar" color={"#FFD066"} height={30} width={100} />
          <Typography variant="caption" sx={{ width: 80 }}>
            {formatCurrency(params.row.adCostShopping, currency, locale, false, 0)}
          </Typography>
        </Stack>
      );
    },
    type: 'number',
  },
  // AD COST SHOPPING COMPARE
  {
    field: 'adCostShoppingCompare',
    disableExport: filterStore?.compare ? true : false,
    hideable: filterStore?.compare ? true : false,
    cellClassName: 'sparkline-cell',
    filterable: filterStore?.compare ? true : false,
    headerName: `${t('dashboard.global.ad_cost_shopping')} (${formatDate(filterStore?.compareStart)} - ${formatDate(filterStore?.compareEnd)})`,
    width: 200, minWidth: filterStore?.compare ? 300 : 100, maxWidth: 225,
    flex: lgUp ? 1 : undefined,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', opacity: .6 }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.global.ad_cost_shopping')}</span>
        {filterStore?.compare && (
          <span style={{ textAlign: 'left' }}>
            {formatDate(filterStore?.compareStart)} - {formatDate(filterStore?.compareEnd)}
          </span>
        )}
      </div>
    ),
    renderCell: (params) => {
      const series = [{
        name: 'Ad Cost Shopping Compare',
        data: params.row.sparkline_compare?.ad_cost_shopping?.map((point: { date: string; value: number }) => ({
          x: new Date(point.date).getTime(),
          y: point.value
        })) || []
      }];
      return (
        <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
          <CustomLineChart series={series} formatValue={(value: any) => formatCurrency(value, currency, locale, false, 2)} type="bar" color={"#FFD066"} height={30} width={100} />
          <Typography variant="caption" sx={{ width: 80 }}>
            {formatCurrency(params.row.adCostShoppingCompare, currency, locale, false, 0)}
          </Typography>
        </Stack>
      );
    },
    type: 'number',

  },
  // AD COST SHOPPING DIFF
  {
    field: 'adCostShoppingDiff',
    disableExport: filterStore?.compare ? true : false,
    hideable: filterStore?.compare ? true : false,
    filterable: filterStore?.compare ? true : false,
    headerName: "Ad Cost Shopping Difference",
    width: 200, minWidth: filterStore?.compare ? 225 : 100, maxWidth: 225,
    flex: lgUp ? 1 : undefined,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', fontWeight: 700 }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.global.ad_cost_shopping')}</span>
        Difference
      </div>
    ),
    renderCell: (params) => (
      <Typography variant='caption' style={{ fontWeight: 900 }}>
        {formatCurrency(params.row.adCostShoppingDiff, currency, locale, false, 0)}
      </Typography>
    ),
    type: 'number',
  },

  // AD COST PMAX
  {
    field: 'adCostPmax',
    cellClassName: 'sparkline-cell',
    headerName: `${t('dashboard.global.ad_cost_pmax')} (${formatDate(filterStore?.start)} - ${formatDate(filterStore?.end)})`,
    width: 200, minWidth: filterStore?.compare ? 300 : 100, maxWidth: 225,
    flex: lgUp ? 1 : undefined,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.global.ad_cost_pmax')}</span>
        {filterStore?.compare && (
          <span style={{ textAlign: 'left' }}>
            {formatDate(filterStore?.start)} - {formatDate(filterStore?.end)}
          </span>
        )}
      </div>
    ),
    renderCell: (params) => {
      const series = [{
        name: 'Ad Cost Pmax',
        data: params.row.sparkline?.ad_cost_pmax?.map((point: { date: string; value: number }) => ({
          x: new Date(point.date).getTime(),
          y: point.value
        })) || []
      }];
      return (
        <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
          <CustomLineChart series={series} formatValue={(value: any) => formatCurrency(value, currency, locale, false, 2)} type="bar" color={"#FFD066"} height={30} width={100} />
          <Typography variant="caption" sx={{ width: 80 }}>
            {formatCurrency(params.row.adCostPmax, currency, locale, false, 0)}
          </Typography>
        </Stack>
      );
    },
    type: 'number',
  },
  // AD COST PMAX COMPARE
  {
    field: 'adCostPmaxCompare',
    disableExport: filterStore?.compare ? true : false,
    hideable: filterStore?.compare ? true : false,
    cellClassName: 'sparkline-cell',
    filterable: filterStore?.compare ? true : false,
    headerName: `${t('dashboard.global.ad_cost_pmax')} (${formatDate(filterStore?.compareStart)} - ${formatDate(filterStore?.compareEnd)})`,
    width: 200, minWidth: filterStore?.compare ? 300 : 100, maxWidth: 225,
    flex: lgUp ? 1 : undefined,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', opacity: .6 }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.global.ad_cost_pmax')}</span>
        {filterStore?.compare && (
          <span style={{ textAlign: 'left' }}>
            {formatDate(filterStore?.compareStart)} - {formatDate(filterStore?.compareEnd)}
          </span>
        )}
      </div>
    ),
    renderCell: (params) => {
      const series = [{
        name: 'Ad Cost Pmax Compare',
        data: params.row.sparkline_compare?.ad_cost_pmax?.map((point: { date: string; value: number }) => ({
          x: new Date(point.date).getTime(),
          y: point.value
        })) || []
      }];
      return (
        <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
          <CustomLineChart series={series} formatValue={(value: any) => formatCurrency(value, currency, locale, false, 2)} type="bar" color={"#FFD066"} height={30} width={100} />
          <Typography variant="caption" sx={{ width: 80 }}>
            {formatCurrency(params.row.adCostPmaxCompare, currency, locale, false, 0)}
          </Typography>
        </Stack>
      );
    },
    type: 'number',
  },
  // AD COST PMAX DIFF
  {
    field: 'adCostPmaxDiff',
    disableExport: filterStore?.compare ? true : false,
    hideable: filterStore?.compare ? true : false,
    filterable: filterStore?.compare ? true : false,
    headerName: "Ad Cost Pmax Difference",
    width: 200, minWidth: filterStore?.compare ? 225 : 100, maxWidth: 225,
    flex: lgUp ? 1 : undefined,
    renderHeader: () => (
      <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', fontWeight: 700 }}>
        <span style={{ textAlign: 'right' }}>{t('dashboard.global.ad_cost_pmax')}</span>
        Difference
      </div>
    ),
    renderCell: (params) => (
      <Typography variant='caption' style={{ fontWeight: 900 }}>
        {formatCurrency(params.row.adCostPmaxDiff, currency, locale, false, 0)}
      </Typography>
    ),
    type: 'number',
  },
];


// ----------------------------------------------------------------------

type Props = {
  data: {
    id: string;
    keyword: string;
    brandSearches?: number; // Add optional brandSearches property
    [key: string]: any; // Allow for other properties that might be used
  }[];
  paginationModel: any;
  onPaginationModelChange: (model: GridPaginationModel) => void;
  paginationMode?: 'server';
  filterMode?: 'server';
  sortMode?: 'server';
  rowCount?: number;
  sortModel?: GridSortModel;
  filterModel?: GridFilterModel;
  onSortModelChange?: (model: GridSortModel) => void;
  onFilterModelChange?: (newModel: GridFilterModel) => void;
};

export default function DataGridCustomKeywords({
  data,
  paginationModel,
  onPaginationModelChange,
  paginationMode,
  rowCount,
  sortModel,
  onSortModelChange,
  onFilterModelChange,
}: Props) {
  const apiRef = useGridApiRef();
  const dispatch = useDispatch();
  const theme = useTheme();
  const filterStore = useSelector((state: any) => state.filters);
  const isAdvancedView = useSelector((state: any) => state.filters.isAdvancedView);
  const { t } = useLocales();
  const lgUp = useResponsive('up', 'lg');
  const workspace = useCurrentWorkspace();
  const location = useLocation();


  // Initialize filter model from URL or localStorage
  const [filterModel, setFilterModel] = useState<GridFilterModel>(() => {
    // Try to get filters from URL parameters
    const urlParams = new URLSearchParams(location.search);
    const filtersParam = urlParams.get('filters');

    if (filtersParam) {
      try {
        return JSON.parse(decodeURIComponent(filtersParam));
      } catch (e) {
        console.error('Failed to parse filters from URL', e);
      }
    }

    // Try to get filters from local storage if not in URL
    const storedFilters = localStorage.getItem('dataGridFilters');
    if (storedFilters) {
      try {
        return JSON.parse(storedFilters);
      } catch (e) {
        console.error('Failed to parse filters from localStorage', e);
      }
    }

    // Default empty filter model
    return {
      items: [],
      logicOperator: GridLogicOperator.And,
    };
  });

  // Add ref to track previous data length
  const prevDataLengthRef = useRef(0);

  // Add ref to track if auto-sizing has been done
  const hasResizedRef = useRef(false);
  // Add ref to track source of data
  const dataSourceRef = useRef('');

  const prevDataJSON = useRef("");
  const hasAutoResized = useRef(false);


  const lang = localStorage.getItem('i18nextLng');

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    id: false,
    brandSearchesCompare: false,
    brandSearchesDiff: false,
    brandTrafficCompare: false,
    brandTrafficDiff: false,
    shoppingCtr: false,
    shoppingCtrCompare: false,
    shoppingCtrDiff: false,
    pmaxCtr: false,
    pmaxCtrCompare: false,
    pmaxCtrDiff: false,
    searchCtr: false,
    searchCtrCompare: false,
    searchCtrDiff: false,
    paidCpc: !!filterStore.compare && hasNonZeroValues(data, 'paidCpc'),
    paidCpcCompare: !!filterStore.compare && hasNonZeroValues(data, 'paidCpc'),
    paidCpcDiff: !!filterStore.compare && hasNonZeroValues(data, 'paidCpc'),
    paidSearchCpc: false,
    paidSearchCpcCompare: false,
    paidSearchCpcDiff: false,
    paidShoppingCpc: false,
    paidShoppingCpcCompare: false,
    paidShoppingCpcDiff: false,
    paidPmaxCpc: false,
    paidPmaxCpcCompare: false,
    paidPmaxCpcDiff: false,
    organicBrandTrafficCompare: false,
    organicBrandTrafficDiff: false,
    paidBrandTrafficCompare: false,
    paidBrandTrafficDiff: false,
    uncapturedBrandTrafficCompare: false,
    uncapturedBrandTrafficDiff: false,
    blendedCtrCompare: false,
    blendedCtrDiff: false,
    paidCtrCompare: false,
    paidCtrDiff: false,
    organicCtrCompare: false,
    organicCtrDiff: false,
    uncapturedPercentCompare: false,
    uncapturedPercentDiff: false,
    adCostCompare: false,
    adCostDiff: false,
    adCostShopping: false,
    adCostShoppingCompare: false,
    adCostShoppingDiff: false,
    adCostPmax: false,
    adCostPmaxCompare: false,
    adCostPmaxDiff: false,
    adCostSearch: false,
    adCostSearchCompare: false,
    adCostSearchDiff: false,
  });

  useEffect(() => {
    setColumnVisibilityModel((prevModel) => ({
      ...prevModel,
      id: false,
      keyword: true,
      brandSearches: true,
      brandTraffic: true,
      organicBrandTraffic: true,
      paidBrandTraffic: true,
      blendedCtr: true,
      paidCpc: true,
      adCost: true,
      organicBrandTrafficCompare: !!filterStore.compare,
      organicBrandTrafficDiff: !!filterStore.compare,
      paidBrandTrafficCompare: !!filterStore.compare,
      paidBrandTrafficDiff: !!filterStore.compare,
      uncapturedBrandTrafficCompare: !!filterStore.compare,
      uncapturedBrandTrafficDiff: !!filterStore.compare,
      brandSearchesCompare: !!filterStore.compare,
      brandSearchesDiff: !!filterStore.compare,
      brandTrafficCompare: !!filterStore.compare,
      brandTrafficDiff: !!filterStore.compare,
      adCostCompare: !!filterStore.compare,
      adCostDiff: !!filterStore.compare,
      paidCtrCompare: !!filterStore.compare,
      paidCtrDiff: !!filterStore.compare,
      blendedCtrCompare: !!filterStore.compare,
      blendedCtrDiff: !!filterStore.compare,
      organicCtrCompare: !!filterStore.compare,
      organicCtrDiff: !!filterStore.compare,
      uncapturedPercentCompare: !!filterStore.compare,
      uncapturedPercentDiff: !!filterStore.compare,
      paidCpcCompare: !!filterStore.compare,
      paidCpcDiff: !!filterStore.compare,
      shoppingCtr: isAdvancedView && hasNonZeroValues(data, 'shoppingCtr'),
      pmaxCtr: isAdvancedView && hasNonZeroValues(data, 'pmaxCtr'),
      searchCtr: isAdvancedView && hasNonZeroValues(data, 'searchCtr'),
      paidSearchCpc: isAdvancedView && hasNonZeroValues(data, 'paidSearchCpc'),
      paidShoppingCpc: isAdvancedView && hasNonZeroValues(data, 'paidShoppingCpc'),
      paidPmaxCpc: isAdvancedView && hasNonZeroValues(data, 'paidPmaxCpc'),
      pmaxCtrDiff: isAdvancedView && !!filterStore.compare && hasNonZeroValues(data, 'pmaxCtr'),
      pmaxCtrCompare: isAdvancedView && !!filterStore.compare && hasNonZeroValues(data, 'pmaxCtr'),
      adCostShopping: isAdvancedView && hasNonZeroValues(data, 'adCostShopping'),
      adCostPmax: isAdvancedView && hasNonZeroValues(data, 'adCostPmax'),
      adCostSearch: isAdvancedView && hasNonZeroValues(data, 'adCostSearch'),
      shoppingCtrCompare: isAdvancedView && !!filterStore.compare && hasNonZeroValues(data, 'shoppingCtr'),
      shoppingCtrDiff: isAdvancedView && !!filterStore.compare && hasNonZeroValues(data, 'shoppingCtr'),
      searchCtrCompare: isAdvancedView && !!filterStore.compare && hasNonZeroValues(data, 'searchCtr'),
      searchCtrDiff: isAdvancedView && !!filterStore.compare && hasNonZeroValues(data, 'searchCtr'),
      paidShoppingCpcCompare: isAdvancedView && !!filterStore.compare && hasNonZeroValues(data, 'paidShoppingCpc'),
      paidShoppingCpcDiff: isAdvancedView && !!filterStore.compare && hasNonZeroValues(data, 'paidShoppingCpc'),
      paidSearchCpcCompare: isAdvancedView && !!filterStore.compare && hasNonZeroValues(data, 'paidSearchCpc'),
      paidSearchCpcDiff: isAdvancedView && !!filterStore.compare && hasNonZeroValues(data, 'paidSearchCpc'),
      paidPmaxCpcCompare: isAdvancedView && !!filterStore.compare && hasNonZeroValues(data, 'paidPmaxCpc'),
      paidPmaxCpcDiff: isAdvancedView && !!filterStore.compare && hasNonZeroValues(data, 'paidPmaxCpc'),
      adCostShoppingCompare: isAdvancedView && !!filterStore.compare && hasNonZeroValues(data, 'adCostShopping'),
      adCostShoppingDiff: isAdvancedView && !!filterStore.compare && hasNonZeroValues(data, 'adCostShopping'),
      adCostPmaxCompare: isAdvancedView && !!filterStore.compare && hasNonZeroValues(data, 'adCostPmax'),
      adCostPmaxDiff: isAdvancedView && !!filterStore.compare && hasNonZeroValues(data, 'adCostPmax'),
      adCostSearchCompare: isAdvancedView && !!filterStore.compare && hasNonZeroValues(data, 'adCostSearch'),
      adCostSearchDiff: isAdvancedView && !!filterStore.compare && hasNonZeroValues(data, 'adCostSearch'),
      paidCtrShoppingCompare: isAdvancedView && !!filterStore.compare && hasNonZeroValues(data, 'paidCtrShopping'),
      paidCtrShoppingDiff: isAdvancedView && !!filterStore.compare && hasNonZeroValues(data, 'paidCtrShopping'),
      paidCtrPmaxCompare: isAdvancedView && !!filterStore.compare && hasNonZeroValues(data, 'paidCtrPmax'),
      paidCtrPmaxDiff: isAdvancedView && !!filterStore.compare && hasNonZeroValues(data, 'paidCtrPmax'),
      paidCtrSearchCompare: isAdvancedView && !!filterStore.compare && hasNonZeroValues(data, 'paidCtrSearch'),
      paidCtrSearchDiff: isAdvancedView && !!filterStore.compare && hasNonZeroValues(data, 'paidCtrSearch'),
    }));
  }, [filterStore.compare, data, isAdvancedView]);

  const handleChangeColumnVisibilityModel = useCallback((newModel: GridColumnVisibilityModel) => {
    setColumnVisibilityModel(newModel);
  }, []);

  const handleRowClick = (params: any) => {
    const keywords = filterStore?.keywords || [];
    const clickedKeyword = params?.row?.keyword;

    let updatedKeywords;

    if (keywords.includes(clickedKeyword)) {
      updatedKeywords = keywords.filter((keyword: string) => keyword !== clickedKeyword);
    } else {
      updatedKeywords = [...keywords, clickedKeyword]
    }

    dispatch(setFilterKeyword(updatedKeywords));
  };

  const handleFilterModelChange = (newModel: GridFilterModel) => {
    setFilterModel(newModel);

    // Persist filters to localStorage
    localStorage.setItem('dataGridFilters', JSON.stringify(newModel));

    // Call parent's onFilterModelChange if provided
    if (onFilterModelChange) {
      onFilterModelChange(newModel);
    }
  };

  // Initialize grid with filters when mounted
  useEffect(() => {
    if (filterModel.items.length > 0 && apiRef.current) {
      apiRef.current.setFilterModel(filterModel);
    }
  }, [apiRef]);

  const columns = useMemo(
    () => createColumns(theme, t, lgUp, filterStore, formatDate, formatCurrency, workspace?.currency, lang),
    [theme, t, lgUp, filterStore, formatDate, formatCurrency, workspace?.currency, lang]
  );

  // Dès que les données sont disponibles, autosize la colonne "keyword"
  useEffect(() => {
    if (data.length > 0 && apiRef.current) {
      const timer = setTimeout(() => {

        ReactDOM.flushSync(() => { });

        apiRef.current.autosizeColumns({
          columns: ['keyword'],
          includeHeaders: true,
          includeOutliers: true,
        });

        const allCols = apiRef.current.getAllColumns();
        const keywordCol = allCols.find((col) => col.field === 'keyword');

        if (keywordCol && keywordCol.computedWidth) {
          apiRef.current.setColumnWidth('keyword', keywordCol.computedWidth);
        }
        hasAutoResized.current = true;
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [data, apiRef]);

  useEffect(() => {
    // Supprime l'entrée des filtres dans le localStorage
    localStorage.removeItem('dataGridFilters');
    // Réinitialise le modèle de filtre
    setFilterModel({
      items: [],
      logicOperator: GridLogicOperator.And,
    });
  }, [workspace]);

  return (
    <Box
      sx={{
        width: '100%',
        '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus': {
          outline: 'none !important',
        },
        '& .MuiDataGrid-cell--pinnedLeft': {
          backgroundColor: theme.palette.mode === "dark" ? '#212b36 !important' : 'white!important',
        },
        '& .MuiDataGrid-cell': {
          cursor: 'pointer',
        },
      }}
    >
      <DataGridPro
        pinnedColumns={{ left: ['keyword'] }}
        autoHeight
        disableDensitySelector
        columnVisibilityModel={columnVisibilityModel}
        checkboxSelection={false}
        disableRowSelectionOnClick
        rows={data} // Use the filtered data here instead of raw data
        pagination
        paginationMode={paginationMode}
        sortingMode="server"
        rowCount={rowCount}
        filterMode="server"
        pageSizeOptions={[10, 20, 50, 100]}
        showCellVerticalBorder={true}
        sortingOrder={['desc', 'asc']}
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        filterModel={filterModel}
        onFilterModelChange={handleFilterModelChange}
        columns={columns}
        onRowClick={handleRowClick}
        onColumnVisibilityModelChange={handleChangeColumnVisibilityModel}
        apiRef={apiRef}
        slots={{
          toolbar: GridToolbar,
          filterPanel: (props) => <FilterPanelCustom {...props} getColumnForNewFilter={(args) => args ? args.columns[0].field : undefined} />,
        }}
        slotProps={{
          toolbar: { printOptions: { disableToolbarButton: true } },
          filterPanel: {
            logicOperators: [GridLogicOperator.And, GridLogicOperator.Or],
          }
        }}
      />
    </Box>
  );
}
