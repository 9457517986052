import { createApi } from "@reduxjs/toolkit/query/react";
import fetchBaseQueryCSRF from "../helper";

const competitorsMonitoring = createApi({
  baseQuery: fetchBaseQueryCSRF({ baseUrl: CROSS_API_URL }),
  reducerPath: 'api.competitorsMonitoring',
  tagTypes: ['competitorsMonitoring'],
  endpoints: (builder) => ({
    getPres: builder.query<any, { display?: any, compareStartDate: string | null, compareEndDate: string | null, startDate: string | null, endDate: string | null, device: string[] | null, countries: string[], keywords: string[], timestep: string, workspaceId: any | null, domain: string | null, platform: any, campaignType: string | null }>({
      query: ({ display, startDate, endDate, device, countries, keywords, timestep, compareStartDate, compareEndDate, workspaceId, domain, platform,campaignType }) => {
        const url = `/competitorsMonitoring/getPres`;
        const body = {
          display,
          startDate,
          endDate,
          compareStartDate,
          compareEndDate,
          device,
          countries,
          keywords,
          timestep,
          workspaceId,
          domain,
          platform,
          campaignType
        };

        return {
          url,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
          },
        };
      },
    }),
    getPresence: builder.query<any, { compareStartDate: string | null, compareEndDate: string | null, startDate: string | null, endDate: string | null, device: string[] | null, countries: string[], keywords: string[], timestep: string, workspaceId: any | null, domain: string | null, platform: any,campaignType: string | null }>({
      query: ({ startDate, endDate, device, countries, keywords, timestep, compareStartDate, compareEndDate, workspaceId, domain, platform ,campaignType}) => {
        const url = `/competitorsMonitoring/getPresence`;
        const body = {
          startDate,
          endDate,
          compareStartDate,
          compareEndDate,
          device,
          countries,
          keywords,
          timestep,
          workspaceId,
          domain,
          platform,
          campaignType
        };

        return {
          url,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
          },
        };
      },
    }),
    getBrand: builder.query<any, { domain: string }>({
      query: ({ domain }) => ({
        url: `/competitorsMonitoring/brands/${domain}`,
        method: 'GET',
      }),
    }),
    getPresenceSpecificDomain: builder.query<any, { compareStartDate: string | null, compareEndDate: string | null, startDate: string | null, endDate: string | null, device: string[] | null, countries: string[], keywords: string[], timestep: string, workspaceId: any | null, additionalDomain: string, platform: any }>({
      query: ({ startDate, endDate, device, countries, keywords, timestep, workspaceId, additionalDomain, compareStartDate, compareEndDate, platform }) => {
        const url = `/competitorsMonitoring/getPresence/specificDomain`;
        const body = {
          startDate,
          endDate,
          compareStartDate,
          compareEndDate,
          device,
          countries,
          keywords,
          timestep,
          workspaceId,
          additionalDomain,
          platform
        };

        return {
          url,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
          },
        };
      },
    }),
    getAdsTxt: builder.query<any, { domain: string, workspaceTable: any, startDate: string | null, endDate: string | null, device: string[] | null, countries: string[], keywords: string[], platform: any }>({
      query: ({ domain, workspaceTable, startDate, endDate, device, countries, keywords, platform }) => {
        const url = `/competitorsMonitoring/getAdsTxt`;
        const body = {
          startDate,
          endDate,
          device,
          countries,
          keywords,
          workspaceTable,
          domain,
          platform
        };
        return {
          url,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
          },
        };
      },
    }),
    getAdsTxtGlobal: builder.query<any, {
      domain: string,
      workspaceTable: any,
      startDate: string | null,
      endDate: string | null,
      device: string[] | null,
      countries: string[],
      keywords: string[],
      page?: number,
      sortBy?: any,
      sortOrder?: any,
      pageSize?: number,
      impressionShare: [number, number],
      position: [number, number],
      brands?: string[],
      domains: any,
      otherDomains: any,
      search?: any,
      platform: any
    }>({
      query: ({
        domain,
        workspaceTable,
        startDate,
        endDate,
        device,
        sortBy,
        sortOrder,
        domains,
        otherDomains,
        countries,
        keywords,
        page,
        pageSize,
        impressionShare,
        position,
        brands,
        search,
        platform
      }) => {
        const url = `/competitorsMonitoring/getAdsTxtGlobal`;
        const body = {
          startDate,
          endDate,
          device,
          countries,
          keywords,
          sortBy,
          sortOrder,
          workspaceTable,
          domain,
          otherDomains,
          page,
          domains,
          pageSize,
          brands,
          impressionShare,
          position,
          search,
          platform
        };
        return {
          url,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
          },
        };
      },
    }),
    getAdsTxtLimit: builder.query<any, { domain: string, workspaceTable: any, startDate: string | null, endDate: string | null, countries: string[], keywords: string[], otherDomains?: string[], platforms: any }>({
      query: ({ domain, otherDomains, workspaceTable, startDate, endDate, countries, keywords, platforms }) => {
        const url = `/competitorsMonitoring/getAdsTxtLimit`;
        const body = {
          startDate,
          otherDomains,
          endDate,
          countries,
          keywords,
          workspaceTable,
          platforms,
          domain,
        };
        return {
          url,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
          },
        };
      },
    }),
    getAdsPla: builder.query<any, { domain: string, workspaceTable: any, startDate: string | null, endDate: string | null, device: string[] | null, countries: string[], keywords: string[], platform: any }>({
      query: ({ domain, workspaceTable, startDate, endDate, device, countries, keywords, platform }) => {
        const url = `/competitorsMonitoring/getAdsPla`;
        const body = {
          startDate,
          endDate,
          device,
          countries,
          keywords,
          workspaceTable,
          domain,
          platform
        };
        return {
          url,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
          },
        };
      },
    }),
    getAdsPlaGlobal: builder.query<any, {
      domain: string,
      workspaceTable: any,
      startDate: string | null,
      endDate: string | null,
      otherDomains: any,
      device: string[] | null,
      countries: string[],
      keywords: string[],
      page?: number,
      advertizer: any;
      sortBy?: any,
      sortOrder?: any,
      pageSize?: number,
      brands?: string[],
      domains: any,
      ratingRange: [number, number],
      impressionShare: [number, number],
      position: [number, number],
      priceRange: any,
      search?: any,
      platform: any
    }>({
      query: ({
        domain,
        workspaceTable,
        startDate,
        endDate,
        device,
        sortBy,
        impressionShare,
        sortOrder,
        advertizer,
        otherDomains,
        domains,
        countries,
        keywords,
        page,
        pageSize,
        brands,
        position,
        ratingRange,
        priceRange,
        search,
        platform
      }) => {
        const url = `/competitorsMonitoring/getAdsPlaGlobal`;
        const body = {
          startDate,
          endDate,
          device,
          countries,
          advertizer,
          keywords,
          sortBy,
          position,
          sortOrder,
          workspaceTable,
          otherDomains,
          impressionShare,
          domain,
          page,
          domains,
          pageSize,
          brands,
          ratingRange,
          priceRange,
          search,
          platform
        };
        return {
          url,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
          },
        };
      },
    }),

    getAdsPlaLimit: builder.query<any, { domain: string, workspaceTable: any, startDate: string | null, endDate: string | null, countries: string[], keywords: string[], otherDomains: string[], platforms: any }>({
      query: ({ domain, workspaceTable, startDate, endDate, countries, keywords, otherDomains, platforms }) => {
        const url = `/competitorsMonitoring/getAdsPlaLimit`;
        const body = {
          startDate,
          endDate,
          countries,
          keywords,
          workspaceTable,
          domain,
          platforms,
          otherDomains
        };
        return {
          url,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
          },
        };
      },
    }),
    getMinDateCompetitor: builder.query<any, { domain: string, workspaceTable: any, workspaceId: any, startDate: string | null, endDate: string | null, countries: string[], keywords: string[] }>({
      query: ({ domain, workspaceTable, workspaceId, startDate, endDate, countries, keywords }) => {
        const url = `/competitorsMonitoring/getMinDateCompetitor`;
        const body = {
          domain,
          workspaceTable,
          workspaceId,
          startDate,
          endDate,
          countries,
          keywords,

        };

        return {
          url,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
          },
        };
      }
    }),


    getCountriesCompetitor: builder.query<any, { startDate: string | null, endDate: string | null, device: string[] | null, keywords: string[], workspaceId: any }>({
      query: ({ startDate, endDate, device, keywords, workspaceId }) => {
        const url = `/competitorsMonitoring/getCountriesCompetitor`;
        const body = {
          startDate,
          endDate,
          device,
          keywords,
          workspaceId
        };

        return {
          url,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
          },
        };
      },
    }),
    getCompetitorCountries:builder.query<any, { startDate: string | null, endDate: string | null, device: string[] | null, keywords: string[], workspaceId: any }>({
      query: ({ startDate, endDate, device, keywords, workspaceId }) => {
        const url = `/competitorsMonitoring/getCompetitorCountries`;
        const body = {
          startDate,
          endDate,
          device,
          keywords,
          workspaceId
        };

        return {
          url,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
          },
        };
      },
    }),
    getCountriesPlaTxt: builder.query<any, { startDate: string | null, endDate: string | null, keywords: string[], workspaceId: any, adType: string, platform: any }>({
      query: ({ startDate, endDate, keywords, workspaceId, adType, platform }) => {
        const url = `/competitorsMonitoring/getCountriesPlaTxt`;
        const body = {
          startDate,
          endDate,
          keywords,
          workspaceId,
          adType,
          platform
        };

        return {
          url,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
          },
        };
      },
    }),
    getDevicesCompetitor: builder.query<any, { startDate: string | null, endDate: string | null, keywords: string[], workspaceId: any, countries: string[] }>({
      query: ({ startDate, endDate, countries, keywords, workspaceId }) => {
        const url = `/competitorsMonitoring/getDevicesCompetitor`;
        const body = {
          startDate,
          endDate,
          countries,
          keywords,
          workspaceId
        };

        return {
          url,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
          },
        };
      },
    }),
    getCompetitorDevices:builder.query<any, { startDate: string | null, endDate: string | null, keywords: string[], workspaceId: any, countries: string[] }>({
      query: ({ startDate, endDate, countries, keywords, workspaceId }) => {
        const url = `/competitorsMonitoring/getCompetitorDevices`;
        const body = {
          startDate,
          endDate,
          countries,
          keywords,
          workspaceId
        };

        return {
          url,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
          },
        };
      },
    }),
    getCompetitorKeywords: builder.query<any, {startDate: string | null, endDate: string | null, workspaceId: any, countries: string[], devices: string[], platform: any}>({
      query: ({ startDate, endDate, countries, devices, workspaceId, platform }) => {
        const url = `/competitorsMonitoring/getCompetitorKeywords`;
        const body = {
          startDate,
          endDate,
          countries,
          devices,
          workspaceId,
          platform
        };


        return {
          url,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
          },
        };
      },
    }),
    getKeywordsCompetitor: builder.query<any, { startDate: string | null, endDate: string | null, workspaceId: any, countries: string[], devices: string[] }>({
      query: ({ startDate, endDate, countries, devices, workspaceId }) => {
        const url = `/competitorsMonitoring/getKeywordsCompetitor`;
        const body = {
          startDate,
          endDate,
          countries,
          devices,
          workspaceId
        };


        return {
          url,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
          },
        };
      },
    }),

    getKeywordsPlaTxt: builder.query<any, { startDate: string | null, endDate: string | null, adType: string | null, workspaceId: any, countries: string[], platform: any }>({
      query: ({ startDate, endDate, adType, countries, workspaceId, platform }) => {
        const url = `/competitorsMonitoring/getKeywordsPlaTxt`;
        const body = {
          startDate,
          endDate,
          adType,
          countries,
          workspaceId,
          platform
        };

        return {
          url,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
          },
        };
      },
    }),
    getCompetitorFilter: builder.query<any, { startDate: string | null, endDate: string | null, keywords: string[], workspaceId: any | null, domain: string | null, platform: any }>({
      query: ({ startDate, endDate, keywords, workspaceId, domain, platform }) => {
        const url = `/competitorsMonitoring/getCompetitorFilters`;
        const body = {
          startDate,
          endDate,
          keywords,
          workspaceId,
          domain,
          platform
        };

        return {
          url,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
          },
        };
      },
    }),
    getBrands: builder.query<any, { bqTable: string }>({
      query: ({ bqTable }) => ({
        url: `/competitorsMonitoring/getBrands?bqTable=${encodeURIComponent(bqTable)}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          Accept: 'application/json',
        },
      }),
    }),
    getPlatformsCompetitor: builder.query<any, { startDate: string | null, endDate: string | null, countries: string[], keywords: string[], devices: string[], workspaceId: any }>({
      query: ({ startDate, endDate, countries, keywords, devices, workspaceId }) => {
        const url = `/competitorsMonitoring/getPlatforms`;
        const body = {
          startDate,
          endDate,
          keywords,
          countries,
          devices,
          workspaceId
        };

        return {
          url,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
          },
        };
      },
    }),
    getPlatformsPlaTxt: builder.query<any, { startDate: string | null, endDate: string | null, countries: string[], keywords: string[], devices: string[], workspaceId: any }>({
      query: ({ startDate, endDate, countries, keywords, devices, workspaceId }) => {
        const url = `/competitorsMonitoring/getPlatformsPlaTxt`;
        const body = {
          startDate,
          endDate,
          keywords,
          countries,
          devices,
          workspaceId
        };

        return {
          url,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
          },
        };
      },
    }),
   
  }),
});



export const {
  useGetPresenceQuery,
  useGetBrandQuery,
  useGetPresenceSpecificDomainQuery,
  useGetAdsTxtQuery,
  useGetAdsTxtLimitQuery,
  useGetAdsPlaQuery,
  useGetAdsPlaLimitQuery,
  useGetMinDateCompetitorQuery,
  useGetCompetitorCountriesQuery,
  useGetCountriesCompetitorQuery,
  useGetDevicesCompetitorQuery,
  useGetCompetitorDevicesQuery,
  useGetCompetitorFilterQuery,
  useGetKeywordsCompetitorQuery,
  useGetCompetitorKeywordsQuery,
  useGetKeywordsPlaTxtQuery,
  useGetCountriesPlaTxtQuery,
  useGetBrandsQuery,
  useGetAdsPlaGlobalQuery,
  useGetAdsTxtGlobalQuery,
  useGetPresQuery,
  useGetPlatformsCompetitorQuery,
  useGetPlatformsPlaTxtQuery
} = competitorsMonitoring;

export default competitorsMonitoring;
