import { Box, Button, Checkbox, useTheme } from "@mui/material";
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { bgBlur } from 'src/theme/css';
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
import Logo from 'src/components/logo';
import SvgColor from 'src/components/svg-color';
import { useSettingsContext } from 'src/components/settings';
import { HEADER, NAV } from '../config-layout';
import { AccountPopover, LanguagePopover, NotificationsPopover } from '../_common';
import { useDateRangeSelect } from 'src/components/generic-select/date-range-select';
import { useEffect, useMemo, useRef, useState } from 'react';
import Iconify from 'src/components/iconify';
import DateRangeSelect from 'src/components/generic-select/date-range-select';
import { fDate } from 'src/utils/format-time';
import { useGetCountriesBrandWaybackQuery, useGetCountriesQuery, useGetDevicesBrandWaybackQuery, useGetDevicesQuery, useGetKeywordsBrandWaybackQuery, useGetKeywordsQuery } from 'src/context/api/trafficAnalysis/api';
import { useDispatch, useSelector } from "react-redux";
import { setFilterDate, setFilterDevice, setFilterCountry, setFilterKeyword, setFilterTimestep, setFilterDateCompare, setFilterCompare, setFilterLoadedCountry, setFilterPlatform, setFilterCampaignType } from '../../context/reducers/filter-settings/filterSlice';
import InfiniteScrollKeywords from "src/sections/overview/dashboards/views/InfiniteScrollKeywords";
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import countriesJson from "../../utils/countries.json"
import { FlagIcon } from "src/components/iconify/flagIcon";
import { subDays } from "date-fns";
import FiltersButton from "../_common/filters-button";
import { useCurrentWorkspace } from "src/context/reducers/app-settings";
import BaseOptions from "src/components/settings/drawer/base-option";
import { useLocales } from "src/locales";

type Props = {
  onOpenNav?: VoidFunction;
};

export default function Header({ onOpenNav }: Props) {
  const theme = useTheme();
  const { t } = useLocales();
  const [filterDevice, setFilterDeviceState] = useState<any>(undefined);
  const [filterCountry, setFilterCountryState] = useState<any>(undefined);
  const [filterKeyword, setFilterKeywordState] = useState<any>(undefined);
  const [filterPlatform, setFilterPlatformState] = useState<any>(undefined);
  const [CampaignType, setCampaignType] = useState<any>(undefined)

  const filterStore = useSelector((state: any) => state.filters);
  const [showFilters, setShowFilters] = useState<boolean>(true);

  const regexCompetitor = /^\/dash\/competitor\/([^/]+)\/$/;
  const regexCompetirorsMonitoring = /^\/dash\/competitors-monitoring$/;
  const regexCompetirorsFocus = /^\/dash\/competitor-focus$/;
  const regexPLAGlobalFocus = /^\/dash\/product-listing-ads$/;
  const regexTXTGlobalFocus = /^\/dash\/text-ads$/;

  const matchCompetitor = location.pathname.match(regexCompetitor);
  const matchCompetitorMonitoring = location.pathname.match(regexCompetirorsMonitoring);
  const matchCompetitorFocus = location.pathname.match(regexCompetirorsFocus);
  const matchPLAGlobalFocus = location.pathname.match(regexPLAGlobalFocus);
  const matchTXTGlobalFocus = location.pathname.match(regexTXTGlobalFocus);

  const workspace: any = useCurrentWorkspace();
  const optionRefs: any = useRef({}); // Référence pour suivre les options survolées

  const [compareStartDate, setCompareStartDate] = useState<any>(filterStore?.compareStart);
  const [compareEndDate, setCompareEndDate] = useState<any>(filterStore?.compareEnd);

  const settings = useSettingsContext();
  const dispatch = useDispatch()

  const isNavHorizontal = settings.themeLayout === 'horizontal';
  const isNavMini = settings.themeLayout === 'mini';
  const lgUp = useResponsive('up', 'lg');
  const offset = useOffSetTop(HEADER.H_DESKTOP);
  const offsetTop = offset && !isNavHorizontal;
  const [minWidth, setMinWidth] = useState(200); // Valeur par défaut

  const [start, end] = useMemo(() => {
    const startDate = new Date();
    startDate.setMonth(startDate.getUTCMonth() - 1);
    startDate.setDate(startDate.getUTCDate());
    const endDate = new Date();
    endDate.setDate(endDate.getUTCDate() - 1);
    return [startDate, endDate];
  }, []);

  const dateRangeSelect = useDateRangeSelect({
    preset: "last_30d",
    step: "year_week",
    selected: "custom",
    start,
    end
  });

  useEffect(() => {
    dispatch(setFilterDateCompare({
      compareStart: fDate(compareStartDate, "yyyy-MM-dd"),
      compareEnd: fDate(compareEndDate, "yyyy-MM-dd")
    }))
  }, [compareStartDate, compareEndDate])

  const updateFilterDate = (startDate: Date | null, endDate: Date | null) => {
    if (startDate && endDate) {
      dispatch(setFilterDate({ start: fDate(startDate, "yyyy-MM-dd"), end: fDate(endDate, "yyyy-MM-dd"), }));
    }
  };
  const skipQuery = !dateRangeSelect.start || !dateRangeSelect.end || !workspace?.id || location?.pathname === "/dash/brand-overview";

  const { data: devicesData } = useGetDevicesQuery({
    startDate: filterStore?.start !== undefined ? fDate(filterStore?.start, "yyyy-MM-dd") : null,
    endDate: filterStore?.end !== undefined ? fDate(filterStore?.end, "yyyy-MM-dd") : null,
    countries: filterStore.countries,
    keywords: filterStore.keywords,
    workspaceId: workspace?.id,
    platforms: filterStore?.platforms
  },
    {
      skip: skipQuery,
      refetchOnMountOrArgChange: false,
    }
  );

  const { data: devicesDataBrandWayback } = useGetDevicesBrandWaybackQuery({
    startDate: filterStore?.start !== undefined ? fDate(filterStore?.start, "yyyy-MM-dd") : null,
    endDate: filterStore?.end !== undefined ? fDate(filterStore?.end, "yyyy-MM-dd") : null,
    countries: filterStore.countries,
    keywords: filterStore.keywords,
    workspaceId: workspace?.id,
    platforms: filterStore?.platforms
  },
    {
      skip: (!filterStore?.start ||
        !filterStore?.end ||
        !workspace?.id ||
        location?.pathname === "/dash/brand-overview" ||
        filterStore.keywords?.length === 0
      ),
      refetchOnMountOrArgChange: false,
    }
  );

  const { data: countriesData } = useGetCountriesQuery({
    startDate: filterStore?.start !== undefined ? fDate(filterStore?.start, "yyyy-MM-dd") : null,
    endDate: filterStore?.end !== undefined ? fDate(filterStore?.end, "yyyy-MM-dd") : null,
    device: filterStore.devices,
    keywords: filterStore.keywords,
    workspaceId: workspace?.id,
    platforms: filterStore?.platforms
  }, {
    skip: skipQuery,
    refetchOnMountOrArgChange: false,
  });

  const { data: countriesDataBrandWayback } = useGetCountriesBrandWaybackQuery({
    startDate: filterStore?.start !== undefined ? fDate(filterStore?.start, "yyyy-MM-dd") : null,
    endDate: filterStore?.end !== undefined ? fDate(filterStore?.end, "yyyy-MM-dd") : null,
    device: filterStore.devices,
    keywords: filterStore.keywords,
    workspaceId: workspace?.id,
    platforms: filterStore?.platforms
  }, {
    skip: (!filterStore?.start ||
      !filterStore?.end ||
      !workspace?.id ||
      location?.pathname === "/dash/brand-overview"
    ),
    refetchOnMountOrArgChange: false,
  });

  useEffect(() => {
    if (countriesDataBrandWayback && location.pathname === "/dash/brand-wayback") {
      if (filterStore?.countries?.length === 0) {
        dispatch(
          setFilterCountry([countriesDataBrandWayback?.filters?.countries[0]])
        );
        setFilterCountryState(countriesDataBrandWayback?.filters?.countries);
      }
    }
  }, [countriesDataBrandWayback])

  const { data: keywordsData } = useGetKeywordsQuery({
    startDate: filterStore?.start !== undefined ? fDate(filterStore?.start, "yyyy-MM-dd") : null,
    endDate: filterStore?.end !== undefined ? fDate(filterStore?.end, "yyyy-MM-dd") : null,
    device: filterStore?.devices,
    countries: filterStore?.countries,
    workspaceId: workspace?.id,
    platforms: filterStore?.platforms
  }, {
    skip: skipQuery,
    refetchOnMountOrArgChange: false,
  });


  const { devices, countries, keywords, campaignType } = useMemo(() => {
    return { devices: devicesData?.filters?.devices, countries: countriesData?.filters?.countries, keywords: keywordsData?.filters?.keywords, campaignType: ['Search', 'Shopping'] }
  }, [devicesData, countriesData, keywordsData])

  useEffect(() => {
    if (lgUp && !workspace?.name.includes('Demo')) {
      if (dateRangeSelect?.start && dateRangeSelect?.end) {
        const endDateString = dateRangeSelect.end.toISOString();

        if (filterStore?.start !== filterStore?.end) {
          dispatch(setFilterDate({
            start: filterStore?.start,
            end: endDateString
          }));

        }
      }
    } else {
      if (lgUp) {
        if (dateRangeSelect?.start && dateRangeSelect?.end && filterStore.timestep == "date") {
          const startDateString = dateRangeSelect.start.toISOString();
          const endDateString = dateRangeSelect.end.toISOString();
          const newStartDate = new Date(2024, 9, 1).toISOString(); // Définir la date de fin au 1er novembre 2024
          const newEndDate = new Date(2024, 9, 30).toISOString(); // Définir la date de fin au 1er novembre 2024

          dispatch(setFilterDate({
            start: newStartDate,
            end: newEndDate
          }));

        } else {

          const newStartDate = new Date(2024, 8, 1).toISOString(); // Définir la date de fin au 1er novembre 2024
          const newEndDate = new Date(2024, 10, 30).toISOString();
          dispatch(setFilterDate({
            start: newStartDate,
            end: newEndDate
          }));
        }
      }
    }
  }, [dateRangeSelect]);

  useEffect(() => {
    setShowFilters(false);
    const timer = setTimeout(() => setShowFilters(true), 1000);
    return () => clearTimeout(timer);
  }, [workspace]);

  useEffect(() => {
    handleResetAllFilters()
  }, [workspace, dispatch]);

  useEffect(() => {
    if (location?.pathname !== "/dash/brand-wayback") {
      if (countries && !matchCompetitor && !matchCompetitorMonitoring && !matchCompetitorFocus && !matchPLAGlobalFocus && !matchTXTGlobalFocus) {
        setFilterCountryState(countries);
      }
    }
  }, [countries, filterCountry, matchCompetitor, matchCompetitorMonitoring, matchCompetitorFocus])

  useEffect(() => {
    if (location?.pathname !== "/dash/brand-wayback") {
      if (keywords && !matchCompetitor && !matchCompetitorMonitoring && !matchCompetitorFocus && !matchPLAGlobalFocus) {
        setFilterKeywordState(keywords);
      }
    }
  }, [keywords, matchCompetitor, matchCompetitorMonitoring, matchCompetitorFocus, matchPLAGlobalFocus, location.pathname])

  useEffect(() => {
    if (location?.pathname !== "/dash/brand-wayback") {
      if (filterPlatform && !matchCompetitor && !matchCompetitorMonitoring && !matchCompetitorFocus && !matchPLAGlobalFocus) {
        setFilterPlatformState(filterPlatform);
      }
    }
  }, [filterPlatform, matchCompetitor, matchCompetitorMonitoring, matchCompetitorFocus, matchPLAGlobalFocus])

  useEffect(() => {
    if (campaignType) {
      setCampaignType(campaignType)
    }
  }, [campaignType])

  useEffect(() => {
    if (devices && !matchCompetitor) {
      setFilterDeviceState(devices);
    }
  }, [devices, matchCompetitor, location?.pathname, devicesDataBrandWayback, filterStore.devices]);

  const handleDeviceFilterChange = (selectedOptions: any) => {
    let updatedOptions;

    if (Array.isArray(selectedOptions)) {
      updatedOptions = selectedOptions.filter(
        (option: string) => option !== t('dashboard.filters.all')
      );
    } else {
      updatedOptions = selectedOptions !== t('dashboard.filters.all')
        ? [selectedOptions]
        : [];
    }

    dispatch(setFilterDevice(updatedOptions));
  };

  const handleCountryFilterChange = (selectedOptions: any) => {
    let updatedOptions;
    if (Array.isArray(selectedOptions)) {
      updatedOptions = selectedOptions.filter(
        (option: string) => option !== t('dashboard.filters.all')
      );
    } else {
      updatedOptions = selectedOptions !== t('dashboard.filters.all')
        ? [selectedOptions]
        : [];
    }

    if (location?.pathname === "/dash/brand-wayback") {
      dispatch(setFilterKeyword([]))
    }

    dispatch(setFilterCountry(updatedOptions));


  };

  const handleKeywordFilterChange = (value: any) => {
    if (filterStore?.keywords) {
      if (filterStore?.keywords.includes(value)) {
        dispatch(setFilterKeyword(filterStore?.keywords.filter((keyword: any) => keyword !== value)))
      } else {
        const updatedKeywords = [...filterStore.keywords, value];
        dispatch(setFilterKeyword(updatedKeywords))
      }
    }
  };

  const handleCampaignTypeFilterChange = (selectedOptions: any) => {
    const updatedOptions = selectedOptions.filter((option: string) => option !== t('dashboard.filters.all'));
    dispatch(setFilterCampaignType(updatedOptions));
  };
  const handleResetAllFilters = () => {
    const newStartDate = new Date();
    newStartDate.setMonth(newStartDate.getUTCMonth() - 1);
    newStartDate.setDate(newStartDate.getUTCDate() + 1);

    const newEndDate = new Date();
    newEndDate.setDate(newEndDate.getUTCDate() - 1);

    dateRangeSelect.onChangeStartDate(newStartDate);
    dateRangeSelect.onChangeEndDate(newEndDate);

    if (workspace?.name.includes('Demo')) {
      dispatch(setFilterTimestep('date'));

    } else {
      dispatch(setFilterTimestep('year_week'));

    }


    dispatch(setFilterDate({
      start: newStartDate.toISOString(),
      end: newEndDate.toISOString(),
    }));

    dispatch(setFilterDateCompare({
      compareStart: subDays(new Date(), 60).toISOString(),
      compareEnd: subDays(new Date(), 31).toISOString(),
    }))


    dispatch(setFilterKeyword([]));
    dispatch(setFilterDevice([]));
    dispatch(setFilterCountry([]));
    dispatch(setFilterCompare(false));
    dispatch(setFilterPlatform([]))
    if (workspace?.template == 'E-commerce') {
      dispatch(setFilterCampaignType([]))
    }
  }

  const onChangePreset = (value: string) => {
    dateRangeSelect.onChangePreset(value);
  };

  const onChangeTimeStep = (value: string) => {
    dispatch(setFilterTimestep(value));
  }

  const iconDevices: any = [
    { name: "TABLET", icon: "icon-park:ipad" },
    { name: "MOBILE", icon: "icon-park:iphone" },
    { name: "DESKTOP", icon: "icon-park:i-mac" }
  ]

  useEffect(() => {
    if (!filterCountry || filterCountry !== filterStore.loadedCountries && (matchCompetitor || matchCompetitorMonitoring || matchCompetitorFocus || matchPLAGlobalFocus || matchTXTGlobalFocus) && filterStore.loadedCountries.length > 0) {
      setFilterCountryState(filterStore.loadedCountries);
    }

    if (!filterDevice || filterDevice !== filterStore.loadedDevices && (matchCompetitor || matchCompetitorFocus || matchCompetitorMonitoring) && filterStore.loadedDevices.length > 0) {
      setFilterDeviceState(filterStore.loadedDevices);
    }
  }, [filterStore, matchCompetitor, matchCompetitorMonitoring, matchCompetitorFocus, location, filterDevice, filterCountry, matchPLAGlobalFocus, filterStore.loadedCountries]);

  useEffect(() => {
    if (filterCountry) {
      const maxLengthCountry = filterCountry.reduce((max: any, country: any) => {
        return country.length > max ? country.length : max;
      }, 0);

      // Calculez la largeur minimale requise
      const calculatedWidth = Math.min(315, maxLengthCountry * 10); // Ajustez le multiplicateur si nécessaire

      setMinWidth(Math.max(calculatedWidth, 200)); // Appliquez une largeur minimale de 200
    }
  }, [filterCountry]);


  // Fonction pour gérer le bouton "Only" pour les pays
  const handleOnlyFilter = (option: string) => {
    dispatch(setFilterCountry([option]));
  };

  const { data: keywordsDataSerpHistory } = useGetKeywordsBrandWaybackQuery({
    startDate: filterStore?.start !== undefined ? fDate(filterStore?.start, "yyyy-MM-dd") : null,
    endDate: filterStore?.end !== undefined ? fDate(filterStore?.end, "yyyy-MM-dd") : null,
    device: filterStore?.devices,
    countries: filterStore?.countries,
    workspaceId: workspace?.id,
    platforms: filterStore?.platforms
  }, {
    skip: (!filterStore?.start ||
      !filterStore?.end ||
      !workspace?.id ||
      location?.pathname === "/dash/brand-overview"
    ),
    refetchOnMountOrArgChange: false,
  });


  useEffect(() => {
    if (keywordsDataSerpHistory && location.pathname === "/dash/brand-wayback") {
      dispatch(setFilterKeyword([keywordsDataSerpHistory?.filters?.keywords[0]?.search_term]));
      setFilterKeywordState(keywordsDataSerpHistory?.filters?.keywords);
    }
  }, [keywordsDataSerpHistory]);

  useEffect(() => {
    if (devices && !matchCompetitor && location?.pathname !== "/dash/brand-wayback") {
      setFilterDeviceState((prevState: any) =>
        JSON.stringify(prevState) !== JSON.stringify(devices) ? devices : prevState
      );
    }

    if (devicesDataBrandWayback?.filters?.devices && location?.pathname === "/dash/brand-wayback") {
      const brandWaybackDevices = devicesDataBrandWayback.filters.devices;

      setFilterDeviceState((prevState: any) =>
        JSON.stringify(prevState) !== JSON.stringify(brandWaybackDevices) ? brandWaybackDevices : prevState
      );

      // Effectuer le dispatch uniquement si le filtre est vide
      if (!filterStore.devices || filterStore.devices.length === 0) {
        const desktopDevice = brandWaybackDevices.find((device: any) => device.toLowerCase() === "desktop");
        if (desktopDevice && !filterStore.devices.includes(desktopDevice)) {
          dispatch(setFilterDevice([desktopDevice]));
        }
      }
    }
  }, [devices, matchCompetitor, location?.pathname, devicesDataBrandWayback, filterStore.devices]);


  const renderContent = (
    <>
      {lgUp && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

      {!lgUp && (
        <IconButton onClick={onOpenNav}>
          <SvgColor src="/assets/icons/navbar/ic_menu_item.svg" />
        </IconButton>
      )}

      {!lgUp &&
        <FiltersButton />
      }

      {(lgUp && window.location.href.split('/')[3] === "dash") &&
        <>

          {(filterStore?.minDatePicker && location.pathname !== "/dash/brand-overview") &&
            <Button onClick={() => handleResetAllFilters()} size="small" sx={{ marginRight: 1, width: '36px!important', height: '36px!important', minWidth: '36px!important' }} variant="contained" color="inherit">
              <Iconify icon="carbon:reset" />
            </Button>
          }


          {(keywordsData && countriesData && devicesData && filterStore?.minDatePicker) && showFilters &&
            <>

              {(location.pathname !== "/dash/brand-overview" && location.pathname !== "/dash/campaign-explorer") && (
                <>
                  {location.pathname !== "/dash/business-overview" &&
                    <InfiniteScrollKeywords
                      keywords={(filterKeyword || [])}
                      onKeywordChange={(it: any) => handleKeywordFilterChange(it)}
                    />
                  }

                  <Autocomplete
                    multiple={location.pathname === "/dash/brand-wayback" ? false : true}
                    disableClearable={location.pathname === "/dash/brand-wayback" ? true : false}
                    size="small"
                    limitTags={1}
                    disableCloseOnSelect
                    sx={{ marginRight: 2, marginLeft: 2 }}
                    options={filterCountry || []}
                    getOptionLabel={(option: any) => option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t('dashboard.filters.countries')}
                        sx={{ minWidth: 200, maxWidth: 240 }}
                      />
                    )}
                    value={
                      filterStore?.countries?.length === 0
                        ? [t('dashboard.filters.all')]
                        : filterStore?.countries
                    }
                    renderOption={(props, option) => {
                      const country = countriesJson?.find((c) => c.name === option);
                      const flagIcon = country ? `circle-flags:${country.code}` : null;

                      return (
                        <li {...props} ref={(el) => (optionRefs.current[option] = el)}
                          style={{ whiteSpace: 'nowrap' }} key={option} onMouseEnter={() => {
                            optionRefs.current[option].classList.add('hovered');
                          }}
                          onMouseLeave={() => {
                            optionRefs.current[option].classList.remove('hovered');
                          }}>
                          {option !== t('dashboard.filters.all') && (
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                              width="100%"
                              sx={{
                                '&:hover .only-button': {
                                  display: 'flex',
                                },
                              }}
                            >
                              <Box>
                                {location.pathname !== "/dash/brand-wayback" &&
                                  <Checkbox
                                    checked={filterStore?.countries.includes(option)}
                                  />
                                }

                                {flagIcon ? (
                                  <Iconify icon={flagIcon} width={16} sx={{ mr: 1, position: 'relative', top: 4 }} />
                                ) : (
                                  <img style={{ width: 14, height: 14, marginRight: 6, position: 'relative', top: -6 }} src="/assets/location.png" alt="flag" />
                                )}
                                <span style={{ position: 'relative', top: 2 }}>{option}</span>
                              </Box>
                              {location.pathname !== "/dash/brand-wayback" &&
                                <Button
                                  className="hover-only-button"
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    handleOnlyFilter(option);
                                  }}
                                  variant="contained"
                                  color="inherit"
                                  size="small"
                                  sx={{
                                    mt: 0.3,
                                    height: 24,
                                    minWidth: 50,
                                    padding: 0,
                                    display: 'none',
                                    justifyContent: 'flex-end',
                                  }}
                                >
                                  Only
                                </Button>
                              }
                            </Box>
                          )}
                          {option === t('dashboard.filters.all') && option}
                        </li>
                      );
                    }}
                    onChange={(event, selectedOptions) => handleCountryFilterChange(selectedOptions)}
                    renderTags={(selected, getTagProps) =>
                      selected.map((option, index) => {
                        const country = countriesJson?.find((c) => c.name === option);
                        const flagCode = country ? country.code : null;

                        return (
                          <Chip
                            {...getTagProps({ index })}
                            key={option}
                            icon={
                              option !== t('dashboard.filters.all') ? (
                                flagCode ? (
                                  <FlagIcon
                                    key={flagCode}
                                    code={flagCode}
                                    sx={{ width: 16, height: 16, borderRadius: '50%' }}
                                  />
                                ) : (
                                  <img style={{ width: 14, height: 14, marginRight: 1 }} src="/assets/location.png" alt="flag" />
                                )
                              ) : undefined
                            }
                            label={option}
                            size="small"
                            deleteIcon={option === t('dashboard.filters.all') ? <></> : undefined}
                            variant="soft"
                          />
                        );
                      })
                    }
                  />


                  {!["/dash/product-listing-ads", "/dash/text-ads"].includes(location.pathname) &&
                    <Autocomplete
                      multiple={location.pathname === "/dash/brand-wayback" ? false : true}
                      limitTags={1}

                      size="small"
                      sx={{ marginRight: 2 }}
                      options={filterDevice.filter((device: any) => device !== "NA") || []}
                      getOptionLabel={(option: any) => option}
                      renderInput={(params) => (
                        <TextField {...params} label={t('dashboard.filters.devices')} />
                      )}
                      value={
                        filterStore?.devices?.length === 0
                          ? [t('dashboard.filters.all')]
                          : location.pathname === "/dash/brand-wayback"
                            ? [filterStore?.devices[0]?.charAt(0).toUpperCase() + filterStore?.devices[0]?.slice(1).toLowerCase()]
                            : filterStore?.devices
                      }
                      renderOption={(props, option) => (
                        <li {...props} key={option}>
                          <Iconify
                            icon={iconDevices.find((device: any) => device.name === option || option.toUpperCase())?.icon}
                            sx={{ width: 16, height: 16, mr: 1 }}
                          />
                          {option.charAt(0).toUpperCase() + option.slice(1).toLowerCase()}
                        </li>
                      )}
                      onChange={(event, selectedOptions) => handleDeviceFilterChange(selectedOptions)}
                      renderTags={(selected, getTagProps) =>
                        selected.map((option, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            key={option}
                            label={option.charAt(0).toUpperCase() + option.slice(1).toLowerCase()}
                            icon={
                              <Iconify
                                icon={iconDevices.find((device: any) => device.name === option)?.icon}
                                sx={{ width: 16, height: 16 }}
                              />
                            }
                            size="small"
                            deleteIcon={option === t('dashboard.filters.all') ? <></> : undefined}
                            variant="soft"
                          />
                        ))
                      }
                    />
                  }

                  {filterStore?.loadedPlatform?.length >= 2 &&
                    <Autocomplete
                      multiple={location.pathname === "/dash/brand-wayback" ? false : true}
                      limitTags={1}
                      disableClearable={location.pathname === "/dash/brand-wayback" ? true : false}
                      size="small"
                      sx={{ marginRight: 2 }}
                      options={filterStore?.loadedPlatform}
                      getOptionLabel={(option: any) => option}
                      renderInput={(params) => (<TextField {...params} label={t('dashboard.filters.platforms')} />)}
                      value={filterStore?.platforms?.length === 0 ? [t('dashboard.filters.all')] : filterStore?.platforms}
                      renderOption={(props, option) => (
                        <li {...props} key={option}>
                          {option === 'Google' || option === 'Bing' ? (
                            <Iconify
                              icon={option === 'Google' ? 'devicon:google' : 'logos:bing'}
                              sx={{ width: 16, height: 16, mr: 1 }}
                            />
                          ) : null}
                          {option}
                        </li>
                      )}
                      onChange={(event, selectedOptions) => {
                        let updatedOptions;

                        if (Array.isArray(selectedOptions)) {
                          updatedOptions = selectedOptions.filter(
                            (option: string) => option !== t('dashboard.filters.all')
                          );
                        } else {
                          updatedOptions = selectedOptions !== t('dashboard.filters.all') ? [selectedOptions] : [];
                        }

                        dispatch(setFilterPlatform(updatedOptions));
                      }}
                      renderTags={(selected, getTagProps) =>
                        selected.map((option, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            key={option}
                            label={option}
                            icon={
                              option === 'Google' || option === 'Bing' ? (
                                <Iconify
                                  icon={option === 'Google' ? 'devicon:google' : 'logos:bing'}
                                  sx={{ width: 16, height: 16 }}
                                />
                              ) : undefined
                            }
                            size="small"
                            deleteIcon={option === t('dashboard.filters.all') ? <></> : undefined}
                            variant="soft"
                          />
                        ))
                      }
                    />
                  }
                  {["/dash/competitors-monitoring"].includes(location.pathname) && workspace?.template === 'E-commerce' &&
                    <Autocomplete
                      multiple={true}
                      limitTags={1}
                      size="small"
                      sx={{ marginRight: 1 }}
                      options={campaignType || []}
                      getOptionLabel={(option: any) => option}
                      renderInput={(params) => (
                        <TextField {...params} label={t('dashboard.filters.campaignType')} />
                      )}
                      value={filterStore?.campaignType?.length === 0 ? [t('dashboard.filters.all')] : filterStore?.campaignType}
                      renderOption={(props, option) => (
                        <li {...props} key={option}>

                          {option.charAt(0).toUpperCase() + option.slice(1).toLowerCase()}
                        </li>
                      )}
                      onChange={(event, selectedOptions) => handleCampaignTypeFilterChange(selectedOptions)}
                      renderTags={(selected, getTagProps) =>
                        selected.map((option, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            key={option}
                            label={option.charAt(0).toUpperCase() + option.slice(1).toLowerCase()}
                            icon={
                              <Iconify
                                icon={iconDevices.find((device: any) => device.name === option)?.icon}
                                sx={{ width: 16, height: 16 }}
                              />
                            }
                            size="small"
                            deleteIcon={option === t('dashboard.filters.all') ? <></> : undefined}
                            variant="soft"
                          />
                        ))
                      }
                    />
                  }

                </>
              )}

            </>
          }

        </>
      }

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1 }}
      >

        {filterStore.minDatePicker &&
          <>
            {!["/", "/admin/features/live-monitoring/add", "/dash/campaign-explorer", "/admin/features/live-monitoring", "/workspaces", "/workspaces/add", "/admin/users", "/admin/users/add"].includes(location.pathname) &&
              <>
                {(lgUp) && (
                  <DateRangeSelect
                    startDate={dateRangeSelect.start || new Date()}
                    endDate={dateRangeSelect.end || new Date()}
                    compareStartDate={undefined}
                    compareEndDate={undefined}
                    selected={
                      dateRangeSelect.selected === "custom"
                        ? `${fDate(filterStore.start)} - ${fDate(filterStore.end)}`
                        : (dateRangeSelect.preset as any).displayName
                    }
                    onChangeEndDate={(endDate: Date | null) => {
                      dateRangeSelect.onChangeEndDate(endDate);
                      updateFilterDate(filterStore?.start, endDate);
                    }}
                    onChangeStartDate={(startDate: Date | null) => {
                      dateRangeSelect.onChangeStartDate(startDate);
                      updateFilterDate(startDate, filterStore?.end);
                    }}
                    onChangeCompareStartDate={(it: any) => setCompareStartDate(it)}
                    onChangeCompareEndDate={(it: any) => setCompareEndDate(it)}
                    onChangePreset={onChangePreset}
                    onChangeTimeStep={onChangeTimeStep}
                    preset={"last_30d"}
                    features={{ time_step: true }}
                    timeStep={dateRangeSelect.step as any}
                  />
                )}
              </>
            }
          </>
        }

        <LanguagePopover />

        <NotificationsPopover />

        <BaseOptions
          value={settings.themeMode}
          onChange={(newValue: string) => settings.onUpdate('themeMode', newValue)}
          options={['light', 'dark']}
          icons={['sun', 'moon']}
        />

        <AccountPopover />
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(lgUp && {
          width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
          height: HEADER.H_DESKTOP,
          ...(offsetTop && {
            height: HEADER.H_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}