import { Box, Grid, Link, MenuItem, Paper, Skeleton, TextField, Typography, useTheme } from "@mui/material";
import Welcome from "./welcome";
import { MotivationIllustration } from "src/assets/illustrations";
import { useResponsive } from "src/hooks/use-responsive";
import { setFilterCountry, setFilterDevice, setFilterKeyword, setFilterLoadedCountry, setFilterLoadedDevice, setFilterLoadedKeyword, setFilterLoadedPlatform, setFilterMinDatePicker } from "src/context/reducers/filter-settings/filterSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fDate } from "src/utils/format-time";
import { useCurrentWorkspace } from "src/context/reducers/app-settings";
import { useGetCompetitorCountriesQuery, useGetCompetitorDevicesQuery, useGetCompetitorKeywordsQuery, useGetCountriesCompetitorQuery, useGetDevicesCompetitorQuery, useGetKeywordsCompetitorQuery, useGetPlatformsCompetitorQuery, useGetPresQuery, useGetPresenceQuery } from "src/context/api/competitorsMonitoring/api";
import DataGridCustomPresence from "src/components/data-grid/data-grid-custom-presence-custom-pagination";
import DataGridCustomPresenceGhostRate from "src/components/data-grid/data-grid-custom-presence-ghost-rate";
import Iconify from "src/components/iconify";
import ChartColumn from "src/sections/charts/chart-column-custom";
import ChartColumnGhost from "src/sections/charts/chart-column-custom_ghost";
import DataGridCustomPresenceKeywords from "src/components/data-grid/data-grid-custom-presence-keywords";
import BankingWidgetSummaryRatio from "./banking-widget-summary-ratio";
import { differenceInDays } from "date-fns";
import { fPercent } from "src/utils/format-number";
import { useLocales } from "src/locales";
import { useBoolean } from "src/hooks/use-boolean";
import { useRouter } from "src/routes/hooks";
import { usePreviousLocation } from "src/components/previousLocationContext";
import DataGridCustomOccupationShoppingKeywords from "src/components/data-grid/data-grid-custom-occupation-shopping-keywords";
import ChartColumnOccupation from "src/sections/charts/chart-column-custom-occupation";

export default function CompetitorsMonitoring() {
    const { t, currentLang } = useLocales();
    const lgUp = useResponsive('up', 'lg');
    const dispatch = useDispatch();
    const workspace: any = useCurrentWorkspace();
    const filterStore = useSelector((state: any) => state.filters);
    const theme = useTheme();
    const disableWelcome = useBoolean();
    const router = useRouter();
    const prevLocation = usePreviousLocation(); // Accéder à l'URL précédente
    const [selectedDisplay, setSelectedDisplay] = useState('competitors_only');

    useEffect(() => {
        if (prevLocation !== "/dash/competitor-focus") {
            dispatch(setFilterLoadedKeyword([]));
            dispatch(setFilterKeyword([]))
        }
    }, [prevLocation])

    useEffect(() => {
        if (prevLocation === "/dash/brand-wayback") {
            dispatch(setFilterLoadedKeyword([]));
            dispatch(setFilterKeyword([]))
            dispatch(setFilterDevice([]))
            dispatch(setFilterLoadedDevice([]))
            dispatch(setFilterCountry([]))
            dispatch(setFilterLoadedCountry([]))
        }
    }, [prevLocation])

    enum ColorSchema {
        PRIMARY = 'primary',
        SECONDARY = 'secondary',
        TERTIARY = 'tertiary',
        ERROR = 'error',
        WARNING = 'warning',
        INFO = 'info',
        SUCCESS = 'success'
    }

    const skipQuery = !filterStore.start || !filterStore.end || !workspace?.id

    const { data: platforms, error: platformsError, isFetching: isFetchingPlatforms } = useGetPlatformsCompetitorQuery({
        startDate: filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        devices: filterStore.devices,
        countries: filterStore.countries,
        keywords: filterStore.keywords,
        workspaceId: workspace?.id,
    },
        { skip: skipQuery }
    );

    useEffect(() => {
        if (platforms)
            dispatch(setFilterLoadedPlatform(platforms))
    }, [platforms])

    const { data: countries, error: countriesError } = useGetCompetitorCountriesQuery({
        startDate: filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        device: filterStore.devices,
        keywords: filterStore.keywords,
        workspaceId: workspace?.id
    }, {
        skip: skipQuery,
    });

    const { data: keywords, error: keywordsError, isFetching: isFetchingKeywords } = useGetCompetitorKeywordsQuery({
        startDate: filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        devices: filterStore.devices,
        countries: filterStore.countries,
        platform: filterStore?.platforms,
        workspaceId: workspace?.id
    }, {
        skip: skipQuery,
    });

    useEffect(() => {
        dispatch(setFilterLoadedKeyword([]));

        if (!isFetchingKeywords) {
            dispatch(setFilterLoadedKeyword(keywords?.filters?.keywords))
        }
    }, [keywords, workspace])

    const { data: devices, error: devicesError } = useGetCompetitorDevicesQuery({
        startDate: filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        countries: filterStore.countries,
        keywords: filterStore.keywords,
        workspaceId: workspace?.id
    }, {
        skip: skipQuery,
    });

    const skipQueryPresence = !filterStore.start || !filterStore.end || !workspace?.id || !workspace;


    const { data: presenceData, error: dataPresenceError, isFetching: isFetchingDataPresence } = useGetPresenceQuery({
        startDate: filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        compareStartDate: filterStore.compareStart !== undefined ? fDate(filterStore.compareStart, "yyyy-MM-dd") : null,
        compareEndDate: filterStore.compareEnd !== undefined ? fDate(filterStore.compareEnd, "yyyy-MM-dd") : null,
        timestep: filterStore.timestep,
        device: filterStore.devices,
        countries: filterStore.countries,
        keywords: filterStore.keywords,
        workspaceId: workspace?.id,
        domain: workspace?.url,
        platform: filterStore?.platforms,
        campaignType: filterStore?.campaignType
    }, {
        skip: skipQueryPresence,
    });



    const { data: dataPres, error: dataPresenceErr, isFetching: isFetchingDataPres } = useGetPresQuery({
        startDate: filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        compareStartDate: filterStore.compareStart !== undefined ? fDate(filterStore.compareStart, "yyyy-MM-dd") : null,
        compareEndDate: filterStore.compareEnd !== undefined ? fDate(filterStore.compareEnd, "yyyy-MM-dd") : null,
        timestep: filterStore.timestep,
        display: selectedDisplay,
        device: filterStore.devices,
        countries: filterStore.countries,
        keywords: filterStore.keywords,
        workspaceId: workspace?.id,
        domain: workspace?.url,
        platform: filterStore?.platforms,
        campaignType: filterStore?.campaignType

    }, {
        skip: skipQueryPresence,
    });

    const getDaysDifference = (startDate: any, endDate: any) => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        return differenceInDays(end, start);
    }

    const getTimestep = (startDate: any, endDate: any) => {
        const daysDifference = getDaysDifference(startDate, endDate);

        if (daysDifference > 365) {
            return 'year_month';
        } else if (daysDifference > 90) {
            return 'year_week';
        } else {
            return 'date';
        }
    }

    const { data: sparkLinesData, error: sparkLinesDataError, isFetching: isFetchingSparkLinesData } = useGetPresenceQuery({
        startDate: filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        compareStartDate: filterStore.compareStart !== undefined ? fDate(filterStore.compareStart, "yyyy-MM-dd") : null,
        compareEndDate: filterStore.compareEnd !== undefined ? fDate(filterStore.compareEnd, "yyyy-MM-dd") : null,
        timestep: getTimestep(filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null, filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null),
        device: filterStore.devices,
        countries: filterStore.countries,
        keywords: filterStore.keywords,
        workspaceId: workspace?.id,
        domain: workspace?.url,
        platform: filterStore?.platforms,
        campaignType: filterStore?.campaignType
    }, {
        skip: skipQueryPresence,
        refetchOnMountOrArgChange: false,
    });


    const dataPresence = dataPres?.presence?.slice(0, 5)?.map((item: any, index: any) => ({
        id: index,
        domain: item?.domain,
        presenceRate: item?.ratio_present_scrap * 100,
        device: item?.device,
        country: item?.country,
    })) || [];
    const updateDataPresence = dataPresence.map((domainData: any) => {

        const matchSearchDomain = dataPres?.presenceSearch?.find((item: any) => item.domain === domainData.domain)
        const matchShoppingDomain = dataPres?.presenceShopping?.find((item: any) => item.domain === domainData.domain)

        return {
            ...domainData,
            presenceSearchRate: matchSearchDomain?.ratio_present_search_scrap * 100 || 0,
            presenceShoppingRate: matchShoppingDomain?.ratio_present_shopping_scrap * 100 || 0
        }

    })

    const dataPresenceSearch = dataPres?.presenceSearch.slice(0, 5)?.map((item: any, index: any) => ({
        id: index,
        domain: item?.domain,
        presenceSearchRate: item?.ratio_present_search_scrap * 100,
    })) || [];

    const dataPresenceShopping = dataPres?.presenceShopping.slice(0, 5)?.map((item: any, index: any) => ({
        id: index,
        domain: item?.domain,
        presenceShoppingRate: item?.ratio_present_shopping_scrap * 100,
    })) || [];

    let competitorsOverviewDatas = dataPresence
    if (workspace?.template === 'E-commerce') {
        if (filterStore?.campaignType.length === 1) {
            switch (filterStore?.campaignType[0]) {
                case 'Search':
                    competitorsOverviewDatas = dataPresenceSearch
                    break
                case 'Shopping':
                    competitorsOverviewDatas = dataPresenceShopping
                    break
            }
        } else if (filterStore?.campaignType.length > 1 || filterStore?.campaignType.length === 0) {
            competitorsOverviewDatas = updateDataPresence
        }

    }
    const [soloBiderLabels, setSoloBiderLabels] = useState<any>([]);
    const [ghostLabels, setGhostLabels] = useState<any>([]);
    const [occupationLabels, setOccupationLabels] = useState<any>([]);

    useEffect(() => {
        if (presenceData?.soloBidderRateOverTime) {
            if (filterStore.timestep === 'year_week') {
                const yearWeekRegex = /^\d{4}-\d{2}$/;
                const formattedLabels = presenceData?.soloBidderRateOverTime.map((item: any) => {
                    return item?.timestep
                });
                setSoloBiderLabels(formattedLabels);
                const formattedLabelsGhost = presenceData?.ghostRateOverTime.map((item: any) => {
                    return item?.timestep
                });
                setGhostLabels(formattedLabelsGhost);
                const formattedLabelsOccupation = presenceData?.occupation_rate_over_time.map((item: any) => {
                    return item?.timestep
                });
                setOccupationLabels(formattedLabelsOccupation);
            } else if (filterStore.timestep === 'year_month') {
                const formatedMontlyDates = presenceData?.soloBidderRateOverTime?.map((item: any) => {
                    const [year, week] = item.timestep.split('-');
                    return `${week}/${year}`;
                })
                setSoloBiderLabels(formatedMontlyDates);

                const formatedMontlyDatesGhost = presenceData?.ghostRateOverTime?.map((item: any) => {
                    const [year, week] = item.timestep.split('-');
                    return `${week}/${year}`;
                })
                setGhostLabels(formatedMontlyDatesGhost);
                const formatedMontlyDatesOccupation = presenceData?.occupation_rate_over_time?.map((item: any) => {
                    const [year, week] = item.timestep.split('-');
                    return `${week}/${year}`;
                })
                setOccupationLabels(formatedMontlyDatesOccupation);
            } else {
                const formattedDayliSoloBider = presenceData?.soloBidderRateOverTime?.map((item: any) => {
                    const newDate = new Date(item.timestep);
                    const month = String(newDate.getUTCMonth() + 1).padStart(2, '0'); // getUTCMonth() is zero-based, so we add 1
                    const day = String(newDate.getUTCDate()).padStart(2, '0');
                    const year = newDate.getUTCFullYear();
                    if (currentLang.value === 'fr') {
                        return `${day}/${month}/${year}`;
                    } else {
                        return `${month}/${day}/${year}`;
                    }
                })
                setSoloBiderLabels(formattedDayliSoloBider);
                const formattedDailyGhost = presenceData?.ghostRateOverTime?.map((item: any) => {
                    const newDate = new Date(item.timestep);
                    const month = String(newDate.getUTCMonth() + 1).padStart(2, '0'); // getUTCMonth() is zero-based, so we add 1
                    const day = String(newDate.getUTCDate()).padStart(2, '0');
                    const year = newDate.getUTCFullYear();
                    if (currentLang.value === 'fr') {
                        return `${day}/${month}/${year}`;
                    } else {
                        return `${month}/${day}/${year}`;
                    }
                })

                setGhostLabels(formattedDailyGhost);
                const formattedDailyOccupation = presenceData?.occupation_rate_over_time?.map((item: any) => {
                    const newDate = new Date(item.timestep);
                    const month = String(newDate.getUTCMonth() + 1).padStart(2, '0'); // getUTCMonth() is zero-based, so we add 1
                    const day = String(newDate.getUTCDate()).padStart(2, '0');
                    const year = newDate.getUTCFullYear();
                    if (currentLang.value === 'fr') {
                        return `${day}/${month}/${year}`;
                    } else {
                        return `${month}/${day}/${year}`;
                    }
                })

                setOccupationLabels(formattedDailyOccupation);
            }
        }

    }, [currentLang.value, presenceData]);

    const dataOccupationRateShoppingTopKeywords = presenceData?.occupation_ratio_shopping_top_keywords?.map((item: any, index: any) => ({
        id: index,
        search_term: item?.search_term,
        occupation_rate_shopping: item?.occupation_rate_shopping * 100
    })) || []
    const dataPresenceSearchTerm = presenceData?.soloGhost?.map((item: any, index: any) => ({
        id: index,
        search_term: item?.search_term,
        presenceRate: item?.ratio_present_bidder * 100
    })) || [];

    const dataPresenceGhostRate = presenceData?.ghostRate?.map((item: any, index: any) => ({
        id: index,
        search_term: item?.search_term,
        ghostRate: item?.ghost_rate * 100
    })) || [];

    useEffect(() => {
        if (presenceData?.minDatePicker) {
            dispatch(setFilterMinDatePicker(presenceData.minDatePicker[0]?.first_date));
        }
    }, [presenceData, dispatch]);

    const labelsCompetitorsOverTime = Array.from(new Set(presenceData?.presenceByDate.flatMap((domainData: any) => domainData[Object.keys(domainData)[0]].map((entry: any) => entry.timestep))));
    const [customLabels, setCustomLabels] = useState<any>([]);
    useEffect(() => {
        if (labelsCompetitorsOverTime) {

            if (filterStore.timestep === 'year_week') {
                const formattedLabels = labelsCompetitorsOverTime.map((item: any) => {
                    return item
                });
                setCustomLabels(formattedLabels);
            } else if (filterStore.timestep === 'year_month') {
                const formatedMontlyDates = labelsCompetitorsOverTime.map((item: any) => {
                    const [year, week] = item.split('-');
                    return `${week}/${year}`;
                })
                setCustomLabels(formatedMontlyDates);

            } else {
                const formatedDailyDates = labelsCompetitorsOverTime.map((item: any) => {
                    const newDate = new Date(item);
                    const month = String(newDate.getUTCMonth() + 1).padStart(2, '0'); // getUTCMonth() is zero-based, so we add 1
                    const day = String(newDate.getUTCDate()).padStart(2, '0');
                    const year = newDate.getUTCFullYear();
                    if (currentLang.value === 'fr') {
                        return `${day}/${month}/${year}`;
                    } else {
                        return `${month}/${day}/${year}`;
                    }
                })
                setCustomLabels(formatedDailyDates);
            }

        }

    }, [currentLang.value, presenceData]);

    const seriesCompetitorsOverTime = dataPres?.presenceByDate.map((domainData: any) => {
        const domain = Object.keys(domainData)[0];

        return {
            name: domain,
            data: labelsCompetitorsOverTime.map(label => {
                const entry = domainData[domain].find((e: any) => e.timestep === label);
                let ratioValue = 0
                if (entry && entry.ratio_present_scrap) {
                    ratioValue = entry.ratio_present_scrap * 100 || 0;
                } else if (entry && entry.ratio_present_search_scrap) {
                    ratioValue = entry.ratio_present_search_scrap * 100 || 0;
                } else if (entry && entry.ratio_present_shopping_scrap) {
                    ratioValue = entry.ratio_present_shopping_scrap * 100 || 0;
                }

                return ratioValue

            })
        };
    });
    const SparkLinesDataSoloBidderRate = sparkLinesData?.soloBidderRateOverTime
        ?.filter((item: any) => item?.ratio_present_bidder !== null && item?.ratio_present_bidder !== undefined)
        ?.map((item: any) => {
            const daysDifference = getDaysDifference(
                filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null,
                filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null
            );

            const sum = item?.ratio_present_bidder;

            if (daysDifference > 365) {
                return { y: sum, x: item?.timestep };
            } else if (daysDifference > 90) {
                return { y: sum, x: item?.timestep };
            } else {
                const date = new Date(item?.timestep);
                const year = date.getUTCFullYear();
                const month = String(date.getUTCMonth() + 1).padStart(2, '0');
                const day = String(date.getUTCDate()).padStart(2, '0');
                return { y: sum, x: `${year}-${month}-${day}` };
            }
        });

    const SparkLinesDataGhostRate = sparkLinesData?.ghostRateOverTime
        ?.filter((item: any) => item?.ghost_rate !== null && item?.ghost_rate !== undefined)  // Filtrer pour enlever les null/undefined
        ?.map((item: any) => {
            const daysDifference = getDaysDifference(
                filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null,
                filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null
            );
            const sum = item?.ghost_rate;

            if (daysDifference > 365) {
                return { y: sum, x: item?.timestep };
            } else if (daysDifference > 90) {
                return { y: sum, x: item?.timestep };
            } else {
                const date = new Date(item?.timestep);
                const year = date.getUTCFullYear();
                const month = String(date.getUTCMonth() + 1).padStart(2, '0');
                const day = String(date.getUTCDate()).padStart(2, '0');
                return { y: sum || 0, x: `${year}-${month}-${day}` };
            }
        });

    const SparkLinesDataPresenceRate = sparkLinesData?.presence_ratio_over_time
        ?.filter((item: any) => {
            return item?.total_nb_scrap !== 0 && item?.total_nb_present / item?.total_nb_scrap !== 0;
        })
        ?.map((item: any) => {
            const daysDifference = getDaysDifference(
                filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null,
                filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null
            );

            const sum = item?.presence_ratio;

            if (daysDifference > 365) {
                return { y: sum, x: item?.timestep };
            } else if (daysDifference > 90) {
                return { y: sum, x: item?.timestep };
            } else {
                const date = new Date(item?.timestep);
                const year = date.getUTCFullYear();
                const month = String(date.getUTCMonth() + 1).padStart(2, '0');
                const day = String(date.getUTCDate()).padStart(2, '0');
                return { y: sum, x: `${year}-${month}-${day}` };
            }
        })
        ?.filter((point: any) => point.y !== null); // Exclure les points avec y à null

    const SparkLinesDataPresenceRateSearch = sparkLinesData?.presence_ratio_search_over_time
        ?.filter((item: any) => {
            return item?.total_nb_scrap !== 0 && item?.total_nb_present / item?.total_nb_scrap !== 0;
        })
        ?.map((item: any) => {
            const daysDifference = getDaysDifference(
                filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null,
                filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null
            );

            const sum = item?.presence_ratio_search;

            if (daysDifference > 365) {
                return { y: sum, x: item?.timestep };
            } else if (daysDifference > 90) {
                return { y: sum, x: item?.timestep };
            } else {
                const date = new Date(item?.timestep);
                const year = date.getUTCFullYear();
                const month = String(date.getUTCMonth() + 1).padStart(2, '0');
                const day = String(date.getUTCDate()).padStart(2, '0');
                return { y: sum, x: `${year}-${month}-${day}` };
            }
        })
        ?.filter((point: any) => point.y !== null);

    const SparkLinesDataPresenceRateShopping = sparkLinesData?.presence_ratio_shopping_over_time
        ?.filter((item: any) => {
            return item?.total_nb_scrap !== 0 && item?.total_nb_present / item?.total_nb_scrap !== 0;
        })
        ?.map((item: any) => {
            const daysDifference = getDaysDifference(
                filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null,
                filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null
            );

            const sum = item?.presence_ratio_shopping;

            if (daysDifference > 365) {
                return { y: sum, x: item?.timestep };
            } else if (daysDifference > 90) {
                return { y: sum, x: item?.timestep };
            } else {
                const date = new Date(item?.timestep);
                const year = date.getUTCFullYear();
                const month = String(date.getUTCMonth() + 1).padStart(2, '0');
                const day = String(date.getUTCDate()).padStart(2, '0');
                return { y: sum, x: `${year}-${month}-${day}` };
            }
        })
        ?.filter((point: any) => point.y !== null);

    const SparkLinesDataOccupationRateShopping = sparkLinesData?.occupation_ratio_shopping_over_time
        ?.filter((item: any) => {
            return item?.total_ad_count !== 0 && item?.total_domain_ad_count / item?.total_ad_count !== 0;
        })
        ?.map((item: any) => {
            const daysDifference = getDaysDifference(
                filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null,
                filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null
            );

            const sum = item?.occupation_rate;

            if (daysDifference > 365) {
                return { y: sum, x: item?.timestep };
            } else if (daysDifference > 90) {
                return { y: sum, x: item?.timestep };
            } else {
                const date = new Date(item?.timestep);
                const year = date.getUTCFullYear();
                const month = String(date.getUTCMonth() + 1).padStart(2, '0');
                const day = String(date.getUTCDate()).padStart(2, '0');
                return { y: sum, x: `${year}-${month}-${day}` };
            }
        })
        ?.filter((point: any) => point.y !== null);

    const SparkLinesDataUniqueCompetitors = sparkLinesData?.total_unique_competitors_over_time?.map((item: any) => {
        const daysDifference = getDaysDifference(
            filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null,
            filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null
        );

        const sum = item?.unique_domain_count;

        if (daysDifference > 365) {
            return { y: sum, x: item?.timestep };
        } else if (daysDifference > 90) {
            return { y: sum, x: item?.timestep };
        } else {
            const date = new Date(item?.timestep);
            const year = date.getUTCFullYear();
            const month = String(date.getUTCMonth() + 1).padStart(2, '0');
            const day = String(date.getUTCDate()).padStart(2, '0');
            return { y: sum || 0, x: `${year}-${month}-${day}` };
        }
    });
    const stats = []
    stats.push(
        {
            title: t('dashboard.competitorsMonitoring.presenceRate'),
            value: fPercent((presenceData?.presence_ratio * 100)?.toFixed(1)),
            compareData: ((presenceData?.presence_ratio - presenceData?.presence_ratio_compare) / presenceData?.presence_ratio_compare) * 100,
            color: ColorSchema.ERROR,
            textColor: '#b71d18',
            spanColor: theme.palette.mode === "dark" ? '#db8377' : 'transparent',
            icon: "icon-park-twotone:check-one",
            series: SparkLinesDataPresenceRate
        },
    )
    if (workspace?.template === 'E-commerce') {
        stats.push(
            {
                title: t('dashboard.competitorsMonitoring.presenceRateSearch'),
                value: fPercent((presenceData?.presence_ratio_search * 100)?.toFixed(1)),
                compareData: ((presenceData?.presence_ratio_search - presenceData?.presence_ratio_search_compare) / presenceData?.presence_ratio_search_compare) * 100,
                color: ColorSchema.SECONDARY,
                textColor: '#b71d18',
                spanColor: theme.palette.mode === "dark" ? '#db8377' : 'transparent',
                icon: "icon-park-twotone:check-one",
                series: SparkLinesDataPresenceRateSearch
            },
        )
    }
    stats.push(
        {
            title: workspace?.template == 'E-commerce' ? t('dashboard.competitorsMonitoring.soloBidderRateSearch') : t('dashboard.competitorsMonitoring.soloBidderRate'),
            value: fPercent((presenceData?.solo_bidder_rate * 100).toFixed(1)),
            compareData: presenceData?.solo_bidder_rate_compare
                ? parseFloat((((presenceData?.solo_bidder_rate - presenceData?.solo_bidder_rate_compare) / presenceData?.solo_bidder_rate_compare) * 100).toFixed(1))
                : undefined,
            color: ColorSchema.SUCCESS,
            textColor: '#118d57',
            icon: "icon-park-twotone:one-key",
            spanColor: theme.palette.mode === "dark" ? '#6dad8d' : 'transparent',
            series: SparkLinesDataSoloBidderRate
        },
        {
            title: workspace?.template == 'E-commerce' ? t('dashboard.competitorsMonitoring.ghostRateSearch') : t('dashboard.competitorsMonitoring.ghostRate'),
            value: fPercent((presenceData?.ghost_rate * 100)?.toFixed(1)),
            compareData: presenceData?.solo_bidder_rate_compare
                ? parseFloat((((presenceData?.ghost_rate - presenceData?.ghost_rate_compare) / presenceData?.ghost_rate_compare) * 100).toFixed(1))
                : undefined,
            series: SparkLinesDataGhostRate,
            color: ColorSchema.INFO,
            textColor: '#5119b7',
            icon: "icon-park-twotone:ghost",
            spanColor: theme.palette.mode === "dark" ? '#9e77da' : 'transparent',
        },
    )
    if (workspace?.template !== 'E-commerce') {
        stats.push({
            title: t('dashboard.competitorsMonitoring.uniqueCompetitors'),
            value: presenceData?.total_unique_competitors,
            compareData: ((presenceData?.total_unique_competitors - presenceData?.total_unique_competitors_compare) / presenceData?.total_unique_competitors_compare) * 100,
            series: SparkLinesDataUniqueCompetitors,
            color: ColorSchema.WARNING,
            textColor: '#b76e00',
            icon: "icon-park-twotone:boxing",
            spanColor: theme.palette.mode === "dark" ? '#ffd066' : 'transparent',
        },)
    }

    if (workspace?.template == 'E-commerce') {
        stats.push(
            {
                title: t('dashboard.competitorsMonitoring.presenceRateShopping'),
                value: fPercent((presenceData?.presence_ratio_shopping * 100)?.toFixed(1)),
                compareData: ((presenceData?.presence_ratio_shopping - presenceData?.presence_ratio_shopping_compare) / presenceData?.presence_ratio_shopping_compare) * 100,
                color: ColorSchema.TERTIARY,
                textColor: theme.palette.mode === "dark" ? '#006c9c' : "#006c9c",
                spanColor: theme.palette.mode === "dark" ? '#db8377' : 'transparent',
                icon: "icon-park-twotone:check-one",
                series: SparkLinesDataPresenceRateShopping
            },
            {
                title: t('dashboard.competitorsMonitoring.occupationRateShopping'),
                value: fPercent((presenceData?.occupation_ratio_shopping * 100)?.toFixed(1)),
                compareData: ((presenceData?.occupation_ratio_shopping - presenceData?.occupation_ratio_shopping_compare) / presenceData?.occupation_ratio_shopping_compare) * 100,
                color: ColorSchema.WARNING,
                textColor: '#b76e00',
                icon: "icon-park-twotone:boxing",
                spanColor: theme.palette.mode === "dark" ? '#ffd066' : 'transparent',
                series: SparkLinesDataOccupationRateShopping,

            },)
    }

    useEffect(() => {
        if (countries) {
            dispatch(setFilterLoadedCountry(countries?.countries));
        }
    }, [countries]);

    useEffect(() => {
        if (devices) {
            dispatch(setFilterLoadedDevice(devices?.devices));
        }
    }, [devices]);

    const displayOptions = [
        {
            value: "competitors_only",
            label: "Competitors Only",
        },
        {
            value: "all_domains",
            label: "All Domains",
        },
    ];


    return (
        <>
            <Box sx={{ marginTop: 1, px: 3 }}>

                {!disableWelcome?.value &&
                    <Grid container spacing={2} sx={{ marginBottom: 3, borderRadius: 2 }}>
                        <Grid item xs={12}>
                            <Welcome
                                onClose={() => disableWelcome.onTrue()}
                                title={t('dashboard.competitorsMonitoring.title')}
                                description={t('dashboard.competitorsMonitoring.description')}
                                img={lgUp ? <MotivationIllustration /> : <></>}
                            />
                        </Grid>
                    </Grid>
                }

                <div>
                    {/* KPIS */}
                    <Grid container spacing={2} sx={{ mb: 2 }}>
                        {stats.map((stat, index) => (
                            <Grid item key={index} xs={6} md={4} lg={workspace?.template == 'E-commerce' ? 2 : 3}>
                                {isFetchingDataPresence || (!dataPresence && !dataPresenceError) ? (
                                    <Skeleton
                                        width={'100%'}
                                        height={'110px'}
                                        animation="wave"
                                    >
                                        <>
                                            <Iconify py="auto" icon="eva:checkmark-circle-2-fill" color="success.main" />
                                            <Typography color="success.main">Active</Typography>
                                        </>
                                    </Skeleton>
                                ) : (
                                    <BankingWidgetSummaryRatio
                                        spanColor={stat.spanColor}
                                        title={stat?.title}
                                        compareData={stat?.compareData}
                                        textColor={stat?.textColor}
                                        icon={stat?.icon}
                                        color={stat.color}
                                        percent={0}
                                        total={stat?.value}
                                        chart={{
                                            series: stat.series,
                                        }}
                                    />
                                )}
                            </Grid>
                        ))}
                    </Grid>
                </div>

                <Grid container spacing={2}>
                    {/* Competitors Overview */}
                    <Grid item xs={12} md={6} lg={6}>
                        <Paper
                            sx={{
                                backgroundColor: theme.palette.mode === "dark" ? '#212b36' : 'white',
                                boxShadow: '0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
                                borderRadius: 2,
                                marginTop: 1.3,
                                marginBottom: 1,
                                width: '100%',
                                height: 550
                            }}
                        >
                            {isFetchingDataPres || (!dataPres && !dataPresenceErr) || !dataPres
                                ? <Skeleton width={'100%'} height={'100%'} style={{ borderRadius: 10 }} variant="rectangular" animation={"wave"}>
                                    <>
                                        <Iconify py={"auto"} icon={"eva:checkmark-circle-2-fill"} color={"success.main"} />
                                        <Typography color={"success.main"}>Active</Typography>
                                    </>
                                </Skeleton>
                                : <div>
                                    <div style={{ paddingTop: 18, paddingLeft: 25, paddingRight: 25, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <Box>
                                            <Typography variant="overline" color="textSecondary" sx={{ mb: 1 }}>{t('dashboard.competitors')}</Typography>

                                            <Typography variant="h6" sx={{ mb: 5 }}>
                                                {t('dashboard.competitorsOverview')}
                                            </Typography>
                                        </Box>

                                        <Box>
                                            <TextField
                                                sx={{ width: 180, height: 50, position: 'relative', top: 5 }}
                                                size="small"
                                                variant="outlined"
                                                select
                                                fullWidth
                                                label={t('dashboard.global.display')}
                                                value={selectedDisplay}
                                                onChange={(event) => setSelectedDisplay(event.target.value)}
                                            >
                                                {displayOptions.map((option, index) => (
                                                    <MenuItem
                                                        key={index}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Box>


                                    </div>

                                    <Box sx={{ height: 370 }}>
                                        <DataGridCustomPresence pageSize={5} data={competitorsOverviewDatas} />
                                    </Box>

                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            width: '100%',
                                            position: 'relative',
                                        }}
                                    >
                                        <Link
                                            component="button"
                                            onClick={() => router.push(`/dash/competitor-focus?workspace=${workspace?.id}`)}
                                            sx={{
                                                fontSize: 14,
                                                fontWeight: 900,
                                                px: 3,
                                                textDecoration: 'none',
                                                color: 'inherit',
                                                '&:hover': {
                                                    textDecoration: 'underline',
                                                    cursor: 'pointer',
                                                },
                                            }}
                                        >
                                            <Typography fontSize={14} fontWeight={900}>
                                                {t('dashboard.competitorsMonitoring.viewAll')} {'>'}
                                            </Typography>
                                        </Link>
                                    </Box>
                                </div>
                            }
                        </Paper>
                    </Grid>
                    {/* COMPETITORS OVER TIME */}
                    <Grid item xs={12} md={6} lg={6}>
                        <Paper
                            sx={{
                                backgroundColor: theme.palette.mode === "dark" ? '#212b36' : 'white',
                                boxShadow: '0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
                                borderRadius: 2,
                                marginTop: 1.3,
                                marginBottom: 1,
                                width: '100%',
                                height: 550
                            }}
                        >
                            {isFetchingDataPres || (!dataPres && !dataPresenceErr) || !dataPres
                                ? <Skeleton width={'100%'} height={'100%'} style={{ borderRadius: 10 }} variant="rectangular" animation={"wave"}>
                                    <>
                                        <Iconify py={"auto"} icon={"eva:checkmark-circle-2-fill"} color={"success.main"} />
                                        <Typography color={"success.main"}>Active</Typography>
                                    </>
                                </Skeleton>
                                : <div>
                                    <div style={{ paddingTop: 18, paddingLeft: 25, paddingRight: 25 }}>
                                        <Typography variant="overline" color="textSecondary" sx={{ mb: 1 }}>{t('dashboard.competitors')}</Typography>

                                        <Typography variant="h6" sx={{ mb: 5 }}>
                                            {t('dashboard.competitorsMonitoring.competitorsOverTime')}
                                        </Typography>

                                    </div>

                                    <Box sx={{ paddingLeft: 2, paddingRight: 2 }}>
                                        <ChartColumn
                                            series={seriesCompetitorsOverTime}
                                            labels={customLabels}
                                        />
                                    </Box>
                                </div>
                            }
                        </Paper>
                    </Grid>
                </Grid>
                {
                    /* OCCUPATION RATE */
                    workspace?.template == 'E-commerce' && (filterStore?.campaignType.includes('Shopping') || filterStore?.campaignType.length == 0) && (
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6} lg={6}>
                                <Paper
                                    sx={{
                                        backgroundColor: theme.palette.mode === "dark" ? '#212b36' : 'white',
                                        boxShadow: '0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
                                        borderRadius: 2,
                                        marginTop: 1.3,
                                        marginBottom: 1,
                                        width: '100%',
                                        height: 550
                                    }}
                                >
                                    {isFetchingDataPresence || (!dataPresence && !dataPresenceError) || !presenceData
                                        ? <Skeleton width={'100%'} height={'100%'} style={{ borderRadius: 10 }} variant="rectangular" animation={"wave"}>
                                            <>
                                                <Iconify py={"auto"} icon={"eva:checkmark-circle-2-fill"} color={"success.main"} />
                                                <Typography color={"success.main"}>Active</Typography>
                                            </>
                                        </Skeleton>
                                        : <div>
                                            <div style={{ paddingTop: 18, paddingLeft: 25, paddingRight: 25 }}>
                                                <Typography variant="overline" color="textSecondary" sx={{ mb: 1 }}>{t('dashboard.competitors')}</Typography>

                                                <Typography variant="h6" sx={{ mb: 5 }}>
                                                    {t('dashboard.competitorsMonitoring.occupationRateShoppingOnTopKeywords')}
                                                </Typography>

                                            </div>

                                            <Box sx={{ height: 432 }}>
                                                <DataGridCustomOccupationShoppingKeywords data={dataOccupationRateShoppingTopKeywords} />
                                            </Box>
                                        </div>
                                    }
                                </Paper>
                            </Grid>

                            {/* OCCUPATION RATE OVER TIME */}
                            <Grid item xs={12} md={6} lg={6}>
                                <Paper
                                    sx={{
                                        backgroundColor: theme.palette.mode === "dark" ? '#212b36' : 'white',
                                        boxShadow: '0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
                                        borderRadius: 2,
                                        marginTop: 1.3,
                                        marginBottom: 1,
                                        width: '100%',
                                        height: 550
                                    }}
                                >
                                    {isFetchingDataPresence || (!dataPresence && !dataPresenceError) || !presenceData
                                        ? <Skeleton width={'100%'} height={'100%'} style={{ borderRadius: 10 }} variant="rectangular" animation={"wave"}>
                                            <>
                                                <Iconify py={"auto"} icon={"eva:checkmark-circle-2-fill"} color={"success.main"} />
                                                <Typography color={"success.main"}>Active</Typography>
                                            </>
                                        </Skeleton>
                                        : <div>
                                            <div style={{ paddingTop: 18, paddingLeft: 25, paddingRight: 25 }}>
                                                <Typography variant="overline" color="textSecondary" sx={{ mb: 1 }}>{t('dashboard.competitors')}</Typography>

                                                <Typography variant="h6" sx={{ mb: 5 }}>
                                                    {t('dashboard.competitorsMonitoring.occupationRateShoppingOverTime')}
                                                </Typography>

                                            </div>

                                            <Box sx={{ height: 432 }}>

                                                <ChartColumnOccupation
                                                    series={[
                                                        { name: t('dashboard.competitorsMonitoring.occupationRateShoppingOverTime'), data: presenceData?.occupation_ratio_shopping_over_time?.map((item: any) => item?.occupation_rate * 100) },
                                                    ]}
                                                    labels={occupationLabels}
                                                />
                                            </Box>
                                        </div>
                                    }
                                </Paper>
                            </Grid>
                        </Grid>
                    )
                }
                {
                    (workspace?.template == 'E-commerce' && (filterStore?.campaignType.includes('Search') || filterStore?.campaignType.length === 0) || workspace?.template == 'Lead') && (
                        <>
                            <Grid container spacing={2} >
                                {/* Solo Bidder Rate On Top Keywords */}
                                <Grid item xs={12} md={6} lg={6}>
                                    <Paper
                                        sx={{
                                            backgroundColor: theme.palette.mode === "dark" ? '#212b36' : 'white',
                                            boxShadow: '0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
                                            borderRadius: 2,
                                            marginTop: 1.3,
                                            marginBottom: 1,
                                            width: '100%',
                                            height: 550
                                        }}
                                    >
                                        {isFetchingDataPresence || (!dataPresence && !dataPresenceError) || !presenceData
                                            ? <Skeleton width={'100%'} height={'100%'} style={{ borderRadius: 10 }} variant="rectangular" animation={"wave"}>
                                                <>
                                                    <Iconify py={"auto"} icon={"eva:checkmark-circle-2-fill"} color={"success.main"} />
                                                    <Typography color={"success.main"}>Active</Typography>
                                                </>
                                            </Skeleton>
                                            : <div>
                                                <div style={{ paddingTop: 18, paddingLeft: 25, paddingRight: 25 }}>
                                                    <Typography variant="overline" color="textSecondary" sx={{ mb: 1 }}>{t('dashboard.competitors')}</Typography>

                                                    <Typography variant="h6" sx={{ mb: 5 }}>
                                                        {workspace?.template === 'E-commerce' ? t('dashboard.competitorsMonitoring.soloBidderRateSearchOnTopKeywords') : t('dashboard.competitorsMonitoring.soloBidderRateOnTopKeywords')}
                                                    </Typography>

                                                </div>

                                                <Box sx={{ height: 432 }}>
                                                    <DataGridCustomPresenceKeywords data={dataPresenceSearchTerm} template={workspace?.template} />
                                                </Box>
                                            </div>
                                        }
                                    </Paper>
                                </Grid>





                                {/* Solo Bidder Rate Over Time */}
                                <Grid item xs={12} md={6} lg={6}>
                                    <Paper
                                        sx={{
                                            backgroundColor: theme.palette.mode === "dark" ? '#212b36' : 'white',
                                            boxShadow: '0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
                                            borderRadius: 2,
                                            marginTop: 1.3,
                                            marginBottom: 1,
                                            width: '100%',
                                            height: 550
                                        }}
                                    >
                                        {isFetchingDataPresence || (!dataPresence && !dataPresenceError) || !presenceData
                                            ? <Skeleton width={'100%'} height={'100%'} style={{ borderRadius: 10 }} variant="rectangular" animation={"wave"}>
                                                <>
                                                    <Iconify py={"auto"} icon={"eva:checkmark-circle-2-fill"} color={"success.main"} />
                                                    <Typography color={"success.main"}>Active</Typography>
                                                </>
                                            </Skeleton>
                                            : <div>
                                                <div style={{ paddingTop: 18, paddingLeft: 25, paddingRight: 25 }}>
                                                    <Typography variant="overline" color="textSecondary" sx={{ mb: 1 }}>{t('dashboard.competitors')}</Typography>

                                                    <Typography variant="h6" sx={{ mb: 5 }}>
                                                        {workspace?.template == 'E-commerce' ? t('dashboard.competitorsMonitoring.soloBidderRateSearchOverTime') : t('dashboard.competitorsMonitoring.soloBidderRateOverTime')}
                                                    </Typography>

                                                </div>

                                                <Box sx={{ paddingLeft: 2, paddingRight: 2 }}>

                                                    <ChartColumn
                                                        series={[
                                                            { name: workspace?.template == 'E-commerce' ? t('dashboard.competitorsMonitoring.soloBidderRateSearch') : t('dashboard.competitorsMonitoring.soloBidderRate'), data: presenceData?.soloBidderRateOverTime?.map((item: any) => item?.ratio_present_bidder * 100) },
                                                        ]}
                                                        labels={soloBiderLabels}
                                                    />

                                                </Box>
                                            </div>
                                        }
                                    </Paper>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                {/* Ghost Rate Top Keywords */}
                                <Grid item xs={12} md={6} lg={6}>
                                    <Paper
                                        sx={{
                                            backgroundColor: theme.palette.mode === "dark" ? '#212b36' : 'white',
                                            boxShadow: '0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
                                            borderRadius: 2,
                                            marginTop: 1.3,
                                            marginBottom: 1,
                                            width: '100%',
                                            height: 550
                                        }}
                                    >
                                        {isFetchingDataPresence || (!dataPresence && !dataPresenceError) || !presenceData
                                            ? <Skeleton width={'100%'} height={'100%'} style={{ borderRadius: 10 }} variant="rectangular" animation={"wave"}>
                                                <>
                                                    <Iconify py={"auto"} icon={"eva:checkmark-circle-2-fill"} color={"success.main"} />
                                                    <Typography color={"success.main"}>Active</Typography>
                                                </>
                                            </Skeleton>
                                            : <div>
                                                <div style={{ paddingTop: 18, paddingLeft: 25, paddingRight: 25 }}>
                                                    <Typography variant="overline" color="textSecondary" sx={{ mb: 1 }}>  {t('dashboard.competitors')}</Typography>

                                                    <Typography variant="h6" sx={{ mb: 5 }}>
                                                        {workspace?.template == 'E-commerce' ? t('dashboard.competitorsMonitoring.ghostRateSearchOnTopKeywords') : t('dashboard.competitorsMonitoring.ghostRateOnTopKeywords')}
                                                    </Typography>

                                                </div>

                                                <Box sx={{ height: 432 }}>
                                                    <DataGridCustomPresenceGhostRate data={dataPresenceGhostRate} template={workspace?.template} />
                                                </Box>
                                            </div>
                                        }
                                    </Paper>
                                </Grid>
                                {/* Ghost Rate Over Time */}
                                <Grid item xs={12} md={6} lg={6}>
                                    <Paper
                                        sx={{
                                            backgroundColor: theme.palette.mode === "dark" ? '#212b36' : 'white',
                                            boxShadow: '0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
                                            borderRadius: 2,
                                            marginTop: 1.3,
                                            marginBottom: 1,
                                            width: '100%',
                                            height: 550
                                        }}
                                    >
                                        {isFetchingDataPresence || (!dataPresence && !dataPresenceError) || !presenceData
                                            ? <Skeleton width={'100%'} height={'100%'} style={{ borderRadius: 10 }} variant="rectangular" animation={"wave"}>
                                                <>
                                                    <Iconify py={"auto"} icon={"eva:checkmark-circle-2-fill"} color={"success.main"} />
                                                    <Typography color={"success.main"}>Active</Typography>
                                                </>
                                            </Skeleton>
                                            : <div>
                                                <div style={{ paddingTop: 18, paddingLeft: 25, paddingRight: 25 }}>
                                                    <Typography variant="overline" color="textSecondary" sx={{ mb: 1 }}>  {t('dashboard.competitors')}</Typography>

                                                    <Typography variant="h6" sx={{ mb: 5 }}>
                                                        {workspace?.template == 'E-commerce' ? t('dashboard.competitorsMonitoring.ghostRateSearchOverTime') : t('dashboard.competitorsMonitoring.ghostRateOverTime')}
                                                    </Typography>

                                                </div>

                                                <Box sx={{ paddingLeft: 2, paddingRight: 2 }}>
                                                    <ChartColumnGhost
                                                        series={[
                                                            { name: workspace?.template == 'E-commerce' ? t('dashboard.competitorsMonitoring.ghostRateSearch') : t('dashboard.competitorsMonitoring.ghostRate'), data: presenceData?.ghostRateOverTime?.map((item: any) => item?.ghost_rate * 100) },
                                                        ]}
                                                        labels={ghostLabels}
                                                    />
                                                </Box>
                                            </div>
                                        }
                                    </Paper>
                                </Grid>
                            </Grid>
                        </>
                    )
                }




            </Box>
        </>
    )
}
