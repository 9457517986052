import { FetchArgs, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BaseQueryApi } from "@reduxjs/toolkit/query";
import { RootState } from "../store";
import csrf from "./csrf/api";
import { useNavigate } from "react-router";

const fetchBaseQueryCSRF = (opts: any) => async (args: FetchArgs, api: BaseQueryApi, extraOptions: any) => {
    let state = api.getState() as RootState;
    let csrfState = csrf.endpoints.getCSRF.select()(state);
  
    // Toujours tenter de rafraîchir le token CSRF si manquant
    if (!csrfState || !csrfState.data) {
        await api.dispatch(csrf.endpoints.getCSRF.initiate());
        state = api.getState() as RootState;
        csrfState = csrf.endpoints.getCSRF.select()(state);

        if (!csrfState || !csrfState.data) {
            throw new Error('Le jeton CSRF est toujours manquant après une tentative de récupération.');
        }
    }

    // Ajouter le token CSRF dans l'en-tête
    const modifiedArgs = {
        ...args,
        headers: {
            ...args.headers,
            'X-CSRFToken': csrfState.data,
        },
    };

    opts.credentials = "include"; // Assurez-vous que les cookies sont inclus

    const result: any = await fetchBaseQuery(opts)(modifiedArgs, api, extraOptions);

    // Si le token est expiré après l'appel, avertir ou forcer la récupération d'un nouveau token
    if (result.error && result.error.status === 400 && result.error.data?.details === "The CSRF token has expired.") {
        window.location.reload();
    }

    return result;
};

export default fetchBaseQueryCSRF;
