import { Route, Routes, useLocation } from 'react-router';
import AccountGeneral from 'src/pages/account';
import { useEffect } from 'react';
import CompetitorsMonitoring from 'src/pages/dashboards/competitorsMonitoring';
import CompetitorFocus from 'src/pages/dashboards/competitorFocus';
import GlobalTextAds from 'src/pages/dashboards/globalTextAds';
import GlobalPlaAds from 'src/pages/dashboards/globalPlaAds';
import BusinessOverview from 'src/pages/dashboards/businessOverview';
import BrandOverview from 'src/pages/dashboards/brandOverview';
import TrafficAnalysis from 'src/pages/dashboards/trafficAnalysis';
import TrafficDetails from 'src/pages/dashboards/trafficDetails';
import AdminUsers from 'src/pages/admin/users';
import Workspace from 'src/pages/workspaces';
import Join from 'src/pages/workspaces/join';
import Users from 'src/pages/workspaces/users';
import WorkspaceGuard from 'src/providers/workspace';
import { useCurrentUserQuery } from 'src/context/api/auth';
import Competitor from 'src/sections/overview/dashboards/views/competitor';
import AdminScrapping from 'src/sections/overview/dashboards/views/admin/scraping';
import Error404 from 'src/pages/error/404';
import KeywordBased from 'src/sections/overview/dashboards/views/keywordBased';
import CampaignBased from 'src/sections/overview/dashboards/views/campaignBased';
import AdGroupBased from 'src/sections/overview/dashboards/views/adGroupBased';
import CampaignExplorer from 'src/sections/overview/dashboards/views/campaign-explorer';
import { useDispatch, useSelector } from 'react-redux';
import { fDate } from 'src/utils/format-time';
import { useCurrentWorkspace } from 'src/context/reducers/app-settings';
import { useGetAdsPlaGlobalQuery, useGetAdsTxtGlobalQuery, useGetBrandsQuery } from 'src/context/api/competitorsMonitoring/api';
import { setNotificationBrandInNonBrandCampaigns, setNotificationBrandInfringement, setNotificationBrandInfringementNotification, setNotificationBrandInfringementNotificationTXT, setNotificationBrandInfringementTXT } from 'src/context/reducers/filter-settings/filterSlice';
import DataNotAvailable from 'src/pages/dashboards/dataNotAvailable';
import { useGetBrandInNonBrandCampaignsQuery } from 'src/context/api/campaignExplorer/api';
import SerpHistory from 'src/pages/dashboards/serpHistory';

export default function Router() {
  const user: any = useCurrentUserQuery();
  const role = user?.data?.role;
  const filterStore = useSelector((state: any) => state.filters);
  const workspace: any = useCurrentWorkspace();
  const dispatch = useDispatch();
  const location = useLocation()

  const { data: brands, error: brandsError, isLoading: isLoadingBrands, isFetching: isFetchingBrands } = useGetBrandsQuery({
    bqTable: workspace?.bqTable,
  });

  const { data: brandInNonBrandCampaigns, error: brandInNonBrandCampaignsError, isLoading: isLoadingBrandInNonBrandCampaigns, isFetching: isFetchingBrandInNonBrandCampaigns } = useGetBrandInNonBrandCampaignsQuery({
    client: workspace?.bqTable,
    brands: brands
  });


  useEffect(() => {
    if (brandInNonBrandCampaigns && brandInNonBrandCampaigns?.length > 0) {
      dispatch(setNotificationBrandInNonBrandCampaigns(true))
    }

  }, [brandInNonBrandCampaigns])

  const {
    data: adsNotificationBrandInfringement,
    error: adsNotificationBrandInfringementError,
    isFetching: isFetchingAdsNotificationBrandInfringement,
    isLoading: isLoadingAdsNotificationBrandInfringement
  }: any = useGetAdsPlaGlobalQuery(
    {
      startDate: filterStore.start ? fDate(filterStore.start, "yyyy-MM-dd") : null,
      endDate: filterStore.end ? fDate(filterStore.end, "yyyy-MM-dd") : null,
      device: filterStore.devices.map((device: string) => device.toLowerCase()),
      countries: filterStore.countries,
      domain: "all",
      workspaceTable: workspace?.bqTable,
      keywords: filterStore?.keywords,
      otherDomains: workspace?.friendlyDomains,
      advertizer: filterStore?.advertizersPLA,
      impressionShare: filterStore?.impressionSharePLA,
      domains: filterStore?.domainsPLA,
      ratingRange: filterStore?.ratingRangePLA,
      priceRange: filterStore?.pricingRangePLA || [0, 50000],
      position: filterStore?.averagePositionPLA,
      brands: brands,
      platform: filterStore?.platforms
    },
    {
      skip: workspace?.showPlaAlert !== true, // Skipper si la condition n'est pas remplie
    }
  );

  useEffect(() => {
    if (adsNotificationBrandInfringement?.ads?.length !== 0) {
      dispatch(setNotificationBrandInfringement(true))
      dispatch(setNotificationBrandInfringementNotification(adsNotificationBrandInfringement))
    }
  }, [adsNotificationBrandInfringement])


  const {
    data: adsNotificationBrandInfringementTXT,
    error: adsNotificationBrandInfringementTXTError,
    isFetching: isFetchingAdsNotificationBrandInfringementTXT,
    isLoading: isLoadingAdsNotificationBrandInfringementTXT
  }: any = useGetAdsTxtGlobalQuery(
    {
      startDate: filterStore.start ? fDate(filterStore.start, "yyyy-MM-dd") : null,
      endDate: filterStore.end ? fDate(filterStore.end, "yyyy-MM-dd") : null,
      device: filterStore.devices.map((device: string) => device.toLowerCase()),
      countries: filterStore.countries,
      domain: "all",
      workspaceTable: workspace?.bqTable,
      keywords: filterStore?.keywords,
      otherDomains: workspace?.friendlyDomains,
      impressionShare: filterStore?.impressionShareTXT,
      domains: filterStore?.domainsTXT,
      position: filterStore?.averagePositionTXT,
      brands: brands,
      platform: filterStore?.platforms
    },
    {
      skip: workspace?.showTxtAlert !== true, // Skipper si la condition n'est pas remplie
    }
  );

  useEffect(() => {
    if (adsNotificationBrandInfringementTXT?.ads?.length !== 0) {
      dispatch(setNotificationBrandInfringementTXT(true))
      dispatch(setNotificationBrandInfringementNotificationTXT(adsNotificationBrandInfringementTXT))

    }
  }, [adsNotificationBrandInfringementTXT])

  return (
    <Routes>
      {/*  */}
      <Route path="/" element={<Workspace />} />
      <Route path="/dash/traffic-analysis" element={<WorkspaceGuard><TrafficAnalysis /></WorkspaceGuard>} />
      <Route path="/dash/traffic-details" element={<WorkspaceGuard><TrafficDetails /></WorkspaceGuard>} />
      <Route path="/dash/traffic-details/countries" element={<WorkspaceGuard><TrafficDetails /></WorkspaceGuard>} />
      {/*  */}
      <Route path="/dash/competitors-monitoring" element={<WorkspaceGuard><CompetitorsMonitoring /></WorkspaceGuard>} />
      <Route path="/dash/competitor-focus" element={<WorkspaceGuard><CompetitorFocus /></WorkspaceGuard>} />
      <Route path="/dash/brand-wayback" element={<WorkspaceGuard><SerpHistory /></WorkspaceGuard>} />
      <Route path="/dash/competitor/:domain" element={<WorkspaceGuard><Competitor /></WorkspaceGuard>} />
      {/*  */}
      <Route path="/dash/business-overview" element={<WorkspaceGuard><BusinessOverview /></WorkspaceGuard>} />
      <Route path="/dash/brand-overview" element={<WorkspaceGuard><BrandOverview /></WorkspaceGuard>} />
      <Route path="/dash/text-ads" element={<WorkspaceGuard><GlobalTextAds /></WorkspaceGuard>} />
      <Route path="/dash/product-listing-ads" element={<WorkspaceGuard><GlobalPlaAds /></WorkspaceGuard>} />
      {/*  */}

      <Route path="/dash/campaign-explorer" element={<WorkspaceGuard><CampaignExplorer /></WorkspaceGuard>} />

      {(role === "admin" || role === "brand specialist" || role === "consultant") && (
        <>
          {/*  */}
          <Route path="/admin/users" element={<WorkspaceGuard><AdminUsers /></WorkspaceGuard>} />
          <Route path="/admin/users/add" element={<WorkspaceGuard><AdminUsers /></WorkspaceGuard>} />
        </>
      )}
      {(role === "admin" || role === "brand specialist") && (
        <>
          {/*  */}
          <Route path="/admin/features/live-monitoring" element={<WorkspaceGuard><AdminScrapping /></WorkspaceGuard>} />
          <Route path="/admin/features/live-monitoring/add" element={<WorkspaceGuard><AdminScrapping /></WorkspaceGuard>} />
          {/*  */}
          <Route path="/admin/features/live-bidding/search/active-mode" element={<WorkspaceGuard><KeywordBased /></WorkspaceGuard>} />
          <Route path="/admin/features/live-bidding/search/ko-bidding/max-cpc" element={<WorkspaceGuard><KeywordBased /></WorkspaceGuard>} />
          <Route path="/admin/features/live-bidding/search/ko-bidding/troas" element={<WorkspaceGuard><KeywordBased /></WorkspaceGuard>} />
          <Route path="/admin/features/live-bidding/search/ko-bidding/tcpa" element={<WorkspaceGuard><KeywordBased /></WorkspaceGuard>} />
          <Route path="/admin/features/live-bidding/search/ko-bidding/target-impression-share" element={<WorkspaceGuard><KeywordBased /></WorkspaceGuard>} />
          <Route path="/admin/features/live-bidding/search/ko-bidding/auto" element={<WorkspaceGuard><KeywordBased /></WorkspaceGuard>} />

          <Route path="/admin/features/live-bidding/campaign-based" element={<WorkspaceGuard><CampaignBased /></WorkspaceGuard>} />
          <Route path="/admin/features/live-bidding/ad-group-based" element={<WorkspaceGuard><AdGroupBased /></WorkspaceGuard>} />
          {/*  */}
        </>
      )}

      <Route path="/account" element={<AccountGeneral />} />
      <Route path='/data-not-available' element={<DataNotAvailable />} />
      <Route path='/workspaces' element={<Workspace />} />
      <Route path='/workspaces/add' element={<Workspace />} />
      {/*  */}
      <Route path='/workspaces/:id/invitations/:token' element={<Join />} />
      <Route path='/workspaces/:id/invitations/:token' element={<Join />} />
      <Route path='/workspaces/:id' element={<Users />} />
      {/*  */}

      <Route path="*" element={<Error404 />} />
    </Routes>
  );
}
