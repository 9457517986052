import { Box, Alert, Typography } from '@mui/material'
import { ComponentBlock } from 'src/sections/mui/component-block'
import { ComponentBlockCustom } from 'src/sections/mui/component-block-custom'

type StepThreeContentShoppingProps = {
  formDataShopping: any
  shoppingDuplicate: any
  shoppingMaxCPCDuplicate: any
  shoppingTargetROASDuplicate: any
}

export function StepThreeContentShopping({
  formDataShopping,
  shoppingDuplicate,
  shoppingMaxCPCDuplicate,
  shoppingTargetROASDuplicate
}: StepThreeContentShoppingProps) {
  return (
    <Box sx={{ mt: 4 }}>
      {shoppingDuplicate && (
        <Alert sx={{ my: 3, backgroundColor: '#322f33', color: '#e0ac8a' }} severity="error">
          <Typography sx={{ fontWeight: 900 }}>{shoppingDuplicate.title}</Typography>
          <Typography fontSize={14} sx={{ mt: 0.4 }}>
            {shoppingDuplicate.description}
          </Typography>
        </Alert>
      )}
      <ComponentBlock sx={{ marginTop: 2, mb: 1 }} title="Review Your Shopping Strategy">
        <ComponentBlockCustom sx={{ marginTop: 2 }} title="Campaign(s)">
          <Typography variant="body1">{formDataShopping.campaign_name}</Typography>
        </ComponentBlockCustom>
        <ComponentBlockCustom sx={{ marginTop: 2 }} title="Account">
          <Typography variant="body1">{formDataShopping.account}</Typography>
        </ComponentBlockCustom>
        <ComponentBlockCustom sx={{ marginTop: 2 }} title="Ad Group">
          <Typography variant="body1">{formDataShopping.ad_group_name}</Typography>
        </ComponentBlockCustom>
        <ComponentBlockCustom sx={{ marginTop: 2 }} title="Target ROAS">
          {shoppingTargetROASDuplicate ? (
            <Box>
              <Typography
                variant="body1"
                sx={{
                  textDecoration: 'line-through',
                  backgroundColor: 'rgba(255, 0, 0, 0.2)',
                  padding: '4px',
                  borderRadius: '4px',
                  display: 'inline-block',
                  mr: 1
                }}
              >
                {formDataShopping.target_roas}
              </Typography>
              <Typography variant="body1" sx={{ display: 'inline' }}>
                {shoppingTargetROASDuplicate.target_roas * 100}
              </Typography>
              <Alert sx={{ mt: 1, backgroundColor: '#322f33', color: '#e0ac8a' }} severity="error">
                <Typography sx={{ fontWeight: 900 }}>{shoppingTargetROASDuplicate.title}</Typography>
                <Typography fontSize={14} sx={{ mt: 0.4 }}>
                  {shoppingTargetROASDuplicate.description}
                </Typography>
              </Alert>
            </Box>
          ) : (
            <Typography variant="body1">{formDataShopping.target_roas}</Typography>
          )}
        </ComponentBlockCustom>
        {formDataShopping.max_cpc_portfolio_strategy && (
          <ComponentBlockCustom sx={{ marginTop: 2 }} title="Advanced Settings (optional)">
            {shoppingMaxCPCDuplicate ? (
              <Box>
                <Typography
                  variant="body1"
                  sx={{
                    textDecoration: 'line-through',
                    backgroundColor: 'rgba(255, 0, 0, 0.2)',
                    padding: '4px',
                    borderRadius: '4px',
                    display: 'inline-block',
                    mr: 1
                  }}
                >
                  {formDataShopping.max_cpc_portfolio_strategy}
                </Typography>
                <Typography variant="body1" sx={{ display: 'inline' }}>
                  {shoppingMaxCPCDuplicate.max_cpc_portfolio_strategie}
                </Typography>
                <Alert sx={{ mt: 1, backgroundColor: '#322f33', color: '#e0ac8a' }} severity="error">
                  <Typography sx={{ fontWeight: 900 }}>{shoppingMaxCPCDuplicate.title}</Typography>
                  <Typography fontSize={14} sx={{ mt: 0.4 }}>
                    {shoppingMaxCPCDuplicate.description}
                  </Typography>
                </Alert>
              </Box>
            ) : (
              <Typography variant="body1">{formDataShopping.max_cpc_portfolio_strategy}</Typography>
            )}
          </ComponentBlockCustom>
        )}
        {!formDataShopping.max_cpc_portfolio_strategy && shoppingMaxCPCDuplicate && (
          <ComponentBlockCustom sx={{ marginTop: 2 }} title="Advanced Settings (optional)">
            <Typography variant="body1" sx={{ display: 'inline' }}>
              {shoppingMaxCPCDuplicate.max_cpc_portfolio_strategie}
            </Typography>
            <Alert sx={{ mt: 1, backgroundColor: '#322f33', color: '#e0ac8a' }} severity="error">
              <Typography sx={{ fontWeight: 900 }}>{shoppingMaxCPCDuplicate.title}</Typography>
              <Typography fontSize={14} sx={{ mt: 0.4 }}>
                {shoppingMaxCPCDuplicate.description}
              </Typography>
            </Alert>
          </ComponentBlockCustom>
        )}
      </ComponentBlock>
    </Box>
  )
}
