import { Button, Paper, Skeleton, Typography } from "@mui/material";
import { Box, useTheme } from "@mui/system";
import { useCallback, useEffect, useState } from "react";
import DataGridCustomLiveBiddingBasedActiveMode from "src/components/data-grid/data-grid-custom-live-bidding-based-active-mode";
import DataGridCustomLiveBiddingBasedKoBidding from "src/components/data-grid/data-grid-custom-live-bidding-based-ko-bidding";
import Iconify from "src/components/iconify";
import {
    useDeleteActiveModeMutation,
    useDeleteKoBiddingMutation,
    useGetActiveModeQuery,
    useGetKoBiddingAutoQuery,
    useGetKoBiddingQuery,
    useGetKoBiddingTargetCPAMaximizeQuery,
    useGetKoBiddingTargetImpressionShareQuery,
    useGetKoBiddingTargetMaximizeQuery,
    useUpdateActiveModeMutation,
    useUpdateKoBiddingMutation,
    useUpdateKoBiddingTCPAMutation
} from "src/context/api/liveBidding/api";
import { useCurrentWorkspace } from "src/context/reducers/app-settings";
import { enqueueSnackbar } from "notistack";
import AddBiddingStrategyModal from "src/layouts/dashboard/AddBiddingStrategyModal/AddBiddingStrategyModal";
import { useLazyTriggerBOQuery } from "src/context/api/admin/api";
import DataGridCustomKoBiddingTargetMaximize from "src/components/data-grid/data-grid-custom-ko-bidding-target-maximize";
import DataGridCustomKoBiddingTargetCPAMaximize from "src/components/data-grid/data-grid-custom-ko-bidding-target-cpa-maximize";
import DataGridCustomKoBiddingTargetImpressionShare from "src/components/data-grid/data-grid-custom-ko-bidding-target-impression-share";
import DataGridCustomKoBiddingAuto from "src/components/data-grid/data-grid-custom-ko-bidding-auto";

import { useLocation } from "react-router";

export default function KeywordBased() {
    const [viewMode, setViewMode] = useState("koBidding");
    const [openModal, setOpenModal] = useState(false);
    const theme = useTheme()
    const location = useLocation();

    const [isEditedActiveMode, setIsEditedActiveMode] = useState(false);
    const [isResetActiveMode, setIsResetActiveMode] = useState(false);
    const [editedRowsActiveMode, setEditedRowsActiveMode] = useState<any>([]);

    const [isEditedKoBidding, setIsEditedKoBidding] = useState(false);
    const [isResetKoBidding, setIsResetKoBidding] = useState(false);
    const [editedRowsKoBidding, setEditedRowsKoBidding] = useState<any>([]);

    const [isEditedKoBiddingCPA, setIsEditedKoBiddingCPA] = useState(false);
    const [isResetKoBiddingCPA, setIsResetKoBiddingCPA] = useState(false);
    const [editedRowsKoBiddingCPA, setEditedRowsKoBiddingCPA] = useState<any>([]);

    const [isEditedKoBiddingTIS, setIsEditedKoBiddingTIS] = useState(false);
    const [isResetKoBiddingTIS, setIsResetKoBiddingTIS] = useState(false);
    const [editedRowsKoBiddingTIS, setEditedRowsKoBiddingTIS] = useState<any>([]);

    const [isEditedKoBiddingAuto, setIsEditedKoBiddingAuto] = useState(false);
    const [isResetKoBiddingAuto, setIsResetKoBiddingAuto] = useState(false);
    const [editedRowsKoBiddingAuto, setEditedRowsKoBiddingAuto] = useState<any>([]);

    const [dataGridView, setDataGridView] = useState("Max CPC")

    const [triggerBO] = useLazyTriggerBOQuery();

    const workspace: any = useCurrentWorkspace();

    const [deleteActiveMode] = useDeleteActiveModeMutation();
    const [updateActiveMode] = useUpdateActiveModeMutation();

    const [deleteKoBidding] = useDeleteKoBiddingMutation();
    const [updateKoBidding] = useUpdateKoBiddingMutation();

    const [updateKoBiddingTCPA] = useUpdateKoBiddingTCPAMutation();

    const { data: dataActiveMode, error: dataActiveModeError, isFetching: isFetchingDataActiveMode, refetch: refetchActiveMode } = useGetActiveModeQuery({
        workspaceId: workspace?.id,
    });

    const { data: dataKoBidding, error: dataKoBiddingError, isFetching: isFetchingDataKoBidding, refetch: refetchKoBidding } = useGetKoBiddingQuery({
        workspaceId: workspace?.id,
    });

    const { data: dataKoBiddingAuto, error: dataKoBiddingAutoError, isFetching: isFetchingDataKoBiddingAuto, refetch: refetchKoBiddingAuto } = useGetKoBiddingAutoQuery({
        workspaceId: workspace?.id,
    });

    const {
        data: dataKoBiddingTargetMaximize,
        error: dataKoBiddingTargetMaximizeError,
        isFetching: isFetchingDataKoBiddingTargetMaximize,
        refetch: refetchKoBiddingTargetMaximize,
    } = useGetKoBiddingTargetMaximizeQuery({ workspaceId: workspace?.id });

    const { data: dataKoBiddingTargetCPAMaximize, error: dataKoBiddingTargetCPAMaximizeError, isFetching: isFetchingDataKoBiddingTargetCPAMaximize, refetch: refetchKoBiddingCPA } = useGetKoBiddingTargetCPAMaximizeQuery({
        workspaceId: workspace?.id,
    });

    const { data: dataKoBiddingTargetImpressionShare, error: dataKoBiddingTargetImpressionShareError, isFetching: isFetchingDataKoBiddingTargetImpressionShare, refetch: refetchKoBiddingTargetImpressionShare } = useGetKoBiddingTargetImpressionShareQuery({
        workspaceId: workspace?.id,
    });

    const dataKeywordBasedActiveMode = dataActiveMode?.map((item: any) => ({
        id: item?.id,
        platform: item?.platform,
        platform_scraping: item?.platform_scraping,
        keyword: item?.keyword,
        keyword_id: item?.keyword_id,
        account: item?.account,
        account_id: item?.account_id,
        country: item?.country,
        country_code: item?.country_code,
        home_language: item?.home_language,
        campaign_name: item?.campaign_name,
        campaign_id: item?.campaign_id,
        frequency: item?.frequency,
        updated_at: item?.updated_at,
        device_gads: item?.device_gads,
        created_at: item?.created_at,
        device: item?.device,
        status: item?.status
    }));

    const dataKeywordBasedKoBidding = dataKoBidding?.map((item: any) => ({
        id: item?.id,
        keyword: item?.keyword,
        platform: item?.platform,
        country: item?.country,
        country_code: item?.country_code,
        status: item?.status,
        mode: item?.mode,
        updated_at: item?.updated_at,
        max_cpc: item?.max_cpc,
        account: item?.account,
        campaign_name: item?.campaign_name,
        keyword_id: item?.keyword_id,
        bidding_strategy_type: item?.bidding_strategy_type,
        keyword_status: item?.keyword_status,
        campaign_id: item?.campaign_id,
        ad_group_name: item?.ad_group_name,
        ad_group_id: item?.ad_group_id,
        created_at: item?.created_at,
        match_type: item?.match_type,
    }));

    const koBiddingTargetMaximizeData: any = dataKoBiddingTargetMaximize?.map((item: any) => ({
        id: item?.id,
        status: item?.status,
        platform: item?.platform,
        target_roas_variation: item?.target_roas_variation,
        campaign_name: item?.campaign_name,
        country: item?.country,
        ad_group_name: item?.ad_group_name,
        updated_at: item?.updated_at,
        target_roas: item?.target_roas * 100,
        mode: item?.mode,
        target_roas_portfolio_strategy: item?.target_roas_portfolio_strategy * 100,
        max_cpc_portfolio_strategy: item?.platform === "Google Ads" ? item?.max_cpc_portfolio_strategy : item?.max_cpc,
        bidding_strategy_type: item?.bidding_strategy_type,
        created_at: item?.created_at,
        account: item?.account
    }))

    const koBiddingTargetCPAMaximizeData: any = dataKoBiddingTargetCPAMaximize?.map((item: any) => ({
        id: item?.id,
        status: item?.status,
        platform: item?.platform,
        ad_group_name: item?.ad_group_name,
        target_cpa_variation: item?.target_cpa_variation,
        target_cpa: item?.target_cpa,
        campaign_name: item?.campaign_name,
        country: item?.country,
        updated_at: item?.updated_at,
        max_cpc: item?.platform === "Google Ads" ? item?.max_cpc_portfolio_strategy : item?.max_cpc,
        bidding_strategy_type: item?.bidding_strategy_type,
        created_at: item?.created_at,
        account: item?.account
    }))

    const koBiddingTargetImpressionShareData: any = dataKoBiddingTargetImpressionShare?.map((item: any) => ({
        id: item?.id,
        status: item?.status,
        platform: item?.platform,
        target_impression_share: item?.target_impression_share,
        target_impression_share_position: item?.target_impression_share_position,
        campaign_name: item?.campaign_name,
        country: item?.country,
        updated_at: item?.updated_at,
        max_cpc: item?.max_cpc,
        bidding_strategy_type: item?.bidding_strategy_type,
        created_at: item?.created_at,
        account: item?.account
    }))

    const handleEditedRowsChangeActiveMode = useCallback((newEditedRows: any) => {
        setEditedRowsActiveMode(newEditedRows);
        setIsEditedActiveMode(newEditedRows.length > 0);
    }, []);

    const validateActiveModeRow = (row: any) => {
        const requiredFields = ['keyword', 'account', 'country', 'campaign_name', 'device', 'device_gads', 'platform'];

        const missingFields = requiredFields.filter(field => {
            // Pour campaign_name, vérifiez s'il s'agit d'un tableau non vide
            if (field === 'campaign_name') {
                return !row[field] || (Array.isArray(row[field]) && row[field].length === 0);
            }
            // Pour les autres champs, vérifiez simplement s'ils sont définis et non vides
            return !row[field];
        });

        return missingFields.length === 0;
    };

    const handleSaveActiveMode = useCallback(async () => {
        try {
            const invalidRows = editedRowsActiveMode.filter(({ changes }: any) => !validateActiveModeRow(changes));

            if (invalidRows.length > 0) {
                enqueueSnackbar("Some fields are empty. Please fill out all required fields.", { variant: 'error' });
                return;
            }

            const updates: any = editedRowsActiveMode.map(({ id, changes }: any) => {
                const { ...otherChanges } = changes;

                const updateData = {
                    id,
                    ...otherChanges,
                };

                return updateData;
            });

            const deletedIds: any = updates
                .filter((item: any) => item.isDeleted === true)
                .map((item: any) => item.id);

            if (deletedIds.length > 0) {
                await Promise.all(
                    deletedIds.map((id: any) => deleteActiveMode({ id: id }).unwrap())
                );
                enqueueSnackbar("Items removed successfully", { variant: 'success' });
            }

            if (updates.length > 0) {
                await updateActiveMode({ updates }).unwrap();
                enqueueSnackbar("Edited successfully", { variant: 'success' });
            }

            triggerBO()
            refetchActiveMode();
            setIsEditedActiveMode(false);
            setEditedRowsActiveMode([]);

        } catch (error) {
            enqueueSnackbar("Failed to edit", { variant: 'error' });
        }
    }, [editedRowsActiveMode, deleteActiveMode, refetchActiveMode]);

    const handleDiscardActiveMode = () => {
        setIsResetActiveMode(true);
        setEditedRowsActiveMode([]);
        setIsEditedActiveMode(false);
        setTimeout(() => setIsResetActiveMode(false), 0);
    };

    const handleEditedRowsChangeKoBidding = ((newEditedRows: any) => {
        setEditedRowsKoBidding(newEditedRows);
        setIsEditedKoBidding(newEditedRows.length > 0);
    });


    const handleEditedRowsChangeKoBiddingCPA = ((newEditedRows: any) => {
        setEditedRowsKoBiddingCPA(newEditedRows);
        setIsEditedKoBiddingCPA(newEditedRows.length > 0);
    });

    const validateRowKoBidding = (row: any) => {
        // Définir les champs requis en fonction de dataGridView
        const requiredFields = dataGridView === "Max CPC"
            ? ['keyword', 'platform', 'country', 'status', 'mode', 'max_cpc', 'account', 'campaign_name', 'ad_group_name', 'match_type']
            : location?.pathname === '/admin/features/live-bidding/search/ko-bidding/tcpa' ? ['platform', 'campaign_name', 'account'] : ['campaign_name', 'account', 'bidding_strategy_type', 'country', 'target_roas'];

        // Vérification que tous les champs requis sont remplis
        return requiredFields.every(field => row[field] !== null && row[field] !== undefined && row[field] !== '');
    };

    const validateRowKoBiddingAuto = (row: any) => {
        // Définir les champs requis en fonction de dataGridView
        const requiredFields = ['keyword', 'platform', 'country', 'status', 'mode', 'account', 'campaign_name', 'ad_group_name', 'match_type']

        // Vérification que tous les champs requis sont remplis
        return requiredFields.every(field => row[field] !== null && row[field] !== undefined && row[field] !== '');
    };

    const handleSaveKoBiddingCPA = useCallback(async () => {
        try {
            const invalidRows = editedRowsKoBiddingCPA.filter(({ changes }: any) => !validateRowKoBidding(changes));

            if (invalidRows.length > 0) {
                enqueueSnackbar("Some fields are empty. Please fill out all required fields.", { variant: 'error' });
                return;
            }

            const updates: any = editedRowsKoBiddingCPA.map(({ id, changes }: any) => {
                const { ...otherChanges } = changes;

                const updateData = {
                    id,
                    ...otherChanges,
                };

                return updateData;
            });

            const deletedIds: any = updates
                .filter((item: any) => item.isDeleted === true) // Vérifie si l'élément doit être supprimé
                .map((item: any) => item.id);

            if (deletedIds.length > 0) {
                await Promise.all(
                    deletedIds.map((id: any) => deleteKoBidding({ id }).unwrap()) // Suppression des éléments
                );
                enqueueSnackbar("Items removed successfully", { variant: 'success' });
            }

            if (updates.length > 0) {
                await updateKoBiddingTCPA({ updates }).unwrap();
                enqueueSnackbar("Edited successfully", { variant: 'success' });
            }

            triggerBO()
            refetchKoBiddingCPA();
            setIsEditedKoBiddingCPA(false);
            setEditedRowsKoBiddingCPA([]);

        } catch (error) {
            enqueueSnackbar("Failed to edit", { variant: 'error' });
        }
    }, [editedRowsKoBiddingCPA, deleteKoBidding, refetchKoBiddingCPA]);

    const handleSaveKoBidding = useCallback(async () => {
        try {
            const invalidRows = editedRowsKoBidding.filter(({ changes }: any) => !validateRowKoBidding(changes));

            if (invalidRows.length > 0) {
                enqueueSnackbar("Some fields are empty. Please fill out all required fields.", { variant: 'error' });
                return;
            }

            const updates: any = editedRowsKoBidding.map(({ id, changes }: any) => {
                const { ...otherChanges } = changes;

                const updateData = {
                    id,
                    ...otherChanges,
                };

                return updateData;
            });

            const deletedIds: any = updates
                .filter((item: any) => item.isDeleted === true) // Vérifie si l'élément doit être supprimé
                .map((item: any) => item.id);

            if (deletedIds.length > 0) {
                await Promise.all(
                    deletedIds.map((id: any) => deleteKoBidding({ id }).unwrap()) // Suppression des éléments
                );
                enqueueSnackbar("Items removed successfully", { variant: 'success' });
            }

            if (updates.length > 0) {
                await updateKoBidding({ updates }).unwrap();
                enqueueSnackbar("Edited successfully", { variant: 'success' });
            }

            triggerBO()
            refetchKoBidding();
            refetchKoBiddingTargetMaximize();
            setIsEditedKoBidding(false);
            setEditedRowsKoBidding([]);

        } catch (error) {
            enqueueSnackbar("Failed to edit", { variant: 'error' });
        }
    }, [editedRowsKoBidding, deleteKoBidding, refetchKoBidding]);

    const handleDiscardKoBidding = () => {
        setIsResetKoBidding(true);
        setEditedRowsKoBidding([]);
        setIsEditedKoBidding(false);
        setTimeout(() => setIsResetKoBidding(false), 0);
    };

    const handleDiscardKoBiddingCPA = () => {
        setIsResetKoBiddingCPA(true);
        setEditedRowsKoBiddingCPA([]);
        setIsEditedKoBiddingCPA(false);
        setTimeout(() => setIsResetKoBiddingCPA(false), 0);
    };

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    useEffect(() => {
        if (location?.pathname === "/admin/features/live-bidding/search/active-mode") {
            setViewMode("active");
        } else if (location?.pathname === "/admin/features/live-bidding/search/ko-bidding/max-cpc") {
            setViewMode("koBidding");
            setDataGridView("Max CPC");
        } else if (location?.pathname === '/admin/features/live-bidding/search/ko-bidding/troas') {
            setViewMode("koBidding");
            setDataGridView("Target ROAS / Maximize Conversion Value")
        } else if (location?.pathname === '/admin/features/live-bidding/search/ko-bidding/tcpa') {
            setViewMode("koBiddingCPA");
            setDataGridView("Target CPA / Maximize Conversion")
        } else if (location?.pathname === '/admin/features/live-bidding/search/ko-bidding/target-impression-share') {
            setViewMode("koBiddingTargetImpressionShare");
            setDataGridView("Target Impression Share")
        } else if (location?.pathname === '/admin/features/live-bidding/search/ko-bidding/auto') {
            setViewMode("koBiddingAuto");
            setDataGridView("KO Bidding Auto")
        }

    }, [location?.pathname])

    // Quand on modifie une ligne, on met à jour l'état
    const handleEditedRowsChangeKoBiddingTIS = (newEditedRows: any) => {
        setEditedRowsKoBiddingTIS(newEditedRows);
        setIsEditedKoBiddingTIS(newEditedRows.length > 0);
    };

    const handleSaveKoBiddingTIS = useCallback(async () => {
        try {
            // Exemple de validation ; adaptez les champs requis selon vos besoins
            const requiredFields = [
                'campaign_name',
                'account',
                'platform',
                'target_impression_share',
                'target_impression_share_position',
                'max_cpc',
                'country'
            ];

            // Filtrer les lignes invalides
            const invalidRows = editedRowsKoBiddingTIS.filter(({ changes }: any) => {
                return !requiredFields.every(
                    (field) =>
                        changes[field] !== null && changes[field] !== undefined && changes[field] !== ''
                );
            });

            if (invalidRows.length > 0) {
                enqueueSnackbar("Some fields are empty. Please fill out all required fields.", { variant: 'error' });
                return;
            }

            // Préparation des updates
            const updates: any = editedRowsKoBiddingTIS.map(({ id, changes }: any) => {
                const { ...otherChanges } = changes;
                return { id, ...otherChanges };
            });

            // Gérer les suppressions
            const deletedIds: any = updates
                .filter((item: any) => item.isDeleted === true)
                .map((item: any) => item.id);

            if (deletedIds.length > 0) {
                // Supposez que vous réutilisez deleteKoBidding pour supprimer, sinon adaptez
                await Promise.all(
                    deletedIds.map((id: any) => deleteKoBidding({ id }).unwrap())
                );
                enqueueSnackbar("Items removed successfully", { variant: 'success' });
            }

            // Gérer les mises à jour
            if (updates.length > 0) {
                // Supposez que vous avez une mutation updateKoBiddingTIS ou réutilisez updateKoBidding
                // selon votre logique :
                await updateKoBidding({ updates }).unwrap();
                enqueueSnackbar("Edited successfully", { variant: 'success' });
            }

            // Réinitialiser et rafraîchir
            triggerBO();
            refetchKoBiddingTargetImpressionShare();
            setIsEditedKoBiddingTIS(false);
            setEditedRowsKoBiddingTIS([]);

        } catch (error) {
            enqueueSnackbar("Failed to edit", { variant: 'error' });
        }
    }, [
        editedRowsKoBiddingTIS,
        deleteKoBidding,
        refetchKoBiddingTargetImpressionShare,
        // + autres dépendances si besoin
    ]);

    const handleDiscardKoBiddingTIS = () => {
        setIsResetKoBiddingTIS(true);
        setEditedRowsKoBiddingTIS([]);
        setIsEditedKoBiddingTIS(false);
        setTimeout(() => setIsResetKoBiddingTIS(false), 0);
    };


    const handleEditedRowsChangeKoBiddingAuto = (newEditedRows: any) => {
        setEditedRowsKoBiddingAuto(newEditedRows);
        setIsEditedKoBiddingAuto(newEditedRows.length > 0);
    };

    const handleSaveKoBiddingAuto = useCallback(async () => {
        try {
            // Vérifier que toutes les lignes ont les champs requis (on peut réutiliser validateRowKoBidding si la validation est identique)
            const invalidRows = editedRowsKoBiddingAuto.filter(({ changes }: any) => !validateRowKoBiddingAuto(changes));
            if (invalidRows.length > 0) {
                enqueueSnackbar("Some fields are empty. Please fill out all required fields.", { variant: 'error' });
                return;
            }
            // Préparation des updates
            const updates: any = editedRowsKoBiddingAuto.map(({ id, changes }: any) => ({ id, ...changes }));
            // Extraction des IDs à supprimer
            const deletedIds: any = updates.filter((item: any) => item.isDeleted === true).map((item: any) => item.id);

            if (deletedIds.length > 0) {
                await Promise.all(
                    deletedIds.map((id: any) => deleteKoBidding({ id }).unwrap())
                );
                enqueueSnackbar("Items removed successfully", { variant: 'success' });
            }

            if (updates.length > 0) {
                await updateKoBidding({ updates }).unwrap();
                enqueueSnackbar("Edited successfully", { variant: 'success' });
            }

            triggerBO();
            refetchKoBiddingAuto();
            setIsEditedKoBiddingAuto(false);
            setEditedRowsKoBiddingAuto([]);

        } catch (error) {
            enqueueSnackbar("Failed to edit", { variant: 'error' });
        }
    }, [editedRowsKoBiddingAuto, deleteKoBidding, refetchKoBiddingAuto]);

    const handleDiscardKoBiddingAuto = () => {
        setIsResetKoBiddingAuto(true);
        setEditedRowsKoBiddingAuto([]);
        setIsEditedKoBiddingAuto(false);
        setTimeout(() => setIsResetKoBiddingAuto(false), 0);
    };

    return (
        <>
            <Box sx={{ px: 2, pb: 2, pt: 1 }}>
                <Paper
                    sx={{
                        backgroundColor: theme.palette.mode === "dark" ? '#212b36' : 'white',
                        boxShadow: '0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
                        height: 'auto',
                        borderRadius: 2,
                        marginBottom: 1
                    }}
                >
                    <Box style={{ display: 'flex', justifyContent: 'space-between', paddingTop: 10, marginBottom: 35 }}>
                        <Box style={{ paddingTop: 18, paddingLeft: 25, paddingRight: 25, marginBottom: '5px' }}>
                            <Typography variant="h6" sx={{ mb: 1 }}>
                                Search Live Bidding Strategies
                            </Typography>
                            <Typography variant="overline" color="textSecondary" sx={{ textTransform: 'none', fontSize: 14 }}>
                                List of live bidding strategies for search campaigns
                            </Typography>
                        </Box>

                        <Box style={{ paddingTop: 18, paddingLeft: 25, paddingRight: 25 }}>
                            {viewMode === "active" &&
                                <Button
                                    variant="contained"
                                    size="small"
                                    onClick={handleOpenModal}
                                    startIcon={<Iconify icon="mingcute:add-line" />}
                                >
                                    Add new bidding strategy
                                </Button>
                            }

                            {viewMode !== "active" &&
                                <Button
                                    variant="contained"
                                    size="small"
                                    onClick={handleOpenModal}
                                    startIcon={<Iconify icon="mingcute:add-line" />}
                                >
                                    Add new bidding strategy
                                </Button>

                            }

                            {(viewMode === "active" && isEditedActiveMode === true) && (
                                <>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        onClick={handleDiscardActiveMode}
                                        startIcon={<Iconify icon="material-symbols:refresh" />}
                                        sx={{ ml: 1, backgroundColor: '#232b35', color: 'white' }}
                                    >
                                        Discard All Changes
                                    </Button>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        onClick={handleSaveActiveMode}
                                        sx={{ ml: 1, backgroundColor: "#4b1db0", color: 'white' }}
                                        startIcon={<Iconify icon="tabler:check" />}
                                    >
                                        Apply All Changes
                                    </Button>
                                </>
                            )}

                            {(viewMode === "koBidding" && isEditedKoBidding === true) && (
                                <>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        onClick={handleDiscardKoBidding}
                                        startIcon={<Iconify icon="material-symbols:refresh" />}
                                        sx={{ ml: 1, backgroundColor: '#232b35', color: 'white' }}
                                    >
                                        Discard All Changes
                                    </Button>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        onClick={handleSaveKoBidding}
                                        sx={{ ml: 1, backgroundColor: "#4b1db0", color: 'white' }}
                                        startIcon={<Iconify icon="tabler:check" />}
                                    >
                                        Apply All Changes
                                    </Button>
                                </>
                            )}

                            {(viewMode === "koBiddingCPA" && isEditedKoBiddingCPA === true) && (
                                <>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        onClick={handleDiscardKoBiddingCPA}
                                        startIcon={<Iconify icon="material-symbols:refresh" />}
                                        sx={{ ml: 1, backgroundColor: '#232b35', color: 'white' }}
                                    >
                                        Discard All Changes
                                    </Button>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        onClick={handleSaveKoBiddingCPA}
                                        sx={{ ml: 1, backgroundColor: "#4b1db0", color: 'white' }}
                                        startIcon={<Iconify icon="tabler:check" />}
                                    >
                                        Apply All Changes
                                    </Button>
                                </>
                            )}

                            {viewMode === "koBiddingTargetImpressionShare" && isEditedKoBiddingTIS === true && (
                                <>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        onClick={handleDiscardKoBiddingTIS}
                                        startIcon={<Iconify icon="material-symbols:refresh" />}
                                        sx={{ ml: 1, backgroundColor: '#232b35', color: 'white' }}
                                    >
                                        Discard All Changes
                                    </Button>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        onClick={handleSaveKoBiddingTIS}
                                        sx={{ ml: 1, backgroundColor: "#4b1db0", color: 'white' }}
                                        startIcon={<Iconify icon="tabler:check" />}
                                    >
                                        Apply All Changes
                                    </Button>
                                </>
                            )}

                            {viewMode === "koBiddingAuto" && isEditedKoBiddingAuto && (
                                <>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        onClick={handleDiscardKoBiddingAuto}
                                        startIcon={<Iconify icon="material-symbols:refresh" />}
                                        sx={{ ml: 1, backgroundColor: '#232b35', color: 'white' }}
                                    >
                                        Discard All Changes
                                    </Button>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        onClick={handleSaveKoBiddingAuto}
                                        sx={{ ml: 1, backgroundColor: "#4b1db0", color: 'white' }}
                                        startIcon={<Iconify icon="tabler:check" />}
                                    >
                                        Apply All Changes
                                    </Button>
                                </>
                            )}


                        </Box>
                    </Box>

                    {viewMode === "active" ? (
                        <>
                            {isFetchingDataActiveMode
                                ? <Skeleton
                                    width={'100%'}
                                    height={'480px'}
                                    sx={{ borderRadius: 0, borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}
                                    variant="rounded"
                                    animation="wave"
                                >
                                    <>
                                        <Iconify py="auto" icon="eva:checkmark-circle-2-fill" color="success.main" />
                                        <Typography color="success.main">Active</Typography>
                                    </>
                                </Skeleton>
                                : dataKeywordBasedActiveMode && <DataGridCustomLiveBiddingBasedActiveMode pageSize={20}
                                    onEdit={(it: any) => setIsEditedActiveMode(it)}
                                    handleSave={handleEditedRowsChangeActiveMode}
                                    data={dataKeywordBasedActiveMode}
                                    onApply={isEditedActiveMode}
                                    onReset={isResetActiveMode}
                                />
                            }
                        </>
                    ) : <>
                        {isFetchingDataKoBidding
                            ? <Skeleton
                                width={'100%'}
                                height={'480px'}
                                sx={{ borderRadius: 0, borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}
                                variant="rounded"
                                animation="wave"
                            >
                                <>
                                    <Iconify py="auto" icon="eva:checkmark-circle-2-fill" color="success.main" />
                                    <Typography color="success.main">Active</Typography>
                                </>
                            </Skeleton>
                            : dataGridView === "Max CPC"
                                ? dataKeywordBasedKoBidding && <DataGridCustomLiveBiddingBasedKoBidding pageSize={20}
                                    onEdit={(it: any) => setIsEditedKoBidding(it)}
                                    handleSave={handleEditedRowsChangeKoBidding}
                                    data={dataKeywordBasedKoBidding}
                                    onApply={isEditedKoBidding}
                                    onReset={isResetKoBidding}
                                />
                                : dataGridView === "Target ROAS / Maximize Conversion Value"
                                    ? koBiddingTargetMaximizeData && <DataGridCustomKoBiddingTargetMaximize
                                        pageSize={20}
                                        onEdit={(it: any) => setIsEditedKoBidding(it)}
                                        handleSave={handleEditedRowsChangeKoBidding}
                                        data={koBiddingTargetMaximizeData}
                                        onApply={isEditedKoBidding}
                                        onReset={isResetKoBidding}
                                    />
                                    : dataGridView === "Target CPA / Maximize Conversion"
                                        ? koBiddingTargetCPAMaximizeData && <DataGridCustomKoBiddingTargetCPAMaximize
                                            pageSize={20}
                                            onEdit={(it: any) => setIsEditedKoBiddingCPA(it)}
                                            handleSave={handleEditedRowsChangeKoBiddingCPA}
                                            data={koBiddingTargetCPAMaximizeData}
                                            onApply={isEditedKoBiddingCPA}
                                            onReset={isResetKoBiddingCPA}
                                        />
                                        : dataGridView === "Target Impression Share" ? dataKoBiddingTargetImpressionShare && <DataGridCustomKoBiddingTargetImpressionShare
                                            pageSize={20}
                                            onEdit={(it: any) => setIsEditedKoBiddingTIS(it)}
                                            handleSave={handleEditedRowsChangeKoBiddingTIS}
                                            data={koBiddingTargetImpressionShareData}
                                            onApply={isEditedKoBiddingTIS}
                                            onReset={isResetKoBiddingTIS}
                                        />
                                            : dataKoBiddingAuto && <DataGridCustomKoBiddingAuto
                                                pageSize={20}
                                                onEdit={(it: any) => setIsEditedKoBiddingAuto(it)}
                                                handleSave={handleEditedRowsChangeKoBiddingAuto}
                                                data={dataKoBiddingAuto}
                                                onApply={isEditedKoBiddingAuto}
                                                onReset={isResetKoBiddingAuto}
                                            />

                        }
                    </>
                    }
                </Paper>
            </Box>

            <AddBiddingStrategyModal open={openModal} onClose={handleCloseModal} />
        </>
    );
}