import { useEffect, useState, useCallback } from 'react'
import {
    Box,
    Button,
    DialogContent,
    DialogActions,
    DialogTitle,
    Dialog,
    Stepper,
    Step,
    StepLabel,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import {
    useCurrentWorkspace
} from 'src/context/reducers/app-settings'
import {
    useAddActiveModeMutation,
    useGetKeywordsKoBiddingQuery,
    useGetKeywordInfoKoBiddingQuery,
    useGetActiveModeQuery,
    useGetCampaignsQuery,
    useGetMatchTypeKoBiddingQuery,
    useGetCountriesQuery,
    useGetKeywordsQuery,
    useGetProfileQuery,
    useGetAdGroupKoBiddingQuery,
    useGetCountriesKoBiddingQuery,
    useAddKoBiddingMutation,
    useGetProfileKoBiddingQuery,
    useGetKoBiddingQuery,
    useGetKeywordInfoActiveModeQuery,
    useGetCampaignsShoppingQuery,
    useGetAdGroupShoppingQuery,
    useGetProfileShoppingQuery,
    useCampaignInfoShoppingQuery,
    useGetCountriesShoppingQuery,
    useAddShoppingMutation,
    useGetShoppingQuery,
    useCheckActiveModeExistsMutation,
    useCheckKoBiddingExistsMutation,
    useCheckShoppingExistsMutation,
    useCheckShoppingCampaignExistsMutation,
    useGetBiddingStrategyTypeQuery,
    useProfileInfoShoppingQuery,
    useGetCampaignsKoBiddingQuery,
    useGetBiddingStrategyTypeKoBiddingQuery,
    useCampaignInfoKoBiddingQuery,
    useProfileInfoKoBiddingQuery,
    useGetAdGroupKoBiddingTargetMaximizeQuery,
    useCampaignInfoKoBiddingTargetMaximizeQuery,
    useProfileInfoKoBiddingTargetMaximizeQuery,
    useGetCountriesKoBiddingTargetMaximizeQuery,
    useCheckKoBiddingCampaignExistsMutation,
    useCheckKoBiddingTcpaExistsMutation,
    useCheckKoBiddingTcpaCampaignExistsMutation,
    useGetMaxCPCInfoKoBiddingQuery,
    useGetAdGroupKoBiddingAutoQuery,
    useGetKeywordsKoBiddingAutoQuery,
    useGetKoBiddingTargetMaximizeQuery,
    useGetKoBiddingTargetCPAMaximizeQuery,
    useGetKoBiddingAutoQuery
} from 'src/context/api/liveBidding/api'
import { useLazyTriggerBOQuery } from 'src/context/api/admin/api'
import { steps, AddBiddingStrategyModalProps } from './types'
import { StepOneContent } from './steps/StepOneContent'
import { StepTwoContentActiveMode } from './steps/StepTwoContentActiveMode'
import { StepTwoContentKoBidding } from './steps/StepTwoContentKoBidding'
import { StepTwoContentShopping } from './steps/StepTwoContentShopping'
import { StepThreeContentActiveMode } from './steps/StepThreeContentActiveMode'
import { StepThreeContentKoBidding } from './steps/StepThreeContentKoBidding'
import { StepThreeContentShopping } from './steps/StepThreeContentShopping'

export default function AddBiddingStrategyModal({ open, onClose }: AddBiddingStrategyModalProps) {
    const { enqueueSnackbar } = useSnackbar()
    const workspace: any = useCurrentWorkspace()
    const [triggerBO] = useLazyTriggerBOQuery()
    const [activeStep, setActiveStep] = useState(0)
    const [selection, setSelection] = useState('Search')
    const [subSelection, setSubSelection] = useState('Ko Bidding')
    const [koBiddingMode, setKOBiddingMode] = useState<any>('Manual')
    const [requestTracker, setRequestTracker] = useState<any>()
    const [requestTrackerShopping, setRequestTrackerShopping] = useState<any>()

    const [searchActiveModeDuplicate, setSearchActiveModeDuplicate] = useState()
    const [searchKoBiddingDuplicate, setSearchKoBiddingDuplicate] = useState()
    const [shoppingDuplicate, setShoppingDuplicate] = useState()
    const [shoppingMaxCPCDuplicate, setShoppingMaxCPCDuplicate] = useState<any>()
    const [shoppingTargetROASDuplicate, setShoppingTargetROASDuplicate] = useState<any>()

    const [keywordError, setKeywordError] = useState(false)
    const [accountIdSa360Error, setAccountIdSa360Error] = useState(false)
    const [keywordKoBiddingError, setKeywordKoBiddingError] = useState(false)
    const [countryError, setCountryError] = useState(false)
    const [platformError, setPlatformError] = useState(false)
    const [platformShoppingError, setPlatformShoppingError] = useState(false)
    const [modeKoBiddingError, setModeKoBiddingError] = useState(false)
    const [launchingDateError, setLaunchingDateError] = useState(false)
    const [campaignError, setCampaignError] = useState(false)
    const [adGroupKoBiddingError, setAdGroupKoBiddingError] = useState(false)
    const [campaignKoBiddingError, setCampaignKoBiddingError] = useState(false)
    const [matchTypeKoBiddingErrorState, setMatchTypeKoBiddingErrorState] = useState(false)
    const [deviceError, setDeviceError] = useState(false)
    const [profileError, setProfileError] = useState(false)
    const [profileActiveModeError, setProfileActiveModeError] = useState(false)
    const [platformActiveModeError, setPlatformActiveModeError] = useState(false)
    const [campaignShoppingError, setCampaignShoppingError] = useState(false)
    const [profileShoppingError, setProfileShoppingError] = useState(false)
    const [adGroupShoppingError, setAdGroupShoppingError] = useState(false)
    const [targetRoasError, setTargetRoasError] = useState(false)
    const [errorTargetCPA, setErrorTargetCPA] = useState(false)

    const [formDataShopping, setFormDataShopping] = useState<any>({
        account: '',
        account_id: '',
        campaign_name: [],
        campaign_id: [],
        country: [],
        country_code: [],
        ad_group_name: '',
        ad_group_id: '',
        target_roas: 1,
        bidding_strategy_type: '',
        domain: '',
        mode: 'KO Bidding Manual',
        targetRoasVariation: false,
        platform: '',
        campaign_type: selection,
        client: '',
        workspaceId: null,
        max_cpc_portfolio_strategy: null,
        name_portfolio_strategy: null,
        target_roas_portfolio_strategy: null,
        status: true
    })

    const [formData, setFormData] = useState<any>({
        keywords: [],
        keyword_id: '',
        country: [],
        account: '',
        frequency: '10 min',
        device_gads: ['desktop'],
        device: ['desktop'],
        campaign_name: [],
        campaign_id: [],
        home_language: '',
        domain: '',
        mode: 'Active Mode',
        account_id: '',
        campaign_type: selection,
        platform: '',
        platform_scraping: null,
        client: '',
        workspaceId: null
    })

    const [formDataKoBidding, setFormDataKoBidding] = useState<any>({
        client: '',
        status: true,
        mode: '',
        platform: '',
        account_id: '',
        campaign_type: selection,
        campaign_name: [],
        target_impression_share: null,
        campaign_id: [],
        bidding_strategy_type: '',
        ad_group_name: '',
        ad_group_id: '',
        targetCpaVariation: false,
        target_cpa: null,
        keyword_bid: '',
        domain: '',
        match_type: '',
        enforce_max_cpc: false,
        target_impression_share_position: null,
        target_roas: null,
        max_cpc_portfolio_strategy: null,
        targetRoasVariation: false,
        target_max_cpc: null,
        max_cpc_daily_reduction: null,
        name_portfolio_strategy: null,
        target_roas_portfolio_strategy: null,
        account_id_sa360: null,
        max_cpc: null,
        country: [],
        country_code: [],
        keyword_status: '',
        keyword_id: '',
        account: '',
        type: null,
        initial_max_cpc: null,
        initial_avg_cpc: null,
        max_cpc_target_reduction: null,
        optimization_phase_duration: null,
        accepted_variation_threshold: null,
        launching_date: null
    })

    const [activeModeSearchExist] = useCheckActiveModeExistsMutation()
    const [koBiddingSearchExist] = useCheckKoBiddingExistsMutation()
    const [koBiddingTcpaSearchExist] = useCheckKoBiddingTcpaExistsMutation()
    const [shoppingExist] = useCheckShoppingExistsMutation()
    const [checkShoppingCampaignExists] = useCheckShoppingCampaignExistsMutation()
    const [checkKoBiddingTcpaCampaignExists] = useCheckKoBiddingTcpaCampaignExistsMutation()
    const [checkKoBiddingCampaignExists] = useCheckKoBiddingCampaignExistsMutation()
    const [addActiveMode] = useAddActiveModeMutation()
    const [addKoBidding] = useAddKoBiddingMutation()
    const [addShopping] = useAddShoppingMutation()

    const { refetch: refetchActiveMode } = useGetActiveModeQuery({
        workspaceId: workspace?.id
    })

    const { refetch: refetchShopping } = useGetShoppingQuery({
        workspaceId: workspace?.id
    })

    const { refetch: refetchKoBidding } = useGetKoBiddingQuery({
        workspaceId: workspace?.id
    })

    const {
        data: dataKoBiddingTargetMaximize,
        error: dataKoBiddingTargetMaximizeError,
        isFetching: isFetchingDataKoBiddingTargetMaximize,
        refetch: refetchKoBiddingTargetMaximize,
    } = useGetKoBiddingTargetMaximizeQuery({ workspaceId: workspace?.id });

    const { data: keywordOptions, isLoading: isLoadingKeywords } = useGetKeywordsQuery({
        client: workspace?.bqTable
    })

    const { data: dataKoBiddingTargetCPAMaximize, error: dataKoBiddingTargetCPAMaximizeError, isFetching: isFetchingDataKoBiddingTargetCPAMaximize, refetch: refetchKoBiddingCPA } = useGetKoBiddingTargetCPAMaximizeQuery({
        workspaceId: workspace?.id,
    });

    const { data: keywordKoBiddingOptions, isLoading: isLoadingKoBiddingKeywords } = useGetKeywordsKoBiddingQuery({
        client: workspace?.bqTable,
        platform: formDataKoBidding.platform,
        campaign: formDataKoBidding.campaign_name,
        account: formDataKoBidding.account
    })

    const { data: keywordKoBiddingAutoOptions, isLoading: isLoadingKoBiddingAutoKeywords } = useGetKeywordsKoBiddingAutoQuery({
        client: workspace?.bqTable,
        platform: formDataKoBidding.platform,
        campaign: formDataKoBidding.campaign_name,
        account: formDataKoBidding.account,
        ad_group: formDataKoBidding.ad_group_name
    })

    const { data: dataKoBiddingAuto, error: dataKoBiddingAutoError, isFetching: isFetchingDataKoBiddingAuto, refetch: refetchKoBiddingAuto } = useGetKoBiddingAutoQuery({
        workspaceId: workspace?.id,
    });

    const { data: matchTypeKoBiddingOptions, error: matchTypeKoBiddingError, isLoading: isLoadingMatchTypeKoBidding } = useGetMatchTypeKoBiddingQuery(
        {
            client: workspace?.bqTable,
            keyword: formDataKoBidding.keyword_bid,
            platform: formDataKoBidding.platform,
            account: formDataKoBidding.account,
            campaign: formDataKoBidding.campaign_name,
            bidding_strategy_type: formDataKoBidding.bidding_strategy_type,
            requestTracker
        } as any,
        {
            skip: formDataKoBidding.keyword_bid === 0
        }
    )

    const { data: profileOptions, isLoading: isLoadingProfile } = useGetProfileQuery(
        {
            client: workspace?.bqTable,
            keyword: formData.keywords
        },
        {
            skip: formData.keywords.length === 0
        }
    )

    const { data: profileKoBiddingOptions, isLoading: isLoadingProfileKoBidding } = useGetProfileKoBiddingQuery(
        {
            client: workspace?.bqTable,
            platform: formDataKoBidding.platform,
            requestTracker
        } as any,
    )

    const { data: campaignOptions, isLoading: isLoadingCampaigns } = useGetCampaignsQuery(
        {
            client: workspace?.bqTable,
            keyword: formData.keywords,
            profile: formData.account,
            country: formData.country,
            platform: formData.platform_scraping,
            match_type: 'Exact'
        },
        {
            skip: formData.keywords.length === 0 || !formData.account
        }
    )

    const { data: campaignOptionsKoBidding, isLoading: isLoadingCampaignsKoBidding } = useGetCampaignsKoBiddingQuery(
        {
            client: workspace?.bqTable,
            profile: formDataKoBidding.account,
            platform: formDataKoBidding.platform
        },
        {
            skip: !formDataKoBidding.account
        }
    )

    const { data: biddingStrategyTypeKoBidding } = useGetBiddingStrategyTypeKoBiddingQuery(
        {
            client: workspace?.bqTable,
            profile: formDataKoBidding.account,
            campaign: formDataKoBidding.campaign_name,
            platform: formDataKoBidding.platform
        },
        {
            skip: !formDataKoBidding.account || !formDataKoBidding.campaign_name[0]
        }
    )

    const { data: maxCPCInfoKoBidding } = useGetMaxCPCInfoKoBiddingQuery({
        client: workspace?.bqTable,
        profile: formDataKoBidding.account,
        campaign: formDataKoBidding.campaign_name,
        platform: formDataKoBidding.platform,
        ad_group_name: formDataKoBidding.ad_group_name,
        keyword: formDataKoBidding.keyword_bid
    })

    const { data: biddingStrategyType } = useGetBiddingStrategyTypeQuery(
        {
            client: workspace?.bqTable,
            profile: formDataShopping.account,
            campaign: formDataShopping.campaign_name,
            platform: formDataShopping.platform
        },
        {
            skip: !formDataShopping.account || !formDataShopping.campaign_name
        }
    )

    const { data: adGroupOptionsKoBidding, isLoading: isLoadingAdGroupKoBidding } = useGetAdGroupKoBiddingQuery(
        {
            client: workspace?.bqTable,
            keyword: formDataKoBidding.keyword_bid,
            profile: formDataKoBidding.account,
            campaign: formDataKoBidding.campaign_name,
            match_type: formDataKoBidding.match_type,
            platform: formDataKoBidding.platform
        },
        {
            skip: !formDataKoBidding.account || !formDataKoBidding.campaign_name
        }
    )

    const { data: adGroupOptionsKoBiddingAuto, isLoading: isLoadingAdGroupKoBiddingAuto } = useGetAdGroupKoBiddingAutoQuery(
        {
            client: workspace?.bqTable,
            profile: formDataKoBidding.account,
            campaign: formDataKoBidding.campaign_name,
            platform: formDataKoBidding.platform
        },
        {
            skip: !formDataKoBidding.account || !formDataKoBidding.campaign_name
        }
    )

    const { data: adGroupOptionsKoBiddingTargetMaximize, isLoading: isLoadingAdGroupKoBiddingTargetMaximize } =
        useGetAdGroupKoBiddingTargetMaximizeQuery(
            {
                client: workspace?.bqTable,
                profile: formDataKoBidding.account,
                campaign: formDataKoBidding.campaign_name
            },
            {
                skip: !formDataKoBidding.account || !formDataKoBidding.campaign_name
            }
        )

    const { data: countryOptions, isLoading: isLoadingCountries } = useGetCountriesQuery({
        client: formData.client,
        keyword: formData.keywords,
        profile: formData.account
    })

    const { data: countryKoBiddingOptions, isLoading: isLoadingCountriesKoBidding } = useGetCountriesKoBiddingQuery({
        client: workspace?.bqTable,
        keyword: formDataKoBidding.keyword_bid,
        campaign: formDataKoBidding.campaign_name,
        profile: formDataKoBidding.account,
        match_type: formDataKoBidding.match_type
    })

    const { data: countryKoBiddingTargetMaximizeOptions } = useGetCountriesKoBiddingTargetMaximizeQuery({
        client: workspace?.bqTable,
        campaign: formDataKoBidding.campaign_name,
        profile: formDataKoBidding.account,
        ad_group_name: formDataKoBidding.ad_group_name
    })

    useEffect(() => {
        if (countryKoBiddingTargetMaximizeOptions) {
            setFormDataKoBidding((prevFormData: any) => ({
                ...prevFormData,
                country: countryKoBiddingTargetMaximizeOptions[0]?.country,
                country_code: countryKoBiddingTargetMaximizeOptions[0]?.country_code
            }))
        }
    }, [countryKoBiddingTargetMaximizeOptions, setFormDataKoBidding])

    const { data: countryShoppingOptions, isLoading: isLoadingCountriesShopping } = useGetCountriesShoppingQuery({
        client: workspace?.bqTable,
        campaign: formDataShopping.campaign_name,
        profile: formDataShopping.account,
        ad_group_name: formDataShopping.ad_group_name
    })

    useEffect(() => {
        if (countryShoppingOptions) {
            setFormDataShopping((prevFormData: any) => ({
                ...prevFormData,
                country: [countryShoppingOptions[0]?.country],
                country_code: [countryShoppingOptions[0]?.country_code]
            }))
        }
    }, [countryShoppingOptions, setFormDataShopping])

    const { data: keywordInfo } = useGetKeywordInfoKoBiddingQuery(
        {
            client: formDataKoBidding.client,
            keyword: formDataKoBidding.keyword_bid,
            campaign: formDataKoBidding.campaign_name,
            profile: formDataKoBidding.account,
            match_type: formDataKoBidding.match_type,
            ad_group_name: formDataKoBidding.ad_group_name
        },
        {
            skip:
                !formDataKoBidding.client ||
                !formDataKoBidding.keyword_bid ||
                !formDataKoBidding.campaign_name ||
                !formDataKoBidding.account ||
                !formDataKoBidding.match_type ||
                !formDataKoBidding.ad_group_name
        }
    )

    const { data: keywordInfoActiveMode } = useGetKeywordInfoActiveModeQuery(
        {
            client: formData.client,
            keyword: formData.keywords,
            campaign: formData.campaign_name[0],
            profile: formData.account,
            country: formData.country
        },
        {
            skip: !formData.client || !formData.keywords || !formData.account || !formData.campaign_name || !formData.country
        }
    )

    const { data: campaignOptionsShopping, isLoading: isLoadingCampaignsShopping } = useGetCampaignsShoppingQuery(
        {
            client: workspace?.bqTable,
            profile: formDataShopping.account,
            platform: formDataShopping.platform
        },
        {
            skip: !workspace?.bqTable || !formDataShopping.account.length
        }
    )

    const { data: profileOptionsShopping, isLoading: isLoadingProfileShopping } = useGetProfileShoppingQuery(
        {
            client: workspace?.bqTable,
            platform: formDataShopping.platform,
            requestTrackerShopping
        }
    )

    const { data: adGroupOptionsShopping, isLoading: isLoadingAdGroupsShopping } = useGetAdGroupShoppingQuery(
        {
            client: workspace?.bqTable,
            campaign: formDataShopping.campaign_name,
            profile: formDataShopping.account,
            platform: formDataShopping.platform
        },
        {
            skip: !formDataShopping.campaign_name.length || !formDataShopping.account.length
        }
    )

    const { data: campaignIdShopping, isLoading: isLoadingCampaignIdShopping } = useCampaignInfoShoppingQuery(
        {
            client: workspace?.bqTable,
            campaign: formDataShopping.campaign_name,
            profile: formDataShopping.account,
            ad_group_name: formDataShopping.ad_group_name
        },
        {
            skip: !formDataShopping.campaign_name.length || !formDataShopping.account.length || !formDataShopping.ad_group_name.length
        }
    )

    const { data: campaignIdKoBidding } = useCampaignInfoKoBiddingQuery(
        {
            client: workspace?.bqTable,
            keyword: formDataKoBidding.keyword_bid,
            campaign: formDataKoBidding.campaign_name,
            profile: formDataKoBidding.account,
            match_type: formDataKoBidding.match_type,
            ad_group_name: formDataKoBidding.ad_group_name
        },
        {
            skip: !formDataKoBidding.campaign_name.length || !formDataKoBidding.account.length || !formDataKoBidding.ad_group_name.length
        }
    )

    useEffect(() => {
        if (campaignIdKoBidding && campaignIdKoBidding.length !== 0) {
            setFormDataKoBidding((prevFormData: any) => ({
                ...prevFormData,
                campaign_id: [campaignIdKoBidding[0].campaign_id]
            }))
        }
    }, [campaignIdKoBidding, setFormDataKoBidding])

    const { data: campaignIdKoBiddingTargetMaximize } = useCampaignInfoKoBiddingTargetMaximizeQuery(
        {
            client: workspace?.bqTable,
            campaign: formDataKoBidding.campaign_name,
            profile: formDataKoBidding.account
        },
        {
            skip: !formDataKoBidding.campaign_name.length || !formDataKoBidding.account.length
        }
    )

    useEffect(() => {
        if (campaignIdKoBiddingTargetMaximize && campaignIdKoBiddingTargetMaximize.length !== 0) {
            setFormDataKoBidding((prevFormData: any) => ({
                ...prevFormData,
                campaign_id: [campaignIdKoBiddingTargetMaximize.campaign_id]
            }))
        }
    }, [campaignIdKoBiddingTargetMaximize, setFormDataKoBidding])

    const { data: profileIdShopping } = useProfileInfoShoppingQuery(
        {
            client: workspace?.bqTable,
            campaign: formDataShopping.campaign_name,
            profile: formDataShopping.account,
            ad_group_name: formDataShopping.ad_group_name
        },
        {
            skip: !formDataShopping.campaign_name.length || !formDataShopping.account.length || !formDataShopping.ad_group_name.length
        }
    )

    useEffect(() => {
        if (profileIdShopping) {
            setFormDataShopping((prevFormData: any) => ({
                ...prevFormData,
                account_id: profileIdShopping[0]?.profile_id
            }))
        }
    }, [profileIdShopping, setFormDataShopping])

    const { data: profileIdKoBidding } = useProfileInfoKoBiddingQuery(
        {
            client: workspace?.bqTable,
            keyword: formDataKoBidding.keyword_bid,
            campaign: formDataKoBidding.campaign_name,
            profile: formDataKoBidding.account,
            match_type: formDataKoBidding.match_type,
            ad_group_name: formDataKoBidding.ad_group_name,
            platform: formDataKoBidding.platform
        },
        {
            skip:
                !formDataKoBidding.campaign_name.length ||
                !formDataKoBidding.account.length ||
                !formDataKoBidding.ad_group_name.length
        }
    )

    useEffect(() => {
        if (profileIdKoBidding && profileIdKoBidding.length !== 0) {
            setFormDataKoBidding((prevFormData: any) => ({
                ...prevFormData,
                account_id: profileIdKoBidding[0].profile_id
            }))
        }
    }, [profileIdKoBidding, setFormDataKoBidding])

    const { data: profileIdKoBiddingTargetMaximize } = useProfileInfoKoBiddingTargetMaximizeQuery(
        {
            client: workspace?.bqTable,
            ad_group_name: formDataKoBidding.ad_group_name,
            campaign: formDataKoBidding.campaign_name,
            profile: formDataKoBidding.account,
            platform: formDataKoBidding.platform
        },
        {
            skip: !formDataKoBidding.campaign_name.length || !formDataKoBidding.account.length
        }
    )

    useEffect(() => {
        if (profileIdKoBiddingTargetMaximize) {
            setFormDataKoBidding((prevFormData: any) => ({
                ...prevFormData,
                account_id: profileIdKoBiddingTargetMaximize.profile_id
            }))
        }
    }, [profileIdKoBiddingTargetMaximize, setFormDataKoBidding])

    useEffect(() => {
        if (campaignIdShopping) {
            setFormDataShopping((prevFormData: any) => ({
                ...prevFormData,
                campaign_id: [campaignIdShopping[0].campaign_id]
            }))
        }
    }, [campaignIdShopping, setFormDataShopping])

    useEffect(() => {
        if (biddingStrategyType) {
            setFormDataShopping((prevFormData: any) => ({
                ...prevFormData,
                bidding_strategy_type: biddingStrategyType.bidding_strategy_type
            }))
        }
    }, [biddingStrategyType, setFormDataShopping])

    useEffect(() => {
        if (biddingStrategyTypeKoBidding && formDataKoBidding.account && formDataKoBidding.campaign_name) {
            setFormDataKoBidding((prevFormData: any) => ({
                ...prevFormData,
                bidding_strategy_type: biddingStrategyTypeKoBidding.bidding_strategy_type
            }))
        }
    }, [biddingStrategyTypeKoBidding, formDataKoBidding.account, formDataKoBidding.campaign_name, setFormDataKoBidding])

    useEffect(() => {
        if (
            maxCPCInfoKoBidding &&
            (formDataKoBidding.mode === 'KO Bidding Blended CTR' || formDataKoBidding.mode === 'KO Bidding Ad CTR')
        ) {
            setFormDataKoBidding((prevFormData: any) => ({
                ...prevFormData,
                initial_max_cpc: maxCPCInfoKoBidding[0]?.max_cpc,
                initial_avg_cpc: maxCPCInfoKoBidding[0]?.avg_cpc
            }))
        }
    }, [maxCPCInfoKoBidding, formDataKoBidding.mode, setFormDataKoBidding])

    useEffect(() => {
        if (keywordInfoActiveMode) {
            setFormData((prevFormDataActiveMode: any) => ({
                ...prevFormDataActiveMode,
                keyword_id: keywordInfoActiveMode[0]?.keyword_id || ''
            }))
        }
    }, [keywordInfoActiveMode, setFormData])

    useEffect(() => {
        if (keywordInfo) {
            setFormDataKoBidding((prevFormDataKoBidding: any) => ({
                ...prevFormDataKoBidding,
                keyword_id: keywordInfo[0]?.keyword_id || '',
                keyword_status: keywordInfo[0]?.keyword_status || ''
            }))
        }
    }, [keywordInfo, setFormDataKoBidding])

    useEffect(() => {
        if (countryKoBiddingOptions && countryKoBiddingOptions.length > 0) {
            const countryData = countryKoBiddingOptions[0]
            setFormDataKoBidding((prevFormDataKoBidding: any) => ({
                ...prevFormDataKoBidding,
                country: countryData.country || [],
                country_code: countryData.country_code || []
            }))
        }
    }, [countryKoBiddingOptions, setFormDataKoBidding])

    useEffect(() => {
        if (workspace) {
            setFormData({
                ...formData,
                domain: workspace.url,
                client: workspace.bqTable,
                workspaceId: workspace.id
            })
            setFormDataKoBidding({
                ...formDataKoBidding,
                domain: workspace.url,
                client: workspace.bqTable,
                workspaceId: workspace.id
            })
            setFormDataShopping({
                ...formDataShopping,
                domain: workspace.url,
                client: workspace.bqTable,
                workspaceId: workspace.id
            })
        }
    }, [workspace, refetchActiveMode, refetchKoBidding, refetchShopping])

    useEffect(() => {
        if (selection === 'Search' && subSelection === 'Ko Bidding') setKOBiddingMode('Manual')
    }, [selection, subSelection])

    const handleNext = () => {
        let hasError = false
        if (activeStep === 1) {
            if (selection === 'Shopping') {
                if (formDataShopping.campaign_name.length === 0) {
                    setCampaignShoppingError(true)
                    hasError = true
                }
                if (
                    formDataShopping.platform === 'Bing Ads' &&
                    ['Target ROAS', 'Maximize Conversion Value'].includes(formDataShopping.bidding_strategy_type) &&
                    parseFloat(formDataShopping.max_cpc_portfolio_strategy) < 0.05
                ) {
                    hasError = true
                }
                if (formDataShopping.account.length === 0) {
                    setProfileShoppingError(true)
                    hasError = true
                }
                if (!formDataShopping.platform) {
                    setPlatformShoppingError(true)
                    hasError = true
                }
                if (formDataShopping.platform !== 'Bing Ads' && !formDataShopping.ad_group_name) {
                    setAdGroupShoppingError(true)
                    hasError = true
                }
                if (hasError) return
            }
            if (subSelection === 'Active Mode' && selection !== 'Shopping') {
                if (formData.keywords.length === 0) {
                    setKeywordError(true)
                    hasError = true
                }
                if (!formData.country || formData.country.length === 0) {
                    setCountryError(true)
                    hasError = true
                }
                if (!formData.platform) {
                    setPlatformActiveModeError(true)
                    hasError = true
                }
                if (!formData.account) {
                    setProfileActiveModeError(true)
                    hasError = true
                }
                if (formData.campaign_name.length === 0) {
                    setCampaignError(true)
                    hasError = true
                }
                if (formData.device.length === 0) {
                    setDeviceError(true)
                    hasError = true
                }
                if (hasError) return
            } else if (subSelection === 'Ko Bidding' && selection !== 'Shopping') {
                if (!validateKoBiddingForm()) {
                    return
                }
            }
        }
        setActiveStep(prevStep => prevStep + 1)
    }

    const handleNextSkipReview = () => {
        let hasError = false
        if (activeStep === 1) {
            if (selection === 'Shopping') {
                if (formDataShopping.campaign_name.length === 0) {
                    setCampaignShoppingError(true)
                    hasError = true
                }
                if (formDataShopping.account.length === 0) {
                    setProfileShoppingError(true)
                    hasError = true
                }
                if (!formDataShopping.ad_group_name && formDataShopping.platform !== 'Bing Ads') {
                    setAdGroupShoppingError(true)
                    hasError = true
                }
                if (hasError) return
            }
            if (subSelection === 'Active Mode' && selection !== 'Shopping') {
                if (formData.keywords.length === 0) {
                    setKeywordError(true)
                    hasError = true
                }
                if (!formData.country || formData.country.length === 0) {
                    setCountryError(true)
                    hasError = true
                }
                if (!formData.account) {
                    setProfileActiveModeError(true)
                    hasError = true
                }
                if (formData.campaign_name.length === 0) {
                    setCampaignError(true)
                    hasError = true
                }
                if (formData.device.length === 0) {
                    setDeviceError(true)
                    hasError = true
                }
                if (hasError) return
            } else if (subSelection === 'Ko Bidding' && selection !== 'Shopping') {
                if (!validateKoBiddingForm()) {
                    return
                }
            }
        }
        if (selection === 'Shopping') {
            handleFinishShopping()
        } else if (subSelection === 'Active Mode') {
            handleFinishActiveMode()
        } else {
            handleFinishKoBidding()
        }
    }

    const handleBack = () => {
        setActiveStep(prevStep => prevStep - 1)
    }

    const isLastStep = activeStep === steps.length - 1

    const validateKoBiddingForm = () => {
        let hasError = false
        if (!formDataKoBidding.platform) {
            setPlatformError(true)
            hasError = true
        }
        if (
            formDataKoBidding.platform === 'Bing Ads' &&
            !['Target ROAS', 'Maximize Conversion Value', 'Maximize Conversions', 'Target CPA'].includes(
                formDataKoBidding.bidding_strategy_type
            ) &&
            parseFloat(formDataKoBidding.max_cpc) < 0.05
        ) {
            hasError = true
        }
        if (
            ['Maximize Conversions', 'Target CPA'].includes(formDataKoBidding.bidding_strategy_type) &&
            formDataKoBidding.platform !== 'Bing Ads'
        ) {
            if (!formDataKoBidding.target_cpa) {
                setErrorTargetCPA(true)
                hasError = true
            }
            if (formDataKoBidding.max_cpc && parseFloat(formDataKoBidding.max_cpc) < 0.05) {
                hasError = true
            }
        }
        if (
            formDataKoBidding.platform === 'Bing Ads' &&
            ['Target ROAS', 'Maximize Conversion Value'].includes(formDataKoBidding.bidding_strategy_type) &&
            parseFloat(formDataKoBidding.max_cpc_portfolio_strategy) < 0.05
        ) {
            hasError = true
        }
        if (
            !['Target ROAS', 'Maximize Conversion Value', 'Maximize Conversions', 'Target CPA', 'Target Impression Share'].includes(
                formDataKoBidding.bidding_strategy_type
            ) &&
            !formDataKoBidding.keyword_bid
        ) {
            setKeywordKoBiddingError(true)
            hasError = true
        }
        if (formDataKoBidding.campaign_name.length === 0) {
            setCampaignKoBiddingError(true)
            hasError = true
        }
        if (
            !['Target ROAS', 'Maximize Conversion Value', 'Maximize Conversions', 'Target CPA', 'Target Impression Share'].includes(
                formDataKoBidding.bidding_strategy_type
            ) &&
            !formDataKoBidding.account_id
        ) {
            hasError = true
        }
        if (
            !['Target ROAS', 'Maximize Conversion Value', 'Maximize Conversions', 'Target CPA', 'Target Impression Share'].includes(
                formDataKoBidding.bidding_strategy_type
            ) &&
            !formDataKoBidding.ad_group_id
        ) {
            hasError = true
        }
        if (
            !['Target ROAS', 'Maximize Conversion Value', 'Maximize Conversions', 'Target CPA', 'Target Impression Share'].includes(
                formDataKoBidding.bidding_strategy_type
            ) &&
            !formDataKoBidding.keyword_id
        ) {
            hasError = true
        }
        if (
            !['Maximize Conversions', 'Target CPA', 'Target Impression Share'].includes(
                formDataKoBidding.bidding_strategy_type
            ) &&
            !formDataKoBidding.ad_group_name
        ) {
            if (formDataKoBidding?.platform === "Google Ads") {
                setAdGroupKoBiddingError(true)
                hasError = true
            }
        }
        if (!formDataKoBidding.account) {
            setProfileError(true)
            hasError = true
        }

        // if (!formDataKoBidding.launching_date){
        //     setLaunchingDateError(true)
        //     hasError = true
        // }

        return !hasError
    }

    async function handleFinishActiveMode() {
        try {
            const response: any = await activeModeSearchExist({
                keywords: formData.keywords,
                country: formData.country,
                profile: formData.account,
                platform: formData.platform,
                workspaceId: workspace.id
            }).unwrap()
            const result = await addActiveMode(formData).unwrap()
            enqueueSnackbar('Bidding strategy added successfully!', { variant: 'success' })
            refetchActiveMode()
            triggerBO()
            onClose()
            setActiveStep(0)
            setFormData({
                keywords: [],
                country: [],
                account: '',
                frequency: '10 min',
                device_gads: ['desktop'],
                device: ['desktop'],
                campaign_name: [],
                campaign_id: [],
                home_language: '',
                platform: [],
                mode: 'Active Mode',
                country_code: [],
                client: workspace?.bqTable,
                domain: workspace?.url,
                workspaceId: workspace?.id,
                account_id: '',
                campaign_type: selection
            })
        } catch (error: any) {
            setSearchActiveModeDuplicate(error?.data)
            enqueueSnackbar('Failed to add bidding strategy!', { variant: 'error' })
        }
    }

    const handleKeywordChange = (value: any) => {
        setFormData({
            ...formData,
            keywords: value,
            campaign_name: [],
            campaign_id: []
        })
        if (value) {
            setKeywordError(false)
        }
    }

    const handleProfileChange = (value: any) => {
        setFormData({
            ...formData,
            account: value?.profile,
            account_id: value?.profile_id,
            campaign_name: [],
            campaign_id: []
        })
        if (value) {
            setProfileActiveModeError(false)
        }
    }

    const handlePlatformActiveModeChange = (value: any) => {
        let platformScrapingValue = ''
        if (value === 'Google Ads') {
            platformScrapingValue = 'Google'
        } else if (value === 'Bing Ads') {
            platformScrapingValue = 'Bing'
        }
        setFormData({
            ...formData,
            platform: value,
            platform_scraping: [platformScrapingValue]
        })
        if (value) {
            setPlatformActiveModeError(false)
        }
    }

    const handleDeviceGAdsChange = (deviceGads: string) => {
        let newDeviceGads = [...formData.device_gads]
        if (newDeviceGads.includes(deviceGads)) {
            newDeviceGads = newDeviceGads.filter(d => d !== deviceGads)
        } else {
            newDeviceGads.push(deviceGads)
        }
        let newDevice: string[] = []
        if (newDeviceGads.includes('desktop') && newDeviceGads.includes('mobile')) {
            newDevice = ['desktop', 'mobile']
        } else if (newDeviceGads.includes('desktop')) {
            newDevice = ['desktop']
        } else if (newDeviceGads.includes('mobile')) {
            newDevice = ['mobile']
        }
        setFormData({
            ...formData,
            device_gads: deviceGads,
            device: deviceGads
        })
        if (newDevice.length > 0) {
            setDeviceError(false)
        }
    }

    const handleDeviceChange = (device: any) => {
        setFormData({ ...formData, device })
        if (device.length > 0) {
            setDeviceError(false)
        }
    }

    const handleCountryChange = (event: any, newValue: any) => {
        setFormData({
            ...formData,
            country: [newValue?.country_name] || [],
            country_code: [newValue?.country_code] || [],
            home_language: newValue?.home_language || ''
        })
        if (newValue) {
            setCountryError(false)
        }
    }

    const handleCampaignChange = (event: any, newValue: any) => {
        if (newValue && newValue.length > 0) {
            const campaign_names = newValue.map((campaign: any) => campaign.campaign_name)
            const campaign_ids = newValue.map((campaign: any) => campaign.campaign_id)
            const account_id = newValue[0].profile_id
            setFormData({
                ...formData,
                campaign_name: campaign_names,
                campaign_id: campaign_ids,
                account_id
            })
            if (campaign_names.length > 0) {
                setCampaignError(false)
            }
        } else {
            setFormData({ ...formData, campaign_name: [], campaign_id: [], account_id: '' })
        }
    }

    const handlePlatformChange = (event: any, newValue: any) => {
        setFormDataKoBidding({
            ...formDataKoBidding,
            platform: newValue || ''
        })
        if (newValue) setPlatformError(false)
    }

    const handlePlatformShoppingChange = (event: any, newValue: any) => {
        setFormDataShopping({
            ...formDataShopping,
            platform: newValue || ''
        })
        if (newValue) setPlatformShoppingError(false)
    }

    const handleKeywordKoBiddingChange = (event: any, newValue: any) => {
        setFormDataKoBidding({
            ...formDataKoBidding,
            keyword_bid: newValue?.keyword_bid || '',
            keyword_id: newValue?.keyword_id || '',
            keyword_status: newValue?.keyword_status || '',
            match_type: ''
        })
        if (newValue) setKeywordKoBiddingError(false)
    }

    const handleMatchTypeChange = (event: any, newValue: any) => {
        setFormDataKoBidding({
            ...formDataKoBidding,
            match_type: newValue || ''
        })
        if (newValue) setMatchTypeKoBiddingErrorState(false)
    }

    const handleAccountKoBiddingChange = (event: any, newValue: any) => {
        setFormDataKoBidding({
            ...formDataKoBidding,
            account: newValue?.profile || '',
            account_id: newValue?.profile_id || ''
        })
        if (newValue) setProfileError(false)
    }

    function handleModeKoBiddingChange(event: any, newValue: any) {
        setFormDataKoBidding({
            ...formDataKoBidding,
            mode: newValue || ''
        })
        if (newValue) setModeKoBiddingError(false)
    }

    const handleCampaignKoBiddingChange = (newValue: any) => {
        setFormDataKoBidding((prevState: any) => ({
            ...prevState,
            campaign_name: newValue?.campaign_name || ''
        }))
        if (newValue) setCampaignKoBiddingError(false)
    }

    const handleProfileShoppingChange = (newValue: any) => {
        setFormDataShopping((prevFormData: any) => ({
            ...prevFormData,
            account: newValue?.profile || ''
        }))
        if (newValue) {
            setProfileShoppingError(false)
        }
    }

    const handleAdGroupKoBiddingChange = (newValue: any) => {
        setFormDataKoBidding({
            ...formDataKoBidding,
            ad_group_name: newValue.ad_group || '',
            ad_group_id: newValue.ad_group_id || ''
        })
        if (newValue) setAdGroupKoBiddingError(false)
    }

    const handleDecimalChange = (value: string) => {
        const regex = /^(0|[1-9]\d*)(\.\d{0,2})?$/
        if (regex.test(value) || value === '' || value === '0') {
            setFormDataKoBidding({
                ...formDataKoBidding,
                max_cpc: value
            })
        } else {
            setFormDataKoBidding({
                ...formDataKoBidding,
                max_cpc: ''
            })
        }
    }

    const handleDecimalPortFolioStratChange = (value: string) => {
        const regex = /^(0|[1-9]\d*)(\.\d{0,2})?$/
        if (regex.test(value) || value === '' || value === '0') {
            setFormDataKoBidding({
                ...formDataKoBidding,
                max_cpc_portfolio_strategy: value
            })
        } else {
            setFormDataKoBidding({
                ...formDataKoBidding,
                max_cpc_portfolio_strategy: ''
            })
        }
    }

    useEffect(() => {
        async function verifyCampaignTCPA() {
            if (activeStep === 2 && selection === 'Search' && formDataKoBidding.target_cpa) {
                try {
                    const { data } = await checkKoBiddingTcpaCampaignExists({
                        workspaceId: workspace?.id,
                        campaign: formDataKoBidding.campaign_name
                    }).unwrap()
                } catch (error: any) {
                    setShoppingMaxCPCDuplicate(error?.data)
                    setShoppingTargetROASDuplicate(error?.data)
                }
            }
        }
        verifyCampaignTCPA()
    }, [
        activeStep,
        selection,
        formDataKoBidding,
        workspace,
        checkKoBiddingTcpaCampaignExists,
        setShoppingMaxCPCDuplicate,
        setShoppingTargetROASDuplicate
    ])


    const handleFinishKoBidding = useCallback(async () => {
        try {
            if (formDataKoBidding.target_roas) {
                await koBiddingTcpaSearchExist({
                    profile: formDataKoBidding.account,
                    campaign: formDataKoBidding.campaign_name,
                    platform: formDataKoBidding.platform,
                    ad_group: formDataKoBidding.ad_group_name,
                    workspaceId: workspace.id
                }).unwrap()
            } else if (
                ['Target CPA', 'Maximize Conversions', 'Target Impression Share'].includes(
                    formDataKoBidding.bidding_strategy_type
                )
            ) {
                await koBiddingTcpaSearchExist({
                    profile: formDataKoBidding.account,
                    campaign: formDataKoBidding.campaign_name,
                    platform: formDataKoBidding.platform,
                    ad_group: formDataKoBidding.ad_group_name,
                    workspaceId: workspace.id
                }).unwrap()
            } else {
                await koBiddingSearchExist({
                    keywords: formDataKoBidding.keyword_bid,
                    profile: formDataKoBidding.account,
                    campaign: formDataKoBidding.campaign_name,
                    match_type: formDataKoBidding.match_type,
                    ad_group: formDataKoBidding.ad_group_name,
                    workspaceId: workspace.id
                }).unwrap()
            }


            const maxCPCPortfolioStrategy = shoppingMaxCPCDuplicate?.max_cpc_portfolio_strategie
            const targetROASPortfolioStrategy = shoppingTargetROASDuplicate?.target_roas
            const isBingAds = formDataKoBidding.platform === 'Bing Ads'

            const formDataWithFloatMaxCPC = {
                ...formDataKoBidding,
                target_roas: isBingAds
                    ? parseFloat(formDataKoBidding.target_roas)
                    : targetROASPortfolioStrategy
                        ? targetROASPortfolioStrategy * 100
                        : parseFloat(formDataKoBidding.target_roas),
                target_roas_portfolio_strategy: isBingAds
                    ? null
                    : targetROASPortfolioStrategy || parseFloat(formDataKoBidding.target_roas),
                max_cpc: formDataKoBidding.max_cpc_portfolio_strategy ? parseFloat(formDataKoBidding?.max_cpc_portfolio_strategy) : parseFloat(formDataKoBidding.max_cpc),
                max_cpc_portfolio_strategy: isBingAds
                    ? null
                    : maxCPCPortfolioStrategy
                        ? maxCPCPortfolioStrategy
                        : parseFloat(formDataKoBidding.max_cpc_portfolio_strategy),
                campaign_name: Array.isArray(formDataKoBidding.campaign_name)
                    ? formDataKoBidding.campaign_name
                    : [formDataKoBidding.campaign_name],
                target_cpa: shoppingMaxCPCDuplicate?.target_cpa
                    ? parseFloat(shoppingMaxCPCDuplicate?.target_cpa)
                    : formDataKoBidding.target_cpa
                        ? parseFloat(formDataKoBidding.target_cpa)
                        : formDataKoBidding.target_cpa,
                target_impression_share: formDataKoBidding.target_impression_share
                    ? parseInt(formDataKoBidding.target_impression_share, 10)
                    : formDataKoBidding.target_impression_share,
                max_cpc_target_reduction: formDataKoBidding.max_cpc_target_reduction
                    ? parseFloat(formDataKoBidding.max_cpc_target_reduction)
                    : formDataKoBidding.max_cpc_target_reduction,
                optimization_phase_duration: formDataKoBidding.optimization_phase_duration
                    ? parseInt(formDataKoBidding.optimization_phase_duration, 10)
                    : formDataKoBidding.optimization_phase_duration,
                accepted_variation_threshold: formDataKoBidding.accepted_variation_threshold
                    ? parseFloat(formDataKoBidding.accepted_variation_threshold)
                    : formDataKoBidding.accepted_variation_threshold,
                max_cpc_daily_reduction: formDataKoBidding.max_cpc_daily_reduction
                    ? parseFloat(formDataKoBidding.max_cpc_daily_reduction)
                    : formDataKoBidding.max_cpc_daily_reduction,
                target_max_cpc: formDataKoBidding.target_max_cpc
                    ? parseFloat(formDataKoBidding.target_max_cpc)
                    : formDataKoBidding.target_max_cpc
            }
            await addKoBidding(formDataWithFloatMaxCPC).unwrap()
            enqueueSnackbar('KO Bidding strategy added successfully!', { variant: 'success' })
            refetchKoBidding();
            refetchKoBiddingTargetMaximize();
            refetchKoBiddingCPA();
            refetchKoBiddingAuto();
            triggerBO()
            onClose()
            setActiveStep(0)
            setFormDataKoBidding({
                client: workspace?.bqTable,
                workspaceId: workspace?.id,
                status: true,
                mode: '',
                platform: '',
                account_id: '',
                campaign_type: selection,
                campaign_name: [],
                campaign_id: [],
                bidding_strategy_type: '',
                ad_group_name: '',
                ad_group_id: '',
                keyword_bid: '',
                domain: '',
                match_type: '',
                enforce_max_cpc: false,
                target_roas: null,
                target_max_cpc: null,
                initial_avg_cpc: null,
                initial_max_cpc: null,
                max_cpc_target_reduction: null,
                optimization_phase_duration: null,
                accepted_variation_threshold: null,
                max_cpc_daily_reduction: null,
                max_cpc_portfolio_strategy: null,
                target_impression_share_position: null,
                targetRoasVariation: false,
                launching_date: null,
                targetCpaVariation: null,
                name_portfolio_strategy: null,
                target_roas_portfolio_strategy: null,
                account_id_sa360: null,
                max_cpc: null,
                country: [],
                country_code: [],
                keyword_status: '',
                keyword_id: '',
                account: '',
                target_cpa: null,
                target_impression_share: null
            })
            setRequestTracker(Date.now())
        } catch (error: any) {
            setSearchKoBiddingDuplicate(error?.data)
            enqueueSnackbar('Failed to add KO Bidding strategy!', { variant: 'error' })
        }
    }, [
        requestTracker,
        formDataKoBidding,
        shoppingExist,
        koBiddingTcpaSearchExist,
        koBiddingSearchExist,
        checkKoBiddingTcpaCampaignExists,
        addKoBidding,
        enqueueSnackbar,
        refetchKoBidding,
        shoppingMaxCPCDuplicate,
        triggerBO,
        onClose,
        setFormDataKoBidding,
        setSearchKoBiddingDuplicate,
        workspace,
        setActiveStep,
        selection
    ])

    useEffect(() => {
        async function verifyShoppingCampaign() {
            if (activeStep === 2 && selection === 'Shopping') {
                try {
                    const { data } = await checkShoppingCampaignExists({
                        workspaceId: workspace?.id,
                        campaign: formDataShopping.campaign_name
                    }).unwrap()
                } catch (error: any) {
                    setShoppingMaxCPCDuplicate(error?.data)
                    setShoppingTargetROASDuplicate(error?.data)
                }
            }
        }
        verifyShoppingCampaign()
    }, [
        activeStep,
        selection,
        formDataShopping,
        workspace,
        checkShoppingCampaignExists,
        setShoppingMaxCPCDuplicate,
        setShoppingTargetROASDuplicate
    ])

    useEffect(() => {
        async function verifyShoppingCampaign() {
            if (activeStep === 2 && selection === 'Search' && formDataKoBidding.target_roas) {
                try {
                    const { data } = await checkKoBiddingCampaignExists({
                        workspaceId: workspace?.id,
                        campaign: formDataKoBidding.campaign_name
                    }).unwrap()
                } catch (error: any) {
                    setShoppingMaxCPCDuplicate(error?.data)
                    setShoppingTargetROASDuplicate(error?.data)
                }
            }
        }
        verifyShoppingCampaign()
    }, [
        activeStep,
        selection,
        formDataKoBidding,
        workspace,
        checkKoBiddingCampaignExists,
        setShoppingMaxCPCDuplicate,
        setShoppingTargetROASDuplicate
    ])

    async function handleFinishShopping() {
        try {
            const campaignNameArray = Array.isArray(formDataShopping.campaign_name)
                ? formDataShopping.campaign_name
                : [formDataShopping.campaign_name]
            await shoppingExist({
                profile: formDataShopping.account,
                campaign: formDataShopping.campaign_name,
                ad_group: formDataShopping.ad_group_name,
                workspaceId: workspace?.id,
                platform: formDataShopping.platform
            }).unwrap()
            const maxCPCPortfolioStrategy = shoppingMaxCPCDuplicate?.max_cpc_portfolio_strategie
            const targetROASPortfolioStrategy = shoppingTargetROASDuplicate?.target_roas
            const isBingAds = formDataShopping.platform === 'Bing Ads'
            const formDataWithFloatTargetRoas = {
                ...formDataShopping,
                campaign_name: campaignNameArray,
                target_roas: isBingAds
                    ? parseFloat(formDataShopping.target_roas)
                    : targetROASPortfolioStrategy
                        ? targetROASPortfolioStrategy * 100
                        : parseFloat(formDataShopping.target_roas),
                target_roas_portfolio_strategy: isBingAds
                    ? null
                    : targetROASPortfolioStrategy || parseFloat(formDataShopping.target_roas),
                max_cpc: isBingAds
                    ? parseFloat(formDataShopping.max_cpc_portfolio_strategy)
                    : undefined,
                max_cpc_portfolio_strategy: isBingAds
                    ? null
                    : maxCPCPortfolioStrategy
                        ? maxCPCPortfolioStrategy
                        : parseFloat(formDataShopping.max_cpc_portfolio_strategy),
                ad_group_name: isBingAds ? null : formDataShopping.ad_group_name,
                ad_group_id: isBingAds ? null : formDataShopping.ad_group_id,
                name_portfolio_strategy: isBingAds ? null : formDataShopping.name_portfolio_strategy,
                domain: workspace?.url,
                client: workspace?.bqTable,
                workspaceId: workspace?.id
            }
            await addShopping(formDataWithFloatTargetRoas).unwrap()
            enqueueSnackbar('Shopping strategy added successfully!', { variant: 'success' })
            triggerBO()
            onClose()
            setActiveStep(0)
            refetchShopping()
            setFormDataShopping({
                account: '',
                account_id: '',
                campaign_name: [],
                campaign_id: [],
                country: [],
                country_code: [],
                client: workspace?.bqTable,
                domain: workspace?.url,
                workspaceId: workspace?.id,
                ad_group_name: '',
                ad_group_id: '',
                mode: 'KO Bidding Manual',
                platform: '',
                campaign_type: selection,
                target_roas: 1,
                targetRoasVariation: false,
                status: true
            })
            setRequestTrackerShopping(Date.now())
        } catch (error: any) {
            setShoppingDuplicate(error?.data)
            enqueueSnackbar('Failed to add Shopping strategy!', { variant: 'error' })
        }
    }

    const handleCampaignShoppingChange = (newValue: any) => {
        setFormDataShopping((prevFormData: any) => ({
            ...prevFormData,
            campaign_name: newValue.campaign_name
        }))
        if (newValue) setCampaignShoppingError(false)
    }

    const handleAdGroupShoppingChange = (newValue: any) => {
        setFormDataShopping((prevFormData: any) => ({
            ...prevFormData,
            ad_group_name: newValue?.ad_group_name || '',
            ad_group_id: newValue?.ad_group_id || ''
        }))
        if (newValue) {
            setAdGroupShoppingError(false)
        }
    }

    const handleTargetRoasChange = (value: string) => {
        const regex = /^\d+(\.\d{0,2})?$/
        if (regex.test(value) || value === '') {
            const parsedValue = parseFloat(value)
            if (parsedValue >= 1 && parsedValue <= 5001) {
                setFormDataShopping({
                    ...formDataShopping,
                    target_roas: value === '' ? '' : value
                })
                setTargetRoasError(false)
            } else {
                setTargetRoasError(true)
            }
        } else {
            setTargetRoasError(true)
        }
    }

    const handleTargetRoasMaximizeChange = (value: string) => {
        const regex = /^\d+(\.\d{0,2})?$/
        if (regex.test(value) || value === '') {
            const parsedValue = parseFloat(value)
            if (parsedValue >= 1 && parsedValue <= 5001) {
                setFormDataKoBidding({
                    ...formDataKoBidding,
                    target_roas: value === '' ? '' : value
                })
                setTargetRoasError(false)
            } else {
                setTargetRoasError(true)
            }
        } else {
            setTargetRoasError(true)
        }
    }

    const handleMaxCPCMaximizePortfolioStartegy = (value: string) => {
        const regex = /^(0|[1-9]\d*)(\.\d{0,2})?$/
        if (regex.test(value) || value === '' || value === '0') {
            setFormDataKoBidding({
                ...formDataKoBidding,
                max_cpc_portfolio_strategy: value,
                target_roas_portfolio_strategy: formDataKoBidding.target_roas
            })
        }
    }

    const handleMaxCPCPortfolioStartegy = (value: string) => {
        const regex = /^(0|[1-9]\d*)(\.\d{0,2})?$/
        if (regex.test(value) || value === '' || value === '0') {
            setFormDataShopping({
                ...formDataShopping,
                max_cpc_portfolio_strategy: value,
                target_roas_portfolio_strategy: formDataShopping.target_roas
            })
        }
    }

    const handleAccountIdSa360Change = (value: any) => {
        setFormDataKoBidding({
            ...formDataKoBidding,
            account_id_sa360: value
        })
        if (value) {
            setAccountIdSa360Error(false)
        }
    }

    const handleClose = () => {
        onClose()
        setActiveStep(0)
        setSelection('Search')
        setSubSelection('Ko Bidding')
        setFormData({
            keywords: [],
            keyword_id: '',
            country: [],
            account: '',
            frequency: '10 min',
            device_gads: ['desktop'],
            device: ['desktop'],
            campaign_name: [],
            campaign_id: [],
            home_language: '',
            domain: '',
            mode: 'Active Mode',
            account_id: '',
            campaign_type: 'Search',
            client: '',
            workspaceId: null
        })
        setFormDataKoBidding({
            client: '',
            status: true,
            mode: '',
            platform: 'Google Ads',
            account_id: '',
            campaign_type: 'Search',
            campaign_name: [],
            campaign_id: [],
            bidding_strategy_type: '',
            ad_group_name: '',
            ad_group_id: '',
            keyword_bid: '',
            domain: '',
            match_type: '',
            enforce_max_cpc: false,
            target_roas: null,
            max_cpc_portfolio_strategy: null,
            name_portfolio_strategy: null,
            target_roas_portfolio_strategy: null,
            account_id_sa360: null,
            max_cpc: null,
            country: [],
            country_code: [],
            keyword_status: '',
            keyword_id: '',
            account: ''
        })
        setFormDataShopping({
            account: '',
            account_id: '',
            campaign_name: [],
            campaign_id: [],
            country: [],
            country_code: [],
            ad_group_name: '',
            ad_group_id: '',
            target_roas: 1,
            bidding_strategy_type: '',
            domain: '',
            mode: 'KO Bidding Manual',
            platform: 'Google Ads',
            campaign_type: 'Search',
            client: '',
            workspaceId: null,
            max_cpc_portfolio_strategy: null,
            name_portfolio_strategy: null,
            target_roas_portfolio_strategy: null,
            status: true
        })
        setKeywordError(false)
        setAccountIdSa360Error(false)
        setKeywordKoBiddingError(false)
        setCountryError(false)
        setPlatformError(false)
        setPlatformShoppingError(false)
        setModeKoBiddingError(false)
        setCampaignError(false)
        setAdGroupKoBiddingError(false)
        setCampaignKoBiddingError(false)
        setMatchTypeKoBiddingErrorState(false)
        setDeviceError(false)
        setProfileError(false)
        setProfileActiveModeError(false)
        setCampaignShoppingError(false)
        setProfileShoppingError(false)
        setAdGroupShoppingError(false)
        setTargetRoasError(false)
        setSearchActiveModeDuplicate(undefined)
        setSearchKoBiddingDuplicate(undefined)
        setShoppingDuplicate(undefined)
        setShoppingMaxCPCDuplicate(undefined)
        setShoppingTargetROASDuplicate(undefined)
    }

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>Add Bidding Strategy</DialogTitle>
            <DialogContent>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label: any) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <Box sx={{ mt: 4 }}>
                    {activeStep === 0 && (
                        <StepOneContent
                            selection={selection}
                            setSelection={setSelection}
                            subSelection={subSelection}
                            setSubSelection={setSubSelection}
                            koBiddingMode={koBiddingMode}
                            setKOBiddingMode={setKOBiddingMode}
                        />
                    )}
                    {activeStep === 1 && subSelection === 'Active Mode' && selection !== 'Shopping' && (
                        <StepTwoContentActiveMode
                            setFormData={setFormData}
                            isLoadingCampaigns={isLoadingCampaigns}
                            campaignOptions={campaignOptions}
                            profileOptions={profileOptions}
                            isLoadingProfile={isLoadingProfile}
                            keywordOptions={keywordOptions || []}
                            isLoadingKeywords={isLoadingKeywords}
                            handlePlatformActiveModeChange={handlePlatformActiveModeChange}
                            platformActiveModeError={platformActiveModeError}
                            isLoadingCountries={isLoadingCountries}
                            countriesOptions={countryOptions || []}
                            formData={formData}
                            handleProfileChange={handleProfileChange}
                            handleKeywordChange={handleKeywordChange}
                            handleCountryChange={handleCountryChange}
                            handleDeviceGAdsChange={handleDeviceGAdsChange}
                            handleDeviceChange={handleDeviceChange}
                            handleCampaignChange={handleCampaignChange}
                            keywordError={keywordError}
                            profileError={profileError}
                            countryError={countryError}
                            campaignError={campaignError}
                            deviceError={deviceError}
                            profileActiveModeError={profileActiveModeError}
                            subSelection={subSelection}
                            selection={selection}
                        />
                    )}
                    {activeStep === 1 && subSelection === 'Ko Bidding' && selection !== 'Shopping' && (
                        <StepTwoContentKoBidding
                            subSelection={subSelection}
                            workspace={workspace}
                            koBiddingMode={koBiddingMode}
                            selection={selection}
                            keywordKoBiddingAutoOptions={keywordKoBiddingAutoOptions || []}
                            adGroupOptionsKoBiddingAuto={adGroupOptionsKoBiddingAuto || []}
                            handleMaxCPCMaximizePortfolioStartegy={handleMaxCPCMaximizePortfolioStartegy}
                            targetRoasError={targetRoasError}
                            handleTargetRoasMaximizeChange={handleTargetRoasMaximizeChange}
                            adGroupOptionsKoBiddingTargetMaximize={adGroupOptionsKoBiddingTargetMaximize || []}
                            formDataKoBidding={formDataKoBidding}
                            launchingDateError={launchingDateError}
                            handleTargetImpressionShareChange={e => {
                                const newValue = e.target.value
                                setFormDataKoBidding((prevData: any) => ({
                                    ...prevData,
                                    target_impression_share: newValue
                                }))
                            }}
                            setErrorTargetCPA={setErrorTargetCPA}
                            setFormDataKoBidding={setFormDataKoBidding}
                            errorTargetCPA={errorTargetCPA}
                            handleTargetImpressionSharePositionChange={newValue => {
                                setFormDataKoBidding((prevData: any) => ({
                                    ...prevData,
                                    target_impression_share_position: newValue || ''
                                }))
                            }}
                            handlePlatformChange={handlePlatformChange}
                            handleKeywordKoBiddingChange={handleKeywordKoBiddingChange}
                            keywordKoBiddingOptions={keywordKoBiddingOptions || []}
                            isLoadingKoBiddingKeywords={isLoadingKoBiddingKeywords}
                            handleMatchTypeChange={handleMatchTypeChange}
                            matchTypeKoBiddingOptions={matchTypeKoBiddingOptions || []}
                            isLoadingMatchTypeKoBidding={isLoadingMatchTypeKoBidding}
                            handleModeKoBiddingChange={handleModeKoBiddingChange}
                            handleAccountKoBiddingChange={handleAccountKoBiddingChange}
                            profileKoBiddingOptions={profileKoBiddingOptions || []}
                            isLoadingProfileKoBidding={isLoadingProfileKoBidding}
                            campaignKoBiddingError={campaignKoBiddingError}
                            handleAccountIdSa360Change={handleAccountIdSa360Change}
                            profileError={profileError}
                            modeKoBiddingError={modeKoBiddingError}
                            adGroupKoBiddingError={adGroupKoBiddingError}
                            handleCampaignKoBiddingChange={handleCampaignKoBiddingChange}
                            accountIdSa360Error={accountIdSa360Error}
                            matchTypeKoBiddingErrorState={matchTypeKoBiddingErrorState}
                            campaignOptionsKoBidding={campaignOptionsKoBidding || []}
                            handleDecimalPortFolioStratChange={handleDecimalPortFolioStratChange}
                            keywordKoBiddingError={keywordKoBiddingError}
                            isLoadingCampaignsKoBidding={isLoadingCampaignsKoBidding}
                            isLoadingAdGroupKoBidding={isLoadingAdGroupKoBidding}
                            platformError={platformError}
                            handleAdGroupKoBiddingChange={handleAdGroupKoBiddingChange}
                            adGroupOptionsKoBidding={adGroupOptionsKoBidding || []}
                            handleDecimalChange={handleDecimalChange}
                        />
                    )}
                    {activeStep === 1 && selection === 'Shopping' && (
                        <StepTwoContentShopping
                            formDataShopping={formDataShopping}
                            handleCampaignShoppingChange={handleCampaignShoppingChange}
                            handlePlatformShoppingChange={handlePlatformShoppingChange}
                            platformShoppingError={platformShoppingError}
                            setFormDataShopping={setFormDataShopping}
                            campaignOptionsShopping={campaignOptionsShopping || []}
                            isLoadingCampaignsShopping={isLoadingCampaignsShopping}
                            campaignShoppingError={campaignShoppingError}
                            handleProfileShoppingChange={handleProfileShoppingChange}
                            isLoadingBiddingStrategyType={false}
                            biddingStrategyType={biddingStrategyType}
                            isLoadingProfileShopping={isLoadingProfileShopping}
                            profileShoppingError={profileShoppingError}
                            profileOptionsShopping={profileOptionsShopping || []}
                            adGroupOptionsShopping={adGroupOptionsShopping || []}
                            isLoadingAdGroupsShopping={isLoadingAdGroupsShopping}
                            handleAdGroupShoppingChange={handleAdGroupShoppingChange}
                            adGroupShoppingError={adGroupShoppingError}
                            targetRoasError={targetRoasError}
                            handleTargetRoasChange={handleTargetRoasChange}
                            handleMaxCPCPortfolioStartegy={handleMaxCPCPortfolioStartegy}
                        />
                    )}
                    {activeStep === 2 && selection === 'Shopping' && (
                        <StepThreeContentShopping
                            formDataShopping={formDataShopping}
                            shoppingDuplicate={shoppingDuplicate}
                            shoppingMaxCPCDuplicate={shoppingMaxCPCDuplicate}
                            shoppingTargetROASDuplicate={shoppingTargetROASDuplicate}
                        />
                    )}
                    {activeStep === 2 && subSelection === 'Active Mode' && selection !== 'Shopping' && (
                        <StepThreeContentActiveMode
                            workspace={workspace}
                            formData={formData}
                            searchActiveModeDuplicate={searchActiveModeDuplicate}
                        />
                    )}
                    {activeStep === 2 && subSelection === 'Ko Bidding' && selection !== 'Shopping' && (
                        <StepThreeContentKoBidding
                            searchKoBiddingDuplicate={searchKoBiddingDuplicate}
                            shoppingDuplicate={shoppingDuplicate}
                            formDataKoBidding={formDataKoBidding}
                            shoppingMaxCPCDuplicate={shoppingMaxCPCDuplicate}
                            shoppingTargetROASDuplicate={shoppingTargetROASDuplicate}
                        />
                    )}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="text" onClick={handleClose} sx={{ color: 'white' }}>
                    Cancel
                </Button>
                {activeStep !== 0 && (
                    <Button variant="contained" onClick={handleBack}>
                        Back
                    </Button>
                )}
                <Button
                    variant="contained"
                    onClick={
                        isLastStep
                            ? selection === 'Shopping'
                                ? handleFinishShopping
                                : subSelection === 'Active Mode'
                                    ? handleFinishActiveMode
                                    : handleFinishKoBidding
                            : handleNext
                    }
                >
                    {isLastStep ? 'Finish' : 'Next'}
                </Button>
                {activeStep !== 0 && (
                    <Button
                        variant="outlined"
                        onClick={handleNextSkipReview}
                        sx={{ color: 'white', border: '1px solid #323c48' }}
                    >
                        Skip Review
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    )
}
