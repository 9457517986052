export const ROOTS = {
  AUTH: '/account',
  DASHBOARD: '/dash',
  WORKSPACES: '/workspaces',
  ADMIN_USERS: '/admin/users',
  ADMIN_LIVE_MONITORING: '/admin/features/live-monitoring',
  ADMIN_LIVE_BIDDING: '/admin/features/live-bidding',
};

// ----------------------------------------------------------------------

export const paths = {
  profile: {
    root: ROOTS.AUTH,
  },
  // DASHBOARD
  dash: {
    root: ROOTS.DASHBOARD,
    //
    competitorsMonitoring: ROOTS.DASHBOARD + '/competitors-monitoring',
    competitorFocus: ROOTS.DASHBOARD + '/competitor-focus',
    textAds: ROOTS.DASHBOARD + '/text-ads',
    productListingAds: ROOTS.DASHBOARD + '/product-listing-ads',
    brandWayback: ROOTS.DASHBOARD + '/brand-wayback',
    campaignExplorer: ROOTS.DASHBOARD + '/campaign-explorer',
    //
    businessOverview: ROOTS.DASHBOARD + '/business-overview',
    brandOverview: ROOTS.DASHBOARD + '/brand-overview',
    //
    trafficAnalysis: ROOTS.DASHBOARD + '/traffic-analysis',
    trafficDetails: ROOTS.DASHBOARD + '/traffic-details',
    //
    creative: ROOTS.DASHBOARD + '/creative',
    landing: ROOTS.DASHBOARD + '/landing',
    video: ROOTS.DASHBOARD + '/videos',
    copy: ROOTS.DASHBOARD + '/copy',
    performance: ROOTS.DASHBOARD + '/performance/facebook',
    attribution: ROOTS.DASHBOARD + '/attribution',
    applications: {
      connections: {
        create: (type: string) => `/apps/${type}/connect`,
        app: (type: string) => `/apps/${type}`,
        root: `/apps`,
      },
    },
  },
  admin: {
    user: ROOTS.ADMIN_USERS,
    userAdd: `${ROOTS.ADMIN_USERS}/add`,
    liveMonitoring: ROOTS.ADMIN_LIVE_MONITORING,
    liveMonitoringAdd: `${ROOTS.ADMIN_LIVE_MONITORING}/add`,

    liveBiddingKeywordBased: `${ROOTS.ADMIN_LIVE_BIDDING}/search`,
    liveBiddingKeywordBasedActiveMode: `${ROOTS.ADMIN_LIVE_BIDDING}/search/active-mode`,

    liveBiddingKeywordBasedKoBidding: `${ROOTS.ADMIN_LIVE_BIDDING}/search/ko-bidding`,
    liveBiddingKeywordBasedKoBiddingMaxCPC: `${ROOTS.ADMIN_LIVE_BIDDING}/search/ko-bidding/max-cpc`,
    liveBiddingKeywordBasedKoBiddingTROAS: `${ROOTS.ADMIN_LIVE_BIDDING}/search/ko-bidding/troas`,
    liveBiddingKeywordBasedKoBiddingTCPA: `${ROOTS.ADMIN_LIVE_BIDDING}/search/ko-bidding/tcpa`,
    liveBiddingKeywordBasedKoBiddingTargetImpressionShare: `${ROOTS.ADMIN_LIVE_BIDDING}/search/ko-bidding/target-impression-share`,
    liveBiddingKeywordBasedKoBiddingAuto: `${ROOTS.ADMIN_LIVE_BIDDING}/search/ko-bidding/auto`,

    liveBiddingCampaignBased: `${ROOTS.ADMIN_LIVE_BIDDING}/campaign-based`,
    liveBiddingAdGroupBased: `${ROOTS.ADMIN_LIVE_BIDDING}/ad-group-based`,

    workspaces: ROOTS.WORKSPACES,
    workspacesAdd: `${ROOTS.WORKSPACES}/add`,

  },
  workspaces: {
    root: ROOTS.WORKSPACES,
    workspace: (id: number) => `${ROOTS.WORKSPACES}/${id}`,
  }
};
