import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  GridFilterForm, GridFilterItem, GridLogicOperator,
  useGridApiContext, useGridRootProps, GridFilterModel
} from '@mui/x-data-grid-pro';
import { Button, Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './module.css';

// Types pour les props du composant
interface FilterPanelCustomProps {
  onFilterChange?: (model: GridFilterModel) => void;
  initialState?: {
    items?: GridFilterItem[];
    logicOperator?: GridLogicOperator;
  };
  columnsSort?: 'asc' | 'desc';
  logicOperators?: GridLogicOperator[];
  getColumnForNewFilter?: (options: { currentFilters: GridFilterItem[], columns: any }) => string | undefined | null;
  filterFormProps?: any;
  disableAddFilterButton?: boolean;
  disableRemoveAllButton?: boolean;
  sx?: any;
}

// Étendre l'interface GridFilterItem pour inclure un opérateur logique par item
interface ExtendedFilterItem extends GridFilterItem {
  logicOperator?: GridLogicOperator;
  readOnly?: boolean;
}

export default function FilterPanelCustom({ 
  onFilterChange, 
  initialState, 
  columnsSort, 
  logicOperators = [GridLogicOperator.And, GridLogicOperator.Or],
  getColumnForNewFilter,
  filterFormProps,
  disableAddFilterButton = false,
  disableRemoveAllButton = false,
  sx,
  ...props 
}: FilterPanelCustomProps) {
  const apiRef = useGridApiContext();
  const rootProps = useGridRootProps();
  const { t } = useTranslation();
  const lastFilterRef = useRef<any>(null);
  
  // Récupérer les colonnes filtrables
  const filterableColumns = apiRef.current.getAllColumns().filter(col => col.filterable);
  const filterableColumnsLookup = filterableColumns.reduce((acc, col) => {
    acc[col.field] = col;
    return acc;
  }, {} as Record<string, any>);

  // Helper pour obtenir un filtre par défaut
  const getDefaultFilter = useCallback((): GridFilterItem | null => {
    let nextColumnWithOperator;
    
    if (getColumnForNewFilter && typeof getColumnForNewFilter === 'function') {
      const currentFilters = apiRef.current.state.filter.filterModel?.items || [];
      const nextFieldName = getColumnForNewFilter({
        currentFilters,
        columns: filterableColumns
      });
      
      if (nextFieldName === null) {
        return null;
      }
      
      nextColumnWithOperator = filterableColumns.find(({ field }) => field === nextFieldName);
    } else {
      nextColumnWithOperator = filterableColumns.find((colDef) => colDef.filterOperators?.length);
    }
    
    if (!nextColumnWithOperator) {
      return null;
    }
    
    return {
      field: nextColumnWithOperator.field,
      operator: nextColumnWithOperator.filterOperators?.[0]?.value || 'equals',
      id: Math.round(Math.random() * 1e5),
      value: null
    };
  }, [apiRef, filterableColumns, getColumnForNewFilter]);

  // Initialiser les filtres
  const initializeFilterItems = useCallback((): ExtendedFilterItem[] => {
    // Priorité 1: État initial depuis les props
    if ((initialState?.items?.length ?? 0) > 0) {
      return [...initialState?.items || []].map((item, index) => ({
        ...item,
        logicOperator: index > 0 ? initialState?.logicOperator || GridLogicOperator.And : undefined
      }));
    }
    
    // Priorité 2: État actuel du DataGrid
    const currentModel = apiRef.current.state.filter.filterModel?.items || [];
    if (currentModel.length > 0) {
      return [...currentModel].map((item, index) => ({
        ...item,
        logicOperator: index > 0 ? apiRef.current.state.filter.filterModel?.logicOperator || GridLogicOperator.And : undefined
      }));
    }
    
    // Priorité 3: Créer un filtre par défaut
    const defaultFilter = getDefaultFilter();
    if (!defaultFilter) {
      return [];
    }
    
    return [{
      ...defaultFilter,
      logicOperator: undefined
    }];
  }, [apiRef, initialState, getDefaultFilter]);
  

  // États locaux pour les filtres - utiliser useRef pour éviter les re-renders
  const [filterItems, setFilterItems] = useState<ExtendedFilterItem[]>(initializeFilterItems);
  const localFilterItemsRef = useRef<ExtendedFilterItem[]>(filterItems);
  
  // Mettre à jour la référence locale quand filterItems change
  useEffect(() => {
    localFilterItemsRef.current = filterItems;
  }, [filterItems]);
  
  // Séparer les filtres valides et en lecture seule
  const { readOnlyFilters, validFilters } = React.useMemo(() => {
    // Vérifier si la colonne est filtrable et active
    const isFilterableAndActive = (field: string) => {
      const column = filterableColumnsLookup[field];
      return column && column.filterable !== false;
    };

    return filterItems.reduce(
      (acc, item) => {
        // S'assurer que tous les filtres sont considérés comme valides
        // sauf s'ils sont explicitement marqués comme readOnly
        if (isFilterableAndActive(item.field) && !item.readOnly) {
          acc.validFilters.push(item);
        } else {
          acc.readOnlyFilters.push(item);
        }
        return acc;
      },
      { readOnlyFilters: [] as ExtendedFilterItem[], validFilters: [] as ExtendedFilterItem[] }
    );
  }, [filterItems, filterableColumnsLookup]);
  
  // Synchronisation avec les changements externes du modèle de filtres
  useEffect(() => {
    const subscription = apiRef.current.subscribeEvent('filterModelChange', (model) => {
      if (model?.items && JSON.stringify(model.items.map(item => ({ id: item.id, field: item.field, operator: item.operator, value: item.value }))) !== 
          JSON.stringify(filterItems.map(item => ({ id: item.id, field: item.field, operator: item.operator, value: item.value })))) {
        const newItems = model.items.map((item, index) => ({
          ...item,
          logicOperator: index > 0 ? model.logicOperator || GridLogicOperator.And : undefined
        }));
        setFilterItems(newItems);
        localFilterItemsRef.current = newItems;
      }
    });
    
    return () => subscription();
  }, [apiRef, filterItems]);

  // Focus sur le dernier filtre ajouté
  useEffect(() => {
    if (validFilters.length > 0 && lastFilterRef.current) {
      lastFilterRef.current.focus();
    }
  }, [validFilters.length]);

  // Callback pour mettre à jour un filtre localement sans déclencher de re-render
  const handleFilterChange = useCallback((updatedItem: ExtendedFilterItem) => {
    // Mettre à jour la référence locale sans déclencher de re-render
    localFilterItemsRef.current = localFilterItemsRef.current.map(item => 
      item.id === updatedItem.id ? { ...item, ...updatedItem } : item
    );
    
    // Mettre à jour l'état local pour que les composants enfants reçoivent les mises à jour
    setFilterItems(localFilterItemsRef.current);
  }, []);

  // Callback pour changer l'opérateur logique global
  const handleLogicOperatorChange = useCallback((operator: GridLogicOperator) => {
    // Mettre à jour localement
    localFilterItemsRef.current = localFilterItemsRef.current.map((item, index) => 
      index > 0 ? { ...item, logicOperator: operator } : item
    );
    
    // Mettre à jour l'état local pour que les composants enfants reçoivent les mises à jour
    setFilterItems(localFilterItemsRef.current);
    
    // Appliquer immédiatement le changement d'opérateur logique au DataGrid
    apiRef.current.setFilterModel({
      items: localFilterItemsRef.current,
      logicOperator: operator
    });
    
    // Remonter les changements au parent si nécessaire
    if (onFilterChange) {
      onFilterChange({ 
        items: localFilterItemsRef.current, 
        logicOperator: operator
      });
    }
  }, [apiRef, onFilterChange]);

  // Supprimer un filtre spécifique
  const handleDeleteFilter = useCallback((item: GridFilterItem) => {
    // Mettre à jour localement
    localFilterItemsRef.current = localFilterItemsRef.current.filter(f => f.id !== item.id);
    setFilterItems(localFilterItemsRef.current);
    
    // Appliquer immédiatement la suppression au modèle de filtres du DataGrid
    // pour éviter les problèmes avec les colonnes inactives
    const currentLogicOperator = localFilterItemsRef.current.length > 1 
      ? localFilterItemsRef.current[1].logicOperator || GridLogicOperator.And 
      : GridLogicOperator.And;
    
    apiRef.current.setFilterModel({
      items: localFilterItemsRef.current,
      logicOperator: currentLogicOperator
    });
    
    // Si tous les filtres sont supprimés, ajouter un filtre vide
    if (localFilterItemsRef.current.length === 0) {
      const newFilter = getDefaultFilter();
      if (newFilter) {
        localFilterItemsRef.current = [{
          ...newFilter,
          logicOperator: undefined
        }];
        setFilterItems(localFilterItemsRef.current);
      }
    }
  }, [apiRef, getDefaultFilter]);

  // Ajouter un nouveau filtre vide
  const handleAddFilter = useCallback(() => {
    const newFilter = getDefaultFilter();
    if (!newFilter) {
      return;
    }
    
    // Ajouter localement
    const newFilterWithLogic = {
      ...newFilter,
      logicOperator: GridLogicOperator.And
    };
    
    localFilterItemsRef.current = [...localFilterItemsRef.current, newFilterWithLogic];
    setFilterItems(localFilterItemsRef.current);
  }, [getDefaultFilter]);

  // Vider tous les filtres
  const handleRemoveAll = useCallback(() => {
    // Ne pas supprimer le premier filtre, mais le réinitialiser
    if (validFilters.length > 0) {
      const defaultFilter = getDefaultFilter();
      if (!defaultFilter) {
        return;
      }
      
      // Garder uniquement les filtres en lecture seule et ajouter un filtre vide
      localFilterItemsRef.current = [
        ...readOnlyFilters,
        {
          ...defaultFilter,
          logicOperator: undefined
        }
      ];
      
      setFilterItems(localFilterItemsRef.current);
      
      // Appliquer immédiatement les changements au modèle de filtres du DataGrid
      // pour éviter les problèmes avec les filtres grisés
      apiRef.current.setFilterModel({
        items: localFilterItemsRef.current,
        logicOperator: GridLogicOperator.And
      });
    }
  }, [apiRef, readOnlyFilters, validFilters, getDefaultFilter]);

  // Appliquer les filtres au DataGrid
  const handleApplyFilters = useCallback(() => {
    // Déterminer l'opérateur logique global à partir du deuxième élément
    const currentLogicOperator = localFilterItemsRef.current.length > 1 
      ? localFilterItemsRef.current[1].logicOperator || GridLogicOperator.And 
      : GridLogicOperator.And;
    
    // S'assurer que tous les filtres (sauf le premier) ont un opérateur logique défini
    const itemsWithLogicOperators = localFilterItemsRef.current.map((item, index) => {
      if (index === 0) return item; // Le premier élément n'a pas d'opérateur logique
      return {
        ...item,
        logicOperator: item.logicOperator || currentLogicOperator
      };
    });
    
    // Appliquer les filtres au DataGrid avec l'opérateur logique global
    apiRef.current.setFilterModel({
      items: itemsWithLogicOperators,
      logicOperator: currentLogicOperator
    });
    
    // Remonter les changements au parent si nécessaire
    if (onFilterChange) {
      onFilterChange({ 
        items: itemsWithLogicOperators, 
        logicOperator: currentLogicOperator
      });
    }
    
    // Fermer le panneau de filtres
    apiRef.current.hideFilterPanel?.();
  }, [apiRef, onFilterChange]);

  // Ajouter un gestionnaire d'événements pour les touches Entrée, + et -
  const handleKeyDown = useCallback((event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && !event.shiftKey && !event.ctrlKey && !event.altKey) {
      handleApplyFilters();
      event.preventDefault();
    } else if ((event.key === '+' || event.key === 'Add') && !disableAddFilterButton) {
      // La touche + du pavé numérique est identifiée comme 'Add' dans certains navigateurs
      handleAddFilter();
      event.preventDefault();
    } 
  }, [handleApplyFilters, handleAddFilter, handleDeleteFilter, disableAddFilterButton, validFilters]);

  const hasMultipleFilters = filterItems.length > 1;

  return (
    <Box 
      sx={{ 
        p: 1, 
        width: 600, 
        display: 'flex',
        flexDirection: 'column',
        minHeight: '130px',           // Hauteur minimale initiale
        maxHeight: 'calc(50vh - 80px)', // Hauteur maximale
        ...sx 
      }}
      onKeyDown={handleKeyDown}
      tabIndex={0}
    >
      {/* Conteneur principal avec défilement */}
      <Box sx={{ 
        flex: 1,
        overflowY: 'auto',
        overflowX: 'hidden',
        pb: 1 // Padding en bas pour éviter que le contenu ne touche les boutons
      }}>
        {readOnlyFilters.map((item, index) => (
          <GridFilterForm
            key={item.id ?? index}
            item={item}
            applyFilterChanges={handleFilterChange}
            deleteFilter={handleDeleteFilter}
            applyMultiFilterOperatorChanges={handleLogicOperatorChange}
            multiFilterOperator={item.logicOperator || GridLogicOperator.And}
            disableMultiFilterOperator={index === 0}
            hasMultipleFilters={hasMultipleFilters}
            showMultiFilterOperators={index > 0}
            logicOperators={logicOperators}
            columnsSort={columnsSort}
            readOnly
            {...filterFormProps}
          />
        ))}
        
        {validFilters.map((item, index) => (
          <GridFilterForm
            key={item.id ?? index + readOnlyFilters.length}
            item={item}
            applyFilterChanges={handleFilterChange}
            deleteFilter={handleDeleteFilter}
            applyMultiFilterOperatorChanges={handleLogicOperatorChange}
            multiFilterOperator={item.logicOperator || GridLogicOperator.And}
            disableMultiFilterOperator={readOnlyFilters.length + index !== 1}
            hasMultipleFilters={hasMultipleFilters}
            showMultiFilterOperators={readOnlyFilters.length + index > 0}
            logicOperators={logicOperators}
            columnsSort={columnsSort}
            focusElementRef={index === validFilters.length - 1 ? lastFilterRef : null}
            {...filterFormProps}
          />
        ))}
      </Box>

      {/* Barre de boutons fixe en bas */}
      <Stack 
        direction="row" 
        spacing={1} 
        justifyContent="space-between" 
        sx={{ 
          mt: 1, 
          px: 1,
          flexShrink: 0 // Empêche la barre de boutons de rétrécir
        }}
      >
        {!disableRemoveAllButton && validFilters.length > 0 ? (
          <Button
            onClick={handleRemoveAll}
            startIcon={rootProps.slots.filterPanelRemoveAllIcon ? <rootProps.slots.filterPanelRemoveAllIcon /> : null}
            {...(rootProps.slotProps?.baseButton || {})}
          >
            {t('dashboard.global.clear')}
          </Button>
        ) : <span />}
        
        <div>
          {!disableAddFilterButton ? (
            <Button
              onClick={handleAddFilter}
              startIcon={rootProps.slots.filterPanelAddIcon ? <rootProps.slots.filterPanelAddIcon /> : null}
              {...(rootProps.slotProps?.baseButton || {})}
              sx={{ mr: 1 }}
            >
              {t('dashboard.filters.add_filter')}
            </Button>
          ) : null}
          
          <Button
            variant="contained" 
            color="primary"
            onClick={handleApplyFilters}
          >
            {t('dashboard.filters.ok')}
          </Button>
        </div>
      </Stack>
    </Box>
  );
}
