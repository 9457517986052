import { useState, useCallback, useEffect } from 'react';
import {
  DataGridPro,
  GridColDef,
  GridColumnVisibilityModel,
} from '@mui/x-data-grid-pro';
import { Box, Button, LinearProgress, Stack, Typography } from '@mui/material';
import { fPercent } from 'src/utils/format-number';
import { useRouter } from 'src/routes/hooks';
import { useCurrentWorkspace } from 'src/context/reducers/app-settings';
import { useLocales } from 'src/locales';
import { useSelector } from 'react-redux';


const createColumns = (router: any, t: any, workspace: any, filterStore: any): GridColDef[] => {
  const baseColumns: GridColDef[] = [
    {
      field: 'Image',
      headerName: "",
      width: 70,
      renderCell: (params) => (
        <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
          <Box 
            component="img" 
            src={workspace.includes('Demo') ? '/assets/logo.png' : `https://www.google.com/s2/favicons?domain=${params?.row?.domain}&sz=64`} 
            sx={{ width: 25, height: 25 }} 
          />
        </Stack>
      ),
    },
    {
      field: 'domain',
      headerName: t('dashboard.global.domain'),
      flex: 1,
    },
  
  ];

  const basicPresenceColumn: GridColDef = {
    field: 'presenceRate',
      headerName: t('dashboard.global.presenceRate'),
      flex: 1,
      width: 160,
      renderCell: (params) => (
        <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
          <LinearProgress
            value={params.row.presenceRate || 0}
            variant="determinate"
            color={
              (Number.isNaN(params.row.presenceRate) && 'success') ||
              (params.row.presenceRate < 30 && 'success') ||
              (params.row.presenceRate >= 30 && params.row.presenceRate < 70 && 'warning') ||
              'error'
            }
            sx={{ width: 1, height: 6 }}
          />
          <Typography variant="caption" sx={{ width: 80 }}>
            {params.row.presenceRate ? fPercent(params.row.presenceRate) : '0%'}
          </Typography>
        </Stack>
      ),
  }

  const searchColumn: GridColDef = {
    field: 'presenceSearchRate',
    headerName: t('dashboard.global.presenceSearchRate'),
    flex: 1,
    width: 160,
    renderCell: (params) => (
      <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
        <LinearProgress
          value={params.row.presenceSearchRate || 0}
          variant="determinate"
          color={
            (Number.isNaN(params.row.presenceSearchRate) && 'success') ||
            (params.row.presenceSearchRate < 30 && 'success') ||
            (params.row.presenceSearchRate >= 30 && params.row.presenceSearchRate < 70 && 'warning') ||
            'error'
          }
          sx={{ width: 1, height: 6 }}
        />
        <Typography variant="caption" sx={{ width: 80 }}>
          {params.row.presenceSearchRate ? fPercent(params.row.presenceSearchRate) : '0%'}
        </Typography>
      </Stack>
    ),
  };

  const shoppingColumn: GridColDef = {
    field: 'presenceShoppingRate',
    headerName: t('dashboard.global.presenceShoppingRate'),
    flex: 1,
    width: 160,
    renderCell: (params) => (
      <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
        <LinearProgress
          value={params.row.presenceShoppingRate || 0}
          variant="determinate"
          color={
            (Number.isNaN(params.row.presenceShoppingRate) && 'success') ||
            (params.row.presenceShoppingRate < 30 && 'success') ||
            (params.row.presenceShoppingRate >= 30 && params.row.presenceShoppingRate < 70 && 'warning') ||
            'error'
          }
          sx={{ width: 1, height: 6 }}
        />
        <Typography variant="caption" sx={{ width: 80 }}>
          {params.row.presenceShoppingRate ? fPercent(params.row.presenceShoppingRate) : '0%'}
        </Typography>
      </Stack>
    ),
  };
  
    // Si filterStore est vide, on affiche toutes les colonnes
    if (!filterStore || filterStore?.campaignType.length === 0 || filterStore?.campaignType.length == 2) {
      return [...baseColumns,basicPresenceColumn, searchColumn, shoppingColumn];
    }

    // Si filterStore contient "search", on affiche uniquement la colonne de recherche
    else if (filterStore?.campaignType.includes("Search") &&  filterStore?.campaignType.length === 1) {
      return [...baseColumns, searchColumn];
    }

    // Si filterStore contient "shopping", on affiche uniquement la colonne shopping
    else if (filterStore?.campaignType.includes("Shopping") &&  filterStore?.campaignType.length === 1) {
      return [...baseColumns, shoppingColumn];
    }
 
  return baseColumns;
};

const createStandardColumns = (router: any, t: any, workspace:any,filterStore:any): GridColDef[] => [
  {
    field: 'Image',
    headerName: "",
    width: 70,
    renderCell: (params) => (
      <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
        <Box component="img" src={ workspace.includes('Demo') ? '/assets/logo.png' :   `https://www.google.com/s2/favicons?domain=${params?.row?.domain}&sz=64`} sx={{ width: 25, height: 25 }} />
      </Stack>
    ),
  },
  {
    field: 'domain',
    headerName: t('dashboard.global.domain'),
    flex: 1,
  },
  {
    field: 'presenceRate',
    headerName: t('dashboard.global.presenceRate'),
    flex: 1,
    width: 160,
    renderCell: (params) => (
      <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
        <LinearProgress
          value={params.row.presenceRate || 0}
          variant="determinate"
          color={
            (Number.isNaN(params.row.presenceRate) && 'success') ||
            (params.row.presenceRate < 30 && 'success') ||
            (params.row.presenceRate >= 30 && params.row.presenceRate < 70 && 'warning') ||
            'error'
          }
          sx={{ width: 1, height: 6 }}
        />
        <Typography variant="caption" sx={{ width: 80 }}>
          {params.row.presenceRate ? fPercent(params.row.presenceRate) : '0%'}
        </Typography>
      </Stack>
    ),
  },

  

  
];


  

type Props = {
  data: {
    id: string;
    role: string;
    email: string;
    profile: {
      firstname: string;
      lastname: string;
    }
  }[];
  pageSize: number;
};

export default function DataGridCustomPresence({ data, pageSize }: Props) {
  const router = useRouter();
  const workspace = useCurrentWorkspace();
  const workspaceId = workspace?.id || 1;
  const { t } = useLocales();
  const filterStore = useSelector((state: any) => state.filters);


  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    id: true,
  });

  const [paginationModel, setPaginationModel] = useState({
    pageSize: pageSize,
    page: 0,
  });

  const handleChangeColumnVisibilityModel = useCallback((newModel: GridColumnVisibilityModel) => {
    setColumnVisibilityModel(newModel);
  }, []);

  const handlePaginationModelChange = (newModel: any) => {
    setPaginationModel(newModel);
  };

  const handleRowClick = (params: any) => {
    router.push(`/dash/competitor/${params?.row?.domain}/?workspace=${workspaceId}`);
  };

  const NoRowsOverlay = () => (
    <Box sx={{ p: 2, textAlign: 'center' }}>
      <Typography variant="h6" color="textSecondary">
        No results found
      </Typography>
    </Box>
  );

  return (
    <Box
      sx={{
        '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus': {
          outline: 'none !important',
        },
        '& .MuiDataGrid-cell': {
          cursor: 'pointer',
        },
        '& .MuiDataGrid-root': {
          '--unstable_DataGrid-radius': '0px',
        },
        '& .css-jr9x4l-MuiTablePagination-displayedRows': {
          'display': 'none!important'
        },
        "& .css-lnsnn8-MuiDataGrid-rowCount": {
          display: 'none!important'
        },
        "& .css-n3104v-MuiToolbar-root-MuiTablePagination-toolbar .MuiTablePagination-actions": {
          display: 'none'
        }
      }}
    >
      <DataGridPro
        checkboxSelection={false}
        disableRowSelectionOnClick
        rows={data}
        pagination={true}
        paginationModel={paginationModel}
        columns={workspace?.template == 'E-commerce' ? createColumns(router, t,workspace?.name,filterStore): createStandardColumns(router, t,workspace?.name,filterStore)}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={handleChangeColumnVisibilityModel}
        onPaginationModelChange={handlePaginationModelChange}
        onRowClick={handleRowClick}
        slots={{
          noRowsOverlay: NoRowsOverlay,
        }}
      />
    </Box>
  );
}
