export interface AddBiddingStrategyModalProps {
    open: boolean
    onClose: () => void
  }
  
  export const steps = [
    'Step 1: Basic Info',
    'Step 2: Targeting',
    'Step 3: Review'
  ]
  