import { useEffect } from 'react'
import { Box, TextField, Chip, Autocomplete } from '@mui/material'
import { ComponentBlock } from 'src/sections/mui/component-block'

type StepTwoContentActiveModeProps = {
  setFormData: (data: any) => void
  formData: any
  profileOptions: any[]
  platformActiveModeError: boolean
  handlePlatformActiveModeChange: (value: any) => void
  isLoadingProfile: boolean
  campaignOptions: any[]
  profileActiveModeError: boolean
  isLoadingCampaigns: boolean
  isLoadingCountries: boolean
  countriesOptions: any[]
  handleKeywordChange: (value: any) => void
  keywordOptions: any[]
  isLoadingKeywords: boolean
  keywordError: boolean
  deviceError: boolean
  campaignError: boolean
  countryError: boolean
  profileError: boolean
  handleProfileChange: (value: any) => void
  handleCountryChange: (event: any, newValue: any) => void
  handleDeviceChange: (device: any) => void
  handleDeviceGAdsChange: (deviceGads: string) => void
  handleCampaignChange: (event: any, newValue: any) => void
  subSelection: string
  selection: string
}

export function StepTwoContentActiveMode({
  setFormData,
  formData,
  profileOptions,
  platformActiveModeError,
  handlePlatformActiveModeChange,
  isLoadingProfile,
  campaignOptions,
  profileActiveModeError,
  isLoadingCampaigns,
  isLoadingCountries,
  countriesOptions,
  handleKeywordChange,
  keywordOptions,
  isLoadingKeywords,
  keywordError,
  deviceError,
  campaignError,
  countryError,
  profileError,
  handleProfileChange,
  handleCountryChange,
  handleDeviceChange,
  handleDeviceGAdsChange,
  handleCampaignChange
}: StepTwoContentActiveModeProps) {
  const isKeywordSelected = formData.keywords.length > 0
  const isProfileSelected = formData.account.length > 0
  useEffect(() => {
    if (profileOptions && profileOptions.length === 1) {
      setFormData({
        ...formData,
        account: profileOptions[0].profile,
        account_id: profileOptions[0].profile_id
      })
    }
  }, [profileOptions, setFormData, formData])
  return (
    <Box>
      <ComponentBlock sx={{ mt: 3 }} title="Keyword Targeting">
        <Box sx={{ width: '100%', mt: 2 }}>
          <Autocomplete
            disableClearable
            multiple={false}
            loading={isLoadingKeywords}
            noOptionsText={keywordOptions ? 'Loading...' : 'No options'}
            options={isLoadingKeywords ? [] : keywordOptions || []}
            value={keywordOptions?.find(option => option === formData.keywords) || null}
            onChange={(event, newValue) => handleKeywordChange(newValue)}
            filterOptions={(options, { inputValue }) => {
              const lowerInputValue = inputValue.toLowerCase()
              const exactMatches = options.filter(option => option.toLowerCase() === lowerInputValue)
              const partialMatches = options.filter(
                option => option.toLowerCase().includes(lowerInputValue) && option.toLowerCase() !== lowerInputValue
              )
              return [...exactMatches, ...partialMatches]
            }}
            renderTags={(value: string[], getTagProps) =>
              value.map((option: string, index: number) => (
                <Chip variant="outlined" label={option} {...getTagProps({ index })} />
              ))
            }
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                label="Keyword (Exact Match)"
                placeholder="Enter keywords"
                error={keywordError}
                helperText={keywordError ? 'At least one keyword is required' : ''}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: keywordError ? 'red' : ''
                    }
                  }
                }}
              />
            )}
          />
        </Box>
        {!isLoadingProfile && profileOptions !== undefined && profileOptions.length !== 1 && (
          <Box sx={{ width: '100%', mt: 2 }}>
            <Autocomplete
              disableClearable
              disabled={!isKeywordSelected}
              loading={isLoadingProfile}
              noOptionsText={profileOptions ? 'Loading...' : 'No options'}
              multiple={false}
              options={isLoadingProfile ? [] : profileOptions || []}
              value={profileOptions.find(option => option.profile === formData.account)}
              getOptionLabel={option => option.profile}
              onChange={(event, newValue) => handleProfileChange(newValue)}
              renderTags={(value: string[], getTagProps) =>
                value.map((option: string, index: number) => (
                  <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                ))
              }
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Account"
                  error={profileActiveModeError}
                  helperText={profileActiveModeError ? 'At least one account is required' : ''}
                  placeholder="Enter account"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&.Mui-disabled': {
                        border: profileActiveModeError ? '1px solid red' : ''
                      },
                      '& fieldset': {
                        borderColor: profileActiveModeError ? 'red' : ''
                      }
                    }
                  }}
                />
              )}
            />
          </Box>
        )}
        <Box sx={{ width: '100%', mt: 2 }}>
          <Autocomplete
            disableClearable
            loading={isLoadingCountries}
            disabled={!isKeywordSelected}
            noOptionsText={countriesOptions ? 'Loading...' : 'No options'}
            options={isLoadingCountries ? [] : countriesOptions || []}
            getOptionLabel={option => option.country_name || ''}
            onChange={handleCountryChange}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                label="Country"
                placeholder="Select a country"
                error={countryError}
                helperText={countryError ? 'Country is required' : ''}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: countryError ? 'red' : ''
                    }
                  }
                }}
              />
            )}
          />
        </Box>
        <Box sx={{ width: '100%', mt: 2 }}>
          <Autocomplete
            disableClearable
            multiple={false}
            options={['Google Ads', 'Bing Ads']}
            onChange={(event, newValue) => handlePlatformActiveModeChange(newValue)}
            renderTags={(value: string[], getTagProps) =>
              value.map((option: string, index: number) => (
                <Chip variant="outlined" label={option} {...getTagProps({ index })} />
              ))
            }
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                label="Platform"
                placeholder="Enter platform"
                error={platformActiveModeError}
                helperText={platformActiveModeError ? 'At least one platform is required' : ''}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: platformActiveModeError ? 'red' : ''
                    }
                  }
                }}
              />
            )}
          />
        </Box>
        <Box sx={{ width: '100%', mt: 2 }}>
          <Autocomplete
            disableClearable
            multiple
            loading={isLoadingCampaigns}
            noOptionsText={campaignOptions ? 'Loading...' : 'No options'}
            disabled={!isKeywordSelected || !isProfileSelected || !formData.country || !formData.platform}
            options={isLoadingCampaigns ? [] : campaignOptions || []}
            getOptionLabel={option => option.campaign_name || ''}
            value={
              campaignOptions?.filter(option => formData.campaign_name.includes(option.campaign_name)) || []
            }
            onChange={handleCampaignChange}
            renderTags={(value: any[], getTagProps) =>
              value.map((option: any, index: number) => (
                <Chip label={option.campaign_name} {...getTagProps({ index })} />
              ))
            }
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                label="Campaign(s)"
                placeholder="Select a campaign"
                error={campaignError}
                helperText={campaignError ? 'At least one campaign is required' : ''}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-disabled': {
                      border: campaignError ? '1px solid red' : ''
                    },
                    '& fieldset': {
                      borderColor: campaignError ? 'red' : ''
                    }
                  }
                }}
              />
            )}
          />
        </Box>
      </ComponentBlock>
      <ComponentBlock sx={{ marginTop: 3, mb: 1 }} title="Device(s)">
        <Box sx={{ width: '100%' }}>
          <Autocomplete
            disableClearable
            multiple
            options={['desktop', 'mobile']}
            value={formData.device_gads}
            onChange={(event, newValue: any) => handleDeviceGAdsChange(newValue)}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                label="Scraping Device(s)"
                placeholder="Select devices"
                error={deviceError}
                helperText={deviceError ? 'At least one device is required' : ''}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: deviceError ? 'red' : ''
                    }
                  }
                }}
              />
            )}
          />
        </Box>
        <Box sx={{ width: '100%', mt: 2 }}>
          <Autocomplete
            disableClearable
            multiple
            options={['desktop', 'mobile']}
            value={formData.device}
            onChange={(event, newValue) => handleDeviceChange(newValue)}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                label="Scraping Active Mode"
                placeholder="Select devices"
                error={deviceError}
                helperText={deviceError ? 'At least one device is required' : ''}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: deviceError ? 'red' : ''
                    }
                  }
                }}
              />
            )}
          />
        </Box>
      </ComponentBlock>
    </Box>
  )
}
